import React, { useCallback, useEffect, useState } from "react";
import { useDropzone, Accept, FileRejection } from "react-dropzone";
import axios from "axios";
import config from "config";
import _ from "lodash";
import { classNames } from "utils";
import {
  DocumentIcon,
  DocumentPlusIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";

axios.defaults.baseURL = config.asset.uri;

interface UploadBoxProps {
  id: string;
  multiple?: boolean;
  maxFiles?: number;
  maxSize?: number;
  minSize?: number;
  accept?: Accept;
  disabled?: boolean;
  label?: string;
  values: any;
  setFieldValue: any;
  setFieldError: any;
  setFieldTouched: any;
  handleBlur: any;
  errors?: any;
  touched?: any;
  className?: string;
  required?: boolean;
  count?: number;
}

interface Upload extends File {
  path: any;
  preview: string;
}

const UploadBox: React.FC<UploadBoxProps> = ({
  multiple = false,
  maxFiles = 2,
  count = 0,
  maxSize = 1024 * 1024 * 5,
  minSize = 1,
  accept = {
    "pdf/*": [".pdf"],
    "doc/*": [".docx", ".doc", ".docm"],
    "excel/*": [".xlsx", ".xls"], // Added Excel files support
  },
  errors,
  values,
  setFieldValue,
  setFieldError,
  setFieldTouched,
  touched,
  id,
  label,
  required,
}) => {
  const [files, setFiles] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [showLoader, setShowLoader] = useState<boolean>(false);

  const oldFiles = _.get(values, id, []);

  const onDrop = useCallback(
    async (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
      setFieldTouched(id, true);

      if (!acceptedFiles.length) {
        const errorMessages = rejectedFiles
          .flatMap((rejection) => rejection.errors)
          .map((error) => _.startCase(error.code))
          .join(", ");

        setFieldError(id, errorMessages);
        return;
      }

      const newFiles = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );

      console.log("newFiles", files, newFiles);

      setFiles((prev) => (multiple ? [...prev, ...newFiles] : newFiles));

      setLoading(true);
      setShowLoader(true);

      try {
        const fileUploadPromises = acceptedFiles.map(async (file) => {
          const data = new FormData();
          data.append("file", file);

          const response = await axios.post("/attachFilesToOffer", data, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });

          if (response.data && response.data.length > 0) {
            const newValue = multiple
              ? [...oldFiles, response.data[0]]
              : [response.data[0], ...oldFiles];

            setFieldValue(id, newValue);
          }
        });

        await Promise.all(fileUploadPromises);
      } catch (error) {
        console.error("File upload error:", error);
      } finally {
        setLoading(false);
        setTimeout(() => setShowLoader(false), 3000);
      }
    },
    [id, multiple, oldFiles, setFieldError, setFieldTouched, setFieldValue]
  );

  const removeFile = async (index: number) => {
    const fileToRemove = values[id][index];

    try {
      await axios.post("/deleteFileFromOffer", {
        file_path: fileToRemove.file_path,
      });

      const newFiles = [...files];
      newFiles.splice(index, 1);
      setFiles(newFiles);

      const newValues = [...values[id]];
      newValues.splice(index, 1);
      setFieldValue(id, newValues);
    } catch (error) {
      console.error("File removal error:", error);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles,
    multiple,
    accept,
    maxSize,
    minSize,
    noClick: false,
    disabled: loading,
  });

  useEffect(() => {
    return () => {
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    };
  }, [files]);

  return (
    <>
      <label htmlFor={id} className="block text-sm font-medium text-gray-700">
        {label} {required && <span className="text-red-500">*</span>}
      </label>
      <div className="grid grid-cols-4 gap-3">
        <div
          {...getRootProps()}
          id={id}
          className={classNames(
            "border h-56 overflow-hidden mt-1 relative cursor-pointer bg-white p-2 rounded-md shadow-md"
          )}
        >
          <input {...getInputProps()} />
          {showLoader && (
            <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
              <div className="text-white">Uploading...</div>
            </div>
          )}
          {!multiple && files.length ? (
            <div className="relative h-full">
              <img
                className="object-cover object-top h-full w-full z-0"
                src={files[0].preview || _.get(values, id)?.[0]}
                alt="preview"
              />
              <div className="absolute inset-0 bg-slate-500/30 flex flex-col items-center justify-center">
                <DocumentPlusIcon className="h-10 w-10 m-auto text-gray-200" />
                <span className="text-gray-200">Click to upload file</span>
              </div>
            </div>
          ) : (
            <div className="h-full space-y-1">
              <div className="border flex-col border-dashed h-2/3 w-full flex rounded-md items-center justify-center">
                <DocumentPlusIcon className="h-10 w-10 mx-auto text-gray-400" />
              </div>
              <div className="h-1/3 bg-gray-100 rounded-md flex items-center justify-center">
                <span className="text-gray-400">Click to upload file</span>
              </div>
            </div>
          )}
        </div>
        {multiple &&
          files.map((file, index) => (
            <div
              key={index}
              className="col-span-1 relative h-56 p-1 rounded-md overflow-hidden"
            >
              {file.type.includes("image") ? (
                <img
                  src={file.preview}
                  alt="preview"
                  className="h-full w-full object-cover"
                />
              ) : (
                <>
                  <div className="h-full w-full bg-gray-200 flex items-center justify-center">
                    <DocumentIcon className="h-10 w-10 text-gray-400" />
                  </div>
                  <span className="text-gray-400">{file.file_name}</span>
                </>
              )}
              <div
                className="p-1 absolute flex text-white flex-row-reverse space-x-2 text-sm top-0 left-0 cursor-pointer bg-red-500"
                onClick={() => removeFile(index)}
              >
                <TrashIcon className="h-4 w-4 text-white mr-1" />
              </div>
            </div>
          ))}
      </div>
      {_.get(errors, id) && _.get(touched, id) ? (
        <p className="mt-2 text-sm text-red-600" id={`${id}-error`}>
          {_.get(errors, id)}
        </p>
      ) : null}
    </>
  );
};

export default UploadBox;
