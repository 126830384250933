import { message } from "antd";
import { ActionButton, Pie, TableComponent, TableLoader } from "components";
import { ToastAction } from "components/core/toast";
import {
  useRemoveParticipantFromParticipation,
} from "hooks/data/use-offers";
import { useToast } from "hooks/ui/use-toast";
import { useMatch, useNavigate } from "react-location";
import { LocationGenerics } from "router/location";
import AddParticipantsPercentageContainer from "./add-percentage";
import { useUrlState } from "utils";
import { Action } from "components/buttons/action-button";
import { Colors } from "_graphql/data";
import { useEndorsement } from "hooks/data/use-endorsement";
import numeral from "numeral";
import { GetEndorsement_singleEndorsement_offer_endorsement_participants } from "_graphql/queries/__generated__/GetEndorsement";
import { useAppLocation, useAppNavigate, useAppSearch } from "_graphql/cache/auth";
import RemoveContainer from "./remove-participants";

export default function OfferParticipation() {
  const [modal, setModal] = useUrlState("modal");
  const { toast } = useToast();
  const { params } = useAppLocation();
  const navigate = useAppNavigate();
  const searchParams = useAppSearch()
  const { loading, endorsement, refetch } = useEndorsement({
    offerEndorsementId: params.endorsement || "",
  });
  const dispatchAction =
    (
      id: string,
      action: Exclude<Action, "expand" | "goto" | "clone">,
      participation_id?: string
    ) =>
      () => {
        navigate({
          search: (old) => ({
            ...old,
            id,
            reinsurer: participation_id?.toString(),
            modal: action,
          }),
        });
      };
  const { removeParticipant } = useRemoveParticipantFromParticipation();

  const initiateRemove = (row: GetEndorsement_singleEndorsement_offer_endorsement_participants) => {
    message.loading({
      content: "Removing participant...",
      key: "removeParticipant",
    });
    removeParticipant({
      variables: {
        offer_id: params?.offer || "",
        offer_participating_id: row?.offer_end_participant_id || "",
        reinsurer_id: row?.reinsurer?.reinsurer_id || "",
      },
    }).then(({ data }) => {
      if (data?.removeParticipantByReinsurer) {
        message.success({
          content: "Participant removed successfully",
          key: "removeParticipant",
        });
        refetch();
      } else {
        message.error({
          content: "Could not remove participant",
          key: "removeParticipant",
        });
      }
    });
  };

  const participants = endorsement?.offer_endorsement_participants?.map((item, idx) => ({
    bgColor: "bg-[" + Colors[idx] + "]",
    color: Colors[idx],
    id: item?.reinsurer?.re_company_name || "",
    label: item?.reinsurer?.re_company_name || "",
    value: item?.offer_end_participant_percentage || 0,
  }) || []) || [];

  const sharedValue = endorsement?.offer_endorsement_participants?.reduce((acc, curr) => acc + (curr?.offer_end_participant_percentage || 0), 0) || 0;


  return (
    <div className="grid grid-cols-4 gap-6 items-start px-3 flex-1">
      <div className="col-span-3">
        <TableComponent
          title={"participants"}
          hasSearch
          loading={loading}
          columns={[
            {
              accessor: "reinsurer.re_company_name",
              name: "Company Name",
            },
            {
              accessor: "reinsurer.re_company_email",
              name: "Company Email",
            },
            {
              accessor: "offer_end_participant_percentage",
              name: "Participant %",
            },
            {
              accessor: "",
              name: "Actions",
              render: (row: GetEndorsement_singleEndorsement_offer_endorsement_participants) => (
                <>
                  {/* <span>{endorsement?.facultative_offer} {sharedValue} {numeral(endorsement?.facultative_offer || 0).subtract(sharedValue || 0).add(row?.offer_end_participant_percentage || 0).format("#,#.####")}</span> */}
                  <ActionButton
                    action={"assignPercentage"}
                    tooltip={
                      row?.offer_end_participant_percentage
                        ? "Update Percentage"
                        : "Add Percentage"
                    }
                    onClick={dispatchAction(
                      row?.offer_end_participant_id || "",
                      "assignPercentage",
                      numeral(endorsement?.facultative_offer || 0).subtract(sharedValue || 0).add(row?.offer_end_participant_percentage || 0).format("#,#.####")
                    )}
                  />
                  <ActionButton
                    action={"remove"}
                    tooltip={"Remove participant"}
                    onClick={dispatchAction(row?.offer_end_participant_id || "", "remove")}
                  />
                </>
              ),
            },
          ]}
          data={endorsement?.offer_endorsement_participants || []}
          renderLoader={() => <TableLoader items={[1, 1, 1, 1]} />}
          refetch={refetch}
        />
      </div>
      <div className="flex flex-col items-center justify-between ">
        <span className="text-left text-sm text-gray-300 font-bold">Percentage Left: {(endorsement?.facultative_offer || 100) - sharedValue}</span>
        <div className="w-60 h-80">
          <Pie data={[...participants, {
            label: "Unassigned",
            value: (endorsement?.facultative_offer || 100) - sharedValue,
            color: "#ccc",
            bgColor: "#ccc",
            id: "Unassigned",
          }]} innerRadius={0.1} />
        </div>

      </div>

      <AddParticipantsPercentageContainer
        open={modal === "assignPercentage"}
        setOpen={(val: boolean) =>
          setModal(val ? "assignPercentage" : undefined)
        }
      />

      {searchParams?.id && (
        <>
          <RemoveContainer
            open={modal === "remove"}
            setOpen={(val: boolean) =>
              setModal(val ? "remove" : undefined)
            }
          />
        </>
      )}
    </div>
  );
}
