import { useAppNavigate } from '_graphql/cache/auth';
import { } from '_graphql/queries/__generated__/GetOfferAssociates';
import { GetTreatyReinsurerAssociates_getTreatyReinsurerAssociates } from '_graphql/queries/__generated__/GetTreatyReinsurerAssociates';
import { ActionButton, Avatar, Show, TableComponent, TableLoader } from 'components';
import { Action } from 'components/buttons/action-button';
import { useTreaty, useTreatyReinsurerAssociates } from 'hooks/data/use-treaties';
import _ from 'lodash';
import { Link, useMatch, useSearch } from 'react-location';
import { LocationGenerics } from 'router/location';
import { classNames, useUrlState } from 'utils';
import RemoveReinsurerAssociateContainer from './remove-reinsurer-associate';

export default function TreatyReinsurersAssociates() {
    const [activeTab] = useUrlState("layer");
    const { params } = useMatch<LocationGenerics>();
    const navigate = useAppNavigate()
    const searchParams = useSearch<LocationGenerics>();
    const { loading, associates, refetch } = useTreatyReinsurerAssociates({
        treatyId: params.treaty || "",
    });

    const { treaty, loading: featchingTreaty } = useTreaty({
        treatyId: params.treaty || "",
    })

    const dispatchAction =
        (id: string, action: Exclude<Action, "expand" | "goto">) =>
            () => {
                navigate({
                    search: (old) => ({
                        ...old,
                        id,
                        modal: action,
                    }),
                });
            };

    return (
        <div className=' flex flex-col flex-1'>
            <Show if={searchParams?.treaty_type === "NONPROPORTIONAL"}>
                <div className="block">
                    <div className="border-b border-shade-500 bg-card-500 px-6">
                        <nav className="-mb-px flex space-x-8 items-center" aria-label="Tabs">
                            {JSON.parse(treaty?.layer_limit || "[]").map((_activeTab: any, index: number) => (
                                <Link
                                    key={_activeTab.name}
                                    search={(old) => ({
                                        ...old,
                                        layer: (index + 1).toString(),
                                    })}
                                    className={classNames(
                                        activeTab === index + 1
                                            ? "border-gray-200 text-gray-300"
                                            : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200",
                                        "whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm"
                                    )}
                                    aria-current={
                                        activeTab === index + 1 ? "page" : undefined
                                    }
                                >
                                    Layer {index + 1}
                                </Link>
                            ))}
                            {/* <div className="h-6 w-px bg-gray-300" /> */}

                        </nav>
                    </div>
                </div>
            </Show>
            <div className="grid grid-cols-3 sm:py-6 items-start px-3 flex-1">
                <div className='col-span-3'>
                    <TableComponent
                        title={'associates'}
                        hasSearch
                        loading={loading || featchingTreaty}
                        columns={[
                            {
                                accessor: "reinsurer_representative.reinsurer.re_company_name",
                                name: "Name",
                                render: (row: GetTreatyReinsurerAssociates_getTreatyReinsurerAssociates) => (
                                    <div className='flex space-x-2 items-center'>
                                        <Avatar alt={[row?.reinsurer_representative?.rep_first_name || "", row?.reinsurer_representative?.rep_last_name || "",].join(" ")} />
                                        <div className='flex flex-col'>
                                            <span className='font-bold text-xs'>{row?.reinsurer_representative?.rep_first_name} {row?.reinsurer_representative?.rep_last_name}</span>
                                        </div>
                                    </div>
                                )
                            },
                            {
                                accessor: "reinsurer_representative.rep_first_name",
                                name: "Email",
                                render: (row: GetTreatyReinsurerAssociates_getTreatyReinsurerAssociates) => (
                                    <div className='flex flex-col'>
                                        <span className='font-bold text-xs'>{row?.reinsurer_representative?.rep_email}</span>
                                    </div>
                                )
                            },
                            {
                                accessor: "",
                                name: "Action",
                                render: (row: GetTreatyReinsurerAssociates_getTreatyReinsurerAssociates) => (
                                    <>
                                        <ActionButton action={'remove-associate'} tooltip={"Remove associate"} onClick={dispatchAction(row?.treaty_to_associate_id || "", "remove-associate")} />
                                    </>
                                )
                            }
                        ]}
                        // data={associates}
                        data={searchParams.treaty_type === "PROPORTIONAL" ? associates : _.get(_.groupBy(associates, "layer_number"), parseInt(searchParams.layer || "1"), [])}
                        renderLoader={() => <TableLoader items={[1, 1, 1, 1]} />}
                        refetch={refetch} />
                </div>
            </div>

            {searchParams?.id && <RemoveReinsurerAssociateContainer
                open={searchParams?.modal === "remove-associate"}
                setOpen={(val: boolean) => navigate({
                    search(prev) {
                        return {
                            ...prev,
                            modal: val ? "remove-associate" : undefined,
                            id: val ? prev?.id : undefined
                        }
                    }
                })}
            />}
        </div>

    )
}
