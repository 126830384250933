import { gql } from "@apollo/client";

export const getReInsurers = gql`
  query Reinsurers {
    count: getReinsurersCount
    rows: reinsurers {
      re_abbrv
      re_company_email
      re_company_name
      re_company_website
      reinsurer_address {
        country
        region
        reinsurer_address_id
        street
        suburb
      }
      reinsurer_id
      reinsurer_representatives {
        reinsurer_representative_id
      }
    }
  }
`;

export const getReinsurerOfferPayments = gql`
  query GetReinsurerParticipantPayments($offerParticipantId: ID) {
    getReinsurerParticipantPayments(offer_participant_id: $offerParticipantId) {
      offer_participant_payment_id
      offer_payment_amount
      paid_details
      offer_participant_payment_comment
      payment_status
      created_at
      updated_at
      offer_deduction_charge {
        offer_deduction_payment_id
        nic_levy_paid
        withholding_tax_paid
        brokerage_amount_paid
        commission_taken
      }
    }
  }
`;
export const getReinsurerOfferPayment = gql`
  query GetReinsurerParticipantPayment($participant_payment_id: ID) {
    getReinsurerParticipantPayment(participant_payment_id: $participant_payment_id) {
      offer_participant_payment_id
      offer_payment_amount
      paid_details
      offer_participant_payment_comment
      payment_status
      created_at
      updated_at
      offer_deduction_charge {
        offer_deduction_payment_id
        nic_levy_paid
        withholding_tax_paid
        brokerage_amount_paid
        commission_taken
      }
    }
  }
`;

export const getReinsurerAssociates = gql`
  query GetReinsurerAssociates($reinsurerId: ID) {
    getReinsurerAssociates(reinsurer_id: $reinsurerId) {
      reinsurer_representative_id
      reinsurersreinsurer_id
      rep_first_name
      rep_last_name
      rep_primary_phonenumber
      rep_secondary_phonenumber
      rep_email
      position
      reinsurer {
        re_abbrv
        re_company_email
        re_company_name
        re_company_website
        reinsurer_id
      }
    }
  }
`;

export const getReInsurerRep = gql`
  query GetReinsurerRep($repId: ID) {
    getReinsurerRep(rep_id: $repId) {
      reinsurer_representative_id
      reinsurersreinsurer_id
      rep_first_name
      rep_last_name
      rep_primary_phonenumber
      rep_secondary_phonenumber
      rep_email
      position
    }
  }
`;

export const getReinsurerReps = gql`
  query GetReinsurerReps {
    getReinsurerReps {
      reinsurer_representative_id
      reinsurersreinsurer_id
      rep_first_name
      rep_last_name
      rep_primary_phonenumber
      rep_secondary_phonenumber
      rep_email
      position
      reinsurer {
        re_abbrv
        re_company_email
        re_company_name
      }
    }
  }
`;

export const getReInsurer = gql`
  query Reinsurer($reinsurerId: ID!) {
    reinsurer(reinsurer_id: $reinsurerId) {
      reinsurer_id
      re_company_name
      re_company_email
      re_company_website
      re_abbrv
      reinsurer_address {
        reinsurer_address_id
        street
        suburb
        region
        country
      }
      reinsurer_overview {
        brokerage_chart
        total_brokerage_amt
        total_closed
        total_fac_premium
        total_nic_tax
        total_paid
        total_pending
        total_unpaid
        total_withholding_tax
      }
    }
  }
`;
