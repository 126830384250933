import { wrapClick } from "utils";
import { useMutation } from "@apollo/client";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { Modal } from "components";
import * as Yup from "yup";
import { BrokerRepForm } from "components/forms";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { useEffect } from "react";
import { updateReBrokerAssociates } from "_graphql/mutation/insurer";
import { useBrokerAssociate } from "hooks/data/use-brokers";
import { useAppLocation } from "_graphql/cache/auth";
import { UpdateReBrokerAssociates, UpdateReBrokerAssociatesVariables } from "_graphql/mutation/__generated__/UpdateReBrokerAssociates";

export const validationSchema = Yup.object().shape({
    reBrokerAssociate: Yup.object().shape({
        re_broker_assoc_first_name: Yup.string().required("First name is required"),
        re_broker_assoc_last_name: Yup.string().required("Last name is required"),
        re_broker_assoc_primary_phone: Yup.string().required("Phone number is required"),
        re_broker_assoc_secondary_phone: Yup.string().notRequired(),
        re_broker_assoc_email: Yup.string().email("Requires a valid email address").required("Email is required"),
        re_broker_assoc_position: Yup.string().required("Position is required"),
    })
});

export default function UpdateContainer({
    open,
    setOpen,
    refetch,
}: {
    open: boolean;
    setOpen: (val: boolean) => void;
    refetch?: () => void;
}) {
    const { params } = useAppLocation()
    const searchParams = useSearch<LocationGenerics>();
    const { associate, loading: fetching } = useBrokerAssociate({
        brokerAssociateId: searchParams.id as string,
    });
    const [create, { loading }] = useMutation<
        UpdateReBrokerAssociates,
        UpdateReBrokerAssociatesVariables
    >(updateReBrokerAssociates, {
        refetchQueries: ["GetBrokerAssociates", "GetBrokerAssociate"],
    });

    const form = useFormik<UpdateReBrokerAssociatesVariables>({
        initialValues: {
            reBrokerAssociate: {
                re_brokersre_broker_id: params?.broker || "",
                re_broker_assoc_position: "",
                re_broker_assoc_email: "",
                re_broker_assoc_first_name: "",
                re_broker_assoc_last_name: "",
                re_broker_assoc_primary_phone: "",
                re_broker_assoc_secondary_phone: "",
            },
            reBrokerAssocId: searchParams?.id || "",
        },
        validationSchema: validationSchema,
        onSubmit: async (variables) => {
            await create({
                variables: variables,
            }).then(({ data }) => {
                if (data?.updateReBrokerAssociates) {
                    toast.success("Reinsurance Broker Representative updated successfully");

                    refetch?.();
                    form.resetForm();
                } else {
                    toast.error("Failed to update Reinsurance Broker Representative");
                }
            });
        },
        onReset: () => {
            setOpen(false);
        },
    });

    useEffect(() => {
        if (associate) {
            form.setValues({
                reBrokerAssociate: {
                    re_brokersre_broker_id: params?.broker || "",
                    re_broker_assoc_position: associate.re_broker_assoc_position,
                    re_broker_assoc_email: associate.re_broker_assoc_email,
                    re_broker_assoc_first_name: associate.re_broker_assoc_first_name,
                    re_broker_assoc_last_name: associate.re_broker_assoc_last_name,
                    re_broker_assoc_primary_phone: associate.re_broker_assoc_primary_phone,
                    re_broker_assoc_secondary_phone: associate.re_broker_assoc_secondary_phone,
                },
                reBrokerAssocId: searchParams?.id || "",
            })
        }
    }, [associate])

    return (
        <Modal
            open={open}
            setOpen={setOpen}
            loading={fetching}
            title="Update Reinsurance Broker Representative"
            description="Provide the details to update a reinsurance broker representative"
            renderActions={() => (
                <>
                    <button
                        type="button"
                        disabled={loading}
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={wrapClick(form.handleSubmit)}
                    >
                        {loading ? "Updating Reinsurance Broker Representative..." : "Update Reinsurance Broker Representative"}
                    </button>
                </>
            )}
        >
            <BrokerRepForm form={form} />
        </Modal>
    );
}
