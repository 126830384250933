import { useInsurers } from "hooks/data/use-insurers";
import _ from "lodash";
import { FC, useMemo, useState } from "react";
import Select from "react-select";
import { classNames } from "utils";

interface SearchSelectInputProps {
  id: string;
  label: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  labelHidden?: boolean;
  values: any;
  errors?: any;
  touched?: any;
  optionsLoading?: boolean;
  setFieldValue: any;
  setFieldTouched: any;
  setFieldError: any;
  rounded?: boolean;
  rawId?: boolean;
  isMulti?: boolean;
  addAllOption?: boolean;
}

const SearchSelectInput: FC<SearchSelectInputProps> = ({
  id,
  values,
  setFieldValue,
  setFieldTouched,
  placeholder,
  label,
  errors,
  touched,
  required,
  labelHidden,
  disabled,
  isMulti,
  rawId,
  addAllOption,
}) => {
  const { insurers, loading } = useInsurers({
    page: 1,
    pageSize: 500,
    search: "",
    searchFields: ["code", "batchCode"],
    sort: "",
  });
  const [query, setQuery] = useState("");
  const options = useMemo(
    () => [
      ...(addAllOption ? [{ label: "All", value: " " }] : []),
      ...insurers.map(({ insurer_company_name, insurer_id, ...rest }) => ({
        label: insurer_company_name,
        value: rawId
          ? insurer_id
          : { insurer_company_name, insurer_id, ...rest },
      })),
    ],
    [insurers]
  );

  const filteredOptions =
    query === ""
      ? options
      : options.filter((option) => {
        return option.label.toLowerCase().includes(query.toLowerCase());
      });

  const comparaytor =
    typeof values[id] === "string" ? values[id] : values[id]?.value || values[id]?.insurer_id || values[id]?.value?.insurer_id;
  const getRawValue = () => {
    const item = options.find((o) => {
      // console.log("O", values[id]?.insurer_id, o.value);
      return typeof o.value === "string"
        ? o.value === comparaytor
        : o.value?.insurer_id === comparaytor;
    }
    );
    // console.log("ITEM", item);
    return item;
  }
  const selectedValue = isMulti
    ? (values[id] || []).map((val: any) => {
      // console.log("VAL", val);
      return options.find((o) => {
        // console.log("O", o);
        return typeof o.value === "string"
          ? o.value === val
          : o.value === val;
      });
    })
    : getRawValue();



  // console.log("SELECTED VALUE", isMulti, selectedValue);

  const handleChange = (selectedOptions: any): void => {
    // console.log("Selected OPTIONS", selectedOptions)
    if (isMulti && Array.isArray(selectedOptions)) {
      const selectedValues = selectedOptions.map((option) => option.value);
      setFieldTouched(id, true);
      setFieldValue(id, selectedValues);
    } else {
      const selectedValue = selectedOptions ? selectedOptions.value : null;
      setFieldTouched(id, true);
      setFieldValue(id, selectedValue);
    }
  };

  return (
    <div>
      {!labelHidden && (
        <label className="block text-sm font-medium text-gray-700">
          {label} {required && <span className="text-red-500">*</span>}
        </label>
      )}
      <Select
        options={[...filteredOptions]}
        value={selectedValue}
        onChange={handleChange}
        isLoading={loading}
        placeholder={placeholder}
        isDisabled={disabled}
        isMulti={isMulti}
        className={classNames(
          _.get(values, id, "") === "" ? "font-light text-xs" : "text-sm",
          _.get(errors, id) && _.get(touched, id)
            ? "focus:ring-red-500 focus:border-red-500 border-red-600"
            : "focus:ring-primary-500 focus:border-gray-200 border-gray-300",
          disabled ? "cursor-not-allowed bg-gray-100" : "",
          "shadow-sm block w-full rounded-md placeholder:font-light placeholder:text-xs h-[38px]"
        )}
      />
      {_.get(errors, id) && _.get(touched, id) && (
        <p className="mt-2 text-sm text-red-600" id={`${id}-error`}>
          {_.get(errors, id)}
        </p>
      )}
    </div>
  );
};

export default SearchSelectInput;
