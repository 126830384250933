import { Modal, PDFRenderer } from "components";
import { useSearch, useMatch } from "react-location";
import { LocationGenerics } from "router/location";
import { useInsurer } from "hooks/data/use-insurers";
import config from "config";

export default function ViewPaymentReceiptContainer({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const { params } = useMatch<LocationGenerics>();
  const { loading } = useInsurer(searchParams?.id || "");

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      loading={loading}
      size="6xl"
      title="Payment Receipt"
      description="Details of payment receipt are shown below"
    >
      <PDFRenderer
        url={`${config.asset.uri}treaty_payment_receipt/${btoa(
          JSON.stringify({
            treaty_id: params?.treaty,
            treaty_payment_id: searchParams?.id || "",
          })
        )}`}
      />
    </Modal>
  );
}
