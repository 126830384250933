import { wrapClick } from "utils";
import { useMutation } from "@apollo/client";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { Modal } from "components";
import * as Yup from "yup";
import { AddParticipantsForm } from "components/forms";
import {
  CreateDistributionList,
  CreateDistributionListVariables,
} from "_graphql/mutation/__generated__/CreateDistributionList";
import { createDistributionList } from "_graphql/mutation/offers";
import { useMatch } from "react-location";
import { LocationGenerics } from "router/location";
import _ from "lodash";

export const valiadationSchema = Yup.object().shape({
  requests: Yup.array()
    .of(
      Yup.object().shape({
        participant_id: Yup.object().required("Participant is required"),
      })
    )
    .min(1)
    .required("At least one participant is required"),
});

export default function AddParticipantsContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const { params } = useMatch<LocationGenerics>();

  const [create, { loading }] = useMutation<
    CreateDistributionList,
    CreateDistributionListVariables
  >(createDistributionList, {
    refetchQueries: ["Offer"],
  });

  const form = useFormik<any>({
    initialValues: {
      requests: [],
    },
    validationSchema: valiadationSchema,
    onSubmit: async (variables) => {
      const items = _.groupBy(
        form.values.requests,
        "participant_id.reinsurersreinsurer_id"
      );
      await create({
        variables: {
          offer_id: params.offer || "",
          reinsurer_reps: Object.keys(items)?.map((item: any) => ({
            reinsurer_id: item,
            representatives_ids: Object.values(items[item])?.map(
              (rep: any) => rep?.participant_id?.reinsurer_representative_id
            ),
          })),
        },
      }).then(({ data }) => {
        if (data?.createDistributionList) {
          toast.success("Distribution list Created Successfully");
          refetch?.();
          form.resetForm();
        } else {
          toast.error("Could not create Distribution list");
        }
      });
    },
    onReset: () => {
      setOpen(false);
    },
  });

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title="Add Participant(s)"
      description="Add participants to this offer"
      renderActions={() => (
        <>
          <button
            type="button"
            disabled={loading}
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(form.handleSubmit)}
          >
            {loading ? "Saving changes..." : "Save changes"}
          </button>
        </>
      )}
    >
      <AddParticipantsForm form={form} />
    </Modal>
  );
}
