import { wrapClick } from "utils";
import { useMutation } from "@apollo/client";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { Modal } from "components";
import * as Yup from "yup";
import {
  CreateClassOfBusiness,
  CreateClassOfBusinessVariables,
} from "_graphql/mutation/__generated__/CreateClassOfBusiness";
import { createClassOfBusiness } from "_graphql/mutation/class-of-business";
import { ProgramForm } from "components/forms";
import {
  CreateTreatyProgram,
  CreateTreatyProgramVariables,
} from "_graphql/mutation/__generated__/CreateTreatyProgram";
import { createTreatyProgram } from "_graphql/mutation/treaty-programs";

export const treatyValidationSchema = Yup.object().shape({
  treaty_name: Yup.string().required("Treaty Name is required"),
  insurer_id: Yup.string().required("Insurer is required"),
  treaty_type: Yup.string()
    .oneOf(["PROPORTIONAL", "NONPROPORTIONAL"])
    .required("Treaty Type is required"),
  requests: Yup.array().of(
    Yup.object().shape({
      keyDetail: Yup.string().required("Detail is required"),
      required: Yup.string().required("Check Required is status"),
      type: Yup.string().required("Type of is required"),
    })
  ),
});

export default function CreateContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const [create, { loading }] = useMutation<
    CreateTreatyProgram,
    CreateTreatyProgramVariables
  >(createTreatyProgram);

  const form = useFormik<any>({
    initialValues: {
      treaty_name: "",
      insurer_id: "",
      treaty_type: "",
      requests: [],
    },
    validationSchema: treatyValidationSchema,
    onSubmit: async (variables) => {
      await create({
        variables: {
          program: {
            treaty_details: JSON.stringify(
              variables.requests?.map((item: any) => ({
                keydetail: item.keyDetail,
                required: item.required,
                type: item.type,
                value: ""
              }))
            ),
            treaty_name: variables.treaty_name,
            treaty_type: variables.treaty_type,
            insurersinsurer_id: variables.insurer_id,
          },
        },
      }).then(({ data }) => {
        if (data?.createTreatyProgram) {
          toast.success("Program created Successfully");
          refetch?.();
          form.resetForm();
        } else {
          toast.error("Failed to create program");
        }
      });
    },
    onReset: () => {
      setOpen(false);
    },
  });

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title="Add New Treaty program"
      description="Provide the details to add a Treaty program"
      renderActions={() => (
        <>
          <button
            type="button"
            disabled={loading}
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(form.handleSubmit)}
          >
            {loading ? "Adding Treaty program..." : "Add Treaty program"}
          </button>
        </>
      )}
    >
      <ProgramForm form={form} />
    </Modal>
  );
}
