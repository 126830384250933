import { gql } from "@apollo/client";

export const getInsurers = gql`
  query Insurers {
  count: getInsurersCount
  rows: insurers {
    insurer_id
    insurer_company_name
    insurer_company_email
    insurer_company_website
    insurer_abbrv
    insurer_address {
      country
      insurer_address_id
      region
      street
      suburb
    }
    remainders {
      remainder_id
    }
  }
}

`;

export const getInsurer = gql`
  query Insurer($insurerId: ID!) {
    insurer(insurer_id: $insurerId) {
      insurer_id
      insurer_company_name
      insurer_company_email
      insurer_company_website
      insurer_abbrv
      insurer_overview {
        total_brokerage_amt
        total_closed
        total_fac_premium
        total_nic_tax
        total_paid
        total_pending
        total_unpaid
        total_withholding_tax
        treaties_overview {
          total_brokerage_amt
          total_nonproportional
          total_paid_treaties
          total_partpayment_treaties
          total_proportional
          total_treaties
          total_unpaid_treaties
        }
      }
      insurer_address {
        country
        insurer_address_id
        region
        street
        suburb
      }
    }
  }
`;

export const getInsurerAssociates = gql`
  query GetInsurerAssociates($insurerId: ID) {
    getInsurerAssociates(insurer_id: $insurerId) {
      insurer_associate_id
      assoc_first_name
      assoc_last_name
      assoc_primary_phonenumber
      assoc_secondary_phonenumber
      assoc_email
      position
    }
  }
`;

export const getInsurerAssociate = gql`
query GetInsurerAssociate($insurerAssociateId: ID) {
  getInsurerAssociate(insurer_associate_id: $insurerAssociateId) {
    insurer_associate_id
    assoc_first_name
    assoc_last_name
    assoc_primary_phonenumber
    assoc_secondary_phonenumber
    assoc_email
    position
  }
}

`;
