import { Modal } from "components";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { useBrokerAssociate } from "hooks/data/use-brokers";
import BrokerRepView from "components/cards/broker-rep-view";

export default function ViewContainer({
    open,
    setOpen,
}: {
    open: boolean;
    setOpen: (val: boolean) => void;
}) {
    const searchParams = useSearch<LocationGenerics>();
    const { associate, loading } = useBrokerAssociate({
        brokerAssociateId: searchParams.id as string,
    });

    return (
        <Modal
            open={open}
            setOpen={setOpen}
            loading={loading}
            title={"Reinsurance Broker Associate Information - " + associate?.re_broker_assoc_first_name + " " + associate?.re_broker_assoc_last_name}
            description="Details of reinsurer manager are shown below"
        >
            <BrokerRepView associate={associate} />
        </Modal>
    );
}
