export default [
  { name: "CEO", value: "CEO" },
  { name: "General Manager", value: "General Manager" },
  { name: "Managing Director", value: "Managing Director" },
  { name: "Senior Broking Officer", value: "Senior Broking Officer" },
  { name: "Broking Officer", value: "Broking Officer" },
  { name: "Finance Officer", value: "Finance Officer" },
  { name: "Frontline Executive", value: "Frontline Executive" },
  { name: "System Administrator", value: "System Administrator" },
] as const;
