import { TextInput } from "components/core";
import { FormikProps, useFormik } from "formik";
import { FC, useEffect } from "react";
import { classNames, wrapClick } from "utils";
import { useLazyQuery } from "@apollo/client";
import { ClipboardDocumentListIcon, DocumentPlusIcon } from "@heroicons/react/24/outline";
import moment from "moment";
import numeral from "numeral";
import { SEARCH_OFFERS } from "../endorsements/offer-form";



interface OfferFormProps {
    handleNext: (values: any) => void;
    handlePrevious: () => void;
    initialValues: any;
    values: any;
    handleCancel: () => void;
    parentForm: FormikProps<any>;
}

const OfferForm: FC<OfferFormProps> = ({
    initialValues,
    handleNext,
    handleCancel,
    parentForm,
}) => {
    const form = useFormik({
        initialValues,
        onSubmit: (values) => {
            handleNext(values);
        },
        onReset: () => {
            handleCancel?.();
        },
    });

    const [searchOffer, { loading, data }] =
        useLazyQuery(SEARCH_OFFERS);

    const servicePointSearchForm = useFormik({
        initialValues: {
            search: "",
        },
        onSubmit: (values) => {
            searchOffer({
                variables: {
                    args: {
                        status: [
                            "CLOSED",
                        ],
                        insurer_id: "",
                        payment_status: [],
                        claim_status: [],
                        offset: 0,
                        limit: 30,
                        search: values.search,
                    }
                },
            })
        },
    });

    const handleSetOfferDetail = (value: any) => {
        parentForm?.setFieldValue("details", {
            class_of_business_id: value.classofbusiness?.class_of_business_id,
            old_class_of_business_id: value.classofbusiness,
            offer_details: JSON.parse(value.offer_detail?.offer_details || "[]"),
            old_offer_detail: JSON.parse(value.offer_detail?.offer_details || "[]"),
            insurer_id: value.insurer?.insurer_id,
            premium: value.premium,
            policy_number: value?.offer_detail?.policy_number,
            insured_by: value?.offer_detail?.insured_by,
            sum_insured: value.sum_insured,
            rate: value.rate,
            facultative_offer: value.facultative_offer,
            commission: value.commission,
            brokerage: value.brokerage,
            currency: value.offer_detail?.currency,
            period_of_insurance_from: value.offer_detail?.period_of_insurance_from,
            period_of_insurance_to: value.offer_detail?.period_of_insurance_to,
            comment: value?.offer_detail?.offer_comment,
        });
    }

    useEffect(() => {
        form.setValues(initialValues);
    }, [initialValues])

    return (
        <div className="flex-1 flex flex-col overflow-hidden">
            <div className="space-y-6 divide-y divide-gray-200 p-6 flex-1 overflow-y-auto">
                <div className="">
                    {/* {JSON.stringify(form?.values, null, 2)} */}
                    <span className="text-xs font-light">Search for Facultative Offer</span>
                    <form
                        onSubmit={servicePointSearchForm.handleSubmit}
                        className="grid grid-cols-8 gap-6 mt-2"
                    >
                        <div className="col-span-6 sm:col-span-3">
                            <TextInput
                                id="search"
                                label="Policy Number"
                                type="text"
                                placeholder="e.g. POL-0000001"
                                {...servicePointSearchForm}
                            />
                        </div>
                        <div className="col-span-6 sm:col-span-2 items-end justify-end flex">
                            <button
                                type="submit"
                                className="w-full inline-flex justify-center rounded-md border border-transparent bg-primary-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                            >
                                {loading ? "Searching Offer..." : "Search Offer"}
                            </button>
                        </div>
                    </form>
                    <div className="grid grid-cols-3 gap-6 mt-6 max-h-64 overflow-y-scroll h-full pt-6 border-t border-gray-200">
                        {data?.offers?.map?.((offer: any) => (
                            <OfferItem
                                key={offer.offer_id}
                                isActive={offer.offer_id === parentForm.values?.details?.offer_id}
                                onClick={wrapClick(() => [
                                    form.setFieldValue("exisitingOffer", offer),
                                    handleSetOfferDetail(offer)
                                ]
                                )}
                                owner={offer}
                            />
                        ))}
                    </div>
                </div>
                <div className="pt-6">
                    <span className="text-xs font-light">Selected Offer</span>
                    {parentForm?.values?.offer?.exisitingOffer || form?.values?.exisitingOffer ? (
                        <div className="border mt-2 border-gray-300 border-dashed bg-gray-100 divide-y divide-dashed divide-gray-300 rounded-md">
                            <div className="grid grid-cols-4 gap-x-4 gap-y-6  p-4">
                                <div className="">
                                    <span className="block text-sm font-light text-gray-700">
                                        Policy Number
                                    </span>
                                    <div className="mt-1 block w-full sm:text-sm">
                                        {form?.values?.exisitingOffer?.offer_detail?.policy_number || "N/A"}
                                    </div>
                                </div>
                                <div className="col-span-2">
                                    <span className="block text-sm font-light text-gray-700">
                                        Cedant
                                    </span>
                                    <div className="mt-1 block w-full sm:text-sm">
                                        {form?.values?.exisitingOffer?.insurer?.insurer_company_name ||
                                            "N/A"}
                                    </div>
                                </div>
                                <div className="col-span-2">
                                    <span className="block text-sm font-light text-gray-700">
                                        Insured
                                    </span>
                                    <div className="mt-1 block w-full sm:text-sm">
                                        {form?.values?.exisitingOffer?.offer_detail?.insured_by ||
                                            "N/A"}
                                    </div>
                                </div>
                            </div>
                            <div className="grid grid-cols-4 gap-x-4 gap-y-6  p-4">
                                <div className="">
                                    <span className="block text-sm font-light text-gray-700">
                                        Currency
                                    </span>
                                    <div className="mt-1 block w-full sm:text-sm">
                                        {form?.values?.exisitingOffer?.offer_detail?.currency || "N/A"}
                                    </div>
                                </div>
                                <div className="">
                                    <span className="block text-sm font-light text-gray-700">
                                        Brokerage
                                    </span>
                                    <div className="mt-1 block w-full sm:text-sm">
                                        {form?.values?.exisitingOffer?.brokrage_amount || "N/A"}
                                    </div>
                                </div>
                                <div>
                                    <span className="block text-sm font-light text-gray-700">
                                        Withholding Tax
                                    </span>
                                    <div className="mt-1 block w-full sm:text-sm">
                                        {numeral(form?.values?.exisitingOffer?.withholding_tax_amount).format("#,#.##") || "N/A"}
                                    </div>
                                </div>
                                <div>
                                    <span className="block text-sm font-light text-gray-700">
                                        Commission
                                    </span>
                                    <div className="mt-1 block w-full sm:text-sm">
                                        {numeral(form?.values?.exisitingOffer?.commission_amount).format("#,#.##") || "N/A"}
                                    </div>
                                </div>
                                <div>
                                    <span className="block text-sm font-light text-gray-700">
                                        Premium
                                    </span>
                                    <div className="mt-1 block w-full sm:text-sm">
                                        {numeral(form?.values?.exisitingOffer?.premium).format("#,#.##") || "N/A"}
                                    </div>
                                </div>
                                <div>
                                    <span className="block text-sm font-light text-gray-700">
                                        Sum Insured
                                    </span>
                                    <div className="mt-1 block w-full sm:text-sm">
                                        {numeral(form?.values?.exisitingOffer?.sum_insured).format("#,#.##") || "N/A"}
                                    </div>
                                </div>
                                <div className="">
                                    <span className="block text-sm font-light text-gray-700">
                                        Fac. Premium
                                    </span>
                                    <div className="mt-1 block w-full sm:text-sm">
                                        {numeral(form?.values?.exisitingOffer?.fac_premium).format("#,#.##") || "N/A"}
                                    </div>
                                </div>
                                <div className="col-span-2">
                                    <span className="block text-sm font-light text-gray-700">
                                        Fac. Sum Insured
                                    </span>
                                    <div className="mt-1 block w-full sm:text-sm">
                                        {numeral(form?.values?.exisitingOffer?.fac_sum_insured).format("#,#.##") || "N/A"}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="flex mt-2 w-full items-center justify-center">
                            <div className="relative  block w-full rounded-lg border-2 border-dashed border-gray-300 p-6 py-24 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2">
                                <DocumentPlusIcon
                                    className="mx-auto h-10 w-10 text-gray-400"
                                    stroke="currentColor"
                                    strokeWidth={1}
                                    aria-hidden="true"
                                />
                                <h3 className="mt-2 text-sm font-medium text-gray-900">
                                    No Offer Selected
                                </h3>
                                <p className="mt-1 text-sm text-gray-500">
                                    Get started by searching for a offer above.
                                </p>
                            </div>
                        </div>
                    )}
                </div>
            </div>

            <div className="bg-gray-50 dark:bg-gray-800 px-4 py-3 sm:py-4 sm:px-6 sm:flex sm:flex-row-reverse border-t border-gray-200">
                <button
                    type="button"
                    onClick={wrapClick(form.handleSubmit)}
                    disabled={!form.isValid}
                    className={classNames(
                        form.isValid ? "cursor-pointer" : "cursor-not-allowed",
                        "w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
                    )}
                >
                    Next
                </button>
                <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 dark:border-gray-600 shadow-sm px-4 py-2 bg-white dark:bg-gray-900 text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 hover:dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={wrapClick(form.resetForm)}
                >
                    Cancel
                </button>
            </div>
        </div>
    );
};

export default OfferForm;


function OfferItem({
    owner,
    onClick,
    isActive,
}: {
    owner: any;
    onClick: any;
    isActive: boolean;
}) {
    return (
        <button
            onClick={onClick}
            className={classNames(
                isActive
                    ? "border-gray-200 dark:border-primary-600 bg-white dark:bg-primary-800 hover:border-primary-700 dark:hover:border-gray-200"
                    : "border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-800 hover:border-gray-400 dark:hover:border-gray-500",
                "focus:outline-none relative rounded-lg border px-3 py-3 shadow-sm flex items-center space-x-3 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
            )}
        >
            <div className="flex-shrink-0">
                <ClipboardDocumentListIcon />
            </div>
            <div className="flex-1 min-w-0 text-left">
                <p className="text-sm font-medium text-gray-900 dark:text-white truncate">
                    {owner?.offer_detail?.policy_number || "N A"}
                </p>
                <p className="text-sm text-gray-500 dark:text-gray-400 truncate">
                    {owner?.insurer?.insurer_company_name || "N/A"}
                </p>
                <p className="text-sm text-gray-500 dark:text-gray-400 truncate">
                    {owner?.offer_detail?.insured_by || "N/A"}
                </p>
                <p className="text-xs text-gray-500 dark:text-gray-400 truncate">
                    {moment(owner?.created_at).format("Do MMM,YYYY") || "N/A"}
                </p>
            </div>
        </button>
    );
}