import { Switch } from "@headlessui/react";
import { Offer_findSingleOffer } from "_graphql/queries/__generated__/Offer";
import clsx from "clsx";
import {
  CurrencyPicker,
  SelectInput,
  TextArea,
  TextInput,
} from "components/core";
import { FormikProps } from "formik";
import numeral from "numeral";
import { FC } from "react";
import { classNames } from "utils";

export interface ClaimPaymentFormProps {
  form: FormikProps<any>;
  offer?: Offer_findSingleOffer;
  reinsurer?: string;
  amountToPay: number;
}

const ClaimPaymentForm: FC<ClaimPaymentFormProps> = ({ form, offer, amountToPay, reinsurer }) => {
  return (
    <div className="space-y-6 divide-y divide-gray-200">
      <div className="border border-gray-300 border-dashed bg-gray-100 divide-y divide-dashed divide-gray-300 rounded-md">
        <div className="grid grid-cols-4 gap-4 p-4">
          <div className="col-span-2">
            <span className="block text-sm font-light text-gray-700">
              Policy Number
            </span>
            <div className="mt-1 block w-full sm:text-sm font-medium">
              {offer?.offer_detail?.policy_number || "N/A"}
            </div>
          </div>
          <div className="col-span-2">
            <span className="block text-sm font-light text-gray-700">
              Insurer
            </span>
            <div className="mt-1 block w-full sm:text-sm font-medium">
              {offer?.insurer?.insurer_company_name || "N/A"}
            </div>
          </div>
          <div className="col-span-2">
            <span className="block text-sm font-light text-gray-700">
              Class of Business
            </span>
            <div className="mt-1 w-full block sm:text-sm font-medium">
              <span>{offer?.classofbusiness?.business_name || "N/A"}</span>
              {[
                "Motor Comprehensive Fleet",
                "Motor Comprehensive",
                "Motor Comprehensive (Automobile Fac Facility)",
              ].includes(offer?.classofbusiness?.business_name || "") && (
                  <span className="font-light">
                    - (
                    {
                      JSON.parse(
                        offer?.offer_detail?.offer_details || "[]"
                      )?.find(
                        (el: { [x: string]: string }) =>
                          el["keydetail"] === "Vehicle Reg No" ||
                          el["keydetail"] === "Vehicle Reg No."
                      )?.value
                    }
                    )
                  </span>
                )}
            </div>
          </div>
          <div className="">
            <span className="block text-sm font-light text-gray-700">
              Insured
            </span>
            <div className="mt-1 block w-full sm:text-sm font-medium">
              {offer?.offer_detail?.insured_by || "N/A"}
            </div>
          </div>
          <div className="">
            <span className="block text-sm font-light text-gray-700">
              Facultative Offer <b>(%)</b>
            </span>
            <div className="mt-1 block w-full sm:text-sm font-medium">
              {offer?.facultative_offer || "N/A"}
            </div>
          </div>
          <div className="col-span-2">
            <span className="block text-sm font-light text-gray-700">
              Reinsurer
            </span>
            <div className="mt-1 block w-full sm:text-sm text-green-600 font-medium">
              {reinsurer}
            </div>
          </div>
          <div className="">
            <span className="block text-sm font-light text-gray-700">
              Expected Amount
            </span>
            <div className="mt-1 block w-full sm:text-sm text-green-600 font-medium">
              {form.values.payment_details.conversion.addExchangeRate
                ? form.values.payment_details.conversion.currency
                : form.values.payment_details.currency}{" "}
              {numeral(amountToPay)
                .multiply(form.values.payment_details.conversion.rate)
                .format("#,#.##") || "N/A"}
            </div>
          </div>
        </div>
      </div>
      <div className="pt-3">
        <div className={clsx("grid grid-cols-2 gap-2")}>
          <div className="col-span-2">
            <SelectInput
              options={[
                {
                  label: "--- Select ---",
                  value: "",
                },
                {
                  label: "Bank Transfer",
                  value: "Bank Transfer",
                },
                {
                  label: "Cheque",
                  value: "Cheque",
                },
              ]}
              id="payment_type"
              label="Payment Type"
              placeholder="e.g. Select Payment"
              required={true}
              {...form}
            />
          </div>
        </div>
      </div>
      <div className="pt-3">
        <span className="text-xs font-light">Payment From</span>
        <div className="grid grid-cols-2 gap-2">
          <div
            className={clsx({
              "col-span-2":
                form.values.payment_type === "Bank Transfer" ||
                !form.values.payment_type,
            })}
          >
            <TextInput
              type="text"
              id="payment_from.bank_name"
              label="Bank Name"
              placeholder="e.g. Access Bank"
              required={true}
              {...form}
            />
          </div>
          {form.values.payment_type === "Cheque" && (
            <div>
              <TextInput
                type="text"
                id="payment_from.cheque_number"
                label="Cheque Number"
                placeholder="e.g. Ch-0000000"
                required={true}
                {...form}
              />
            </div>
          )}
          {form.values.payment_type === "Cheque" && (
            <div className={clsx("col-span-2")}>
              <TextInput
                type="date"
                id="payment_from.date_on_cheque"
                label="Date on Cheque"
                placeholder="e.g. Access Bank"
                required={true}
                {...form}
              />
            </div>
          )}
        </div>
      </div>
      <div className="pt-3">
        <span className="text-xs font-light">Payment To</span>
        <div className="grid grid-cols-2 gap-2">
          <div className={clsx("col-span-2")}>
            <TextInput
              type="text"
              id="payment_to"
              label="Bank Name"
              placeholder="e.g. Access Bank"
              required={true}
              {...form}
            />
          </div>
        </div>
      </div>
      <div className="pt-3">
        <span className="text-xs font-light">Payment Details</span>
        <div className="grid grid-cols-2 gap-2">
          <div>
            <TextInput
              type="number"
              step={0.01}
              id="payment_details.payment_amount"
              label="Amount"
              placeholder="e.g. 1200"
              required={true}
              {...form}
            />
          </div>
          <div>
            <CurrencyPicker
              id="payment_details.currency"
              label="Currency"
              placeholder="e.g. USD"
              disabled
              required={true}
              {...form}
            />
          </div>
          <div className="col-span-2">
            <TextArea
              id="offer_payment_comment"
              label="Comment"
              placeholder="e.g. Comment goes here ..."
              required={true}
              {...form}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClaimPaymentForm;
