import { UserPlusIcon } from '@heroicons/react/24/outline';
import { useAppNavigate, useAppSearch } from '_graphql/cache/auth';
import { GetBrokerAssociate_getBrokerAssociate } from '_graphql/queries/__generated__/GetBrokerAssociate';
import { GetInsurerAssociates_getInsurerAssociates } from '_graphql/queries/__generated__/GetInsurerAssociates';
import { ActionButton, OfficeButton, TableComponent, TableLoader } from 'components';
import { Action } from 'components/buttons/action-button';
import { useBrokerAssociates } from 'hooks/data/use-brokers';
import { useInsurerManagers } from 'hooks/data/use-insurers'
import CreateInsurerManagerContainer from 'pages/brokers/add-manager';
import { FC } from 'react'
import { useMatch } from 'react-location';
import { LocationGenerics } from 'router/location';
import { useUrlState } from 'utils';
import ViewContainer from './view';
import UpdateContainer from './update';

type Props = {}

const Associates: FC<Props> = (props) => {
    const { params } = useMatch<LocationGenerics>();
    const navigate = useAppNavigate();
    const searchParams = useAppSearch();
    const [modal, setModal] = useUrlState("modal");
    const { associates, refetch, loading } = useBrokerAssociates({
        brokerId: params?.broker as string
    })

    const dispatchAction =
        (id: string, action: Exclude<Action, "expand" | "goto" | "clone">) =>
            () => {
                navigate({
                    search: (old) => ({
                        ...old,
                        id,
                        modal: action,
                    }),
                });
            };

    return (
        <div className='w-full'>
            <TableComponent hasSearch title={'managers'} columns={[
                {
                    label: 'Name',
                    accessor: 'assoc_first_name',
                    name: 'Name',
                    render: (value: GetBrokerAssociate_getBrokerAssociate) => (
                        <span>
                            {value.re_broker_assoc_first_name} {value.re_broker_assoc_position}
                        </span>
                    )
                },
                {
                    name: "Email",
                    accessor: "re_broker_assoc_email",
                },
                {
                    name: "Position",
                    accessor: "re_broker_assoc_position",
                },
                {
                    name: "Primary Phone Number",
                    accessor: "re_broker_assoc_primary_phone",
                },
                {
                    name: "Secondary Phone Number",
                    accessor: "re_broker_assoc_secondary_phone",
                },
                {
                    name: "Actions",
                    accessor: "insurer_associate_id",
                    render: (value: GetBrokerAssociate_getBrokerAssociate) => (
                        <>
                            <ActionButton action='view-associate' onClick={dispatchAction(value?.re_broker_associate_id || "", "view-associate")} />
                            <ActionButton action='update-associate' onClick={dispatchAction(value?.re_broker_associate_id || "", "update-associate")} />
                            <ActionButton action='remove-associate' onClick={dispatchAction(value?.re_broker_associate_id || "", "remove-associate")} />
                        </>
                    )
                }
                //Add actions
            ]} data={associates} refetch={refetch}
                loading={loading}
                renderHeaderItems={() => (
                    <OfficeButton onClick={dispatchAction("", "create")}>
                        <span>Add Representative</span>
                        <UserPlusIcon className="h-6 w-6" />
                    </OfficeButton>
                )}
                renderLoader={() => <TableLoader items={[1, 1, 1, 1, 1]} showAction actionsCount={3} />}
            />


            <CreateInsurerManagerContainer
                open={modal === "create"}
                setOpen={(val: boolean) => setModal(val ? "create" : undefined)}
            />

            {searchParams?.id && (
                <>
                    <ViewContainer
                        open={modal === "view-associate"}
                        setOpen={(val: boolean) => setModal(val ? "view-associate" : undefined)}
                    />
                    <UpdateContainer
                        open={modal === "update-associate"}
                        setOpen={(val: boolean) => setModal(val ? "update-associate" : undefined)}
                    />

                </>
            )}

        </div>
    )
}

export default Associates