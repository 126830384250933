import { useReactiveVar } from "@apollo/client";
import { FC } from "react";
import { useSearch, MakeGenerics, Navigate, Outlet } from "react-location";
import { currentTokenVar } from "_graphql/cache/auth";

export type AuthLocationGenerics = MakeGenerics<{
  Search: {
    redirect?: string;
  };
}>;

const AuthLayout: FC = () => {
  const search = useSearch<AuthLocationGenerics>();
  const currentToken = useReactiveVar(currentTokenVar);

  if (currentToken) {
    // check if theres a token
    // if yes hit server to reauthenticate and redirect to app
    return <Navigate to={search?.redirect ?? "/"} replace />;
  }
  return (
    <div className="h-screen">
      <div className="min-h-full flex flex-row-reverse">
        <div className="flex-1 flex flex-col justify-center bg-shade-500  py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24 relative min-w-1/3">
          <Outlet />
          <span className="absolute text-sm bottom-8 text-gray-200 text-center inset-x-0">
            &copy; {new Date().getFullYear()} - Powered by{" "}
            <a
              href="https://comfytechnology.tech"
              target={"_blank"}
              className="text-green-700 hover:text-green-900"
              rel="noreferrer"
            >
              Comfy Technology
            </a>
          </span>
        </div>
        <div className="hidden lg:block relative w-0 flex-1">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src={require("assets/hero.png")}
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
