import { LanguagePicker, Modal, PDFRenderer } from "components";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import config from "config";
import { useFormik } from "formik";

export default function ViewOfferSlipContainer({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const form = useFormik({
    initialValues: {
      lang: "en"
    },
    onSubmit(values, formikHelpers) {

    },
  })
  return (
    <Modal
      open={open}
      setOpen={setOpen}
      size="6xl"
      title="Placing Slip"
      description="Details of placing slip are shown below"
    >
      <div className="mb-3">
        <LanguagePicker id={"lang"} labelHidden {...form} />
      </div>
      <PDFRenderer
        url={`${config.asset.uri}generate_placing_slip/${btoa(
          JSON.stringify({
            offer_id: searchParams?.id,
            lang: form.values.lang,
          })
        )}`}
      />
    </Modal>
  );
}
