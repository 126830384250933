import { FC, useState } from 'react'
import {
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@heroicons/react/20/solid'
import { classNames, wrapClick } from "utils";
import moment from 'moment';
import _ from 'lodash';

const DatePickerMonth: FC<{
  date: { year: number; month: number };
  min: { year: number; month: number };
  max: { year: number; month: number };
  setDate: any;
}> = ({ date, setDate, min, max }) => {

  const [view, setView] = useState<"months" | "years">("months")
  const [startYear, setStartYear] = useState<number>(min.year);
  const [tempYear, setTempYear] = useState<number>(date.year);

  const __setMonth = (newMonth: number) => {
    setDate({
      month: newMonth,
      year: tempYear
    });
  }

  const __setYear = (newYear: number) => {
    setTempYear(newYear);
    setView("months");
  }

  switch (view) {
    case "months": {
      return (
        <div className="text-center w-64">
          <div className="flex items-center text-gray-900">
            <button
              type="button"
              disabled={tempYear === min.year}
              onClick={wrapClick(() => setTempYear(tempYear - 1))}
              className={classNames(
                tempYear === min.year ? "cursor-not-allowed" : "cursor-pointer",
                "-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
              )}
            >
              <span className="sr-only">Previous year</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            <button
              type="button"
              onClick={wrapClick(() => setView("years"))}
              className="flex-auto font-semibold"
            >
              {tempYear}
            </button>
            <button
              type="button"
              disabled={tempYear === max.year}
              onClick={wrapClick(() => setTempYear(tempYear + 1))}
              className={classNames(
                tempYear === max.year ? "cursor-not-allowed" : "cursor-pointer",
                "-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
              )}
            >
              <span className="sr-only">Next year</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
          <div className="isolate mt-2 grid grid-cols-4 gap-px rounded-lg bg-gray-200 text-sm shadow ring-1 ring-gray-200">
            {_.times(12, (monthIdx) => {
              const isSelected = monthIdx === date.month;
              const isCurrentMonth = monthIdx === date.month;
              const isDisabled = (monthIdx < min.month && tempYear <= min.year) || (monthIdx > max.month && tempYear >= max.year)

              return (
                <button
                  key={monthIdx}
                  type="button"
                  disabled={isDisabled}
                  onClick={wrapClick(() => __setMonth(monthIdx))}
                  className={classNames(
                    'py-1.5  focus:z-10 ',
                    (isSelected) ? 'font-semibold' : '',
                    (isSelected) ? 'text-white' : '',
                    isDisabled ? "cursor-not-allowed bg-gray-100 hover:bg-gray-100" : "",
                    (isCurrentMonth) && !(isSelected) ? 'text-primary-600' : '',
                    monthIdx === 0 ? 'rounded-tl-lg' : '',
                    monthIdx === 3 ? 'rounded-tr-lg' : '',
                    monthIdx === 12 - 4 ? 'rounded-bl-lg' : '',
                    monthIdx === 12 - 1 ? 'rounded-br-lg' : '',
                    (isSelected) ? 'bg-primary-600 hover:bg-primary-700' :"",
                    !isDisabled && !isSelected?  'hover:bg-gray-100 bg-white' : "",
                  )}
                >
                  <span
                    className={classNames(
                      'mx-auto flex h-7 w-7 items-center justify-center ',
                    )}
                  >
                    {moment().set("month", monthIdx).format("MMM")}
                  </span>
                </button>
              )
            })}
          </div>
        </div>
      )
    }
    case "years": {
      return (
        <div className="text-center w-64">
          <div className="flex items-center text-gray-900">
            <button
              type="button"
              disabled={startYear <= min.year}
              onClick={wrapClick(() => setStartYear(startYear - 12))}
              className={classNames(
                startYear <= min.year ? "cursor-not-allowed" : "cursor-pointer",
                "-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
              )}
            >
              <span className="sr-only">Previous years</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            <div className="flex-auto font-semibold">{startYear} - {startYear + 11}</div>
            <button
              type="button"
              onClick={wrapClick(() => setStartYear(startYear + 12))}
              className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
            >
              <span className="sr-only">Next years</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
          <div className="isolate mt-2 grid grid-cols-4 gap-px rounded-lg bg-gray-200 text-sm shadow ring-1 ring-gray-200">
            {_.times(12, (idx) => {
              const yearIdx = startYear + idx

              return (
                <button
                  key={yearIdx}
                  type="button"
                  disabled={yearIdx > max.year}
                  onClick={wrapClick(() => __setYear(yearIdx))}
                  className={classNames(
                    'py-1.5  focus:z-10',
                    !(yearIdx > max.year) && (yearIdx !== tempYear) ? "cursor-pointer bg-white hover:bg-gray-100" : "",
                    yearIdx > max.year ? "cursor-not-allowed bg-gray-100 hover:bg-gray-100" : "",
                    (yearIdx === tempYear) ? 'font-semibold' : '',
                    (yearIdx === tempYear) ? 'text-white' : '',
                    (moment().year() === yearIdx) && !(yearIdx === tempYear) ? 'text-primary-600' : '',
                    idx === 0 ? 'rounded-tl-lg' : '',
                    idx === 3 ? 'rounded-tr-lg' : '',
                    idx === 12 - 4 ? 'rounded-bl-lg' : '',
                    idx === 12 - 1 ? 'rounded-br-lg' : '',
                    (yearIdx === tempYear) ? 'bg-primary-600 hover:bg-primary-700' : '',
                  )}
                >
                  <span
                    className={classNames(
                      'mx-auto flex h-7 w-7 items-center justify-center ',
                    )}
                  >
                    {moment().set("year", yearIdx).format("YYYY")}
                  </span>
                </button>
              )
            })}
          </div>
        </div>
      )
    }
  }
}


export default DatePickerMonth;