import { useUrlState, wrapClick } from "utils";
import { useMutation } from "@apollo/client";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { Modal } from "components";
import * as Yup from "yup";
import { ClaimForm } from "components/forms";
import { useOffer } from "hooks/data/use-offers";
import { LocationGenerics } from "router/location";
import { useSearch } from "react-location";
import numeral from "numeral";
import {
  MakeClaim,
  MakeClaimVariables,
} from "_graphql/mutation/__generated__/MakeClaim";
import { createOfferClaim } from "_graphql/mutation/claims";
import { useEffect } from "react";

export const validationSchema = Yup.object().shape({
  amount: Yup.number()
    .min(1, "Amount must be greater than or equal to 1")
    .typeError("Amount must be a number")
    .required("Amount is required")
    .test(
      'max',
      "Amount shouldn't be more than the claimable amount",
      function (value) {
        const { amountToClaim, rate } = this.parent;
        // console.log({ amountToClaim, rate }, value,);
        return value <= parseFloat((amountToClaim * rate).toFixed(2));
      }
    ),
  claim_date: Yup.string().required("Claim Date is required"),
  comment: Yup.string().notRequired(),
});

export default function CreateContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const [rate, setRate] = useUrlState("rate");
  const { offer, loading: fetching } = useOffer({
    offer_id: searchParams?.id || "",
  });
  const [create, { loading }] = useMutation<MakeClaim, MakeClaimVariables>(
    createOfferClaim
  );
  const amountSpent =
    offer?.offer_claims?.reduce(
      (total, currentClaim) => total + (currentClaim?.claim_amount || 0),
      0
    ) || 0;

  const claimableAmount =
    numeral(offer?.fac_sum_insured || 0)
      .subtract(amountSpent)
      .value() || 0;

  const form = useFormik<any>({
    initialValues: {
      offer_id: searchParams?.id,
      currency: offer?.offer_detail?.currency,
      rate: 1,
      amount: "",
      claim_date: "",
      comment: "",
      anotherCurrency: false,
      amountToClaim: 0,
    },
    validationSchema: validationSchema,
    onSubmit: async (variables) => {
      await create({
        variables: {
          data: {
            claim_amount: variables.amount,
            claim_date: variables.claim_date,
            offer_id: searchParams?.id || "",
            claim_comment: variables.comment,
            currency: variables.currency,
            rate: variables.rate,
          },
        },
      }).then(({ data }) => {
        if (data?.makeClaimOnOffer) {
          toast.success("Claim Created Successfully");
          refetch?.();
          form.resetForm();
        } else {
          toast.error("Failed to create Claim");
        }
      });
    },
    onReset: () => {
      setOpen(false);
    },
  });

  useEffect(() => {
    if (claimableAmount) {
      form.setFieldValue("amountToClaim", claimableAmount);
    }
  }, [claimableAmount]);
  useEffect(() => {
    if (offer?.offer_detail?.currency) {
      form.setFieldValue("currency", offer?.offer_detail?.currency);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offer?.offer_detail?.currency]);

  return (
    <Modal
      open={open}
      setOpen={(value) => {
        setOpen(value);
        form.resetForm();
      }}
      size="4xl"
      loading={fetching}
      title="Make Claim"
      description="Create a new claim"
      renderActions={() => (
        <>
          <button
            type="button"
            disabled={loading}
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(form.handleSubmit)}
          >
            {loading ? "Making Claim..." : "Make Claim"}
          </button>
        </>
      )}
    >
      {/* {JSON.stringify(form.values, null, 2)} */}
      <ClaimForm
        form={form}
        offer={offer}
        claimableAmount={
          numeral(claimableAmount).multiply(form.values.rate).value() || 0
        }
      />
    </Modal>
  );
}
