import React from 'react'

type Props = {
    notification: any
    parsedNotification: any
}

const BusinessNotificationComponent = ({ notification, parsedNotification }: Props) => {
    return (
        <div className="container mx-auto px-4">
            <div className="bg-yellow-200 p-4 mb-4">
                {JSON.parse(notification?.system_notification?.notification_content || "{}").message}
            </div>

            <div className="mb-4">
                <table className="w-full border-collapse">
                    <tbody>
                        <tr>
                            <td className="py-2">Business Name</td>
                            <td className="py-2">
                                <strong>{parsedNotification?.business_name}</strong>
                            </td>
                        </tr>
                        <tr>
                            <td className="py-2">Created On</td>
                            <td className="py-2">
                                <strong>{new Date(notification?.system_notification?.created_at).toDateString()}</strong>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <span className="text-xs font-light">Business Class Details</span>
            <div className="mt-1 w-full ">
                <table className="min-w-full divide-y divide-gray-300 border border-gray-300 rounded-md">
                    <thead className="bg-gray-50">
                        <tr className="divide-x divide-gray-200">
                            <th
                                scope="col"
                                className="whitespace-nowrap px-2 py-2 text-left text-sm font-semibold text-gray-900"
                            >
                                Detail
                            </th>
                            <th
                                scope="col"
                                className="whitespace-nowrap px-2 py-2 text-right text-sm font-semibold text-gray-900"
                            >
                                Type
                            </th>
                            <th
                                scope="col"
                                className="whitespace-nowrap px-2 py-2 text-left text-sm font-semibold text-gray-900"
                            >
                                Required
                            </th>
                        </tr>
                    </thead>
                    {/* {JSON.stringify(offerDetails)} */}
                    <tbody className="divide-y divide-gray-200 bg-white">
                        {JSON.parse(parsedNotification?.business_details)?.map((step: any, idx: string) => (
                            <tr key={idx} className="divide-x divide-gray-200">
                                <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-700">
                                    {step?.keydetail}
                                </td>
                                <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 text-right">
                                    {step?.type || "Text"}
                                </td>
                                <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                                    {step?.required || "Yes"}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>


    )
}

export default BusinessNotificationComponent