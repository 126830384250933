import { TextInput } from "components/core";
import { FormikProps } from "formik";
import _ from "lodash";
import { FC } from "react";

export interface AddParticipantFormProps {
  form: FormikProps<any>;
  type?: "reinsurer" | "broker";
}

const AddParticipantForm: FC<AddParticipantFormProps> = ({
  form,
  type = "reinsurer",
}) => {
  return (
    <div className="space-y-6 divide-y divide-gray-200">
      <div>
        <div className="grid grid-cols-2 gap-2">
          {type === "reinsurer" ? (
            <div className="col-span-3">
              <div className="">
                <div className="grid grid-cols-3 gap-6 mt-2">
                  <div className="col-span-3">
                    <TextInput
                      postText={`Out of ${form?.values?.expected}`}
                      id={"percentage"}
                      type="number"
                      label="Percentage"
                      required
                      placeholder="Eg. 10"
                      {...form}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className="col-span-3">
                <div className="">
                  <div className="grid grid-cols-3 gap-6 mt-2">
                    <div className="col-span-3">
                      <TextInput
                        id={"share_percentage"}
                        type="number"
                        label="Share percentage"
                        required
                        placeholder="Eg. 10"
                        {...form}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-span-3">
                <div className="">
                  <div className="grid grid-cols-3 gap-6 mt-2">
                    <div className="col-span-3">
                      <TextInput
                        id={"admin_percentage"}
                        type="number"
                        label="Admin Percentage"
                        required
                        placeholder="Eg. 10"
                        {...form}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddParticipantForm;
