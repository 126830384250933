import { Modal, ProgramView } from "components";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { useTreatyProgram } from "hooks/data/use-treaty-programs";

export default function ViewContainer({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const { loading, treatyProgram } = useTreatyProgram({
    programId: searchParams.id || "",
  });

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      loading={loading}
      title="Treaty Program Information"
      description="Details of program are shown below"
    >
      <ProgramView program={treatyProgram} />
    </Modal>
  );
}
