import { useAppNavigate, useAppSearch } from "_graphql/cache/auth";
import { Treaty_treaty } from "_graphql/queries/__generated__/Treaty";
import { ActionButton, TableComponent } from "components";
import numeral from "numeral";
import React from "react";
import ViewLayerLimitDebitNoteContainer from "./view-debit-note";
import SendLayerDebitNoteContainer from "./send-layer-debit-note";
import UpdateLayerLimitContainer from "./update-limit-layer";

type Props = {
  treaty: Treaty_treaty | null | undefined;
};

const LayerLimits = ({ treaty }: Props) => {
  const searchParams = useAppSearch();
  const navigate = useAppNavigate();

  const orderHereOn = treaty?.order_hereon || 100;

  return (
    <>
      <div className="py-6">
        <TableComponent
          title={"layer limits"}
          columns={[
            {
              accessor: "no",
              name: "Layer No.",
            },
            {
              accessor: "limit",
              name: "Limit",
            },
            {
              accessor: "deductible",
              name: "Deductible",
            },
            {
              accessor: "m_and_d_premium",
              name: "M&D Premium	",
            },
            {
              accessor: "installment_type",
              name: "Actions",
              render: (val: any) => {
                return (
                  <>
                    <ActionButton
                      action={"update"}
                      onClick={() =>
                        navigate({
                          search(prev) {
                            return {
                              ...prev,
                              layer: val?.no,
                              modal: "update-layer",
                            };
                          },
                        })
                      }
                    />
                    {getLayerParticipantsPercentageTotal(treaty, val?.no) >=
                      orderHereOn && (
                      <>
                        <ActionButton
                          action={"send"}
                          onClick={() =>
                            navigate({
                              search(prev) {
                                return {
                                  ...prev,
                                  layer: val?.no,
                                  modal: "send-layer",
                                };
                              },
                            })
                          }
                        />
                        <ActionButton
                          action={"view"}
                          onClick={() =>
                            navigate({
                              search(prev) {
                                return {
                                  ...prev,
                                  layer: val?.no,
                                  modal: "view-layer",
                                };
                              },
                            })
                          }
                        />
                      </>
                    )}
                  </>
                );
              },
            },
          ]}
          data={JSON.parse(treaty?.layer_limit || "[]")?.map(
            (_limit: any, index: number) => ({
              ..._limit,
              no: index + 1,
              deductible: numeral(_limit.deductible).format("#,#.##"),
              limit: numeral(_limit.limit).format("#,#.##"),
              m_and_d_premium: numeral(_limit.m_and_d_premium).format("#,#.##"),
            })
          )}
          refetch={() => {}}
        />
      </div>

      {searchParams?.layer && (
        <>
          <ViewLayerLimitDebitNoteContainer
            open={searchParams?.modal === "view-layer"}
            setOpen={(val) =>
              navigate({
                search(prev) {
                  return {
                    ...prev,
                    layer: val ? prev?.layer : undefined,
                    modal: val ? "view-layer" : undefined,
                  };
                },
              })
            }
          />
          <SendLayerDebitNoteContainer
            open={searchParams?.modal === "send-layer"}
            setOpen={(val) =>
              navigate({
                search(prev) {
                  return {
                    ...prev,
                    layer: val ? prev?.layer : undefined,
                    modal: val ? "send-layer" : undefined,
                  };
                },
              })
            }
          />
          <UpdateLayerLimitContainer
            open={searchParams?.modal === "update-layer"}
            setOpen={(val) =>
              navigate({
                search(prev) {
                  return {
                    ...prev,
                    layer: val ? prev?.layer : undefined,
                    modal: val ? "update-layer" : undefined,
                  };
                },
              })
            }
          />
        </>
      )}
    </>
  );
};

export default LayerLimits;

const getLayerParticipantsPercentageTotal = (
  treaty: Treaty_treaty | undefined | null,
  layerNumber: number
) => {
  return (
    treaty?.treaty_participants?.reduce((acc, cur) => {
      if (cur?.layer_number === layerNumber) {
        return acc + (cur?.treaty_participation_percentage || 0);
      }
      return acc;
    }, 0) || 0
  );
};
