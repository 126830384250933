import { Switch } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { BusinessGroupPicker, SelectInput, TextInput } from "components/core";
import { FormikProps } from "formik";
import _ from "lodash";
import numeral from "numeral";
import { FC } from "react";
import { classNames, wrapClick } from "utils";

export interface BusinessFormProps {
  form: FormikProps<any>;
}

const BusinessForm: FC<BusinessFormProps> = ({ form }) => {
  const removeItem = (field: string, id: number) => {
    form.setFieldValue(field, [
      ..._.get(form.values, field)?.filter(
        (val: string, _id: number) => _id !== id
      ),
    ]);
  };

  return (
    <div className="space-y-6 divide-y divide-gray-200">
      <div>
        <span className="text-xs font-light">Business Information</span>
        <div className="grid grid-cols-2 gap-2">
          <div>
            <TextInput
              id="business_name"
              label="Business Name"
              type="text"
              placeholder="e.g. Marine Cargo"
              required={true}
              {...form}
            />
          </div>

          <div className="space-y-1 ">
            <BusinessGroupPicker
              id="business_group"
              label="Business Group"
              placeholder="e.g. Motor"
              required={true}
              hideAll={true}
              {...form}
            />
          </div>

          <div className="col-span-3">
            <div className="">
              <span className="text-xs font-light">Business Details</span>
              <div className="grid grid-cols-3 gap-6 mt-2">
                <div className="col-span-3">
                  <span className="block text-sm font-medium text-gray-700">
                    Details
                  </span>
                  <div className="mt-1 w-full ">
                    <table className="min-w-full divide-y divide-gray-300 border border-gray-300 rounded-md">
                      <thead className="bg-gray-50">
                        <tr className="divide-x divide-gray-200">
                          <th
                            scope="col"
                            className="whitespace-nowrap px-2 py-2 text-left text-sm font-semibold text-gray-900"
                          >
                            Detail
                          </th>
                          <th
                            scope="col"
                            className="whitespace-nowrap px-2 py-2 text-left text-sm font-semibold text-gray-900"
                          >
                            Type
                          </th>
                          <th
                            scope="col"
                            className="whitespace-nowrap px-2 py-2 text-left text-sm font-semibold text-gray-900"
                          >
                            Required
                          </th>
                          <th
                            scope="col"
                            className="whitespace-nowrap px-2 py-2 text-sm font-semibold text-gray-900 w-[38px]"
                          ></th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {_.times(form.values.requests?.length + 1, (idx) => {
                          const id = `requests.${idx}`;
                          const isLast =
                            (form.values.requests?.length || 0) === idx;

                          return (
                            <tr key={idx} className="divide-x divide-gray-200">
                              <td className="p-0">
                                <input
                                  key={idx}
                                  type={"text"}
                                  id={`${id}.keyDetail`}
                                  name={`${id}.keyDetail`}
                                  onChange={form.handleChange}
                                  onBlur={form.handleBlur}
                                  value={_.get(
                                    form.values,
                                    `${id}.keyDetail`,
                                    ""
                                  )}
                                  placeholder={"eg. Number Plate"}
                                  className={classNames(
                                    _.get(form.errors, `${id}.keyDetail`) &&
                                      _.get(form.touched, `${id}.keyDetail`)
                                      ? "focus:ring-primary-500 focus:border-gray-200 ring-red-500 border-red-600 border"
                                      : "focus:ring-primary-500 focus:border-gray-200 border-gray-200 border-0 focus:border",
                                    "outline-none relative pl-[54px] block w-full sm:text-sm placeholder:font-light placeholder:text-xs h-[38px]"
                                  )}
                                />
                              </td>
                              <td className="p-0 relative">
                                <select
                                  key={idx}
                                  id={`${id}.type`}
                                  name={`${id}.type`}
                                  value={_.get(form.values, `${id}.type`, "")}
                                  onChange={form.handleChange}
                                  onBlur={form.handleBlur}
                                  placeholder={"eg. 2000"}
                                  // max={bill?.totalAmountDue}
                                  className={classNames(
                                    _.get(form.errors, `${id}.type`) &&
                                      _.get(form.touched, `${id}.type`)
                                      ? "focus:ring-primary-500 focus:border-gray-200 ring-red-500 border-red-600 border"
                                      : "focus:ring-primary-500 focus:border-gray-200 border-gray-200 border-0 focus:border",
                                    "outline-none relative pl-[54px] block w-full sm:text-sm placeholder:font-light placeholder:text-xs h-[38px]"
                                  )}
                                >
                                  <option value=""> --- SELECT --- </option>
                                  <option value="Number">Number</option>
                                  <option value="Text">Text</option>
                                </select>
                              </td>
                              <td className="p-0 relative">
                                <select
                                  key={idx}
                                  id={`${id}.required`}
                                  name={`${id}.required`}
                                  value={_.get(
                                    form.values,
                                    `${id}.required`,
                                    ""
                                  )}
                                  onChange={form.handleChange}
                                  onBlur={form.handleBlur}
                                  placeholder={"eg. 2000"}
                                  // max={bill?.totalAmountDue}
                                  className={classNames(
                                    _.get(form.errors, `${id}.required`) &&
                                      _.get(form.touched, `${id}.required`)
                                      ? "focus:ring-primary-500 focus:border-gray-200 ring-red-500 border-red-600 border"
                                      : "focus:ring-primary-500 focus:border-gray-200 border-gray-200 border-0 focus:border",
                                    "outline-none relative pl-[54px] block w-full sm:text-sm placeholder:font-light placeholder:text-xs h-[38px]"
                                  )}
                                >
                                  <option value=""> --- SELECT --- </option>
                                  <option value="Yes">Yes</option>
                                  <option value="No">No</option>
                                </select>
                              </td>
                              <td className="p-0 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400 text-right w-[38px] h-[38px] items-center justify-center flex">
                                {!isLast && (
                                  <button
                                    type="button"
                                    onClick={wrapClick(() =>
                                      removeItem("requests", idx)
                                    )}
                                    className="inline-flex items-center rounded-full border border-transparent p-1 text-red-500 hover:bg-red-300 hover:text-red-900 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                                  >
                                    <XMarkIcon
                                      className="h-5 w-5"
                                      aria-hidden="true"
                                    />
                                  </button>
                                )}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessForm;
