import { DocumentPlusIcon } from "@heroicons/react/24/outline";
import { GetOfferPayments_getOfferPayments } from "_graphql/queries/__generated__/GetOfferPayments";
import {
  ActionButton,
  OfficeButton,
  OfficeHeader,
  TableComponent,
} from "components";
import { Action } from "components/buttons/action-button";
import { useOfferPayments } from "hooks/data/use-payments";
import moment from "moment";
import numeral from "numeral";
import { FC } from "react";
import { useMatch, useNavigate, useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import CreateInsurerPaymentContainer from "./create";
import { useUrlState, withRoles } from "utils";
import ViewInsurerPaymentReceiptContainer from "./view-receipt";
import RemoveContainer from "./remove";
import UpdateInsurerPaymentContainer from "./update";
import DistributePaymentContainer from "./distribute";

type Props = {};

const InsurerPaymentsPage: FC<Props> = (props) => {
  const [modal, setModal] = useUrlState("modal");
  const { params } = useMatch<LocationGenerics>();
  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const dispatchAction =
    (id: string, action: Exclude<Action, "expand" | "goto" | "clone">) =>
      () => {
        navigate({
          search: (old) => ({
            ...old,
            id,
            modal: action,
          }),
        });
      };
  const dispatchUpdateAction =
    (id: string, rate: string, action: Exclude<Action, "expand" | "goto" | "clone">) =>
      () => {
        navigate({
          search: (old) => ({
            ...old,
            id,
            rate: JSON.parse(rate)?.conversion?.rate,
            modal: action,
          }),
        });
      };
  const { refetch, payments, loading } = useOfferPayments({
    args: {
      offer_id: params.offer as string,
    },
  });
  return (
    <main className="flex-1 flex flex-col overflow-hidden bg-shade-500">
      <OfficeHeader
        renderActions={() => (
          <OfficeButton onClick={() => setModal("create")}>
            <DocumentPlusIcon className="h-5 w-5 mr-2" />
            Add Payment
          </OfficeButton>
        )}
      />
      <div className="flex flex-1 overflow-y-auto">
        <div className="flex-1 mx-auto min-w-0  py-4 sm:py-6 overflow-hidden overflow-y-auto light flex">
          <TableComponent
            loading={loading}
            title={"payments"}
            hasSearch
            columns={[
              {
                name: "Payment Type",
                accessor: "type",
                render: (value: GetOfferPayments_getOfferPayments) => (
                  <span>
                    {JSON.parse(value.payment_details || "{}")?.payment_type ||
                      "N/A"}
                    {JSON.parse(value.payment_details || "{}")?.payment_type ===
                      "Cheque"
                      ? " - "
                      : ""}
                    {JSON.parse(value.payment_details || "{}")?.payment_from
                      ?.cheque_number || ""}
                  </span>
                ),
              },
              {
                name: "Bank Name",
                accessor: "bank_name",
                render: (value: GetOfferPayments_getOfferPayments) => (
                  <span>
                    {JSON.parse(value.payment_details || "{}")?.payment_from
                      ?.bank_name || "N/A"}
                  </span>
                ),
              },
              {
                name: "Beneficiary Bank Name",
                accessor: "beneficiary_bank",
                render: (value: GetOfferPayments_getOfferPayments) => (
                  <span>
                    {JSON.parse(value.payment_details || "{}")?.payment_to ||
                      "N/A"}
                  </span>
                ),
              },
              {
                name: "Payment Amt",
                accessor: "payment_amount",
                render: (value: GetOfferPayments_getOfferPayments) => (
                  <span>{JSON.parse(value.payment_details || "{}")?.conversion?.currency || JSON.parse(value.payment_details || "{}")?.currency} {numeral(value.payment_amount).format("0,0.00")}</span>
                ),
              },
              {
                name: "Payment Date",
                accessor: "created_at",
                render: (value: GetOfferPayments_getOfferPayments) => (
                  <span>{moment(value.created_at).format("DD-MM-YYYY")}</span>
                ),
              },
              {
                name: "Last Payment Upate",
                accessor: "updated_at",
                render: (value: GetOfferPayments_getOfferPayments) => (
                  <span>{moment(value.updated_at).format("DD-MM-YYYY")}</span>
                ),
              },
              {
                name: "Distibution Status",
                accessor: "distribute_payment",
                // render: (value: GetOfferPayments_getOfferPayments) => (
                //   <span>{moment(value.updated_at).format("DD-MM-YYYY")}</span>
                // ),
              },
              {
                name: "Actions",
                accessor: "actions",
                render: (value: GetOfferPayments_getOfferPayments) => (
                  <>
                    {withRoles(["Finance Officer", "System Administrator", "Managing Director", "Managing Director", "CEO"])(
                      <ActionButton
                        action={"update"}
                        tooltip="Update payment details"
                        onClick={dispatchUpdateAction(value.offer_payment_id, value?.payment_details, "update")}
                      />
                    )}
                    {withRoles(["Finance Officer", "System Administrator", "Managing Director", "Managing Director", "CEO"])(
                      <ActionButton
                        action={"resolve"}
                        tooltip="Generate payment receipt"
                        onClick={dispatchAction(value.offer_payment_id, "view")}
                      />
                    )}
                    {value.distribute_payment !== "DISTRIBUTED" && withRoles(["Finance Officer", "System Administrator", "Managing Director", "Managing Director", "CEO"])(
                      <ActionButton
                        action={"distribute"}
                        tooltip="Dsitribute payment"
                        onClick={dispatchAction(value.offer_payment_id || "", "distribute")}
                      />
                    )}
                    {withRoles(["Finance Officer", "System Administrator", "Managing Director", "Managing Director", "CEO"])(
                      <ActionButton
                        action={"remove"}
                        tooltip="Remove payment"
                        onClick={dispatchAction(value.offer_payment_id, "remove")}
                      />
                    )}
                  </>
                ),
              },
            ]}
            data={payments}
            refetch={refetch}
          />
        </div>
      </div>
      {modal === "create" && (
        <CreateInsurerPaymentContainer
          open={modal === "create"}
          setOpen={(val: boolean) => setModal(val ? "create" : undefined)}
          refetch={refetch}
        />
      )}
      {modal === "distribute" && (
        <DistributePaymentContainer
          open={modal === "distribute"}
          setOpen={(val: boolean) => setModal(val ? "distribute" : undefined)}
          refetch={refetch}
        />
      )}
      {modal === "update" && (
        <UpdateInsurerPaymentContainer
          open={modal === "update"}
          setOpen={(val: boolean) => setModal(val ? "update" : undefined)}
          refetch={refetch}
        />
      )}
      {searchParams.id && (
        <>
          <ViewInsurerPaymentReceiptContainer
            open={modal === "view"}
            setOpen={(val: boolean) => setModal(val ? "view" : undefined)}
          />
          <RemoveContainer
            open={modal === "remove"}
            setOpen={(val: boolean) => setModal(val ? "remove" : undefined)}
          />
        </>
      )}
    </main>
  );
};

export default InsurerPaymentsPage;
