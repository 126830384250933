import { wrapClick } from "utils";
import { useFormik } from "formik";
import { Modal } from "components";
import { DuplicateTreatyCurrencyForm } from "components/forms";
import { useMatch } from "react-location";
import { LocationGenerics } from "router/location";
import { useTreaty, useTreatyAccounts } from "hooks/data/use-treaties";
import _ from "lodash";
import { useMutation } from "@apollo/client";
import { DuplicateTreaty, DuplicateTreatyVariables } from "_graphql/mutation/__generated__/DuplicateTreaty";
import { duplicateTreatyCurrency } from "_graphql/mutation/treaty";
import toast from "react-hot-toast";




export default function DuplicateTretyCurrencyContainer({
    open,
    setOpen,
    refetch,
}: {
    open: boolean;
    setOpen: (val: boolean) => void;
    refetch?: () => void;
}) {
    const { params } = useMatch<LocationGenerics>();
    const { treaty, loading: fetching } = useTreaty({
        treatyId: params.treaty || "",
    });
    const [duplicate, { loading }] = useMutation<DuplicateTreaty, DuplicateTreatyVariables>(duplicateTreatyCurrency, {
        refetchQueries: ["Treaty"],
    });
    const { accounts, loading: loadingAccounts } = useTreatyAccounts({
        insurer_id: treaty?.insurer?.insurer_id || "",
        treaty_period_from: treaty?.treaty_deduction?.treaty_period_from || "",
        treaty_period_to: treaty?.treaty_deduction?.treaty_period_to || "",
        type: true,
        treaty_program_name: treaty?.treaty_program?.treaty_name || "",
    });


    const form = useFormik<any>({
        initialValues: {
            currency: [],
        },
        onSubmit: async (variables) => {
            duplicate({
                variables: {
                    treaty_id: params.treaty,
                    currencies: variables.currency,
                },
            }).then(({ data }) => {
                if (data?.duplicateTreaty) {
                    toast.success("Treaty duplicated successfully");
                    refetch && refetch();
                    setOpen(false);
                }
            }).catch(er => {
                toast.error("Failed to duplicate treaty");
                console.log(er)
            });
        },
        onReset: () => {
            setOpen(false);
        },
    });

    return (
        <Modal
            open={open}
            setOpen={setOpen}
            title={"Treaty currency"}
            loading={fetching || loadingAccounts}
            description="Select the currency you want to duplicate the treaty to"
            renderActions={() => (
                <>
                    <button
                        type="button"
                        disabled={loadingAccounts || fetching}
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={wrapClick(form.handleSubmit)}
                    >
                        {loading ? "Saving changes..." : "Save changes"}
                    </button>
                </>
            )}
        >
            <DuplicateTreatyCurrencyForm form={form} treaty={treaty} accounts={accounts} />
        </Modal>
    );
}
