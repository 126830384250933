import { useQuery } from "@apollo/client";
import {
  GetDashboardOverview,
  GetDashboardOverviewVariables,
} from "_graphql/queries/__generated__/GetDashboardOverview";
import { getDashboardOverview } from "_graphql/queries/dashboard";

export const useOverview = (variables?: GetDashboardOverviewVariables) => {
  const { data, ...rest } = useQuery<
    GetDashboardOverview,
    GetDashboardOverviewVariables
  >(getDashboardOverview, {
    variables,
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
  });
  return {
    overview: data?.getDashboardOverview,
    ...rest,
  };
};
