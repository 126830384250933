import { GetReinsurerOffers_getReinsurerOffers } from "_graphql/queries/__generated__/GetReinsurerOffers";
import clsx from "clsx";
import {
  ActionButton,
  Paginator,
  TableComponent,
  TableLoader,
} from "components";
import { Action } from "components/buttons/action-button";
import { usePagination } from "hooks";
import { useReInsurerOffers } from "hooks/data/use-offers";
import _ from "lodash";
import moment from "moment";
import numeral from "numeral";
import { useMemo } from "react";
import { useSearch, useMatch, useNavigate } from "react-location";
import { LocationGenerics } from "router/location";
import SetupDeductionContainer from "./setup-deductions";
import ViewReinsurerOfferContainer from "./view-offer";
import YearPicker from "layouts/calendar-wrapper/year-picker";

type Props = {};

const OfferParticipation = (props: Props) => {
  const searchParams = useSearch<LocationGenerics>();
  const { params } = useMatch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const { offset, limit, setPage, search } = usePagination();

  const filter = useMemo(
    () => ({
      page: searchParams.page || 1,
      pageSize: searchParams.pageSize || 12,
      search: (search || "").toString(),
      args: {
        reinsurer_id: params?.reInsurer,
        status: ["PENDING", "OPEN", "CLOSED"],
        year: searchParams?.year?.toString(),
      },
    }),
    [searchParams, params?.reInsurer, search]
  );

  const { loading, offers, refetch, count } = useReInsurerOffers(filter);

  const dispatchAction =
    (id: string, action: Exclude<Action, "expand" | "goto" | "clone">, offer_participant_id?: string) =>
      () => {
        navigate({
          search: (old) => ({
            ...old,
            id,
            modal: action,
            reinsurer: offer_participant_id
          }),
        });
      };

  const expandItem = (id: string, offer_id: string) => {
    navigate({
      to: `./${id}/payments/${offer_id}`,
    });
  };

  return (
    <div className="flex flex-1 overflow-y-auto">
      <div className="flex-1 mx-auto min-w-0  py-4 sm:py-6 overflow-hidden overflow-y-auto light flex">
        <TableComponent
          title={"offers"}
          hasSearch
          loading={loading}
          pagination={() => (
            <Paginator
              offset={offset}
              limit={limit}
              totalItems={count || 0}
              currentSize={offers.length}
              setOffset={setPage}
            />
          )}
          renderHeaderItems={() => (
            <YearPicker />
          )}
          columns={[
            {
              name: "Policy #",
              accessor: "reinsurer_offers_only.offer_detail.policy_number",
            },
            {
              name: "Class of Business",
              accessor: "reinsurer_offers_only.classofbusiness.business_name",
            },
            {
              name: "Insurer | Insured",
              accessor: "reinsurer_offers_only.insurer.insurer_company_name",
              render: (value: GetReinsurerOffers_getReinsurerOffers) => (
                <div className="flex flex-col">
                  <span>
                    {
                      value?.reinsurer_offers_only?.insurer
                        ?.insurer_company_name
                    }
                  </span>
                  <span className="font-bold">
                    {" "}
                    {value?.reinsurer_offers_only?.offer_detail?.insured_by}
                  </span>
                </div>
              ),
            },
            {
              name: "Participating %",
              accessor: "offer_participant_percentage",
            },
            {
              name: "Sum Insured",
              accessor: "reinsurer_offers_only.sum_insured",
              render: (value: GetReinsurerOffers_getReinsurerOffers) => (
                <span>
                  {value?.offer_detail?.currency}{" "}
                  {numeral(value?.reinsurer_offers_only?.sum_insured).format(
                    "0,0.00"
                  )}
                </span>
              ),
            },

            {
              name: "Comission (%)",
              accessor: "reinsurer_offers_only.commission",
            },
            {
              name: "Offer Status",
              accessor: "reinsurer_offers_only.offer_status",
              render: ({
                reinsurer_offers_only: { offer_status },
              }: GetReinsurerOffers_getReinsurerOffers) => (
                <>
                  <div
                    className={clsx(
                      "w-auto flex items-center justify-center p-0.5 rounded-full",
                      {
                        "bg-yellow-100": offer_status === "PENDING",
                        "bg-blue-100": offer_status === "OPEN",
                        "bg-green-100": offer_status === "CLOSED",
                      }
                    )}
                  >
                    <span
                      className={clsx("text-xs font-bold", {
                        "text-yellow-600": offer_status === "PENDING",
                        "text-blue-600": offer_status === "OPEN",
                        "text-green-600": offer_status === "CLOSED",
                      })}
                    >
                      {_.startCase(offer_status?.toLowerCase())}
                    </span>
                  </div>
                </>
              ),
            },
            {
              name: "Payment Status",
              accessor: "reinsurer_offers_only.payment_status",
              render: ({
                reinsurer_offers_only: { payment_status },
              }: GetReinsurerOffers_getReinsurerOffers) => (
                <div>
                  <div
                    className={clsx(
                      "w-auto flex items-center justify-center p-0.5 rounded-full",
                      {
                        "bg-red-100": payment_status === "UNPAID",
                        "bg-teal-100": payment_status === "PAID",
                        "bg-sky-100": payment_status === "PARTPAYMENT",
                      }
                    )}
                  >
                    <span
                      className={clsx("text-xs font-bold", {
                        "text-red-600": payment_status === "UNPAID",
                        "text-teal-600": payment_status === "PAID",
                        "text-sky-600": payment_status === "PARTPAYMENT",
                      })}
                    >
                      {_.startCase(payment_status?.toLowerCase())}
                    </span>
                  </div>
                </div>
              ),
            },
            {
              name: "Offer Date",
              accessor: "offer_date",
              render: (value: GetReinsurerOffers_getReinsurerOffers) => (
                <span>
                  {moment(value?.reinsurer_offers_only?.created_at).format(
                    "DD-MM-YYYY"
                  )}
                </span>
              ),
            },
            {
              name: "Actions",
              accessor: "salary",
              render: ({
                offersoffer_id,
                offer_participant_id,
              }: GetReinsurerOffers_getReinsurerOffers) => (
                <>
                  <ActionButton
                    action="view"
                    tooltip="View Offer"
                    onClick={dispatchAction(offersoffer_id || "", "view")}
                  />
                  <ActionButton
                    action="configure"
                    tooltip="Offer Deductions"
                    onClick={dispatchAction(
                      offersoffer_id || "",
                      "configure"
                    )}
                  />
                  <ActionButton
                    action="expand"
                    tooltip="View payments"
                    onClick={() =>
                      expandItem(
                        offer_participant_id || "",
                        offersoffer_id || ""
                      )
                    }
                  />
                </>
              ),
            },
          ]}
          data={offers}
          refetch={refetch}
          renderLoader={() => (
            <TableLoader items={[1, 1, 1, 1, 1, 1, 1, 1, 1, 1]} />
          )}
        />
      </div>
      {searchParams?.id && (
        <>
          <SetupDeductionContainer open={searchParams?.modal === "configure"} setOpen={() => navigate({
            search: (old) => ({
              ...old,
              modal: undefined,
              id: undefined,
            }),
          })} />
          <ViewReinsurerOfferContainer open={searchParams?.modal === "view"} setOpen={() => navigate({
            search: (old) => ({
              ...old,
              modal: undefined,
              id: undefined,
            }),
          })} />
        </>
      )}
    </div>
  );
};

export default OfferParticipation;
