import { makeVar, useReactiveVar } from "@apollo/client";
import Cookies from "js-cookie";
import config from "config";
import { Permissions } from "_graphql/data";
import { Login_login_user } from "_graphql/mutation/__generated__/Login";
import { useMatch, useNavigate, useSearch } from "react-location";
import { LocationGenerics } from "router/location";

export const UserPermissions = Permissions.map(({ value }) => value);
export const UserRoles = Permissions.map(({ value }) => value);
export type UserPermission = (typeof UserPermissions)[number];
export type UserRole = (typeof UserRoles)[number];





export const isLoggedInVar = makeVar<boolean>(!!Cookies.get(`visalre:auth:token`));
export const languageVar = makeVar<boolean>(!!Cookies.get(`visalre:app:language`));
export const currentTokenVar = makeVar<string | null>(
  localStorage.getItem(`visalre:auth:token`) || null
);
export const currentUserVar = makeVar<Login_login_user>(
  JSON.parse(localStorage.getItem(`visalre:auth:user`) ?? "{}") ?? null
);
export const useCurrentUser = () => useReactiveVar(currentUserVar);
export const useCurrentLanguage = () => useReactiveVar(languageVar);
export const useAppSearch = () => useSearch<LocationGenerics>()
export const useAppLocation = () => useMatch<LocationGenerics>()
export const useAppNavigate = () => useNavigate<LocationGenerics>()
export const currentPushTokenVar = makeVar<string | null>(
  Cookies.get(`visalre:auth:push-token`) || null
);

export const setAuth = ({ user, token }: { user: Login_login_user; token: string }) => {
  currentUserVar(user);
  localStorage.setItem(`visalre:auth:user`, JSON.stringify(user));
  isLoggedInVar(true);
  currentTokenVar(token);
  console.log("Setting User", user)
  localStorage.setItem(`visalre:auth:token`, token);
};

export const setMe = (user: Login_login_user) => {
  currentUserVar(user);
  console.log("Setting User", user)
  localStorage.setItem(`visalre:auth:user`, JSON.stringify(user));
};

export const setPushToken = (token: any) => {
  currentPushTokenVar(token);
  Cookies.set(`visalre:auth:push-token`, token, { ...config.cookies, expires: 1, secure: true, sameSite: "strict" });
};

export const clearAuth = () => {
  isLoggedInVar(false);
  currentTokenVar(null);
  // TODO: handle extra checks for better user experience
  Object.keys(Cookies.get()).forEach((key) => {
    localStorage.removeItem(key);
  });
};
