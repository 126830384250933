import { Switch } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { ReInsurerAssociatesPicker, ReInsurerPicker } from "components/core";
import { FormikProps } from "formik";
import _ from "lodash";
import numeral from "numeral";
import { FC } from "react";
import { classNames, wrapClick } from "utils";

export interface AddParticipantFormProps {
    form: FormikProps<any>;
}

const AddParticipantForm: FC<AddParticipantFormProps> = ({ form }) => {
    const removeItem = (field: string, id: number) => {
        form.setFieldValue(field, [
            ..._.get(form.values, field)?.filter(
                (val: string, _id: number) => _id !== id
            ),
        ]);
    };

    return (
        <div className="space-y-6 divide-y divide-gray-200">
            <div>
                <div className="grid grid-cols-2 gap-2">
                    <div className="col-span-3">
                        {/* {JSON.stringify(_.groupBy(form.values.requests, "participant_id.reinsurersreinsurer_id"), null, 2)} */}
                        <div className="">
                            <span className="text-xs font-light">Participants</span>
                            <div className="grid grid-cols-3 gap-6 mt-2">
                                <div className="col-span-3">
                                    <span className="block text-sm font-medium text-gray-700">
                                        Select Participants
                                    </span>
                                    <div className="mt-1 w-full ">
                                        <table className="min-w-full divide-y divide-gray-300 border border-gray-300 rounded-md">
                                            <thead className="bg-gray-50">
                                                <tr className="divide-x divide-gray-200">
                                                    <th
                                                        scope="col"
                                                        className="whitespace-nowrap px-2 py-2 text-left text-sm font-semibold text-gray-900"
                                                    >
                                                        Associates
                                                    </th>

                                                    <th
                                                        scope="col"
                                                        className="whitespace-nowrap px-2 py-2 text-sm font-semibold text-gray-900 w-[38px]"
                                                    ></th>
                                                </tr>
                                            </thead>
                                            <tbody className="divide-y divide-gray-200 bg-white">
                                                {_.times(form.values.requests?.length + 1, (idx) => {
                                                    const id = `requests.${idx}`;
                                                    const isLast = (form.values.requests?.length || 0) === idx;

                                                    return (
                                                        <tr key={idx} className="divide-x divide-gray-200">
                                                            <td className="p-0">
                                                                <ReInsurerAssociatesPicker
                                                                    label={""} labelHidden key={idx}
                                                                    id={`${id}.participant_id`}
                                                                    {...form}
                                                                    rounded={true}
                                                                    placeholder={"eg. Emmanuel Baidoo"}
                                                                />
                                                            </td>
                                                            <td className="p-0 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400 text-right w-[38px] h-[38px] items-center justify-center flex">
                                                                {!isLast && (
                                                                    <button
                                                                        type="button"
                                                                        onClick={wrapClick(() =>
                                                                            removeItem("requests", idx)
                                                                        )}
                                                                        className="inline-flex items-center rounded-full border border-transparent p-1 text-red-500 hover:bg-red-300 hover:text-red-900 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                                                                    >
                                                                        <XMarkIcon
                                                                            className="h-5 w-5"
                                                                            aria-hidden="true"
                                                                        />
                                                                    </button>
                                                                )}
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default AddParticipantForm;
