import { FC, useEffect, useMemo } from "react";
import { useNavigate, useSearch } from "react-location";
import {
  DocumentPlusIcon,
  EnvelopeIcon,
  IdentificationIcon,
  LockClosedIcon,
  RectangleStackIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import { useReactiveVar } from "@apollo/client";
import lodash from "lodash";
import { currentUserVar, UserRole } from "_graphql/cache/auth";
import numeral from "numeral";
import { useOverview } from "hooks/data/use-overview";
import { LocationGenerics } from "router/location";
import moment from "moment";
import {
  Avatar,
  CalendarHeader,
  EmployeePicker,
  Loader,
  Pie,
} from "components";
import { useFormik } from "formik";
import { classNames, withRoles } from "utils";
import _ from "lodash";

const stats = [
  {
    id: 1,
    name: "Total Offers",
    accessor: "create-slip",
    icon: RectangleStackIcon,
    valueKey: "total_offers",
  },
  {
    id: 2,
    name: "Total Pending Offers",
    accessor: "create-slip",
    icon: DocumentPlusIcon,
    valueKey: "total_pending_offers",
  },
  {
    id: 3,
    name: "Total Closed Offers",
    accessor: "create-closing",
    valueKey: "total_closed_offers",
    icon: LockClosedIcon,
  },
  {
    id: 4,
    name: "Total open Offers",
    accessor: "create-closing",
    valueKey: "total_open_offers",
    icon: LockClosedIcon,
  },
  {
    id: 5,
    name: "Total Claims",
    accessor: "create-closing",
    valueKey: "total_claim_offers",
    icon: LockClosedIcon,
  },
  {
    id: 6,
    name: "Total Unclaimed Offers",
    accessor: "create-closing",
    valueKey: "total_unclaim_offers",
    icon: LockClosedIcon,
  },
];


type ServiceRequestStatsType = {
  id: number;
  name: string;
  icon: any;
  accessor: string;
  accessRoles?: UserRole[];
}

const serviceRequestStats: ServiceRequestStatsType[] = [
  {
    id: 1,
    name: "Brokers",
    icon: UsersIcon,
    accessor: "total_brokers",
    accessRoles: [],
  },
  {
    id: 2,
    name: "Re-Insurers",
    icon: UsersIcon,
    accessor: "total_reinsurers",
    accessRoles: []
  },
  {
    id: 3,
    name: "Insurers",
    icon: UsersIcon,
    accessor: "total_insurers",
    accessRoles: []
  },
];

type AmountValueType = {
  title: string;
  value: string;
  display: "tab" | "pie";
  accessRoles?: UserRole[];
}

const amountValues: AmountValueType[] = [
  {
    title: "Brokerage Earning",
    value: "brokerage_earnings",
    display: "tab",
    accessRoles: ["CEO", "Senior Broking Officer", "Finance Officer"],
  },
  {
    title: "Premium",
    value: "total_premium",
    display: "pie",
    accessRoles: []
  },
  {
    title: "Facultative Premium",
    value: "total_fac_premium",
    display: "pie",
    accessRoles: []
  },
  {
    title: "Sum Insured",
    value: "total_sum_insured",
    display: "pie",
    accessRoles: []
  },
  {
    title: "Facultative Sum Insured",
    value: "total_fac_sum_insured",
    display: "pie",
    accessRoles: []
  },
];

const DashboardPage: FC = () => {
  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const currentUser = useReactiveVar(currentUserVar);

  const form = useFormik<any>({
    initialValues: {
      employee: "",
    },
    onSubmit: (values) => {
      navigate({
        search: (old) => ({
          ...old,
          ...values,
        }),
      });
    },
  });

  const filter = useMemo(
    () => ({
      from: searchParams.fromDate || moment().format("YYY-MM-DD"),
      to: searchParams.toDate || moment().format("YYY-MM-DD"),
      for: form.values.employee,
      filter_by: ["OPEN", "PENDING", "CLOSED"],
    }),
    [form.values.employee, searchParams.fromDate, searchParams.toDate]
  );
  const { overview, loading } = useOverview({
    dashboardInput: filter,
  });

  useEffect(() => {
    navigate({
      search: (old) => ({
        ...old,
        ...(!old?.fromDate
          ? {
            fromDate: searchParams?.fromDate || moment().format("YYYY-MM-DD"),
          }
          : {}),
        ...(!old?.toDate
          ? { toDate: searchParams?.toDate || moment().format("YYYY-MM-DD") }
          : {}),
        ...(!old?.view ? { view: "month" } : {}),
      }),
    });
  }, [navigate, searchParams?.fromDate, searchParams?.toDate]);

  if (loading) return <Loader />;

  return (
    <main className="flex-1 flex flex-col overflow-hidden h-screen bg-shade-500">
      <CalendarHeader
        renderActions={() => (
          <>
            {withRoles(["System Administrator", "Managing Director", "CEO", "Senior Broking Officer"])(
              <EmployeePicker
                id={"employee"}
                placeholder="Select Employee"
                labelHidden
                rawId
                {...form}
              />
            )}
          </>
        )}
      />
      <div className="flex-1 p-4 sm:p-6 overflow-y-auto bg-shade-500">
        <div className="grid grid-cols-1 items-start gap-4 lg:grid-cols-12 lg:gap-8">
          <div className="col-span-12">
            <dl className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4">
              <div className="relative isolate overflow-hidden rounded-lg bg-primary-400 px-4 pt-3 shadow sm:px-6 sm:pt-6 row-span-1 col-span-2">
                <div className="absolute inset-y-0 right-8 -z-10 mr-16 w-[200%] origin-bottom-left skew-x-[-15deg] bg-primary-500 shadow-xl shadow-primary-400 ring-1 ring-primary-400 sm:mr-28 lg:mr-0 xl:mr-16 xl:origin-center" />
                <div className="absolute inset-y-0 right-24 -z-10 mr-16 w-[200%] origin-bottom-left skew-x-[-20deg] bg-primary-600 shadow-xl shadow-primary-500 ring-1 ring-primary-500 sm:mr-28 lg:mr-0 xl:mr-16 xl:origin-center" />
                <div className="absolute inset-0 -z-10 bg-gradient-to-b from-transparent via-primary-600/80 to-primary-600" />
                <div className="">
                  {/* Profile */}
                  <div className="flex items-center">
                    <Avatar
                      alt={[
                        currentUser?.employee?.employee_first_name || "U",
                        currentUser?.employee?.employee_last_name || "A",
                      ].join(" ")}
                      size="md"
                      src={""}
                    />
                    <div>
                      <div className="flex items-center">
                        <h1 className="ml-3 text-white text-xl font-extralight leading-7  sm:truncate sm:leading-9">
                          Welcome back{" "}
                          <span className="font-semibold">
                            {currentUser?.employee?.employee_first_name}
                          </span>
                        </h1>
                      </div>
                      <dl className="mt-6 flex flex-col sm:ml-3 sm:mt-1 sm:flex-row sm:flex-wrap">
                        <dt className="sr-only">Company</dt>
                        <dd className="flex items-center text-sm font-medium capitalize text-gray-50 sm:mr-6">
                          <EnvelopeIcon
                            className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-100"
                            aria-hidden="true"
                          />
                          {currentUser?.employee?.employee_email}
                        </dd>
                        <dt className="sr-only">Account status</dt>
                        <dd className="mt-3 flex items-center text-sm font-medium capitalize text-gray-50 sm:mr-6 sm:mt-0">
                          <IdentificationIcon
                            className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-100"
                            aria-hidden="true"
                          />
                          {currentUser?.position}
                        </dd>
                      </dl>
                    </div>
                  </div>
                </div>
              </div>
              {true &&
                stats.map((item) => (
                  <div
                    key={item.id}
                    className="relative overflow-hidden rounded-lg bg-card-500 px-4 pt-5 shadow sm:px-6 sm:pt-6"
                  >
                    <dt>
                      <div className="absolute rounded-md bg-card-200 p-3">
                        <item.icon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </div>
                      <p className="ml-16 truncate text-sm font-medium text-gray-300">
                        {item.name}
                      </p>
                    </dt>
                    <dd className="ml-16 flex justify-between items-end pb-6 sm:pb-7">
                      <p className="text-2xl font-semibold text-gray-200">
                        {numeral(lodash.get(overview, item.valueKey, 0)).format(
                          "0,0"
                        )}
                      </p>
                    </dd>
                  </div>
                ))}
            </dl>
          </div>
        </div>
        <div className="mt-6">
          <h3 className="text-base font-semibold leading-6 text-gray-300">
            Clients Overview
          </h3>

          <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
            {serviceRequestStats.map((item) => withRoles(item?.accessRoles || [])(
              <div
                key={item.id}
                className="flex items-center space-x-6 overflow-hidden rounded-lg bg-card-500 p-4 shadow sm:p-6"
              >
                <div className="rounded-md bg-primary-200 flex items-center justify-center aspect-square h-14 w-14">
                  <item.icon
                    className="h-6 w-6 text-primary-600"
                    aria-hidden="true"
                  />
                </div>
                <div className="flex-1">
                  <dt>
                    <p className=" truncate text-sm font-medium text-gray-100">
                      {item.name}
                    </p>
                  </dt>
                  <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
                    <div className="flex items-baseline text-2xl font-semibold text-white">
                      {numeral(lodash.get(overview, item.accessor, 0)).format(
                        "0,0"
                      )}
                    </div>
                  </dd>
                </div>
              </div>
            ))}
          </dl>
        </div>
        <div className="mt-6">
          <div className="grid grid-cols-2 gap-5">
            {amountValues.map(({ title, value, display, accessRoles }) =>
              withRoles(accessRoles || [])(
                <>
                  {display === "pie" ? (
                    <div className="flex flex-col">
                      <h3 className="text-md font-medium leading-6 text-gray-300">
                        {title}
                      </h3>
                      <div className="mt-3 flex bg-view-500 items-center space-x-6 overflow-hidden rounded-lg bg-card-500 p-4 shadow sm:p-6">
                        <div className="flex-1 flex space-x-12">
                          <div className="flex-1 space-y-3 my-auto">
                            {[
                              {
                                color: "#2563EB",
                                bgColor: "bg-blue-600",
                                id: "Ghanaian Cedi",
                                label: "Ghanaian Cedi",
                                value:
                                  numeral(
                                    lodash.get(overview, `${value}.total_cedis`, 0)
                                  ).value() || 0,
                              },
                              {
                                color: "#0d9488",
                                bgColor: "bg-teal-600",
                                id: "US Dollar",
                                label: "US Dollar",
                                value:
                                  numeral(
                                    lodash.get(overview, `${value}.total_dollar`, 0)
                                  ).value() || 0,
                              },
                              {
                                color: "#9333ea",
                                bgColor: "bg-purple-600",
                                id: "Euro",
                                label: "Euro",
                                value:
                                  numeral(
                                    lodash.get(overview, `${value}.total_euros`, 0)
                                  ).value() || 0,
                              },
                              {
                                color: "#CA8A04",
                                bgColor: "bg-yellow-600",
                                id: "British Pound",
                                label: "British Pound",
                                value:
                                  numeral(
                                    lodash.get(overview, `${value}.total_pounds`, 0)
                                  ).value() || 0,
                              },
                            ]?.map?.((item: any) => (
                              <div
                                key={item?._id}
                                className="rounded-lg bg-card-500 p-2 flex space-x-4"
                              >
                                <div>
                                  <div
                                    className={classNames(
                                      item?.bgColor,
                                      "rounded-md flex items-center justify-center p-2.5",
                                      `bg-[${item?.color}]`
                                    )}
                                  >
                                    <span
                                      className="text-lg text-white"
                                      aria-hidden="true"
                                    ></span>
                                  </div>
                                </div>
                                <div className="flex flex-col justify-between h-full flex-1 text-left space-y-2">
                                  <div>
                                    <dt className="truncate text-xs text-gray-500">
                                      {item?.label}
                                    </dt>
                                    <dd className="flex items-baseline justify-between">
                                      <p className="text-xl font-normal text-gray-100">
                                        {numeral(item?.value).format("0,0.00")}
                                      </p>
                                    </dd>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                          {/* {numeral(lodash.get(overview, `${value}.total_cedis`)).value()} */}
                          <div className="w-72 h-72">
                            <Pie
                              data={[
                                {
                                  color: "#2563EB",
                                  bgColor: "bg-blue-600",
                                  id: "Ghanaian Cedi",
                                  label: "Ghanaian Cedi",
                                  value:
                                    numeral(
                                      lodash.get(
                                        overview,
                                        `${value}.total_cedis`,
                                        0
                                      )
                                    ).value() || 0,
                                },
                                {
                                  color: "#0d9488",
                                  bgColor: "bg-teal-600",
                                  id: "US Dollar",
                                  label: "US Dollar",
                                  value:
                                    numeral(
                                      lodash.get(
                                        overview,
                                        `${value}.total_dollar`,
                                        0
                                      )
                                    ).value() || 0,
                                },
                                {
                                  color: "#9333ea",
                                  bgColor: "bg-purple-600",
                                  id: "Euro",
                                  label: "Euro",
                                  value:
                                    numeral(
                                      lodash.get(
                                        overview,
                                        `${value}.total_euros`,
                                        0
                                      )
                                    ).value() || 0,
                                },
                                {
                                  color: "#CA8A04",
                                  bgColor: "bg-yellow-600",
                                  id: "British Pound",
                                  label: "British Pound",
                                  value:
                                    numeral(
                                      lodash.get(
                                        overview,
                                        `${value}.total_pounds`,
                                        0
                                      )
                                    ).value() || 0,
                                },
                              ]}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="space-y-3 first:col-span-2">
                      <span className="font-medium text-gray-300 text-sm">{title}</span>
                      <div className="rounded-lg bg-card-500 p-4 shadow sm:p-6 grid grid-cols-4">
                        <div className="flex flex-col space-y-2 items-start">
                          <span className="text-xs text-gray-300">Total GHS</span>
                          <span className="text-lg font-bold text-gray-200">
                            {lodash.get(overview, `${value}.total_cedis`, 0)}
                          </span>
                        </div>
                        <div className="flex flex-col space-y-2 items-start">
                          <span className="text-xs text-gray-300">Total USD</span>
                          <span className="text-lg font-bold text-gray-200">
                            {lodash.get(overview, `${value}.total_dollar`, 0)}
                          </span>
                        </div>
                        <div className="flex flex-col space-y-2 items-start">
                          <span className="text-xs text-gray-300">Total EUR</span>
                          <span className="text-lg font-bold text-gray-200">
                            {lodash.get(overview, `${value}.total_euros`, 0)}
                          </span>
                        </div>
                        <div className="flex flex-col space-y-2 items-start">
                          <span className="text-xs text-gray-300">Total GBP</span>
                          <span className="text-lg font-bold text-gray-200">
                            {lodash.get(overview, `${value}.total_pounds`, 0)}
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )
            )}
          </div>
        </div>
      </div>
    </main>
  );
};

export default DashboardPage;
