import { useQuery } from "@apollo/client";
import { GetEndorsement, GetEndorsementVariables } from "_graphql/queries/__generated__/GetEndorsement";
import {
  GetEndorsements,
  GetEndorsementsVariables,
} from "_graphql/queries/__generated__/GetEndorsements";
import { getEndorsement, getOfferEndorsements } from "_graphql/queries/endorsements";

export const useEndorsements = (variables: GetEndorsementsVariables) => {
  const { data, ...rest } = useQuery<GetEndorsements, GetEndorsementsVariables>(
    getOfferEndorsements,
    {
      fetchPolicy: "network-only",
      variables,
      notifyOnNetworkStatusChange: true,
    }
  );

  return {
    endorsements: data?.rows || [],
    count: data?.count,
    ...rest,
  };
};
export const useEndorsement = (variables: GetEndorsementVariables) => {
  const { data, ...rest } = useQuery<GetEndorsement, GetEndorsementVariables>(
    getEndorsement,
    {
      fetchPolicy: "no-cache",
      variables,
      notifyOnNetworkStatusChange: true,
    }
  );

  return {
    endorsement: data?.singleEndorsement,
    ...rest,
  };
};
