import { gql } from "@apollo/client";

export const createOfferClaim = gql`
  mutation MakeClaim($data: Claim_data!) {
    makeClaimOnOffer(claim_data: $data)
  }
`;
export const updateOfferClaim = gql`
  mutation UpdateClaimAmount($offerClaimId: ID!, $claimData: Claim_data!) {
    updateClaimAmount(offer_claim_id: $offerClaimId, claim_data: $claimData)
  }
`;

export const removeOfferClaim = gql`
  mutation DeleteClaimAmount($offerId: ID!, $offerClaimId: ID!) {
    deleteClaimAmount(offer_id: $offerId, offer_claim_id: $offerClaimId)
  }
`;

export const sendClaimNotification = gql`
  mutation SendClaimNotification($emailData: Email_data!) {
    sendClaimNotification(email_data: $emailData)
  }
`;
