import {
  InsurerPicker,
  OfficeButton,
  PDFRenderer,
  ReInsurerPicker,
  TextInput,
} from "components";
import config from "config";
import { useFormik } from "formik";
import _ from "lodash";
import { useEffect } from "react";
import { wrapClick } from "utils";
import * as Yup from "yup";

type Props = {};

const MonthlyReportTab = () => {
  const form = useFormik<any>({
    initialValues: {
      from: "",
      to: "",
    },
    onSubmit: () => {
      form.setFormikState((state) => ({ ...state, status: "SUBMIITED" }));
      form.setSubmitting(false);
      return;
    },
    validationSchema: Yup.object().shape({
      from: Yup.date().required("From is required"),
      to: Yup.date().required("To  is required"),
    }),
    initialStatus: "NOT_SUBMIITED",
  });

  useEffect(() => {
    form.setFormikState((state) => ({ ...state, status: "NOT_SUBMIITED" }));
    form.setSubmitting(false);
  }, [form.values.currency, form.values.date, form.values.cheque]);

  return (
    <div className="w-full">
      <div className="py-3 flex justify-between">
        <h1 className="font-bold text-gray-300 text-lg">Monthly Report</h1>
        <OfficeButton
          disabled={form.isSubmitting}
          onClick={wrapClick(form.handleSubmit)}
        >
          {form.isSubmitting ? "Generating ..." : "Generate Report"}
        </OfficeButton>
      </div>
      <form className="bg-white p-6 space-y-6">
        <div className=" grid gap-3  grid-cols-2 shadow-sm">
          <div>
            <TextInput
              id="from"
              label="From"
              type="date"
              required={true}
              {...form}
            />
          </div>
          <div>
            <TextInput
              id="to"
              label="To"
              type="date"
              required={true}
              {...form}
            />
          </div>
        </div>
      </form>
      {form.status === "SUBMIITED" && form.isValid && (
        <PDFRenderer
          url={`${config.asset.uri}excel_monthly_brokerage/${btoa(
            JSON.stringify({
              from: form?.values?.from,
              to: form?.values?.to,
            })
          )}`}
        />
      )}
    </div>
  );
};

export default MonthlyReportTab;
