import { TreatyProgram_treatyProgram } from "_graphql/queries/__generated__/TreatyProgram";
import clsx from "clsx";
import {
    CurrencyPicker,
    SelectInput,
    TextArea,
    TextInput,
} from "components/core";
import { FormikProps } from "formik";
import _ from "lodash";
import { FC } from "react";

export interface DeductionFormProps {
    form: FormikProps<any>;
    program?: TreatyProgram_treatyProgram | null;
}

const DeductionForm: FC<DeductionFormProps> = ({ form, program }) => {
    return (
        <div className="space-y-6 divide-y divide-gray-200">
            <div className="mt-6 border border-gray-300 border-dashed bg-gray-100 divide-y divide-dashed divide-gray-300 rounded-md">
                <div className="grid grid-cols-1 gap-4 p-4">
                    <div className="">
                        <span className="block text-sm font-light text-gray-700">
                            Treaty Program
                        </span>
                        <div className="mt-1 block w-full sm:text-sm font-medium">
                            {program?.treaty_name || "N/A"}
                        </div>
                    </div>
                    <div className="col-span-2">
                        <span className="block text-sm font-light text-gray-700">
                            Insurer
                        </span>
                        <div className="mt-1 block w-full sm:text-sm font-medium">
                            {program?.insurer?.insurer_company_name || "N/A"}
                        </div>
                    </div>
                    <div className="">
                        <span className="block text-sm font-light text-gray-700">
                            Last Known Period
                        </span>
                        <div className="mt-1 block w-full sm:text-sm font-medium">
                            {`${_.last(program?.treaty_associate_deductions)?.treaty_period_from} to ${_.last(program?.treaty_associate_deductions)?.treaty_period_to}` || "N/A"}
                        </div>
                    </div>

                </div>
            </div>

            <div className="pt-3">
                <span className="text-xs font-light">Deductions</span>
                <div className="grid grid-cols-2 gap-2">
                    <div>
                        <TextInput
                            type="number"
                            id="deductions.commission"
                            label="Commision (%)"
                            placeholder="Commision (%)"
                            required={true}
                            {...form}
                        />
                    </div>
                    <div>
                        <TextInput
                            type="number"
                            id="deductions.brokerage"
                            label="Brokerage (%)"
                            placeholder="Brokerage (%)"
                            required={true}
                            {...form}
                        />
                    </div>
                    <div>
                        <TextInput
                            type="number"
                            id="deductions.nic_levy"
                            label="NIC Levy "
                            placeholder="NIC Levy "
                            required={true}
                            {...form}
                        />
                    </div>
                    <div>
                        <TextInput
                            type="number"
                            id="deductions.withholding_tax"
                            label="Withholding Tax (%)"
                            placeholder="Withholding Tax (%)"
                            required={true}
                            {...form}
                        />
                    </div>
                    <div>
                        <TextInput
                            type="date"
                            id="deductions.treaty_period_from"
                            label="Period From"
                            placeholder=""
                            required={true}
                            {...form}
                        />
                    </div>
                    <div>
                        <TextInput
                            type="date"
                            id="deductions.treaty_period_to"
                            label="Period To"
                            placeholder=""
                            required={true}
                            {...form}
                        />
                    </div>
                </div>
            </div>

        </div>
    );
};

export default DeductionForm;
