import { GetReinsurerAssociates_getReinsurerAssociates } from "_graphql/queries/__generated__/GetReinsurerAssociates";
import { ActionButton, OfficeButton, TableComponent, TableLoader } from "components";
import { useReInsurerAssociates } from "hooks/data/use-re-insurers";
import { useMatch, useNavigate, useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import RemoveContainer from "./remove-associate";
import { useUrlState } from "utils";
import { Action } from "components/buttons/action-button";
import UpdateManagerContainer from "./update-associates";
import ViewReinsurerAssociateContainer from "./view-associate";
import { UserPlusIcon } from "@heroicons/react/24/outline";
import CreateInsurerManagerContainer from "pages/re-insurers/add-manager";

type Props = {};

const Associates = (props: Props) => {
  const { params } = useMatch<LocationGenerics>();
  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const [modal, setModal] = useUrlState("modal");
  const { loading, associates, refetch } = useReInsurerAssociates({
    reinsurerId: params.reInsurer as string,
  });

  const dispatchAction =
    (id: string, action: Exclude<Action, "expand" | "goto" | "clone">) =>
      () => {
        navigate({
          search: (old) => ({
            ...old,
            id,
            modal: action,
          }),
        });
      };

  return (
    <div className="pt-6">
      <TableComponent
        hasSearch
        title={"associates"}
        columns={[
          {
            accessor: "rep_first_name",
            name: "First Name",
          },
          {
            accessor: "rep_last_name",
            name: "Last Name",
          },
          {
            accessor: "rep_email",
            name: "Email",
          },
          {
            accessor: "reinsurer.re_company_phone_number",
            name: "Phone Number",
            render: (value: GetReinsurerAssociates_getReinsurerAssociates) => (
              <div className="flex flex-col">
                <span>{value?.rep_primary_phonenumber}</span>
                <span>{value?.rep_secondary_phonenumber}</span>
              </div>
            ),
          },
          {
            accessor: "position",
            name: "Actions",
            render: (value: GetReinsurerAssociates_getReinsurerAssociates) => (
              <>
                <ActionButton
                  action={"view"}
                  tooltip="View Associate"
                  onClick={dispatchAction(value.reinsurer_representative_id || "", "view")}
                />
                <ActionButton
                  action={"update"}
                  tooltip="Edit Associate"
                  onClick={dispatchAction(value.reinsurer_representative_id || "", "update")}
                />
                <ActionButton
                  action={"remove"}
                  tooltip="Remove Associate"
                  onClick={dispatchAction(value.reinsurer_representative_id || "", "remove")}
                />
              </>
            ),
          },
        ]}
        renderLoader={() => <TableLoader items={[1, 1, 1, 2, 1]} />}
        data={associates}
        loading={loading}
        refetch={refetch}
        renderHeaderItems={() => (
          <OfficeButton onClick={dispatchAction("", "create")}>
            <span>Add Associate</span>
            <UserPlusIcon className="h-6 w-6" />
          </OfficeButton>
        )}
      />

      <CreateInsurerManagerContainer
        open={modal === "create"}
        setOpen={(val: boolean) => setModal(val ? "create" : undefined)}
      />

      {searchParams?.id && (
        <>
          <ViewReinsurerAssociateContainer
            open={modal === "view"}
            setOpen={(val: boolean) => setModal(val ? "view" : undefined)}
          />
          <RemoveContainer
            open={modal === "remove"}
            setOpen={(val: boolean) => setModal(val ? "remove" : undefined)}
          />
          <UpdateManagerContainer
            open={modal === "update"}
            setOpen={(val: boolean) => setModal(val ? "update" : undefined)}
          />
        </>
      )}
    </div>
  );
};

export default Associates;
