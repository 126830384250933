

import React from 'react'

type Props = {
    data: any
}

const TreatyFormSummary = ({ data }: Props) => {
    return (
        <div className="border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-md p-3">
            <div>
                <div className="grid grid-cols-3 gap-6">
                    <div>
                        <span className="block text-sm font-light text-gray-700">
                            Treaty Reference
                        </span>
                        <div className="mt-1 block w-full sm:text-sm">
                            {data?.treaty_reference || "N/A"}
                        </div>
                    </div>
                    <div className="">
                        <span className="block text-sm font-light text-gray-700">
                            Treaty Type
                        </span>
                        <div className="mt-1 block w-full sm:text-sm">
                            {data?.treaty_type || "N/A"}
                        </div>
                    </div>
                    <div>
                        <span className="block text-sm font-light text-gray-700">
                            Treaty Program
                        </span>
                        <div className="mt-1 block w-full sm:text-sm">
                            {data?.treaty_program?.treaty_name || "N/A"}
                        </div>
                    </div>
                    <div>
                        <span className="block text-sm font-light text-gray-700">
                            Treaty Duration
                        </span>
                        <div className="mt-1 block w-full sm:text-sm">
                            {data?.treaty_program_deduction?.treaty_period_from || "N/A"} to {data?.treaty_program_deduction?.treaty_period_to || "N/A"}
                        </div>
                    </div>
                    <div>
                        <span className="block text-sm font-light text-gray-700">Order Hereon</span>
                        <div className="mt-1 block w-full sm:text-sm">
                            {data?.order_hereon || "N/A"}
                        </div>
                    </div>
                    <div>
                        <span className="block text-sm font-light text-gray-700">
                            Visal Reference
                        </span>
                        <div className="mt-1 block w-full sm:text-sm">
                            {data?.kek_reference || "N/A"}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TreatyFormSummary