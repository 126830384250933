import { ResponsivePie } from '@nivo/pie'
import numeral from 'numeral';
import { FC } from 'react'

interface PieProps {
    data: {
        id: string;
        label: string;
        value: number;
        color: string;
        bgColor: string;
    }[]
    innerRadius?: number;
}

const Pie: FC<PieProps> = ({ data, innerRadius }) => (
    <ResponsivePie
        data={data}
        margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
        // margin={{ top: 20, right: 80, bottom: 80, left: 80 }}

        innerRadius={innerRadius ?? 0}
        padAngle={0.9}
        cornerRadius={5}
        activeOuterRadiusOffset={8}
        borderWidth={1}
        borderColor={{
            from: 'color',
            modifiers: [
                [
                    'darker',
                    0.2
                ]
            ]
        }}
        colors={{ datum: 'data.color' }}
        enableArcLinkLabels={false}
        arcLabelsSkipAngle={10}
        enableArcLabels={true}
        arcLabelsTextColor={"white"}
        arcLabel={function (e) { return numeral(e.value).format('0.0a') }}
    />
)

export default Pie