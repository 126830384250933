import { validators, wrapClick } from "utils";
import { gql, useMutation } from "@apollo/client";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { Modal } from "components";
import * as Yup from "yup";
import { createEmployee } from "_graphql/mutation/employee";
import { CreateEmployeeAccount, CreateEmployeeAccountVariables } from "_graphql/mutation/__generated__/CreateEmployeeAccount";
import { EmployeeForm } from "components/forms";


export const employeeValidationSchema = Yup.object().shape({
    employee_first_name: Yup.string().required("First name is required"),
    employee_last_name: Yup.string().required("Last name is required"),
    employee_phonenumber: Yup.string().required("Phone number is required"),
    employee_email: Yup.string().required("Email is required"),
    employee_position: Yup.string().required("Position is required"),
});

export default function CreateEmployeeContainer({
    open,
    setOpen,
    refetch,
}: {
    open: boolean;
    setOpen: (val: boolean) => void;
    refetch?: () => void;
}) {
    const [create, { loading }] = useMutation<CreateEmployeeAccount, CreateEmployeeAccountVariables>(createEmployee);

    const form = useFormik<CreateEmployeeAccountVariables["employee"]>({
        initialValues: {
            employee_email: "",
            employee_first_name: "",
            employee_last_name: "",
            employee_phonenumber: "",
            employee_position: "",
        },
        validationSchema: employeeValidationSchema,
        onSubmit: async (variables) => {
            await create({
                variables: {
                    employee: variables,
                },
            }).then(({ data }) => {
                if (data?.createEmployeeAccount) {
                    toast.success("Employee created successfully");
                    refetch?.();
                    form.resetForm();
                } else {
                    toast.error("Failed to create employee");
                }
            });
        },
        onReset: () => {
            setOpen(false);
        },
    });

    return (
        <Modal
            open={open}
            setOpen={setOpen}
            title="Add New Employee"
            description="Provide the details to add a new employee"
            renderActions={() => (
                <>
                    <button
                        type="button"
                        disabled={loading}
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={wrapClick(form.handleSubmit)}
                    >
                        {loading ? "Adding Employee..." : "Add Employee"}
                    </button>
                </>
            )}
        >
            <EmployeeForm form={form} />
        </Modal>
    );
}
