import { gql } from "@apollo/client";


export const getEmployees = gql`
query Employees {
  rows: employees {
    employee_id
    employee_first_name
    employee_last_name
    employee_phonenumber
    employee_email
    emp_abbrv
    user {
      position
    }
  }
  count: getEmployeesCount
}
`;

export const getEmployee = gql`
query Employee($employeeId: ID) {
  employee(employee_id: $employeeId) {
    employee_id
    employee_first_name
    employee_last_name
    employee_phonenumber
    employee_email
    emp_abbrv
    user {
      position
    }
  }
}`;