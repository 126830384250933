import { gql } from "@apollo/client";

export const generateReport = gql`
  mutation ReportOnQuery($data: Query_data) {
    reportOnQuery(query_data: $data)
  }
`;

export const getStatementPaymennts = gql`
  query GetPaidChequesForStatement {
    getPaidChequesForStatement {
      cheque_number
      reinsured
      offer_ids
      payment_ids
    }
  }
`;
