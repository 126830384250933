import { TextInput } from "components/core";
import { FormikProps } from "formik";
import _ from "lodash";
import { FC } from "react";

export interface AddEndorsmentPercentageFormProps {
    form: FormikProps<any>;
}

const AddEndorsmentPercentageForm: FC<AddEndorsmentPercentageFormProps> = ({ form }) => {
    return (
        <div className="space-y-6 divide-y divide-gray-200">
            <div>
                <div className="grid grid-cols-2 gap-2">
                    <div className="col-span-3">
                        <div className="">
                            <div className="grid grid-cols-3 gap-6 mt-2">
                                <div className="col-span-3">
                                    <TextInput postText={`Out of ${form?.values?.expected}`} id={"deductions.participating_percentage"} type="number" label="Percentage" required placeholder="Eg. 10" {...form} />
                                </div>
                                <div className="col-span-2">
                                    <TextInput id={"deductions.brokerage"} type="number" label="Agreed brokerage %" required placeholder="Eg. 10" {...form} />
                                </div>
                                <div className="">
                                    <TextInput id={"deductions.wht"} type="number" label="Withholding tax %" required placeholder="Eg. 10" {...form} />
                                </div>
                                <div className="">
                                    <TextInput id={"deductions.nic"} type="number" label="NIC Levy %" required placeholder="Eg. 10" {...form} />
                                </div>
                                <div className="col-span-2">
                                    <TextInput id={"deductions.commission"} type="number" label="Agreed commission %" required placeholder="Eg. 10" {...form} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddEndorsmentPercentageForm;
