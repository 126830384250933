import { Offers_rows } from "_graphql/queries/__generated__/Offers";
import {
  ActionButton,
  InsurerPicker,
  MonthPicker,
  OfficeHeader,
  Paginator,
  RegularInsurerPicker,
  SearchSelectInput,
  Show,
  TableComponent,
  TableLoader,
} from "components";
import { useFormik } from "formik";
import { usePagination } from "hooks";
import { useOffers } from "hooks/data/use-offers";
import numeral from "numeral";
import { FC, useEffect, useMemo } from "react";
import { Link, useNavigate, useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { classNames, useUrlState, withRoles } from "utils";
import CreateContainer from "./create";
import { Action } from "components/buttons/action-button";
import moment from "moment";
import NotifyContainer from "./notify";
import YearPicker from "layouts/calendar-wrapper/year-picker";

type Props = {};

const tabs = [{ name: "Unclaimable Offers ", href: "unclaimable" }];

const ClaimsPage: FC<Props> = () => {
  const [modal, setModal] = useUrlState("modal");
  const [orderStatus] = useUrlState("orderStatus");
  const [year] = useUrlState("year");
  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const { offset, limit, setPage, search } = usePagination();

  const form = useFormik<any>({
    initialValues: {
      insurer: "",
      claim_status: "",
    },
    onSubmit: (values) => {
      console.log("values", values);
    },
  });

  const filter = useMemo(
    () => ({
      page: searchParams.page || 1,
      pageSize: searchParams.pageSize || 10,
      search: (search || "")?.toString(),
      args: {
        status: !orderStatus ? ["CLOSED"] : ["OPEN", "PENDING"],
        insurer_id: form.values.insurer || "",
        payment_status: ["PAID"],
        claim_status: form.values.claim_status
          ? [form.values.claim_status]
          : [],
        year: year || moment().format("YYYY"),
      },
    }),
    [
      form.values.insurer,
      form.values.claim_status,
      searchParams.page,
      searchParams.pageSize,
      search,
      year,
      orderStatus
    ]
  );

  const { offers, loading, refetch, count } = useOffers(filter);

  const expandItem = (id: string) => () => {
    navigate({
      to: `./${id}/view`,
    });
  };

  const dispatchAction =
    (id: string, action: Exclude<Action, "expand" | "goto" | "clone">) =>
      () => {
        navigate({
          search: (old) => ({
            ...old,
            id,
            modal: action,
          }),
        });
      };

  useEffect(() => {
    if (form.values.insurer) {
      setPage(1);
    }
  }, [setPage, form.values.insurer]);

  return (
    <main className="flex-1 flex flex-col overflow-hidden bg-shade-500">
      <OfficeHeader renderActions={() => (
        <>
          <YearPicker />
        </>
      )} />
      <div className="block">
        <div className="border-b border-shade-500 bg-card-500 px-6">
          <nav className="-mb-px flex space-x-8 items-center" aria-label="Tabs">
            <Link
              search={(old) => ({
                ...old,
                orderStatus: undefined,
              })}
              className={classNames(
                !orderStatus
                  ? "border-gray-200 text-gray-100"
                  : "border-transparent text-gray-100 hover:text-gray-700 hover:border-gray-200",
                "whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm"
              )}
              aria-current={!orderStatus ? "page" : undefined}
            >
              Claimable Offers
            </Link>
            {tabs.map((_orderStatus) => (
              <Link
                key={_orderStatus.name}
                search={(old) => ({
                  ...old,
                  orderStatus: _orderStatus.href,
                })}
                className={classNames(
                  orderStatus === _orderStatus.href
                    ? "border-gray-200 text-gray-100"
                    : "border-transparent text-gray-100 hover:text-gray-700 hover:border-gray-200",
                  "whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm"
                )}
                aria-current={
                  orderStatus === _orderStatus.href ? "page" : undefined
                }
              >
                {_orderStatus.name}
              </Link>
            ))}
          </nav>
        </div>
      </div>
      <div className="flex flex-1 overflow-y-auto">
        <div className="flex-1 mx-auto min-w-0  py-4 sm:py-6 overflow-hidden overflow-y-auto light flex">
          <TableComponent
            hasSearch
            title={"offers"}
            loading={loading}
            columns={[
              {
                name: "Policy #",
                accessor: "offer_detail.policy_number",
                render: (value: Offers_rows) => (
                  <span className="font-bold text-xs">
                    {value.offer_detail?.policy_number || "N/A"}
                  </span>
                ),
              },
              {
                name: "Insurance Company",
                accessor: "insurer.insurer_company_name",
                render: (value: Offers_rows) => (
                  <div className="flex flex-col text-xs">
                    <span className="font-medium">
                      {value.insurer?.insurer_company_name || "N/A"}
                    </span>
                    <span className="font-light">
                      {value.insurer?.insurer_company_email || "N/A"}
                    </span>
                  </div>
                ),
              },
              {
                name: "Insured",
                accessor: "offer_detail.insured_by",
                render: (value: Offers_rows) => {
                  const details = JSON.parse(value?.offer_detail?.offer_details || "[]");
                  return (
                    <div className="flex flex-col text-xs ">
                      <span className="font-medium">
                        {value.offer_detail?.insured_by || "N/A"}
                      </span>
                      <span className="font-light">
                        {[
                          "Motor Comprehensive Fleet",
                          "Motor Comprehensive",
                          "Motor Comprehensive (Automobile Fac Facility)",
                        ].includes(value?.classofbusiness?.business_name || "") ? details?.find(
                          (el: { [x: string]: string; }) =>
                            el["keydetail"] === "Vehicle Reg No" ||
                            el["keydetail"] === "Vehicle Reg No."
                        )?.value : ""}
                      </span>
                    </div>
                  )
                },
              },
              {
                name: "Class Of Business",
                accessor: "classofbusiness.business_name",
                render: (value: Offers_rows) => (
                  <div className="flex flex-col text-xs">
                    <span className="font-medium">
                      {value.classofbusiness?.business_name || "N/A"}
                    </span>
                  </div>
                ),
              },
              {
                name: "Sum Insured",
                accessor: "sum_insured",
                render: (value: Offers_rows) => (
                  <div className="flex flex-col text-xs">
                    <span className="font-medium">
                      {value?.offer_detail?.currency}{" "}
                      {numeral(value?.sum_insured).format("0,0 ") || "N/A"}
                    </span>
                  </div>
                ),
              },
              {
                name: "Premium",
                accessor: "premium",
                render: (value: Offers_rows) => (
                  <div className="flex flex-col text-xs">
                    <span className="font-medium">
                      {value?.offer_detail?.currency}{" "}
                      {numeral(value?.premium).format("0,0 ") || "N/A"}
                    </span>
                  </div>
                ),
              },
              {
                name: "Participants",
                accessor: "premium",
                render: (value: Offers_rows) => (
                  <div className="flex flex-col text-xs">
                    <span className="font-medium">
                      {value?.offer_participant?.length}{" "}
                    </span>
                  </div>
                ),
              },
              {
                name: "Claim Status",
                accessor: "claim_status",
                render: (value: Offers_rows) => (
                  <div className="flex flex-col text-xs">
                    <span className="font-medium">
                      {value?.claim_status || "N/A"}
                    </span>
                  </div>
                ),
              },
              {
                name: "Period",
                accessor: "created_at",
                render: (value: Offers_rows) => `${moment(value?.offer_detail?.period_of_insurance_from).format("MM/DD/YYYY")} - ${moment(value?.offer_detail?.period_of_insurance_to).format("MM/DD/YYYY")}`,
              },
              {
                name: "Offer Date",
                accessor: "created_at",
                render: (value: Offers_rows) => moment(value?.created_at).format("MM/DD/YYYY"),
              },
              {
                name: "Created By",
                accessor: "employee.employee_first_name",
                render: (value: Offers_rows) => (
                  <div className='flex flex-row leading-0'>
                    <span>{value?.employee?.employee_first_name} {value?.employee?.employee_last_name}</span>
                  </div>
                )
              },
              {
                name: "Actions",
                accessor: "actions",
                render: (value: Offers_rows) => (
                  <>
                    {(orderStatus !== "unclaimable" && value?.claim_status === "CLAIMED") && <ActionButton
                      action="view"
                      tooltip="View Claims"
                      onClick={expandItem(value?.offer_id || "")}
                    />}
                    <ActionButton
                      action="alert"
                      tooltip="Claim Request"
                      onClick={dispatchAction(value?.offer_id || "", "alert")}
                    />
                    {orderStatus !== "unclaimable" && withRoles(["Finance Officer", "Broking Officer", "General Manager", "System Administrator", "Senior Broking Officer", "Managing Director"])(
                      <ActionButton
                        action="calculate"
                        tooltip="Make Claim"
                        onClick={dispatchAction(
                          value?.offer_id || "",
                          "calculate"
                        )}
                      />
                    )}
                  </>
                ),
              },
            ]}
            data={offers}
            renderHeaderItems={() => (
              <div className="space-x-2 flex">
                <Show if={!orderStatus}>
                  <SearchSelectInput
                    id={"claim_status"}
                    label={""}
                    labelHidden
                    placeholder="Filter by claim status"
                    options={[
                      {
                        label: {
                          title: "Claimed",
                        },
                        value: "CLAIMED",
                      },
                      {
                        label: {
                          title: "Unclaimed",
                        },
                        value: "UNCLAIMED",
                      },
                    ]}
                    {...form}
                  />
                </Show>
                <RegularInsurerPicker
                  placeholder="Filter by Cedant"
                  id={"insurer"}
                  label={""}
                  rawId
                  labelHidden
                  {...form}
                />
              </div>
            )}
            pagination={() => (
              <Paginator
                offset={offset}
                limit={limit}
                totalItems={count || 0}
                currentSize={offers.length}
                setOffset={setPage}
              />
            )}
            refetch={refetch}
            renderLoader={() => (
              <TableLoader items={[1, 2, 1, 1, 1, 1, 1, 1, 2, 2]} />
            )}
          />
        </div>
      </div>



      {
        searchParams?.id && (
          <>
            <NotifyContainer
              open={modal === "alert"}
              setOpen={(val: boolean) => setModal(val ? "alert" : undefined)}
              refetch={refetch}
            />
            <CreateContainer
              open={modal === "calculate"}
              setOpen={(val: boolean) => setModal(val ? "calculate" : undefined)}
              refetch={refetch}
            />
          </>
        )
      }
    </main>
  );
};

export default ClaimsPage;
