

import React from 'react'

type Props = {
    data: any
}

const TreatySurplusFormSummary = ({ data }: Props) => {
    return (
        <div className="border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-md p-3">
            <div>
                <div className="grid grid-cols-2 gap-6">
                    <div>
                        <span className="block text-sm font-light text-gray-700">
                            Profit Commission (%)
                        </span>
                        <div className="mt-1 block w-full sm:text-sm">
                            {data?.profit_commission || "N/A"}
                        </div>
                    </div>
                    <div className="">
                        <span className="block text-sm font-light text-gray-700">
                            Reinsurers Management Expense (%)
                        </span>
                        <div className="mt-1 block w-full sm:text-sm">
                            {data?.reinsurers_management_expense || "N/A"}
                        </div>
                    </div>
                    <div>
                        <span className="block text-sm font-light text-gray-700">
                            Currency
                        </span>
                        <div className="mt-1 block w-full sm:text-sm">
                            {data?.currency || "N/A"}
                        </div>
                    </div>
                    <div>
                        <span className="block text-sm font-light text-gray-700">
                            Estimated Retained Premium Income
                        </span>
                        <div className="mt-1 block w-full sm:text-sm">
                            {data?.estimated_retained_premium_income || "N/A"}
                        </div>
                    </div>
                    <div>
                        <span className="block text-sm font-light text-gray-700">Premium Portfolio Withdrawal (%)</span>
                        <div className="mt-1 block w-full sm:text-sm">
                            {data?.portfolio_entry?.withdrawal_percentage || "N/A"}
                        </div>
                    </div>
                    <div>
                        <span className="block text-sm font-light text-gray-700">
                            Premium Portfolio Withdrawal loss (%)
                        </span>
                        <div className="mt-1 block w-full sm:text-sm">
                            {data?.portfolio_entry?.withdrawal_loss_percentage || "N/A"}
                        </div>
                    </div>
                    <div>
                        <span className="block text-sm font-light text-gray-700">
                            Premium Portfolio Assumption (%)
                        </span>
                        <div className="mt-1 block w-full sm:text-sm">
                            {data?.portfolio_entry?.assumption_percentage || "N/A"}
                        </div>
                    </div>
                    <div className=''>
                        <span className="block text-sm font-light text-gray-700">
                            Loss Portfolio Withdrawal loss (%)
                        </span>
                        <div className="mt-1 block w-full sm:text-sm">
                            {data?.portfolio_entry?.assumption_loss_percentage || "N/A"}
                        </div>
                    </div>
                    <div className='col-span-2'>
                        <table className="min-w-full table-fixed divide-y divide-gray-300 border border-gray-300 rounded-md">
                            <thead className="bg-gray-50">
                                <tr className="divide-x divide-gray-200">
                                    <th
                                        scope="col"
                                        className="whitespace-nowrap px-2 py-2 text-left text-sm font-semibold text-gray-900"
                                    >
                                        Surplus #
                                    </th>
                                    <th
                                        scope="col"
                                        className="whitespace-nowrap px-2 py-2 text-left text-sm font-semibold text-gray-900"
                                    >
                                        Commission
                                    </th>
                                    <th
                                        scope="col"
                                        className="whitespace-nowrap px-2 py-2 text-left text-sm font-semibold text-gray-900"
                                    >
                                        Profit Commission
                                    </th>

                                    <th
                                        scope="col"
                                        className="whitespace-nowrap px-2 py-2 text-left text-sm font-semibold text-gray-900"
                                    >
                                        Retained Premium Income
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200 bg-white">
                                {data?.surpluses?.map((material: any, idx: number) => (
                                    <tr key={idx} className="divide-x divide-gray-200">
                                        <td className="whitespace- text-center px-2 py-2 text-sm text-gray-700 text-ellipsis overflow-hidden ">
                                            {idx + 2}
                                        </td>
                                        <td className="whitespace- text-center px-2 py-2 text-sm text-gray-700 text-ellipsis overflow-hidden ">
                                            {material?.commision || "N/A"}
                                        </td>
                                        <td className="whitespace-nowrap text-center px-2 py-2 text-sm text-gray-500 ">
                                            {material?.profit_commision || "N/A"}
                                        </td>
                                        <td className="whitespace-nowrap text-center px-2 py-2 text-sm text-gray-500 ">
                                            {material?.retained_premium_income || "N/A"}
                                        </td>

                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                </div>
            </div>
        </div >
    )
}

export default TreatySurplusFormSummary