import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { FetchTreatyAccounts_fetchTreatyAccounts } from "_graphql/queries/__generated__/FetchTreatyAccounts";
import { Treaty_treaty } from "_graphql/queries/__generated__/Treaty";
import { RegularSelectInput, TextInput } from "components/core";
import { FormikProps } from "formik";
import _ from "lodash";
import moment from "moment";
import { FC, useEffect, useMemo } from "react";

export interface QuarterFormProps {
  form: FormikProps<any>;
  treaty?: Treaty_treaty | null;
  accounts?: (FetchTreatyAccounts_fetchTreatyAccounts | null)[];
  update?: boolean;
}

const QuarterForm: FC<QuarterFormProps> = ({
  form,
  treaty,
  accounts,
  update,
}) => {
  const currencies = useMemo(
    () =>
      accounts?.map((el) => ({
        label: el?.currency || "",
        value: el?.treaty_id || "",
      })) || [],
    [accounts]
  );

  const notes = useMemo(() => {
    // if (!form.values.currency) return [];
    // console.log(
    //   "CHANHES",
    //   accounts?.find((el) => el?.treaty_id === form.values.currency)
    // );
    const items =
      accounts
        ?.find((el) => el?.treaty_id === form.values.currency)
        ?.quarters?.map((el) => ({
          label: el || "",
          value: el || "",
        })) || [];
    return [
      ...(update
        ? [
          {
            label: form.values.account_periods,
            value: form.values.account_periods,
          },
        ]
        : []),
      ...items,
    ];
  }, [form.values.currency, accounts]);

  useEffect(() => {
    if (form.values.currency) {
      if (!update) {
        // console.log("ACCOUNTS", treaty?.treaty_accounts);
        const _surpluses =
          JSON.parse(
            accounts?.find(
              (el) => el?.treaty_id === form.values.currency?.toString()
            )?.layer_limit || "[]"
          )?.map((el: any) => {
            console.log("EL", el);
            return {
              gross_premium: update ? el?.gross_premium : "",
              cash_loss: update ? el?.cash_loss : "",
              claim_settled: update ? el?.claim_settled : "",
              surpulus_uuid: el?.surpulus_uuid || "",
            };
          }) || [];
        form.setFieldValue("surpluses", _surpluses);
      } else {
        // console.log("ACCOUNTS", form.values);
      }
    }
  }, [form.values.currency, accounts]);

  return (
    <>
      {/* {JSON.stringify(form.values, null, 2)} */}
      {/* {JSON.stringify(accounts, null, 2)} */}
      <div className="space-y-6 divide-y divide-gray-200">
        <div className="border-l-4 border-yellow-400 bg-yellow-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <ExclamationTriangleIcon
                className="h-5 w-5 text-yellow-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3">
              <p className="text-yellow-700 text-sm">
                This session will allow you to create a quarterly statement for{" "}
                <b>{treaty?.treaty_program?.treaty_name}</b> with reference
                number <b>{treaty?.treaty_reference}</b> and period{" "}
                <b>
                  {moment(treaty?.treaty_deduction?.treaty_period_from).format(
                    "LL"
                  )}{" "}
                  to{" "}
                  {moment(treaty?.treaty_deduction?.treaty_period_to).format(
                    "LL"
                  )}
                </b>
              </p>
              <span className="text-yellow-700 text-sm">
                <b className="text-yellow-500">NOTE:</b>
                <p>
                  Account year is dependent on the year the quarter statement is
                  being filed{" "}
                </p>
              </span>
            </div>
          </div>
        </div>
        <div>
          <div className="grid grid-cols-2 gap-2">
            <div className="col-span-3">
              <div className="pt-6">
                <span className="text-xs font-light">Assign Percentage</span>
                <div className="grid grid-cols-2 gap-6 mt-2">
                  <div className="col-span-2">
                    <RegularSelectInput
                      disabled={update}
                      id={"currency"}
                      options={currencies}
                      label="Currency"
                      required
                      placeholder="Eg. 10"
                      {...form}
                    />
                  </div>

                  {form.values.currency && notes.length >= 1 && (
                    <div className="">
                      {/* {form.values.account_periods} */}
                      <RegularSelectInput
                        id={"account_periods"}
                        options={notes}
                        disabled={update}
                        label="Quarter"
                        required
                        placeholder="Eg. 10"
                        {...form}
                      />
                    </div>
                  )}
                  <div className="">
                    <TextInput
                      id={"account_year"}
                      type="text"
                      label="Account Year"
                      required
                      placeholder="Eg. 2023"
                      {...form}
                    />
                  </div>
                  {form.values.currency && notes.length < 1 && (
                    <div className="rounded-md bg-red-50 p-4 col-span-2">
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <CheckCircleIcon
                            className="h-5 w-5 text-red-400"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="ml-3">
                          <p className="text-sm font-medium text-red-800">
                            No Quarter to Select for chosen Currency
                          </p>
                        </div>
                        <div className="ml-auto pl-3">
                          <div className="-mx-1.5 -my-1.5">
                            <button
                              type="button"
                              onClick={() => form.setFieldValue("currency", "")}
                              className="inline-flex rounded-md bg-red-50 p-1.5 text-red-500 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2 focus:ring-offset-red-50"
                            >
                              <span className="sr-only">Dismiss</span>
                              <XMarkIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* {JSON.stringify(form.values.surpluses, null, 2)} */}
        {form.values.currency && (
          <div className="py-6">
            <div className="mt-1 w-full ">
              <table className="min-w-full divide-y divide-gray-300 border border-gray-300 rounded-md">
                <thead className="bg-gray-50">
                  <tr className="divide-x divide-gray-200">
                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-2 text-left text-sm font-semibold text-gray-900"
                    >
                      Surplus #
                    </th>
                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-2 text-left text-sm font-semibold text-gray-900"
                    >
                      Gross Premium
                    </th>
                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-2 text-left text-sm font-semibold text-gray-900"
                    >
                      Cash loss
                    </th>
                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-2 text-left text-sm font-semibold text-gray-900"
                    >
                      Claim Settled
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {_.map(form.values.surpluses, (_, idx) => {
                    const id = `surpluses.${idx}`;
                    return (
                      <tr key={idx} className="divide-x divide-gray-200">
                        <td className="text-center bg-gray-50">{idx + 1}</td>
                        <td className="p-0">
                          <TextInput
                            label={""}
                            labelHidden
                            key={idx}
                            id={`${id}.gross_premium`}
                            borderless
                            type="number"
                            {...form}
                            placeholder={"Gross Premiums"}
                          />
                        </td>
                        <td className="p-0">
                          <TextInput
                            label={""}
                            labelHidden
                            key={idx}
                            borderless
                            type="number"
                            id={`${id}.cash_loss`}
                            {...form}
                            placeholder={"Cash Loss"}
                          />
                        </td>
                        <td className="p-0">
                          <TextInput
                            label={""}
                            labelHidden
                            key={idx}
                            id={`${id}.claim_settled`}
                            borderless
                            type="number"
                            {...form}
                            placeholder={"Claim Settled"}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default QuarterForm;
