import { wrapClick } from "utils";
import { useMutation } from "@apollo/client";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { Modal } from "components";
import * as Yup from "yup";
import { ReInsurerForm } from "components/forms";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { useEffect } from "react";
import {
  UpdateReinsurer,
  UpdateReinsurerVariables,
} from "_graphql/mutation/__generated__/UpdateReinsurer";
import { updateReInsurer } from "_graphql/mutation/re-insurer";
import { useReInsurer } from "hooks/data/use-re-insurers";

export const valiadationSchema = Yup.object().shape({
  re_company_name: Yup.string().required("Company name is required"),
  re_company_website: Yup.string().notRequired(),
  re_company_email: Yup.string()
    .email("Invalid Email address")
    .required("Email is required"),
  country: Yup.string().required("Country is required"),
  region: Yup.string().required("Region/State is required"),
  street: Yup.string().required("Street Address is required"),
  suburb: Yup.string().required("Suburb/City is required"),
});

export default function UpdateInsurerContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const [update, { loading }] = useMutation<
    UpdateReinsurer,
    UpdateReinsurerVariables
  >(updateReInsurer, {
    refetchQueries: ["Reinsurers", "Reinsurer"],
  });
  const { reInsurer, loading: fetching } = useReInsurer(searchParams.id || "");
  const form = useFormik<UpdateReinsurerVariables["reinsurer"]>({
    initialValues: {
      re_company_email: "",
      re_company_name: "",
      re_company_website: "",
      country: "",
      region: "",
      street: "",
      suburb: "",
    },
    validationSchema: valiadationSchema,
    onSubmit: async (variables) => {
      await update({
        variables: {
          reinsurer: variables,
          reinsurerId: searchParams.id || "",
        },
      }).then(({ data }) => {
        if (data?.updateReinsurer) {
          toast.success("Re-insurer Updated Successfully");
          refetch?.();
          form.resetForm();
        } else {
          toast.error("Failed to update Re-insurer");
        }
      });
    },
    onReset: () => {
      setOpen(false);
    },
  });

  useEffect(() => {
    if (reInsurer) {
      form.setFieldValue("re_company_email", reInsurer.re_company_email);
      form.setFieldValue("re_company_name", reInsurer.re_company_name);
      form.setFieldValue("re_company_website", reInsurer.re_company_website);
      form.setFieldValue("country", reInsurer?.reinsurer_address?.country);
      form.setFieldValue("region", reInsurer.reinsurer_address?.region);
      form.setFieldValue("street", reInsurer.reinsurer_address?.street);
      form.setFieldValue("suburb", reInsurer.reinsurer_address?.suburb);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reInsurer]);

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title="Update Re-insurer"
      loading={fetching}
      description="Provide the details to update re-insurer"
      renderActions={() => (
        <>
          <button
            type="button"
            disabled={fetching || loading}
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(form.handleSubmit)}
          >
            {loading ? "Updating Re-insurer..." : "Update Re-insurer"}
          </button>
        </>
      )}
    >
      <ReInsurerForm form={form} />
    </Modal>
  );
}
