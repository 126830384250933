import { useQuery } from "@apollo/client";
import {
  ClassOfBusinesses,
  ClassOfBusinessesVariables,
} from "_graphql/queries/__generated__/ClassOfBusinesses";
import {
  getClassOfBusiness,
  getClassOfBusinesses,
} from "_graphql/queries/class-of-business";
import { iVariables } from "./use-offers";
import {
  SingleClassOfBusiness,
  SingleClassOfBusinessVariables,
} from "_graphql/queries/__generated__/SingleClassOfBusiness";

export const useClassOfBusinesses = (
  filter: ClassOfBusinessesVariables & iVariables
) => {
  const { page, pageSize, search, ...variables } = filter || {
    page: 1,
    pageSize: 10,
    search: "",
  };
  const start = (page - 1) * pageSize;
  const end = start + pageSize;
  const { data, ...rest } = useQuery<
    ClassOfBusinesses,
    ClassOfBusinessesVariables
  >(getClassOfBusinesses, {
    variables: variables,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });
  const items = data?.rows
    ?.filter((el) => {
      return (
        el?.business_category?.toLowerCase().includes(search.toLowerCase()) ||
        el?.business_name?.toLowerCase().includes(search.toLowerCase())
      );
    })
    .slice(start, end);
  const length = data?.count || 0;
  return {
    classOfBusinesses: items || [],
    length,
    ...rest,
  };
};

export const useClassOfBusiness = (
  variables: SingleClassOfBusinessVariables
) => {
  const { data, ...rest } = useQuery<
    SingleClassOfBusiness,
    SingleClassOfBusinessVariables
  >(getClassOfBusiness, {
    variables: variables,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });
  return {
    singleClassOfBusiness: data?.singleClassOfBusiness,
    ...rest,
  };
};
