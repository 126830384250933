import { gql } from "@apollo/client";

export const createEndorsement = gql`
  mutation CreateEndorsementOffer($offerInput: Offer_data!, $offerId: ID) {
    createEndorsementOffer(offer_input: $offerInput, offer_id: $offerId)
  }
`;

export const updateEndorsement = gql`
mutation UpdateEndorsementOffer(
  $offerInput: Offer_data!
  $offerEndorsementDetailId: ID
  $offerEndorsementId: ID
  $offerId: ID
) {
  updateEndorsementOffer(
    offer_input: $offerInput
    offer_endorsement_detail_id: $offerEndorsementDetailId
    offer_endorsement_id: $offerEndorsementId
    offer_id: $offerId
  )
}

`;


export const addReinsurersToEndorsement = gql`
mutation AddReinsurerToEndorsement(
  $reinsurerIds: [ID]
  $offerEndorsementId: ID
  $offerId: ID
) {
  addReinsurerToEndorsement(
    reinsurer_ids: $reinsurerIds
    offer_endorsement_id: $offerEndorsementId
    offer_id: $offerId
  )
}
`;