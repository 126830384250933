import { wrapClick } from "utils";
import { useMutation } from "@apollo/client";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { Modal } from "components";
import * as Yup from "yup";
import { updateReinsurerPayment } from "_graphql/mutation/insurer";
import { ReinsurerPaymentForm } from "components/forms";
import { useEffect } from "react";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { useReInsurerOfferPayment } from "hooks/data/use-re-insurers";
import { UpdateReinsurerPayment, UpdateReinsurerPaymentVariables } from "_graphql/mutation/__generated__/UpdateReinsurerPayment";

export const paymentValidationSchema = Yup.object().shape({
  payment_type: Yup.string()
    .oneOf(["Bank Transfer", "Cheque"])
    .required("Invalid Payment Type"),
  payment_from: Yup.object()
    .when("payment_type", {
      is: "Cheque",
      then: (schema) =>
        schema.shape({
          bank_name: Yup.string().required("Bank Name is required"),
          cheque_number: Yup.string().required("Cheque Number is required"),
          date_on_cheque: Yup.string().required("Date on cheque is required"),
        }),
      otherwise: (schema) =>
        schema
          .shape({
            bank_name: Yup.string().required("Bank Name is required"),
          })
          .required(),
    })
    .required(),
  payment_to: Yup.string().required("Beneficiary Bank Name is required"),
  payment_details: Yup.object()
    .shape({
      payment_amount: Yup.string().required("Payment Amount is required"),
      currency: Yup.string().required("Currency is required"),
    })
    .required(),
  offer_payment_comment: Yup.string().required("Payment Comment is required"),
});

export default function UpdateInsurerPaymentContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const { payment, loading: fetching } = useReInsurerOfferPayment({
    participant_payment_id: searchParams?.id || "",
  });
  const [create, { loading }] = useMutation<
    UpdateReinsurerPayment,
    UpdateReinsurerPaymentVariables
  >(updateReinsurerPayment);

  const form = useFormik<any>({
    initialValues: {
      payment_type: "",
      payment_from: {
        bank_name: "",
        cheque_number: "",
        date_on_cheque: "",
      },
      offer_payment_comment: "",
      payment_to: "",
      payment_details: {
        payment_amount: 0,
        currency: "",
        conversion: {
          rate: 1,
          currency: "",
        },
      },
    },
    validationSchema: paymentValidationSchema,
    onSubmit: async (variables) => {
      await create({
        variables: {
          offer_participant_payment_id: searchParams?.id || "",
          Offer_payment_comment: variables.offer_payment_comment,
          paid_details: JSON.stringify({
            payment_type: variables.payment_type,
            payment_from: {
              bank_name: variables.payment_from.bank_name,
              cheque_number: variables.payment_from.cheque_number,
              date_on_cheque: variables.payment_from.date_on_cheque,
            },
            payment_to: variables.payment_to,
            payment_details: {
              payment_amount: variables.payment_details.payment_amount,
              currency: variables.payment_details.currency,
              conversion: {
                rate: variables.payment_details.conversion.rate,
                currency: variables.payment_details.conversion.currency,
              },
            },
          }),
        },
      }).then(({ data }) => {
        if (data?.updateReinsurerPaymentDetails) {
          toast.success("Payment Updated Successfully");
          refetch?.();
          form.resetForm();
        } else {
          toast.error("Failed to update payment"
          );
        }
      });
    },
    onReset: () => {
      setOpen(false);
    },
  });
  useEffect(() => {
    if (payment) {
      const details = JSON.parse(payment.paid_details || "{}");
      form.setValues({
        payment_type: details.payment_type,
        payment_from: {
          bank_name: details.payment_from?.bank_name,
          cheque_number: details.payment_from?.cheque_number,
          date_on_cheque: details.payment_from?.date_on_cheque,
        },
        offer_payment_comment: payment.offer_payment_amount,
        payment_to: details.payment_to,
        payment_details: {
          payment_amount: payment.offer_payment_amount,
          currency: details.payment_details?.currency,
          conversion: {
            rate: details.payment_details?.conversion?.rate,
            currency: details.payment_details?.conversion?.currency,
          },
        },
      });
    }
  }, [payment]);

  return (
    <Modal
      open={open}
      loading={fetching}
      setOpen={setOpen}
      title="Update Payment"
      description="Provide the details to update payment record"
      renderActions={() => (
        <>
          <button
            type="button"
            disabled={loading}
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(form.handleSubmit)}
          >
            {loading ? "Adding payment..." : "Add Payment"}
          </button>
        </>
      )}
    >
      <ReinsurerPaymentForm form={form} amountToPay={3000} />
    </Modal>
  );
}
