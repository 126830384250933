import { gql } from "@apollo/client";

export const getBrokers = gql`
  query Brokers {
    brokers {
      re_broker_id
      re_broker_name
      re_broker_email
      re_broker_website
      re_broker_address {
        re_broker_address_id
        street
        city
        region
        country
        re_primary_phone
        re_secondary_phone
      }
      re_broker_associates {
        re_broker_associate_id
        re_broker_assoc_first_name
        re_broker_assoc_last_name
        re_broker_assoc_primary_phone
        re_broker_assoc_secondary_phone
        re_broker_assoc_position
        re_broker_assoc_email
        re_broker {
          re_broker_id
          re_broker_name
        }
      }
    }
  }
`;

export const getBroker = gql`
  query Broker($brokerId: ID) {
    broker(broker_id: $brokerId) {
      re_broker_id
      re_broker_name
      re_broker_email
      re_broker_website
      broker_overview {
        total_treaties
        total_proportional
        total_nonproportional
        total_unpaid_treaties
        total_paid_treaties
        total_partpayment_treaties
        brokerage_chart
        total_brokerage_amt
      }
      re_broker_address {
        re_broker_address_id
        street
        city
        region
        country
        re_primary_phone
        re_secondary_phone
      }
    }
  }
`;

export const getBrokerAssociates = gql`
query GetBrokerAssociates($brokerId: ID) {
  getBrokerAssociates(broker_id: $brokerId) {
    re_broker_associate_id
    re_broker_assoc_first_name
    re_broker_assoc_last_name
    re_broker_assoc_primary_phone
    re_broker_assoc_secondary_phone
    re_broker_assoc_position
    re_broker_assoc_email
  }
}
`;

export const getBrokerAssociate = gql`
query GetBrokerAssociate($brokerAssociateId: ID) {
  getBrokerAssociate(broker_associate_id: $brokerAssociateId) {
    re_broker_associate_id
    re_broker_assoc_first_name
    re_broker_assoc_last_name
    re_broker_assoc_primary_phone
    re_broker_assoc_secondary_phone
    re_broker_assoc_position
    re_broker_assoc_email
  }
}
`;
