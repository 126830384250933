import ApolloProvider from "_graphql";
import LayoutProvider from "layouts";
import { Tooltip } from "react-tooltip";
import RoutesProvider from "router";
import { MantineProvider } from "@mantine/core";
import { Toaster } from "react-hot-toast";
// import TranslationProvider from 'translation';

function App() {
  return (
    <MantineProvider>
      <ApolloProvider>
        <RoutesProvider>
          <LayoutProvider />
          <Tooltip id="global-tooltip" />
          <Toaster />
        </RoutesProvider>
      </ApolloProvider>
    </MantineProvider>
  );
}

export default App;
