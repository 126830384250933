import React from 'react'
import { Link } from 'react-location'

type Props = {
    notification: any
    parsedNotification: any
}

const ReinsurerNotificationComponent = ({ notification, parsedNotification }: Props) => {
    if (parsedNotification.hasOwnProperty("rep_email")) {
        return (
            <div className="container mx-auto px-4">
                <div className="bg-yellow-200 p-4 mb-4">
                    {JSON.parse(notification?.system_notification?.notification_content || "{}").message}
                </div>
                <div className="mb-4">
                    <table className="w-full border-collapse">
                        <tbody>
                            <tr>
                                <th className="text-left py-2">Associate Name</th>
                                <td className="py-2">
                                    {parsedNotification?.rep_first_name} {parsedNotification?.rep_last_name}
                                </td>
                                <th className="text-left py-2">Associate Position</th>
                                <td className="py-2">{parsedNotification?.position}</td>
                            </tr>
                            <tr>
                                <th className="text-left py-2">Associate Email</th>
                                <td className="py-2">{parsedNotification?.rep_email}</td>
                                <th className="text-left py-2">Phone</th>
                                <td className="py-2">
                                    {parsedNotification?.rep_primary_phonenumber},{" "}
                                    {parsedNotification?.rep_secondary_phonenumber}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="mb-4">
                    <Link
                        // to={{
                        //     pathname: "/admin/insurers-details",
                        //     state: { insurer_id: parsedNotification?.insurer_id },
                        // }}
                        className="btn bg-green-500 text-white py-2 px-4 rounded"
                    >
                        Go to Reinsurer
                    </Link>
                </div>
            </div>
        );
    } else if (parsedNotification.hasOwnProperty("re_company_email")) {
        return (
            <div className="container mx-auto px-4">
                <div className="bg-yellow-200 p-4 mb-4">
                    {JSON.parse(notification?.system_notification?.notification_content || "{}").message}
                </div>
                <div className="h-1/3 flex justify-center items-center mb-4">
                    <div className="rounded-full bg-blue-100 text-blue-500 p-4 font-semibold text-lg">
                        {parsedNotification?.re_abbrv}
                    </div>
                </div>
                <div className="mb-4">
                    <table className="w-full border-collapse">
                        <tbody>
                            <tr>
                                <th className="text-left py-2">Company Name</th>
                                <td className="py-2">{parsedNotification?.re_company_name}</td>
                                <th className="text-left py-2">Company Website</th>
                                <td className="py-2">{parsedNotification?.re_company_website}</td>
                            </tr>
                            <tr>
                                <th className="text-left py-2">Company Email</th>
                                <td className="py-2">{parsedNotification?.re_company_email}</td>
                                <th className="text-left py-2">Address</th>
                                <td className="py-2">
                                    {parsedNotification?.address?.suburb} - {parsedNotification?.address?.region},{" "}
                                    {parsedNotification?.address?.country}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="mb-4">
                    <Link
                        // to={{
                        //     pathname: "/admin/re-insurers-detail",
                        //     state: { data: { reinsurer_id: parsedNotification?.reinsurer_id } },
                        // }}
                        className="btn bg-green-500 text-white py-2 px-4 rounded"
                    >
                        Go to Reinsurer
                    </Link>
                </div>
            </div>
        );
    } else {
        return (
            <div className="container mx-auto px-4">
                <div className="bg-yellow-200 p-4 mb-4">
                    {JSON.parse(notification?.system_notification?.notification_content || "{}").message}
                </div>
            </div>
        );
    }

}

export default ReinsurerNotificationComponent