import { wrapClick } from "utils";
import { useMutation } from "@apollo/client";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { Modal } from "components";
import * as Yup from "yup";
import { BrokerForm } from "components/forms";
import { CreateReBroker, CreateReBrokerVariables } from "_graphql/mutation/__generated__/CreateReBroker";
import { createBroker } from "_graphql/mutation/broker";
import { ReBrokerInput } from "_graphql/__generated__/globalTypes";


export const brokerValidationSchema = Yup.object().shape({
    re_broker_name: Yup.string().required("Company name is required"),
    re_broker_website: Yup.string().notRequired(),
    re_broker_email: Yup.string().email("Invalid Email address").required("Email is required"),
    re_broker_address: Yup.object().shape({
        city: Yup.string().required("City is required"),
        country: Yup.string().required("Country is required"),
        re_primary_phone: Yup.string().required("Primary Phone is required"),
        re_secondary_phone: Yup.string().notRequired(),
        region: Yup.string().required("Region/State is required"),
        street: Yup.string().required("Street Address is required"),
        // suburb: Yup.string().required("Suburb/City is required"),
    }),
});

export default function CreateReBrokerContainer({
    open,
    setOpen,
    refetch,
}: {
    open: boolean;
    setOpen: (val: boolean) => void;
    refetch?: () => void;
}) {
    const [create, { loading }] = useMutation<CreateReBroker, CreateReBrokerVariables>(createBroker);

    const form = useFormik<ReBrokerInput>({
        initialValues: {
            re_broker_email: "",
            re_broker_name: "",
            re_broker_website: "",
            re_broker_address: {
                city: "",
                country: "",
                re_primary_phone: "",
                re_secondary_phone: "",
                region: "",
                street: ""
            }
        },
        validationSchema: brokerValidationSchema,
        onSubmit: async (variables) => {
            await create({
                variables: {
                    reBroker: variables,
                },
            }).then(({ data }) => {
                if (data?.createReBroker) {
                    toast.success("Broker Created Successfully");
                    refetch?.();
                    form.resetForm();
                } else {
                    toast.error("Failed to create Broker");
                }
            });
        },
        onReset: () => {
            setOpen(false);
        },
    });

    return (
        <Modal
            open={open}
            setOpen={setOpen}
            title="Add New Reinsurance Broker"
            description="Provide the details to add a new broker"
            renderActions={() => (
                <>
                    <button
                        type="button"
                        disabled={loading}
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={wrapClick(form.handleSubmit)}
                    >
                        {loading ? "Adding Broker..." : "Add Broker"}
                    </button>
                </>
            )}
        >
            {/* {JSON.stringify(form.errors, null, 2)} */}
            <BrokerForm form={form} />
        </Modal>
    );
}
