import { Modal, OfferView } from "components";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { useOffer } from "hooks/data/use-offers";

export default function ViewReinsurerOfferContainer({
    open,
    setOpen,
}: {
    open: boolean;
    setOpen: (val: boolean) => void;
}) {
    const searchParams = useSearch<LocationGenerics>();
    const { offer, loading } = useOffer({
        offer_id: searchParams.id as string,
    });

    return (
        <Modal
            open={open}
            setOpen={setOpen}
            loading={loading}
            title="Offer Information"
            description="Details of offer are shown below"
        >
            <OfferView offer={offer} />
        </Modal>
    );
}
