import { wrapClick } from "utils";
import { useMutation, useReactiveVar } from "@apollo/client";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { Modal } from "components";
import * as Yup from "yup";
import {
  CreateInputOffer,
  CreateInputOfferVariables,
} from "_graphql/mutation/__generated__/CreateInputOffer";
import { currentUserVar } from "_graphql/cache/auth";
import { createOffer } from "_graphql/mutation/offers";
import EndorsementFormContainer from "components/forms/endorsements";

export const valiadationSchema = Yup.object().shape({
  class_of_business_id: Yup.object().required("Business is required"),
  offer_details: Yup.array().notRequired(),
  insurer_id: Yup.string().required("Insurer is required"),
  premium: Yup.number().min(0, "Number must be greater than 0")
    .typeError("Must be a number")
    .required("Premium is required"),
  policy_number: Yup.string().required("Policy Number is required"),
  insured_by: Yup.string().required("Insured is required"),
  sum_insured: Yup.number().min(0, "Number must be greater than 0")
    .typeError("Must be a number")
    .required("Sum Insured is required"),
  rate: Yup.number().min(0, "Number must be greater than 0").typeError("Must be a number").required("Rate is required"),
  facultative_offer: Yup.number().min(0, "Number must be greater than 0")
    .typeError("Must be a number")
    .required("Facultative Offer is required"),
  commission: Yup.number().min(0, "Number must be greater than 0")
    .typeError("Must be a number")
    .required("Commission is required"),
  brokerage: Yup.number().min(0, "Number must be greater than 0")
    .typeError("Must be a number")
    .required("Brokerage is required"),
  currency: Yup.string().required("Currency is required"),
  period_of_insurance_from: Yup.date().required(
    "Insurance Start Date is required"
  ),
  period_of_insurance_to: Yup.date()
    .min(Yup.ref("period_of_insurance_from"))
    .required("Insurance End Date is required"),
  comment: Yup.string().notRequired(),
});

export default function CreateOfferContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const user = useReactiveVar(currentUserVar);
  const [create, { loading }] = useMutation<
    CreateInputOffer,
    CreateInputOfferVariables
  >(createOffer);

  const form = useFormik<any>({
    initialValues: {
      class_of_business_id: "",
      offer_details: [],
      insurer_id: "",
      premium: "",
      policy_number: "",
      insured_by: "",
      sum_insured: "",
      rate: "",
      facultative_offer: "",
      commission: "",
      brokerage: "",
      currency: "",
      period_of_insurance_from: "",
      period_of_insurance_to: "",
      comment: "",
    },
    validationSchema: valiadationSchema,
    onSubmit: async (variables) => {
      await create({
        variables: {
          offerInput: {
            brokerage: parseFloat(variables.brokerage),
            insurer_id: variables.insurer_id,
            premium: parseFloat(variables.premium),
            policy_number: variables.policy_number,
            offer_details: JSON.stringify(variables.offer_details),
            class_of_business_id:
              variables.class_of_business_id?.class_of_business_id,
            employee_id: user?.employee?.employee_id ?? "",
            rate: parseFloat(variables.rate),
            commission: parseFloat(variables.commission),
            facultative_offer: parseFloat(variables.facultative_offer),
            sum_insured: parseFloat(variables.sum_insured),
            insured_by: variables.insured_by,
            currency: variables.currency,
            offer_comment: variables.comment,
            period_of_insurance_from: variables.period_of_insurance_from,
            period_of_insurance_to: variables.period_of_insurance_to,
          },
        },
      }).then(({ data }) => {
        if (data?.createInputOffer.offer_id) {
          toast.success("Offer Created Successfully");
          refetch?.();
          form.resetForm();
        } else {
          toast.error("Failed to create Offer");
        }
      });
    },
    onReset: () => {
      setOpen(false);
    },
  });

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      size="4xl"
      title="Create New Offer Endorsement"
      hideActions
      description="Provide the details to add a new offer endorsement."
      renderActions={() => (
        <>
          <button
            type="button"
            disabled={loading}
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(form.handleSubmit)}
          >
            {loading ? "Creating Endorsement..." : "Create Endorsement"}
          </button>
        </>
      )}
    >
      <EndorsementFormContainer setClose={setOpen} />
    </Modal>
  );
}
