import { useMutation } from "@apollo/client";
import toast from "react-hot-toast";
import { PopUp } from "components";
import { resetEmployee } from "_graphql/mutation/employee";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { AdminResetEmployeePassword, AdminResetEmployeePasswordVariables } from "_graphql/mutation/__generated__/AdminResetEmployeePassword";



export default function ResetEmployeeCredentialsContainer({
    open,
    setOpen,
    refetch,
}: {
    open: boolean;
    setOpen: (val: boolean) => void;
    refetch?: () => void;
}) {
    const searchParams = useSearch<LocationGenerics>()
    const [remove, { loading }] = useMutation<AdminResetEmployeePassword, AdminResetEmployeePasswordVariables>(resetEmployee, {
        refetchQueries: ["Employees"]
    });


    const handleSubmit = async () => {
        await remove({
            variables: {
                employeeId: searchParams?.id || ""
            },
        }).then(({ data }) => {
            if (data?.adminResetEmployeePassword) {
                toast.success("Employee credentials reset successfully");
                refetch?.();
                setOpen?.(false);
            } else {
                toast.error("Failed to reset employee credentials");
            }
        });
    }

    return (
        <PopUp
            open={open}
            setOpen={setOpen}
            title="Reset Employee Password"
            cancelText="No, Don't Reset Employee"
            variant="warning"
            description="This action would reset the employee's password and send a mail to the employee to reset his/her password."
            renderActions={() => (
                <>
                    <button
                        type="button"
                        disabled={loading}
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-yellow-600 text-base font-medium text-white hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={handleSubmit}
                    >
                        {loading ? "Resetting Employee Credentials..." : "Yes, Reset Employee Credentials"}
                    </button>
                </>
            )}
        />
    );
}
