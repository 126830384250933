import { classNames, useUrlState, wrapClick } from "utils";
import { InsurerView, LanguagePicker, Modal, PDFRenderer } from "components";
import { useSearch, useNavigate, useMatch, Link } from "react-location";
import { LocationGenerics } from "router/location";
import { Action } from "components/buttons/action-button";
import { useInsurer } from "hooks/data/use-insurers";
import config from "config";
import { useState } from "react";
import { useOffer } from "hooks/data/use-offers";
import { useFormik } from "formik";

const tabs = [
  {
    name: "Cover Note",
    href: "COVER_NOTE",
  },
  {
    name: "Debit Note",
    href: "DEBIT_NOTE",
  },
];

export default function ViewNotesContainer({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const [orderStatus, setOrderStatus] = useState("COVER_NOTE");
  const { loading, offer } = useOffer({
    offer_id: searchParams?.id || "",
  });

  const form = useFormik({
    initialValues: {
      lang: "en"
    },
    onSubmit(values, formikHelpers) {

    },
  })
  const url =
    orderStatus === "COVER_NOTE"
      ? `${config.asset.uri}generate_cover_slip/${btoa(
        JSON.stringify({
          offer_id: searchParams?.id,
          period_from: offer?.offer_detail?.period_of_insurance_from,
          period_to: offer?.offer_detail?.period_of_insurance_to,
          lang: form.values.lang,
        })
      )}`
      : `${config.asset.uri}generate_debit_slip/${btoa(
        JSON.stringify({
          offer_id: searchParams?.id,
          period_from: offer?.offer_detail?.period_of_insurance_from,
          period_to: offer?.offer_detail?.period_of_insurance_to,
          lang: form.values.lang,
        })
      )}`;

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      loading={loading}
      hideActions
      size="6xl"
      title="Cover and Debit Note"
      description="View Cover and Debit Note"
    >
      <div className="block">
        <div className="border-b border-shade-500 bg-card-500 px-6">
          <nav className="-mb-px flex space-x-8 items-center" aria-label="Tabs">
            {tabs.map((_orderStatus) => (
              <button
                key={_orderStatus.name}
                onClick={wrapClick(() => setOrderStatus(_orderStatus.href))}
                className={classNames(
                  orderStatus === _orderStatus.href
                    ? "border-gray-200 text-gray-300"
                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200",
                  "whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm"
                )}
                aria-current={
                  orderStatus === _orderStatus.href ? "page" : undefined
                }
              >
                {_orderStatus.name}
              </button>
            ))}
          </nav>
        </div>
      </div>
      <div className="p-3">
        <div className="mb-3">
          <LanguagePicker id={"lang"} labelHidden {...form} />
        </div>
        <PDFRenderer url={url} />
      </div>
    </Modal>
  );
}
