import { useQuery } from "@apollo/client";
import { Broker, BrokerVariables } from "_graphql/queries/__generated__/Broker";
import {
  Brokers,
  Brokers_brokers,
} from "_graphql/queries/__generated__/Brokers";
import { GetBrokerAssociate, GetBrokerAssociateVariables } from "_graphql/queries/__generated__/GetBrokerAssociate";
import { GetBrokerAssociates, GetBrokerAssociatesVariables } from "_graphql/queries/__generated__/GetBrokerAssociates";
import { getBroker, getBrokerAssociate, getBrokerAssociates, getBrokers } from "_graphql/queries/brokers";

export const useBrokers = (variables?: any) => {
  const { page, pageSize, search } = variables || {
    page: 1,
    pageSize: 10,
    search: "",
  };
  const start = (parseInt(page) - 1) * pageSize;
  const end = start + pageSize;
  const { data, ...rest } = useQuery<Brokers>(getBrokers, {
    variables: undefined,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });
  const items: (Brokers_brokers | null)[] =
    data?.brokers
      ?.filter((el) => {
        return (
          el?.re_broker_name?.toLowerCase().includes(search.toLowerCase()) ||
          el?.re_broker_email?.toLowerCase().includes(search.toLowerCase()) ||
          el?.re_broker_website?.toLowerCase().includes(search.toLowerCase())
        );
      })
      .slice(start, end) || [];
  return {
    brokers: items || [],
    ...rest,
  };
};

export const useBroker = (id: string) => {
  const { data, ...rest } = useQuery<Broker, BrokerVariables>(getBroker, {
    variables: {
      brokerId: id ?? "",
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "no-cache",
  });
  return {
    broker: data?.broker,
    ...rest,
  };
};

export const useBrokerAssociates = (variables?: GetBrokerAssociatesVariables) => {
  const { data, ...rest } = useQuery<GetBrokerAssociates, GetBrokerAssociatesVariables>(getBrokerAssociates, {
    variables,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "no-cache",
  });
  return {
    associates: data?.getBrokerAssociates || [],
    ...rest,
  };
};

export const useBrokerAssociate = (variables?: GetBrokerAssociateVariables) => {
  const { data, ...rest } = useQuery<GetBrokerAssociate, GetBrokerAssociateVariables>(getBrokerAssociate, {
    variables,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "no-cache",
  });
  return {
    associate: data?.getBrokerAssociate,
    ...rest,
  };
};
