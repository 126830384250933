import { wrapClick } from "utils";
import { useMutation } from "@apollo/client";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { Modal } from "components";
import * as Yup from "yup";
import { createInsurer } from "_graphql/mutation/insurer";
import { PaymentForm } from "components/forms";
import {
  MakePaymentOnOffer,
  MakePaymentOnOfferVariables,
} from "_graphql/mutation/__generated__/MakePaymentOnOffer";
import { useOffer } from "hooks/data/use-offers";
import { LocationGenerics } from "router/location";
import { useMatch } from "react-location";
import { useOfferPayments } from "hooks/data/use-payments";
import { useMemo } from "react";
import numeral from "numeral";

export const paymentValidationSchema = Yup.object().shape({
  payment_type: Yup.string()
    .oneOf(["Bank Transfer", "Cheque"])
    .required("Invalid Payment Type"),
  payment_from: Yup.object()
    .when("payment_type", {
      is: "Cheque",
      then: (schema) =>
        schema.shape({
          bank_name: Yup.string().required("Bank Name is required"),
          cheque_number: Yup.string().required("Cheque Number is required"),
          date_on_cheque: Yup.string().required("Date on cheque is required"),
        }),
      otherwise: (schema) =>
        schema
          .shape({
            bank_name: Yup.string().required("Bank Name is required"),
          })
          .required(),
    })
    .required(),
  payment_to: Yup.string().required("Beneficiary Bank Name is required"),
  payment_details: Yup.object()
    .shape({
      payment_amount: Yup.string().required("Payment Amount is required"),
      currency: Yup.string().required("Currency is required"),
    })
    .required(),
  offer_payment_comment: Yup.string().required("Payment Comment is required"),
});

export default function CreateInsurerPaymentContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const { params } = useMatch<LocationGenerics>();
  const { offer, loading: fetchingOffer } = useOffer({
    offer_id: params.offer || "",
  });
  const { payments, loading: fetchingPayments } = useOfferPayments({
    args: {
      offer_id: params.offer || "",
    },
  });
  const oldPayments = useMemo(
    () => payments?.reduce((acc, curr) => acc + (curr?.payment_amount || 0), 0),
    [payments]
  );
  const amountToPay = useMemo(
    () =>
      numeral(offer?.fac_premium)
        .subtract(numeral(offer?.commission_amount).add(oldPayments).value())
        .value() || 0,
    [offer, oldPayments]
  );
  const [create, { loading }] = useMutation<
    MakePaymentOnOffer,
    MakePaymentOnOfferVariables
  >(createInsurer);

  const form = useFormik<any>({
    initialValues: {
      payment_type: "",
      payment_from: {
        bank_name: "",
        cheque_number: "",
        date_on_cheque: "",
      },
      offer_payment_comment: "",
      payment_to: "",
      payment_details: {
        payment_amount: 0,
        currency: "",
        conversion: {
          rate: 1,
          currency: "",
        },
      },
    },
    validationSchema: paymentValidationSchema,
    onSubmit: async (variables) => {
      await create({
        variables: {
          paymentInfo: variables,
        },
      }).then(({ data }) => {
        if (data?.makePaymentOnOffer) {
          toast.success("Payment Created Successfully");
          refetch?.();
          form.resetForm();
        } else {
          toast.error("Failed to create payment");
        }
      });
    },
    onReset: () => {
      setOpen(false);
    },
  });

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      loading={fetchingOffer}
      title="Add Payment"
      description="Provide the details to add a new payment record"
      renderActions={() => (
        <>
          <button
            type="button"
            disabled={loading}
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(form.handleSubmit)}
          >
            {loading ? "Adding payment..." : "Add Payment"}
          </button>
        </>
      )}
    >
      <PaymentForm offer={offer} form={form} amountToPay={amountToPay} />
    </Modal>
  );
}
