import React, { useCallback, useEffect, useState } from "react";
import { useDropzone, Accept, FileRejection } from "react-dropzone";
import axios from "axios";
import config from "config";
import _ from "lodash";
import { classNames } from "utils";
import {
  DocumentIcon,
  DocumentPlusIcon,
  PaperClipIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";

axios.defaults.baseURL = config.asset.uri;

interface UploadItemProps {
  id: string;
  multiple?: boolean;
  maxFiles?: number;
  maxSize?: number;
  minSize?: number;
  accept?: Accept;
  disabled?: boolean;
  label?: string;
  values: any;
  setFieldValue: any;
  setFieldError: any;
  setFieldTouched: any;
  handleBlur: any;
  errors?: any;
  touched?: any;
  className?: string;
  required?: boolean;
  count?: number;
}

interface Upload extends File {
  path: any;
  preview: string;
}

const UploadItem: React.FC<UploadItemProps> = ({
  multiple = false,
  maxFiles = 2,
  count = 0,
  maxSize = 1024 * 1024 * 20,
  minSize = 1,
  accept = {
    "pdf/*": [".pdf"],
    "doc/*": [".docx", ".doc", ".docm"],
    "excel/*": [".xlsx", ".xls"], // Added Excel files support
  },
  errors,
  values,
  setFieldValue,
  setFieldError,
  setFieldTouched,
  touched,
  id,
  label,
  required,
}) => {
  const [files, setFiles] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [showLoader, setShowLoader] = useState<boolean>(false);

  const oldFiles = _.get(values, id, []);

  const onDrop = useCallback(
    async (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
      setFieldTouched(id, true);

      if (!acceptedFiles.length) {
        const errorMessages = rejectedFiles
          .flatMap((rejection) => rejection.errors)
          .map((error) => _.startCase(error.code))
          .join(", ");

        setFieldError(id, errorMessages);
        return;
      }

      const newFiles = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );

      console.log("newFiles", files, newFiles);

      setFiles((prev) => (multiple ? [...prev, ...newFiles] : newFiles));

      setLoading(true);
      setShowLoader(true);

      try {
        const fileUploadPromises = acceptedFiles.map(async (file) => {
          const data = new FormData();
          data.append("file", file);

          const response = await axios.post("/attachFilesToOffer", data, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });

          if (response.data && response.data.length > 0) {
            const newValue = multiple
              ? [...oldFiles, response.data[0]]
              : [response.data[0], ...oldFiles];

            setFieldValue(id, newValue);
          }
        });

        await Promise.all(fileUploadPromises);
      } catch (error) {
        console.error("File upload error:", error);
      } finally {
        setLoading(false);
        setTimeout(() => setShowLoader(false), 3000);
      }
    },
    [id, multiple, oldFiles, setFieldError, setFieldTouched, setFieldValue]
  );

  const removeFile = async (index: number) => {
    const fileToRemove = values[id][index];

    try {
      await axios.post("/deleteFileFromOffer", {
        file_path: fileToRemove.file_path,
      });

      const newFiles = [...files];
      newFiles.splice(index, 1);
      setFiles(newFiles);

      // remove value from formik values
      values[id].splice(index, 1);

      console.log("index", id, values[id]?.splice(index, 1));
      setFieldValue(id, values[id].filter((file: any) => file.file_path !== fileToRemove.file_path));
    } catch (error) {
      console.error("File removal error:", error);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles,
    multiple,
    accept,
    maxSize,
    minSize,
    noClick: false,
    disabled: loading,
  });

  useEffect(() => {
    return () => {
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    };
  }, [files]);

  return (
    <>
      <label htmlFor={id} className="block text-sm font-medium text-gray-700">
        {label} {required && <span className="text-red-500">*</span>}
      </label>
      <ul role="list" className="divide-y divide-gray-100 rounded-md border border-gray-200">
        <li {...getRootProps()} className="flex cursor-pointer items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
          <div className="flex w-0 flex-1 items-center">
            <DocumentPlusIcon aria-hidden="true" className="h-5 w-5 flex-shrink-0 text-gray-400" />
            <div className="ml-4 flex min-w-0 flex-1 gap-2">
              <span className="truncate font-medium">Click to upload file</span>
              {/* <span className="flex-shrink-0 text-gray-400">2.4mb</span> */}
            </div>
          </div>
          <div className="ml-4 flex-shrink-0">
            <input {...getInputProps()} />
            {loading ? "Uploading ..." : ''}
          </div>
        </li>
        {files?.map((file, index) => (
          <li className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
            <div className="flex w-0 flex-1 items-center">
              <PaperClipIcon aria-hidden="true" className="h-5 w-5 flex-shrink-0 text-gray-400" />
              <div className="ml-4 flex min-w-0 flex-1 gap-2">
                <span className="truncate font-medium">{file?.path}</span>
                {/* <span className="flex-shrink-0 text-gray-400">2.4mb</span> */}
              </div>
            </div>
            <div className="ml-4 flex-shrink-0">
              <button onClick={() => removeFile(index)} className="font-medium text-red-600 hover:text-red-500">
                Remove
              </button>
            </div>
          </li>
        ))}
      </ul>
      {_.get(errors, id) && _.get(touched, id) ? (
        <p className="mt-2 text-sm text-red-600" id={`${id}-error`}>
          {_.get(errors, id)}
        </p>
      ) : null}
    </>
  );
};

export default UploadItem;
