import { wrapClick } from "utils";
import { useMutation } from "@apollo/client";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { Modal } from "components";
import * as Yup from "yup";
import {
  AddParticipantsTreatyForm,
} from "components/forms";
import { useMatch } from "react-location";
import { LocationGenerics } from "router/location";
import _ from "lodash";
import { useTreaty } from "hooks/data/use-treaties";
import {
  CreateReinsurersTreatyDistribution,
  CreateReinsurersTreatyDistributionVariables,
} from "_graphql/mutation/__generated__/CreateReinsurersTreatyDistribution";
import { updateTreatyReinsurers } from "_graphql/mutation/treaty";

export const valiadationSchema = Yup.object().shape({
  requests: Yup.array()
    .of(
      Yup.object().shape({
        participant_id: Yup.object().required("Participant is required"),
      })
    )
    .min(1)
    .required("At least one participant is required"),
});

export default function AddParticipantsContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const { params } = useMatch<LocationGenerics>();
  const { loading: fetching, treaty } = useTreaty({
    treatyId: params.treaty || "",
  });
  const [create, { loading }] = useMutation<
    CreateReinsurersTreatyDistribution,
    CreateReinsurersTreatyDistributionVariables
  >(updateTreatyReinsurers, {
    refetchQueries: ["Treaty", "GetTreatyReinsurerParticipants"],
  });

  const form = useFormik<any>({
    initialValues: {
      requests: [],
      layer: "",
    },
    validationSchema: valiadationSchema,
    onSubmit: async (variables) => {
      const items = _.groupBy(
        form.values.requests,
        "participant_id.reinsurersreinsurer_id"
      );
      await create({
        variables: {
          treaty_id: params.treaty || "",
          ids: Object.keys(items)?.map((item: any) => ({
            reinsurer_id: item,
            representatives_ids: Object.values(items[item])?.map(
              (rep: any) => rep?.participant_id?.reinsurer_representative_id
            ),
          })),
          layer_number:
            variables.layer === "0"
              ? [
                ...JSON.parse(treaty?.layer_limit || "[]")?.map(
                  (_: any, index: number) => index + 1
                ),
              ]
              : [variables.layer],
          treaty_associate_deduction_id:
            treaty?.treaty_deduction?.treaty_associate_deduction_id || "",
        },
      }).then(({ data }) => {
        if (data?.createReinsurersDistribution) {
          toast.success("Distribution list created successfully");
          refetch?.();
          form.resetForm();
        } else {
          toast.error("Failed to create distribution list");
        }
      });
    },
    onReset: () => {
      setOpen(false);
    },
  });

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      loading={fetching}
      title="Add Participant(s)"
      description="Add participants to this treaty"
      renderActions={() => (
        <>
          <button
            type="button"
            disabled={loading}
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(form.handleSubmit)}
          >
            {loading ? "Saving changes..." : "Save changes"}
          </button>
        </>
      )}
    >
      <AddParticipantsTreatyForm form={form} treaty={treaty} />
    </Modal>
  );
}
