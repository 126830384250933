import { useReInsurers } from "hooks/data/use-re-insurers";
import _ from "lodash";
import { FC, useMemo, useState } from "react";
import Select from "react-select";

interface Option {
  label: {
    title: string;
    imageUrl?: string;
  };
  value: string | object;
}

interface SearchSelectInputProps {
  id: string;
  label: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  labelHidden?: boolean;
  values: any;
  errors?: any;
  touched?: any
  optionsLoading?: boolean;
  setFieldValue: any
  setFieldTouched: any
  setFieldError: any
  rounded?: boolean
  isMulti?: boolean;
  rawId?: boolean;
  addAllOption?: boolean;
}

const SearchSelectInput: FC<SearchSelectInputProps> = ({ id, values, setFieldValue, setFieldTouched, placeholder, label, errors, touched, required, labelHidden, disabled, isMulti, rawId, addAllOption }) => {
  const { reInsurers, loading } = useReInsurers({
    page: 1,
    pageSize: 500,
    search: "",
    sort: "",
  })
  const [query, setQuery] = useState('');
  const options = useMemo(() => [...(addAllOption ? [{ label: 'All', value: ' ' }] : []), ...reInsurers.map(({ re_company_name, reinsurer_id, ...rest }) => ({ label: re_company_name, value: rawId ? reinsurer_id : { re_company_name, reinsurer_id, ...rest } }))], [reInsurers]);

  const filteredOptions =
    query === ''
      ? options
      : options.filter((option) => {
        return option.label.toLowerCase().includes(query.toLowerCase())
      })

  const selectedValue = isMulti
    ? (values[id] || []).map((val: string) =>
      options.find((o) => o.value === val)
    )
    : options.find((o) => o.value === values[id]);

  const handleChange = (
    selectedOptions: any
  ): void => {
    if (isMulti && Array.isArray(selectedOptions)) {
      const selectedValues = selectedOptions.map((option) => option.value);
      setFieldTouched(id, true);
      setFieldValue(id, selectedValues);
    } else {
      const selectedValue = selectedOptions ? selectedOptions.value : null;
      setFieldTouched(id, true);
      setFieldValue(id, selectedValue);
    }
  };

  return (
    <div>
      {!labelHidden && (
        <label className="block text-sm font-medium text-gray-700">
          {label} {required && <span className="text-red-500">*</span>}
        </label>
      )}
      <Select
        options={[
          ...filteredOptions,
        ]}
        value={selectedValue}
        onChange={handleChange}
        placeholder={placeholder}
        isLoading={loading}
        isClearable
        isDisabled={disabled}
        isMulti={isMulti}
        className="w-full mt-1"
      />
      {_.get(errors, id) && _.get(touched, id) && (
        <p className="mt-2 text-sm text-red-600" id={`${id}-error`}>
          {_.get(errors, id)}
        </p>
      )}
    </div>
  )
}

export default SearchSelectInput;