import { CurrencyPicker, TextInput } from "../..";
import * as yup from "yup";
import { useFormik } from "formik";
import { wrapClick } from "utils";
import { XMarkIcon } from "@heroicons/react/24/outline";
import _ from "lodash";

type Props = {
  handleNext: (_values: any) => void;
  handlePrevious: () => void;
  handleCancel: () => void;
  initialValues: any;
};

const validationSchema = yup.object().shape({
  profit_commission: yup
    .number()
    .min(0, "Number must be greater than 0")
    .max(100, "Number must be less than or equal to  100")
    .required("Profit Commission is required"),
  reinsurers_management_expense: yup
    .number()
    .min(0, "Number must be greater than 0")
    .max(100, "Number must be less than or equal to  100")
    .required("Reinsurers Management Expense is required"),
  currency: yup.string().required("Currency is required"),
  estimated_retained_premium_income: yup
    .number()
    .min(0, "Number must be greater than 0")
    .required("Estimated Retained Premium Income is required"),
  portfolio_entry: yup.object().shape({
    withdrawal_percentage: yup
      .number()
      .min(0, "Number must be greater than 0")
      .required("Premium Portfolio Withdrawal % is required"),
    withdrawal_loss_percentage: yup
      .number()
      .min(0, "Number must be greater than 0")
      .required("Premium Portfolio Withdrawal loss % is required"),
    assumption_percentage: yup
      .number()
      .min(0, "Number must be greater than 0")
      .required("Premium Portfolio Assumption % is required"),
    assumption_loss_percentage: yup
      .number()
      .min(0, "Number must be greater than 0")
      .required("Loss Portfolio Withdrawal % is required"),
  }),
  surpluses: yup.array().of(
    yup.object().shape({
      commision: yup.string().required("Commission is required"),
      profit_commision: yup.string().required("Profit Commission is required"),
      retained_premium_income: yup
        .string()
        .required("Retained Premium Income is required"),
    })
  ),
});

const TreatySurplusForm = ({
  handleCancel,
  handleNext,
  initialValues,
  handlePrevious,
}: Props) => {
  const form = useFormik<any>({
    initialValues,
    onSubmit: (values) => {
      handleNext(values);
    },
    validationSchema,
    onReset: () => {
      handleCancel();
    },
  });

  const removeItem = (field: string, id: number) => {
    form.setFieldValue(field, [
      ..._.get(form.values, field)?.filter(
        (val: string, _id: number) => _id !== id
      ),
    ]);
  };

  return (
    <div className="w-full">
      <form
        onSubmit={form.handleSubmit}
        className="flex-1 flex flex-col overflow-hidden"
      >
        {/* {JSON.stringify(initialValues, null, 2)} */}
        <div className="space-y-6 divide-y divide-gray-200 p-6 flex-1">
          <div>
            <span className="text-xs font-light">Proportional details</span>
            <div className="grid grid-cols-2 mb-4 gap-6">
              <div className="">
                <TextInput
                  id="profit_commission"
                  label="Profit Commission (%)"
                  type="number"
                  placeholder="Profit Commission (%)"
                  required={true}
                  {...form}
                />
              </div>
              <div className="">
                <TextInput
                  id="reinsurers_management_expense"
                  label="Reinsurers Management Expense (%)"
                  type="number"
                  placeholder="Reinsurers Management Expense (%)"
                  required={true}
                  {...form}
                />
              </div>
              <div className="">
                <CurrencyPicker
                  id="currency"
                  label="Currency"
                  placeholder="Currency"
                  required={true}
                  {...form}
                />
              </div>
              <div className="">
                <TextInput
                  id="estimated_retained_premium_income"
                  label="Estimated Retained Premium Income"
                  type="number"
                  placeholder="Estimated Retained Premium Income"
                  postText={form.values.currency}
                  required={true}
                  {...form}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="space-y-6 divide-y divide-gray-200 p-6 flex-1">
          <div>
            <span className="text-xs font-light">Portfolio Percentages</span>
            <div className="grid grid-cols-2 mb-4 gap-6">
              <div className="">
                <TextInput
                  id="portfolio_entry.withdrawal_percentage"
                  label="Premium Portfolio Withdrawal %"
                  type="number"
                  placeholder="Premium Portfolio Withdrawal %"
                  required={true}
                  {...form}
                />
              </div>
              <div className="">
                <TextInput
                  id="portfolio_entry.withdrawal_loss_percentage"
                  label="Premium Portfolio Withdrawal loss %"
                  type="number"
                  placeholder="Premium Portfolio Withdrawal loss %"
                  required={true}
                  {...form}
                />
              </div>
              <div className="">
                <TextInput
                  id="portfolio_entry.assumption_percentage"
                  label="Premium Portfolio Assumption %"
                  placeholder="Premium Portfolio Assumption %"
                  required={true}
                  {...form}
                />
              </div>
              <div className="">
                <TextInput
                  id="portfolio_entry.assumption_loss_percentage"
                  label="Loss Portfolio Withdrawal %"
                  type="number"
                  placeholder="Loss Portfolio Withdrawal %"
                  required={true}
                  {...form}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="p-6">
          <div className="mt-1 w-full ">
            <table className="min-w-full divide-y divide-gray-300 border border-gray-300 rounded-md">
              <thead className="bg-gray-50">
                <tr className="divide-x divide-gray-200">
                  <th
                    scope="col"
                    className="whitespace-nowrap px-2 py-2 text-left text-sm font-semibold text-gray-900"
                  >
                    Surplus #
                  </th>
                  <th
                    scope="col"
                    className="whitespace-nowrap px-2 py-2 text-left text-sm font-semibold text-gray-900"
                  >
                    Commission
                  </th>
                  <th
                    scope="col"
                    className="whitespace-nowrap px-2 py-2 text-left text-sm font-semibold text-gray-900"
                  >
                    Profit Commission
                  </th>
                  <th
                    scope="col"
                    className="whitespace-nowrap px-2 py-2 text-left text-sm font-semibold text-gray-900"
                  >
                    Retained Premium Income
                  </th>

                  <th
                    scope="col"
                    className="whitespace-nowrap px-2 py-2 text-sm font-semibold text-gray-900 w-[38px]"
                  ></th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {_.times(form.values.surpluses?.length + 1, (idx) => {
                  const id = `surpluses.${idx}`;
                  const isLast = (form.values.surpluses?.length || 0) === idx;

                  return (
                    <tr key={idx} className="divide-x divide-gray-200">
                      <td className="text-center bg-gray-50">{idx + 2}</td>
                      <td className="p-0">
                        <TextInput
                          label={""}
                          labelHidden
                          key={idx}
                          id={`${id}.commision`}
                          borderless
                          type="number"
                          {...form}
                          placeholder={" Commission %"}
                        />
                      </td>
                      <td className="p-0">
                        <TextInput
                          label={""}
                          labelHidden
                          key={idx}
                          borderless
                          type="number"
                          id={`${id}.profit_commision`}
                          {...form}
                          placeholder={"Profit Commission %"}
                        />
                      </td>
                      <td className="p-0">
                        <TextInput
                          label={""}
                          labelHidden
                          key={idx}
                          id={`${id}.retained_premium_income`}
                          borderless
                          type="number"
                          {...form}
                          placeholder={" Retained Premium Income %"}
                        />
                      </td>
                      <td className="p-0 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400 text-right w-[38px] h-[38px] items-center justify-center flex">
                        {!isLast && (
                          <button
                            type="button"
                            onClick={wrapClick(() =>
                              removeItem("surpluses", idx)
                            )}
                            className="inline-flex items-center rounded-full border border-transparent p-1 text-red-500 hover:bg-red-300 hover:text-red-900 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                          >
                            <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                          </button>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>

        <div className="bg-gray-50 dark:bg-gray-800 px-4 py-3 sm:py-4 sm:px-6 sm:flex sm:flex-row-reverse border-t border-gray-200">
          <button
            type="submit"
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
          >
            Next
          </button>
          <button
            type="button"
            onClick={wrapClick(handlePrevious)}
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
          >
            Previous
          </button>
          <button
            type="button"
            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 dark:border-gray-600 shadow-sm px-4 py-2 bg-white dark:bg-gray-900 text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 hover:dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(form.resetForm)}
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default TreatySurplusForm;
