import { wrapClick } from "utils";
import { useMutation } from "@apollo/client";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { Modal } from "components";
import * as Yup from "yup";
import { SendEmailForm } from "components/forms";
import {
  sendOfferEmails,
} from "_graphql/mutation/offers";
import { useMatch } from "react-location";
import { LocationGenerics } from "router/location";
import _ from "lodash";
import {
  SendOfferAsBroadCast,
  SendOfferAsBroadCastVariables,
} from "_graphql/mutation/__generated__/SendOfferAsBroadCast";

export const valiadationSchema = Yup.object().shape({
  message: Yup.string().required("Message is required"),
  subject: Yup.string().required("Subject is required"),
});

export default function SendEmailsContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const { params } = useMatch<LocationGenerics>();

  const [create, { loading }] = useMutation<
    SendOfferAsBroadCast,
    SendOfferAsBroadCastVariables
  >(sendOfferEmails, {
    refetchQueries: ["Offer"],
  });

  const form = useFormik<any>({
    initialValues: {
      copied_emails: [],
      subject: "",
      message: "",
      include_attachment: false,
      should_send: 0,
      attachments: [],
    },
    validationSchema: valiadationSchema,
    onSubmit: async (variables) => {
      // console.log(variables.copied_emails);
      // return;
      await create({
        variables: {
          data: {
            offer_id: params.offer || "",
            copied_emails:
              variables.copied_emails?.map((el: any) => el) ||
              [],
            message_content: variables?.message || "",
            attachment:
              variables.attachments?.map((el: any) => el?.file_path || "") || [],
            subject: variables?.subject || "",
          },
          include_attachment: variables?.include_attachment || false,
          should_send: variables?.should_send || false,
        },
      })
        .then(({ data }) => {
          toast.success("Emails sent successfully.");
          refetch?.();
          form.resetForm();
        })
        .catch((e) => {
          console.log(e);
          toast.error(e?.message);
        });
    },
    onReset: () => {
      setOpen(false);
    },
  });

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title="Send Email to Participant(s)"
      description="Draft an email to send to the participant(s) of this offer."
      renderActions={() => (
        <>
          <button
            type="button"
            disabled={loading}
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(form.handleSubmit)}
          >
            {loading ? "Sending emails..." : "Send Emails"}
          </button>
        </>
      )}
    >
      {/* {JSON.stringify(form.values, null, 2)} */}
      <SendEmailForm form={form} />
    </Modal>
  );
}
