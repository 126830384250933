import { wrapClick } from "utils";
import { useMutation } from "@apollo/client";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { Modal } from "components";
import * as Yup from "yup";
import { ReInsurerRepForm } from "components/forms";
import { createReInsurerRep, updateReinsurerRep } from "_graphql/mutation/re-insurer";
import {
    CreateReinsurerRep,
    CreateReinsurerRepVariables,
} from "_graphql/mutation/__generated__/CreateReinsurerRep";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { useReInsurer, useReInsurerRep, useReInsurerReps } from "hooks/data/use-re-insurers";
import { useEffect } from "react";
import { UpdateReinsurerRep, UpdateReinsurerRepVariables } from "_graphql/mutation/__generated__/UpdateReinsurerRep";

export const insurerRepValidationSchema = Yup.object().shape({
    rep_first_name: Yup.string().required("First name is required"),
    rep_last_name: Yup.string().required("Last name is required"),
    rep_primary_phonenumber: Yup.string().required("Phone number is required"),
    rep_secondary_phonenumber: Yup.string().notRequired(),
    rep_email: Yup.string().required("Email is required"),
    position: Yup.string().required("Position is required"),
});

export default function UpdateManagerContainer({
    open,
    setOpen,
    refetch,
}: {
    open: boolean;
    setOpen: (val: boolean) => void;
    refetch?: () => void;
}) {
    const searchParams = useSearch<LocationGenerics>();
    const { associate, loading: fetching } = useReInsurerRep({
        repId: searchParams?.id || "",
    })
    const [create, { loading }] = useMutation<
        UpdateReinsurerRep,
        UpdateReinsurerRepVariables
    >(updateReinsurerRep);

    const form = useFormik<any>({
        initialValues: {
            reinsurersreinsurer_id: searchParams?.id || "",
            rep_first_name: "",
            rep_last_name: "",
            rep_primary_phonenumber: "",
            rep_secondary_phonenumber: "",
            rep_email: "",
            position: "",
        },
        validationSchema: insurerRepValidationSchema,
        onSubmit: async (variables) => {
            await create({
                variables: {
                    reinsurerRep: {
                        ...variables,
                        reinsurersreinsurer_id: associate?.reinsurersreinsurer_id || "",
                    },
                    reinsurerRepresentativeId: searchParams?.id || "",
                },
            }).then(({ data }) => {
                if (data?.updateReinsurerRep) {
                    toast.success("Re-Insurer Representative updated successfully");
                    refetch?.();
                    form.resetForm();
                } else {
                    toast.error("Failed to update Re-Insurer Representative");
                }
            });
        },
        onReset: () => {
            setOpen(false);
        },
    });

    useEffect(() => {
        if (associate) {
            form.setValues({
                reinsurersreinsurer_id: associate.reinsurersreinsurer_id,
                rep_first_name: associate.rep_first_name,
                rep_last_name: associate.rep_last_name,
                rep_primary_phonenumber: associate.rep_primary_phonenumber,
                rep_secondary_phonenumber: associate.rep_secondary_phonenumber,
                rep_email: associate.rep_email,
                position: associate.position,
            })
        }
    }, [associate])

    return (
        <Modal
            open={open}
            setOpen={setOpen}
            loading={fetching}
            title="Update Re-Insurer Representative"
            description="Provide the details to add a new re-insurer rep"
            renderActions={() => (
                <>
                    <button
                        type="button"
                        disabled={loading}
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={wrapClick(form.handleSubmit)}
                    >
                        {loading ? "Updating Re-Insurer Rep..." : "Update Re-Insurer Rep"}
                    </button>
                </>
            )}
        >
            <ReInsurerRepForm form={form} />
        </Modal>
    );
}
