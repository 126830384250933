import { FC } from "react";
import { useMutation } from "@apollo/client";
import { useNavigate, useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import * as Yup from "yup";
import { TextInput } from "components/core";
import { useFormik } from "formik";
import { CHANGE_PASSWORD } from "_graphql/mutation/auth";
import { ChangePassword, ChangePasswordVariables } from "_graphql/mutation/__generated__/ChangePassword";
import toast from "react-hot-toast";



const ResetPasswordPage: FC = () => {
  const search = useSearch<LocationGenerics>();
  const navigate = useNavigate();
  const [changePassword, { loading }] = useMutation<ChangePassword, ChangePasswordVariables>(CHANGE_PASSWORD);

  const { handleSubmit, ...form } = useFormik({
    initialValues: {
      password: "",
      confirm_password: "",
    },
    validationSchema: Yup.object().shape({
      password: Yup.string().min(8, "Password should be a minimum of 8").required(),
      confirm_password: Yup.string().test(
        "passwords-match",
        "Passwords must match",
        function (value) {
          return this.parent.password === value;
        }
      ).required("Password confirmation is required"),
    }),
    onSubmit: async (values) => {
      await changePassword({
        variables: {
          password: values.password,
          id: search?.id?.toString() ?? "",
        },
      }).then(({ data }) => {
        console.log(data)
        if (!data?.changePasswordByEmployee) return toast.error("Could not reset password");
        return navigate({
          replace: true,
          to: `/?from=${'reset_success'}&redirect=${search?.redirect ?? "/"}`,
        });
      }).catch((err) => {
        toast.error(err?.message ?? "Could not reset password");
      });
    },
  });

  return (
    <div className="mx-auto w-full max-w-sm lg:w-96 ">
      <div>
        <div className="flex items-end flex-row">
          <div className="h-16 w-16 flex-shrink-0 p-1 bg-gray-300">
            <img
              className="h-14 w-14 border-white border p-1"
              src={require("assets/logo.png")}
              alt="SUBS"
            />
          </div>
          <div className="ml-3">
            <span className="text-lg text-primary-600 font-mono">
              Visal Reinsurance
              <br />
              Dashboard
            </span>
          </div>
        </div>
        <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
          Reset Password
        </h2>
        <p className="mt-2 text-sm text-gray-600">
          Provide new password to keep your access secure
        </p>
      </div>

      <div className="mt-8">
        <div className="mt-6">
          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <TextInput
                id="password"
                label="Password"
                type="password"
                placeholder="e.g.  **************"
                {...form}
              />
            </div>

            <div className="space-y-1">
              <TextInput
                id="confirm_password"
                label="Confirm Password"
                type="password"
                placeholder="e.g.  **************"
                {...form}
              />
            </div>


            <div>
              <button
                type="submit"
                disabled={loading}
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
              >
                {loading ? "Resetting..." : "Reset Password"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordPage;
