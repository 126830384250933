import { FC } from "react";
import { useUrlState } from "utils";

import _ from "lodash";
import { Header } from "components";

interface OfficeHeaderProps {
  renderActions?: FC<{}>;
}

const OfficeHeader: FC<OfficeHeaderProps> = ({ renderActions }) => {
  return (
    <Header
      renderActions={() => (
        <div className="flex items-center space-x-3">
          {renderActions && (
            <>
              <div className="h-6 w-px bg-primary-300" />
              {renderActions?.({})}
            </>
          )}
        </div>
      )}
    />
  );
};

export default OfficeHeader;
