import { useMutation } from '@apollo/client';
import { CreateTreatyProgramAssociateDeductions, CreateTreatyProgramAssociateDeductionsVariables } from '_graphql/mutation/__generated__/CreateTreatyProgramAssociateDeductions';
import { UpdateTreatyProgramAssociateDeductions, UpdateTreatyProgramAssociateDeductionsVariables } from '_graphql/mutation/__generated__/UpdateTreatyProgramAssociateDeductions';
import { createAssociateDeduction, updateAssociateDeduction } from '_graphql/mutation/treaty-programs';
import { Modal, OfficeButton } from 'components';
import { DeductionForm } from 'components/forms';
import { useFormik } from 'formik';
import { useTreatyProgram, useTreatyProgramDeduction } from 'hooks/data/use-treaty-programs';
import { FC, useEffect } from 'react'
import toast from 'react-hot-toast';
import { useMatch, useSearch } from 'react-location';
import { LocationGenerics } from 'router/location';
import { wrapClick } from 'utils';
import * as Yup from 'yup';

type Props = {
    open: boolean;
    setOpen: (val: boolean) => void;
    refetch?: () => void;
}

const validationSchema = Yup.object().shape({
    deductions: Yup.object().shape({
        treaty_period_from: Yup.date().required("Period From is required"),
        treaty_period_to: Yup.date().required("Period To is required"),
        nic_levy: Yup.number().min(0,"Number must be greater than 0").typeError("Must be a number").required("NIC Levy is required"),
        withholding_tax: Yup.number().min(0,"Number must be greater than 0").typeError("Must be a number").required("Withholding Tax is required"),
        brokerage: Yup.number().min(0,"Number must be greater than 0").typeError("Must be a number").required("Brokerage is required"),
        commission: Yup.number().min(0,"Number must be greater than 0").typeError("Must be a number").required("Commission is required"),
        treaty_programstreaty_program_id: Yup.string().required("Treaty Program is required"),
    })
})

const UpdateDeductionContainer: FC<Props> = ({ open, setOpen }) => {
    const searchParams = useSearch<LocationGenerics>()
    const { params } = useMatch<LocationGenerics>()
    const [initiate, { loading }] = useMutation<UpdateTreatyProgramAssociateDeductions, UpdateTreatyProgramAssociateDeductionsVariables>(updateAssociateDeduction, {
        refetchQueries: ["GetTreatyProgramDeductions"]
    })
    const form = useFormik({
        initialValues: {
            deductions: {
                treaty_period_from: "",
                treaty_period_to: "",
                nic_levy: 0,
                withholding_tax: 0,
                brokerage: 0,
                commission: 0,
                treaty_programstreaty_program_id: params?.treaty_program || ""
            }
        },
        validationSchema,
        onSubmit: (values) => {
            initiate({
                variables: {
                    deductions: values.deductions,
                    deduction_id: searchParams?.id || ""
                }
            }).then(({ data }) => {
                if (data?.updateTreatyProgramAssociateDeductions) {
                    toast.success("Deduction added successfully")
                    setOpen(false)
                    form.resetForm();
                }
            }).catch(err => {
                toast.error(err.message)
            })
        }
    });
    const { treatyProgram, loading: fetchingProgram } = useTreatyProgram({
        programId: params?.treaty_program || ""
    })
    const { deduction, loading: fetching } = useTreatyProgramDeduction({
        treatyAssociateDeductionId: searchParams?.id || ""
    });

    useEffect(() => {
        if (deduction) {
            form.setValues({
                deductions: {
                    brokerage: deduction?.brokerage || 0,
                    commission: deduction?.commission || 0,
                    nic_levy: deduction?.nic_levy || 0,
                    treaty_period_from: deduction?.treaty_period_from || "",
                    treaty_period_to: deduction?.treaty_period_to || "",
                    withholding_tax: deduction?.withholding_tax || 0,
                    treaty_programstreaty_program_id: deduction.treaty_program?.treaty_program_id || ""
                }
            })
        }
    }, [deduction])

    return (
        <Modal
            open={open}
            setOpen={setOpen}
            loading={fetching || fetchingProgram}
            size="4xl"
            title="Update deduction"
            description="Provide the details to update a new deduction"
            renderActions={() => (
                <div className='ml-3'>
                    <OfficeButton onClick={wrapClick(form.handleSubmit)}>
                        {loading ? "Updating Deduction..." : "Update Deduction"}
                    </OfficeButton>
                </div>
            )}
        >
            <DeductionForm program={treatyProgram} form={form} />
        </Modal>
    )
}

export default UpdateDeductionContainer