import { gql } from "@apollo/client";

export const getTreatyPrograms = gql`
  query TreatyPrograms($args: TreatyProgramFilters) {
    rows: getTreatyPrograms(args: $args) {
      treaty_program_id
      treaty_name
      treaty_details
      treaty_type
      insurer {
        insurer_abbrv
        insurer_company_email
        insurer_company_name
        insurer_id
      }
      treaty_associate_deductions {
        treaty_associate_deduction_id
        commission
        brokerage
        nic_levy
        withholding_tax
        treaty_period_from
        treaty_period_to
      }
    }
    count: getTreatyProgramsCount(args: $args)
  }
`;

export const getTreatyProgram = gql`
  query TreatyProgram($programId: ID) {
    treatyProgram(program_id: $programId) {
      treaty_program_id
      treaty_name
      treaty_details
      treaty_type
      insurer {
        insurer_abbrv
        insurer_company_email
        insurer_company_name
        insurer_id
      }
      treaty_associate_deductions {
        treaty_associate_deduction_id
        commission
        brokerage
        nic_levy
        withholding_tax
        treaty_period_from
        treaty_period_to
      }
    }
  }
`;

export const getTreatyProgramDeductions = gql`
  query GetTreatyProgramDeductions($treatyProgramId: ID) {
    getTreatyProgramDeductions(treaty_program_id: $treatyProgramId) {
      treaty_associate_deduction_id
      commission
      brokerage
      nic_levy
      withholding_tax
      treaty_period_from
      treaty_period_to
    }
  }
`;
export const getTreatyProgramDeduction = gql`
  query GetTreatyProgramDeduction($treatyAssociateDeductionId: ID!) {
    getTreatyProgramDeduction(
      treaty_associate_deduction_id: $treatyAssociateDeductionId
    ) {
      treaty_associate_deduction_id
      commission
      brokerage
      nic_levy
      withholding_tax
      treaty_period_from
      treaty_period_to
      treaty_program {
        treaty_program_id
        treaty_details
        treaty_name
        treaty_type
      }
    }
  }
`;
