import { wrapClick } from "utils";
import { InsurerView, Modal, PDFRenderer } from "components";
import { useSearch, useNavigate, useMatch } from "react-location";
import { LocationGenerics } from "router/location";
import { Action } from "components/buttons/action-button";
import { useInsurer } from "hooks/data/use-insurers";
import config from "config";

export default function ViewInsurerPaymentReceiptContainer({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const { params } = useMatch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const { insurer, loading } = useInsurer(searchParams?.id || "");
  const dispatchAction =
    (id: string, action: Exclude<Action, "expand" | "goto" | "clone">) =>
    () => {
      navigate({
        search: (old) => ({
          ...old,
          id,
          modal: action,
        }),
      });
    };
  const expandItem = () => {
    navigate({
      to: `./${searchParams.id}/view`,
    });
  };

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      loading={loading}
      size="6xl"
      title="Payment Receipt"
      description="Details of payment receipt are shown below"
    >
      <PDFRenderer
        url={`${config.asset.uri}payment_receipt/${btoa(
          JSON.stringify({
            offer_id: params?.offer,
            payment_id: searchParams?.id,
          })
        )}`}
      />
    </Modal>
  );
}
