import {
  InsurerPicker,
  SelectInput,
  TextArea,
  TextInput,
  TreatyProgramDeductionsPicker,
  TreatyProgramPicker,
  TreatyTypePicker,
} from "../../../components";
import * as yup from "yup";
import { useFormik } from "formik";
import { wrapClick } from "utils";
import { useEffect } from "react";
import _ from "lodash";

type Props = {
  handleNext: (_values: any) => void;
  handleCancel: () => void;
  parentForm?: any;
  initialValues: any;
};

const validationSchema = yup.object().shape({
  treaty_reference: yup.string().required("Treaty Reference is required"),
  treaty_type: yup.string().required("Treaty Type is required"),
  treaty_program: yup
    .object()
    .nonNullable()
    .typeError("Select one treaty program")
    .required("Treaty Program is required"),
  treaty_program_deduction: yup
    .object()
    .nonNullable()
    .typeError("Select one treaty pepriod")
    .required("Treaty Period is required"),
  kek_reference: yup.string().required("Visal Reference is required"),
  order_hereon: yup
    .number()
    .typeError("Must be a number")
    .required("Order hereon is required"),
});

const TreatyForm = ({ handleCancel, handleNext, initialValues }: Props) => {
  const form = useFormik<{
    treaty_reference: string;
    treaty_type: string;
    treaty_program: any;
    kek_reference: string;
    order_hereon: number;
    treaty_program_deduction: any;
    insurer: any;
  }>({
    initialValues,
    onSubmit: (values) => {
      handleNext(values);
    },
    validationSchema,
    onReset: () => {
      handleCancel();
    },
  });

  useEffect(() => {
    form.setValues(initialValues);
  }, [initialValues]);

  return (
    <div className="w-full h-full overflow-hidden">
      <form
        onSubmit={form.handleSubmit}
        className="flex-1 flex flex-col overflow-hidden"
      >
        {JSON.stringify(_.last(form.values?.treaty_program?.treaty_associate_deductions), null, 2)}
        <div className="space-y-6 divide-y divide-gray-200 p-6 overflow-y-scroll">
          <div>
            <div className="grid grid-cols-2 mb-4 gap-6">
              <div className="col-span-2">
                <InsurerPicker
                  id="insurer"
                  label="Insurer"
                  placeholder="Select Insurer"
                  required={true}
                  {...form}
                />
              </div>
              <div className="">
                <TextInput
                  id="treaty_reference"
                  label="Treaty Reference"
                  type="text"
                  placeholder="Treaty Ref#"
                  required={true}
                  {...form}
                />
              </div>
              <div className="">
                <TreatyTypePicker
                  id="treaty_type"
                  label="Treaty Type"
                  placeholder="Treaty Type"
                  required={true}
                  {...form}
                />
              </div>
              <div className="">
                <TreatyProgramPicker
                  id="treaty_program"
                  label="Treaty Program"
                  placeholder="Treaty Program"
                  filter={{
                    type: form.values?.treaty_type as any,
                    insurer_id: form.values?.insurer?.insurer_id || "",
                  }}
                  required={true}
                  {...form}
                />
              </div>
              <div className="">
                <TreatyProgramDeductionsPicker
                  id="treaty_program_deduction"
                  label="Treaty Period"
                  placeholder="Treaty Period"
                  filter={{
                    id: form.values?.treaty_program?.treaty_program_id || "",
                  }}
                  required={true}
                  {...form}
                />
              </div>
              {form.values?.treaty_program_deduction && (
                <div className="col-span-2 mt-6 border border-gray-300 border-dashed bg-gray-100 divide-y divide-dashed divide-gray-300 rounded-md">
                  <div className="grid grid-cols-2 gap-4 p-4">
                    <div className="">
                      <span className="block text-sm font-light text-gray-700">
                        Commission (%)
                      </span>
                      <div className="mt-1 block w-full sm:text-sm font-medium">
                        {form.values?.treaty_program_deduction?.commission ||
                          "N/A"}
                      </div>
                    </div>
                    <div className="">
                      <span className="block text-sm font-light text-gray-700">
                        Withholing Tax (%)
                      </span>
                      <div className="mt-1 block w-full sm:text-sm font-medium">
                        {form.values?.treaty_program_deduction
                          ?.withholding_tax || "N/A"}
                      </div>
                    </div>
                    <div className="">
                      <span className="block text-sm font-light text-gray-700">
                        Brokerage (%)
                      </span>
                      <div className="mt-1 block w-full sm:text-sm font-medium">
                        {form.values?.treaty_program_deduction?.brokerage ||
                          "N/A"}
                      </div>
                    </div>
                    <div className="">
                      <span className="block text-sm font-light text-gray-700">
                        NIC Levy (%)
                      </span>
                      <div className="mt-1 block w-full sm:text-sm font-medium">
                        {form.values?.treaty_program_deduction?.nic_levy ||
                          "N/A"}
                      </div>
                    </div>
                    <div className="">
                      <span className="block text-sm font-light text-gray-700">
                        Period From
                      </span>
                      <div className="mt-1 block w-full sm:text-sm font-medium">
                        {form.values?.treaty_program_deduction
                          ?.treaty_period_from || "N/A"}
                      </div>
                    </div>
                    <div className="">
                      <span className="block text-sm font-light text-gray-700">
                        Period To
                      </span>
                      <div className="mt-1 block w-full sm:text-sm font-medium">
                        {form.values?.treaty_program_deduction
                          ?.treaty_period_to || "N/A"}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="">
                <TextInput
                  id="order_hereon"
                  label="Order Hereon"
                  placeholder="Order Hereon %"
                  required={true}
                  {...form}
                />
              </div>
              <div className="">
                <TextInput
                  id="kek_reference"
                  label="Visal Reference"
                  placeholder="Visal Ref#"
                  required={true}
                  {...form}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="bg-gray-50 dark:bg-gray-800 px-4 py-3 sm:py-4 sm:px-6 sm:flex sm:flex-row-reverse border-t border-gray-200">
          <button
            type="submit"
            disabled={form.isSubmitting || !form.isValid}
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto disabled:cursor-pointer disabled:bg-gray-400 sm:text-sm"
          >
            Next
          </button>
          <button
            type="button"
            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 dark:border-gray-600 shadow-sm px-4 py-2 bg-white dark:bg-gray-900 text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 hover:dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(form.resetForm)}
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default TreatyForm;
