import { DocumentPlusIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import {
  ActionButton,
  InsurerPicker,
  OfficeButton,
  OfficeHeader,
  Paginator,
  TableComponent,
  TableLoader,
} from "components";
import { Action } from "components/buttons/action-button";
import numeral from "numeral";
import { FC, useEffect, useMemo } from "react";
import { useNavigate, useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { useUrlState, withRoles } from "utils";
import ViewOfferSlipContainer from "./view-slip";
import CreateOfferContainer from "./create";
import { usePagination } from "hooks";
import { useFormik } from "formik";
import { useEndorsements } from "hooks/data/use-endorsement";
import { GetEndorsements_rows } from "_graphql/queries/__generated__/GetEndorsements";
import UpdateEndorsementContainer from "./update";

type Props = {};


const CreateSlipPage: FC<Props> = () => {
  const [modal, setModal] = useUrlState("modal");
  const [orderStatus] = useUrlState("orderStatus");
  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const { offset, limit, setPage, search } = usePagination();
  const form = useFormik<any>({
    initialValues: {
      insurer: "",
    },
    onSubmit: (values) => {
      console.log("values", values);
    },
  });

  const filter = useMemo(
    () => ({
      args: {
        // status: orderStatus ? [orderStatus] : ["PENDING", "OPEN"],
        insurer_id: form.values.insurer || "",
        // offer_id: searchParams.id || " ",
        search: search || "",
        // payment_status: [],
        // claim_status: [],
        offset,
        limit,
      },
    }),
    [
      form.values.insurer,
      limit,
      offset,
      orderStatus,
      searchParams.page,
      searchParams.pageSize,
      search,
    ]
  );

  const { endorsements, loading, refetch, count } = useEndorsements(filter);

  const dispatchAction =
    (id: string, action: Exclude<Action, "expand" | "goto" | "clone">) =>
      () => {
        navigate({
          search: (old) => ({
            ...old,
            id,
            modal: action,
          }),
        });
      };

  const expandItem = (id: string, policy_number: string) => {
    navigate({
      to: `./${id}/endorsement/${policy_number}`,
    });
  };

  useEffect(() => {
    if (orderStatus || form.values.insurer) {
      setPage(1);
    }
  }, [orderStatus, setPage, form.values.insurer]);

  return (
    <main className="flex-1 flex flex-col overflow-hidden bg-shade-500">
      <OfficeHeader
        renderActions={() => (
          <OfficeButton onClick={() => setModal("create")}>
            <DocumentPlusIcon className="w-5 h-5 mr-2" />
            Create Endorsement
          </OfficeButton>
        )}
      />
      <div className="flex flex-1 overflow-y-auto">
        <div className="flex-1 mx-auto min-w-0  py-4 sm:py-6 overflow-hidden overflow-y-auto light flex">
          <TableComponent
            title={"endorsements"}
            hasSearch
            loading={loading}
            renderHeaderItems={() => (
              <>
                <InsurerPicker
                  placeholder="Filter by Cedant"
                  id={"insurer"}
                  label={""}
                  labelHidden
                  rawId
                  {...form}
                />
              </>
            )}
            pagination={() => (
              <Paginator
                offset={offset}
                limit={limit}
                totalItems={count || 0}
                currentSize={endorsements.length}
                setOffset={setPage}
              />
            )}
            columns={[
              {
                name: "Policy #",
                accessor: "offer_detail.policy_number",
                render: (value: GetEndorsements_rows) => (
                  <span className="font-bold text-xs px-2">
                    {value.offer_endorsement_detail?.policy_number || "N/A"}
                  </span>
                ),
              },
              {
                name: "Insurance Company",
                accessor: "insurer.insurer_company_name",
                render: (value: GetEndorsements_rows) => (
                  <div className="flex flex-col text-xs">
                    <span className="font-medium">
                      {value.insurer?.insurer_company_name || "N/A"}
                    </span>
                    <span className="font-light">
                      {value.insurer?.insurer_company_email || "N/A"}
                    </span>
                  </div>
                ),
              },
              {
                name: "Insured",
                accessor: "offer_detail.insured_by",
                render: (value: GetEndorsements_rows) => (
                  <div className="flex flex-col text-xs">
                    <span className="font-light">
                      {value.offer_endorsement_detail?.insured_by || "N/A"}
                    </span>
                  </div>
                ),
              },
              {
                name: "Class Of Business",
                accessor: "classofbusiness.business_name",
                render: (value: GetEndorsements_rows) => (
                  <div className="flex flex-col text-xs">
                    <span className="font-medium">
                      {value.classofbusiness?.business_name || "N/A"}
                    </span>
                  </div>
                ),
              },
              {
                name: "Sum Insured",
                accessor: "sum_insured",
                render: (value: GetEndorsements_rows) => (
                  <div className="flex flex-col text-xs">
                    <span className="font-medium">
                      {value?.offer_endorsement_detail?.currency}{" "}
                      {numeral(value.sum_insured).format("0,0 ") || "N/A"}
                    </span>
                  </div>
                ),
              },
              {
                name: "Rate",
                accessor: "rate",
                render: (value: GetEndorsements_rows) => (
                  <div className="flex flex-col text-xs">
                    <span className="font-medium">
                      {numeral(value.rate).format("0,0.00%") || "N/A"}
                    </span>
                  </div>
                ),
              },
              {
                name: "Status",
                accessor: "offer_status",
                render: ({ endorsement_status }: GetEndorsements_rows) => (
                  <span
                    className={clsx("text-xs", {
                      "text-blue-600": endorsement_status === "OPEN",
                      "text-yellow-600": endorsement_status === "PENDING",
                    })}
                  >
                    {endorsement_status}
                  </span>
                ),
              },
              {
                name: "Offer Date",
                accessor: "created_at",
                render: (value: GetEndorsements_rows) => (
                  <span>{new Date(value.created_at).toLocaleDateString()}</span>
                ),
              },
              {
                name: "Actions",
                accessor: "actions",
                render: (value: GetEndorsements_rows) => (
                  <>
                    <ActionButton
                      action="resolve"
                      tooltip="View Placing Slip"
                      onClick={dispatchAction(
                        value.offer_endorsement_id || "",
                        "resolve"
                      )}
                    />
                    <ActionButton
                      action="update"
                      tooltip="Edit Offer"
                      onClick={dispatchAction(
                        value.offer_endorsement_id || "",
                        "update"
                      )}
                    />
                    <ActionButton
                      action="expand"
                      tooltip="View Offer"
                      onClick={() =>
                        expandItem(
                          value?.offer_endorsement_id || "",
                          value?.offer_endorsement_detail?.policy_number || ""
                        )
                      }
                    />
                    {withRoles(["CEO", "System Administrator", "Senior Broking Officer", "Finance Officer"])(<ActionButton
                      action="remove"
                      tooltip="Delete Offer"
                      onClick={dispatchAction(
                        value.offer_endorsement_id || "",
                        "remove"
                      )}
                    />)}
                  </>
                ),
              },
            ]}
            data={endorsements}
            refetch={refetch}
            renderLoader={() => (
              <TableLoader items={[1, 2, 1, 1, 1, 1, 1, 1, 1]} />
            )}
          />
        </div>
      </div>
      <CreateOfferContainer
        open={modal === "create"}
        setOpen={(val: boolean) => setModal(val ? "create" : undefined)}
        refetch={refetch}
      />
      {searchParams.id && (
        <>
          <ViewOfferSlipContainer
            open={modal === "resolve"}
            setOpen={(val: boolean) => setModal(val ? "resolve" : undefined)}
          />
          <UpdateEndorsementContainer
            open={modal === "update"}
            setOpen={(val: boolean) => setModal(val ? "update" : undefined)}
            refetch={refetch}
          />
        </>
      )}
    </main>
  );
};

export default CreateSlipPage;
