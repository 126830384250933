import {
  CurrencyPicker,
  OfficeButton,
  PDFRenderer,
  SelectInput,
  TextInput,
} from "components";
import config from "config";
import { useFormik } from "formik";
import { useStatementPaidCheques } from "hooks/data/use-statement-paid-cheques";
import _ from "lodash";
import { useEffect } from "react";
import { wrapClick } from "utils";
import * as Yup from "yup";

type Props = {};

const BrokerageStatementTab = () => {
  const form = useFormik<any>({
    initialValues: {
      currency: "",
      date: "",
      cheque: [],
    },
    onSubmit: () => {
      form.setFormikState((state) => ({ ...state, status: "SUBMIITED" }));
      form.setSubmitting(false);
      return;
    },
    validationSchema: Yup.object().shape({
      currency: Yup.string().required("Currency is required"),
      date: Yup.date().required("Date is required"),
      cheque: Yup.array()
        .of(Yup.array().of(Yup.string()))
        .required("Cheque is required"),
    }),
    initialStatus: "NOT_SUBMIITED",
  });
  const { loadingCheques, cheques } = useStatementPaidCheques();


  useEffect(() => {
    form.setFormikState((state) => ({ ...state, status: "NOT_SUBMIITED" }));
    form.setSubmitting(false);
  }, [form.values.currency, form.values.date, form.values.cheque]);

  return (
    <div className="w-full">
      <div className="py-3 flex justify-between">
        <h1 className="font-bold text-gray-300 text-lg">Brokerage Statement</h1>
        <div>
          <OfficeButton
            disabled={form.isSubmitting}
            onClick={wrapClick(form.handleSubmit)}
          >
            {form.isSubmitting ? "Generating ..." : "Generate Report"}
          </OfficeButton>
          {form.status === "SUBMIITED" && form.isValid && (
            <a href="https://manage.visalreinsurance.com/generate_brokerage_statement_excel">
              <OfficeButton variant="success" onClick={undefined}>Download Excel</OfficeButton>
            </a>
          )}
        </div>
      </div>
      <form className="bg-white py-6 px-6 h-1/3 ">
        <div className="grid gap-3  grid-cols-2 shadow-sm">
          <div>
            <TextInput
              id="date"
              label="Date"
              type="date"
              placeholder="e.g. Afro Asian"
              required={true}
              {...form}
            />
          </div>
          <div>
            <CurrencyPicker
              id={"currency"}
              required
              placeholder="select Currency"
              label={"Currency"}
              {...form}
            />
          </div>
          <div className="col-span-2">
            <SelectInput
              optionsLoading={loadingCheques}
              required
              multiple
              options={[
                ...cheques.map((item) => ({
                  label: item?.reinsured + " - " + item?.cheque_number,
                  value: item?.payment_ids,
                })),
              ]}
              id={"cheque"}
              label={"Cheque"}
              placeholder="select Insurer"
              {...form}
            />
          </div>
        </div>
      </form>
      {form.status === "SUBMIITED" && form.isValid && (
        <PDFRenderer
          url={`${config.asset.uri}generate_brokerage_statement/${btoa(
            JSON.stringify({
              currency: form.values.currency,
              date: form.values.date,
              payment_ids: _.flatten(form.values.cheque),
            })
          )}`}
        />
      )}
    </div>
  );
};

export default BrokerageStatementTab;
