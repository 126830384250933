import { BusinessPicker, CurrencyPicker, InsurerPicker, RichEditor, TextInput } from "../..";
import * as yup from "yup";
import { useFormik } from "formik";
import { wrapClick } from "utils";
import _ from "lodash";
import { useEffect } from "react";

type Props = {
    handleNext: (_values: any) => void;
    handlePrevious: () => void;
    handleCancel: () => void;
    initialValues: any;
};

const validationSchema = yup.object().shape({

});


const TreatyDeductionForm = ({ handleCancel, handleNext, initialValues, handlePrevious }: Props) => {
    const form = useFormik<any>({
        initialValues,
        onSubmit: (values) => {
            handleNext(values);
        },
        // validationSchema,
        onReset: () => {
            handleCancel();
        },
    });


    const details = JSON.parse(
        _.get(form.values, "old_class_of_business_id.business_details") || "[]"
    );

    useEffect(() => {
        form.setFieldValue(
            "offer_details",
            form?.values?.old_class_of_business_id &&
                form?.values?.old_class_of_business_id ===
                form?.values?.class_of_business_id?.class_of_business_id
                ? form?.values?.old_offer_detail
                : JSON.parse(
                    _.get(form.values, "class_of_business_id.business_details") || "[]"
                ).map((detail: any) => ({ keydetail: detail.keydetail, value: detail?.keyValue || "" }))
        );
    }, [form.values.class_of_business_id]);

    return (
        <div className="flex-1 flex flex-col overflow-hidden">
            {/* {JSON.stringify(form.errors, null, 2)} */}
            <form
                onSubmit={form.handleSubmit}
                className="space-y-6 divide-y divide-gray-200 overflow-y-auto"
            >
                <div className="space-y-6 divide-y divide-gray-200 p-6 flex-1 ">
                    <div>
                        <span className="text-xs font-light">Offer</span>
                        <div className="grid grid-cols-2 gap-2">
                            <div className="">
                                <InsurerPicker
                                    id="insurer_id"
                                    label="Insurer"
                                    placeholder="e.g. Select Insurer"
                                    required={true}
                                    disabled
                                    rawId
                                    {...form}
                                />
                            </div>
                            <div className="">
                                <BusinessPicker
                                    id="class_of_business_id"
                                    disabled
                                    label="Class of business"
                                    placeholder="e.g. Select class of business"
                                    required={true}
                                    rawId
                                    {...form}
                                />
                            </div>
                        </div>
                    </div>
                    {details?.length > 0 && (
                        <>
                            <div className="pt-6">
                                <span className="text-xs font-light">Business Details</span>
                                <div className="grid grid-cols-2 gap-2">
                                    {details?.map((detail: any, index: number) => (
                                        <div className="last-of-type:odd:col-span-2">
                                            <TextInput
                                                type="text"
                                                id={`offer_details.${index}.value`}
                                                disabled
                                                label={detail?.keydetail}
                                                placeholder={`${detail?.keydetail}`}
                                                required={
                                                    !detail?.required ? false : detail?.required === "Yes"
                                                }
                                                {...form}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </>
                    )}
                    <div className="pt-6">
                        <span className="text-xs font-light">Offer Details</span>
                        <div className="grid grid-cols-2 gap-2">
                            <div>
                                <TextInput
                                    type="text"
                                    id="policy_number"
                                    label="Policy Number"
                                    placeholder="e.g. SGS-11288/12334"
                                    required={true}
                                    {...form}
                                />
                            </div>
                            <div>
                                <TextInput
                                    type="text"
                                    id="insured_by"
                                    label="Insured"
                                    placeholder="e.g. ECG Ghana"
                                    required={true}
                                    {...form}
                                />
                            </div>
                            <div>
                                <TextInput
                                    type="text"
                                    id="sum_insured"
                                    label="Sum Insured"
                                    placeholder="e.g. ECG Ghana"
                                    required={true}
                                    {...form}
                                />
                            </div>
                            <div>
                                <TextInput
                                    type="text"
                                    id="rate"
                                    label="Rate (%)"
                                    placeholder="e.g. 12.5"
                                    required={true}
                                    {...form}
                                />
                            </div>
                            <div>
                                <TextInput
                                    type="text"
                                    id="premium"
                                    label="Premium"
                                    placeholder="e.g. 1200"
                                    required={true}
                                    {...form}
                                />
                            </div>
                            <div>
                                <TextInput
                                    type="text"
                                    id="facultative_offer"
                                    label="Facultative Offer (%)"
                                    disabled
                                    placeholder="e.g. 12.5"
                                    required={true}
                                    {...form}
                                />
                            </div>
                            <div>
                                <TextInput
                                    type="text"
                                    id="commission"
                                    label="Commission (%)"
                                    disabled
                                    placeholder="e.g. 12.5"
                                    required={true}
                                    {...form}
                                />
                            </div>
                            <div>
                                <TextInput
                                    type="text"
                                    id="brokerage"
                                    label="Preliminary Brokerage (%)"
                                    disabled
                                    placeholder="e.g. 12.5"
                                    required={true}
                                    {...form}
                                />
                            </div>
                            <div className="col-span-2">
                                <CurrencyPicker
                                    id="currency"
                                    label="Currency"
                                    disabled
                                    placeholder="e.g. USD"
                                    required={true}
                                    {...form}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="pt-6">
                        <span className="text-xs font-light">Period</span>
                        <div className="grid grid-cols-2 gap-2">
                            <div>
                                <TextInput
                                    type="date"
                                    id="period_of_insurance_from"
                                    label="From"
                                    placeholder=""
                                    required={true}
                                    {...form}
                                />
                            </div>
                            <div>
                                <TextInput
                                    type="date"
                                    id="period_of_insurance_to"
                                    label="To"
                                    required={true}
                                    {...form}
                                />
                            </div>
                            <div className="col-span-2 mt-6">
                                <RichEditor
                                    id="comment"
                                    label="Comment"
                                    placeholder="e.g. Comment goes here ..."
                                    required={true}
                                    {...form}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </form>

            <div className="bg-gray-50 dark:bg-gray-800 px-4 py-3 sm:py-4 sm:px-6 sm:flex sm:flex-row-reverse border-t border-gray-200">
                <button
                    type="button"
                    onClick={wrapClick(form.handleSubmit)}
                    disabled={!form.isValid}
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
                >
                    Next
                </button>
                <button
                    type="button"
                    onClick={wrapClick(handlePrevious)}
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
                >
                    Previous
                </button>
                <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 dark:border-gray-600 shadow-sm px-4 py-2 bg-white dark:bg-gray-900 text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 hover:dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={wrapClick(form.resetForm)}
                >
                    Cancel
                </button>
            </div>
        </div>
    );
};

export default TreatyDeductionForm;
