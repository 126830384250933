import { DocumentPlusIcon } from '@heroicons/react/24/outline'
import { GetTreatyProgramDeductions_getTreatyProgramDeductions } from '_graphql/queries/__generated__/GetTreatyProgramDeductions'
import { ActionButton, OfficeButton, OfficeHeader, TableComponent, TableLoader } from 'components'
import { useTreatyProgramDeductions } from 'hooks/data/use-treaty-programs'
import { FC } from 'react'
import { useMatch, useNavigate, useSearch } from 'react-location'
import { LocationGenerics } from 'router/location'
import { useUrlState } from 'utils'
import CreateDeductionContainer from './create'
import RemoveDeductionContainer from './remove'
import { Action } from 'components/buttons/action-button'
import UpdateDeductionContainer from './update'

type Props = {}

const TreatyProgramDeductionsPage: FC<Props> = (props) => {
    const [modal, setModal] = useUrlState("modal");
    const { params } = useMatch<LocationGenerics>();
    const navigate = useNavigate<LocationGenerics>();
    const searchParams = useSearch<LocationGenerics>();
    const { deductions, loading, refetch } = useTreatyProgramDeductions({
        treatyProgramId: params.treaty_program,
    });


    const dispatchAction =
        (id: string, action: Exclude<Action, "expand" | "goto" | "clone">) =>
            () => {
                navigate({
                    search: (old) => ({
                        ...old,
                        id,
                        modal: action,
                    }),
                });
            };


    return (
        <main className="flex-1 flex flex-col overflow-hidden bg-shade-500">
            <OfficeHeader
                renderActions={() => (
                    <OfficeButton onClick={() => setModal("create")}>
                        <DocumentPlusIcon className="w-5 h-5 mr-2" />
                        <span className="hidden sm:inline">Create Deduction</span>
                    </OfficeButton>
                )}
            />
            <div className="flex flex-1 overflow-y-auto">
                <div className="flex-1 mx-auto min-w-0  py-4 sm:py-6 overflow-hidden overflow-y-auto light flex">
                    <TableComponent
                        title={''}
                        columns={[
                            {
                                name: "NIC Levy",
                                accessor: "nic_levy",
                            },
                            {
                                name: "Withholding Tax",
                                accessor: "withholding_tax",
                            },
                            {
                                name: "Brokerage",
                                accessor: "brokerage",
                            },
                            {
                                name: "Commission",
                                accessor: "commission",
                            },
                            {
                                name: "Period",
                                accessor: "period",
                                render: (row: GetTreatyProgramDeductions_getTreatyProgramDeductions) => `${row.treaty_period_from} to ${row.treaty_period_to}`,
                            },
                            {
                                name: "Actions",
                                accessor: "nic_levy",
                                render: (row: GetTreatyProgramDeductions_getTreatyProgramDeductions) => (
                                    <div className="flex justify-start space-x-2">
                                        <ActionButton action="update" onClick={dispatchAction(row?.treaty_associate_deduction_id || "", "update")} />
                                        <ActionButton action="remove" onClick={dispatchAction(row?.treaty_associate_deduction_id || "", "remove")} />
                                    </div>
                                ),
                            },
                        ]}
                        data={deductions}
                        loading={loading}
                        refetch={refetch}
                        renderLoader={() => <TableLoader items={[1, 1, 1, 1]} actionsCount={2} showAction />}
                    />
                </div>
            </div>
            <CreateDeductionContainer
                open={modal === "create"}
                setOpen={(val: boolean) => setModal(val ? "create" : undefined)}
                refetch={refetch}
            />

            {searchParams?.id && (
                <>
                    <RemoveDeductionContainer open={modal === "remove"} setOpen={(val: boolean) => setModal(val ? "remove" : undefined)} />
                    <UpdateDeductionContainer open={modal === "update"} setOpen={(val: boolean) => setModal(val ? "update" : undefined)} />
                </>
            )}

        </main>
    )
}

export default TreatyProgramDeductionsPage