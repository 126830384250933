import { wrapClick } from "utils";
import { useMutation } from "@apollo/client";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { Modal } from "components";
import * as Yup from "yup";
import { AdjustmentStatementForm } from "components/forms";
import { useMatch } from "react-location";
import { LocationGenerics } from "router/location";
import { createOrUpdateAdjustmentStatment } from "_graphql/mutation/treaty";
import _ from "lodash";
import {
  CreateOrUpdateAdjustmentStatment,
  CreateOrUpdateAdjustmentStatmentVariables,
} from "_graphql/mutation/__generated__/CreateOrUpdateAdjustmentStatment";
import { useTreaty } from "hooks/data/use-treaties";
import { useEffect } from "react";

const validationSchema = Yup.object().shape({
  claimsPaid: Yup.number()
    .min(0, "Number cannot be less than 0")
    .required("Required"),
  outstandingPayment: Yup.number()
    .min(0, "Number cannot be less than 0")
    .required("Required"),
  //   treatyNpDetailId: Yup.string().required("Required"),
});

export default function CreateAdjustmentStatementContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const { params } = useMatch<LocationGenerics>();
  const { loading: fetchingTreaty, treaty } = useTreaty({
    treatyId: params?.treaty,
  });
  const [create, { loading }] = useMutation<
    CreateOrUpdateAdjustmentStatment,
    CreateOrUpdateAdjustmentStatmentVariables
  >(createOrUpdateAdjustmentStatment, {
    refetchQueries: ["Treaty"],
  });

  const form = useFormik<any>({
    initialValues: {
      treatyId: params?.treaty,
      claimsPaid: "",
      outstandingPayment: "",
      treatyNpDetailId: treaty?.treaty_np_detail?.treaty_np_id,
    },
    validationSchema: validationSchema,
    onSubmit: async (variables) => {
      await create({
        variables: {
          ...variables,
          treatyNpDetailId: treaty?.treaty_np_detail?.treaty_np_id,
        },
      }).then(({ data }) => {
        if (data?.createOrUpdateAdjustmentStatment) {
          toast.success(
            `Adjustment Statement ${
              treaty?.treaty_np_detail?.claims_paid ? "updated" : "created"
            } successfully`
          );
          refetch?.();
          setOpen?.(false);
          form.resetForm();
        } else {
          toast.error(
            `Failed to ${
              treaty?.treaty_np_detail?.claims_paid ? "update" : "create"
            } Adjustment Statement`
          );
        }
      });
    },
    onReset: () => {
      setOpen(false);
    },
  });

  useEffect(() => {
    if (treaty) {
      form.setValues({
        ...form.values,
        treatyNpDetailId: treaty?.treaty_np_detail?.treaty_np_id,
        claimsPaid: _.get(treaty, "treaty_np_detail.claims_paid", ""),
        outstandingPayment: _.get(
          treaty,
          "treaty_np_detail.outstanding_payment",
          ""
        ),
      });
    }
  }, [treaty]);

  return (
    <Modal
      open={open}
      loading={fetchingTreaty}
      setOpen={setOpen}
      title={"Create Adjustment Statement"}
      description="Provide the details for the adjustment statement"
      renderActions={() => (
        <>
          <button
            type="button"
            disabled={loading}
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(form.handleSubmit)}
          >
            {loading
              ? `${
                  treaty?.treaty_np_detail?.claims_paid
                    ? "Updating"
                    : "Creating"
                } ...`
              : treaty?.treaty_np_detail?.claims_paid
              ? "Update Adjustment statement"
              : "Create Adjustment Statement"}
          </button>
        </>
      )}
    >
      <AdjustmentStatementForm form={form} />
    </Modal>
  );
}
