import { DocumentPlusIcon } from '@heroicons/react/24/outline'
import { ClassOfBusinesses_rows } from '_graphql/queries/__generated__/ClassOfBusinesses'
import { ActionButton, OfficeButton, OfficeHeader, Paginator, TableComponent, TableLoader } from 'components'
import { useClassOfBusinesses } from 'hooks/data/use-class-of-business'
import moment from 'moment'
import { FC, useMemo } from 'react'
import { useSearch, useNavigate } from 'react-location'
import { LocationGenerics } from 'router/location'
import { useUrlState, withRoles } from 'utils'
import CreateContainer from './create'
import ViewContainer from './view'
import { Action } from 'components/buttons/action-button'
import UpdateContainer from './update'
import RemoveContainer from './remove'
import { usePagination } from 'hooks'

type Props = {}

const ClassOFBusinessPage: FC<Props> = (props) => {
    const [modal, setModal] = useUrlState("modal");
    const searchParams = useSearch<LocationGenerics>();
    const { currentPage, limit, offset, setPage } = usePagination()
    const navigate = useNavigate<LocationGenerics>();
    const dispatchAction =
        (id: string, action: Exclude<Action, "expand" | "goto" | "clone">) =>
            () => {
                navigate({
                    search: (old) => ({
                        ...old,
                        id,
                        modal: action,
                    }),
                });
            };
    const filter = useMemo(
        () => ({
            page: currentPage || 1,
            pageSize: limit || 12,
            search: (searchParams.search || "").toString(),
            args: {
                status: ["CLOSED"],
            },
        }),
        [searchParams]
    );
    const { classOfBusinesses, loading, refetch, length } = useClassOfBusinesses(filter);


    return (
        <main className="flex-1 flex flex-col overflow-hidden bg-shade-500">
            <OfficeHeader
                renderActions={() => withRoles(["Senior Broking Officer", "Finance Officer", "CEO"])(
                    <OfficeButton onClick={() => setModal("create")} >
                        <DocumentPlusIcon className="w-5 h-5 mr-2" />
                        <span className="hidden sm:inline">Create Business</span>
                    </OfficeButton>
                )} />
            <div className="flex flex-1 overflow-y-auto">
                <div className="flex-1 mx-auto min-w-0  py-4 sm:py-6 overflow-hidden overflow-y-auto light flex">
                    <TableComponent
                        title={'Class of Business'}
                        hasSearch
                        columns={[
                            {
                                name: "Business Name",
                                accessor: "business_name",
                            },
                            {
                                name: "Business Group",
                                accessor: "business_category",
                            },
                            {
                                name: "Created date",
                                accessor: "created_at",
                                render: (value: ClassOfBusinesses_rows) => (
                                    <span className="font-bold text-xs">
                                        {moment(value.created_at).format("DD-MM-YYYY") || "N/A"}
                                    </span>

                                )
                            },
                            {
                                name: "Actions",
                                accessor: "actions",
                                render: (value: ClassOfBusinesses_rows) => (
                                    <>
                                        {withRoles([])(<ActionButton action='view' onClick={dispatchAction(value.class_of_business_id, "view")} />)}
                                        {withRoles(["CEO", "Senior Broking Officer", "Finance Officer"])(< ActionButton action='update' onClick={dispatchAction(value.class_of_business_id, "update")} />)}
                                        {withRoles(["CEO", "Senior Broking Officer", "Finance Officer"])(<ActionButton action='remove' onClick={dispatchAction(value.class_of_business_id, "remove")} />)}
                                    </>
                                )
                            },
                        ]}
                        renderLoader={() => <TableLoader items={[1, 1, 1, 1]} />}
                        loading={loading}
                        data={classOfBusinesses}
                        refetch={refetch}
                        pagination={() => <Paginator offset={offset} limit={limit} totalItems={length} currentSize={classOfBusinesses.length} setOffset={setPage} />}
                    />
                </div>
            </div>

            <CreateContainer
                open={modal === "create"}
                setOpen={(val: boolean) => setModal(val ? "create" : undefined)}
                refetch={refetch}
            />
            {searchParams.id && (
                <>
                    <ViewContainer
                        open={modal === "view"}
                        setOpen={(val: boolean) => setModal(val ? "view" : undefined)}
                    />
                    <UpdateContainer
                        open={modal === "update"}
                        setOpen={(val: boolean) => setModal(val ? "update" : undefined)}
                        refetch={refetch}
                    />
                    <RemoveContainer
                        open={modal === "remove"}
                        setOpen={(val: boolean) => setModal(val ? "remove" : undefined)}
                        refetch={refetch}
                    />
                </>
            )}
        </main>
    )
}

export default ClassOFBusinessPage