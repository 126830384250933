import { wrapClick } from "utils";
import { useMutation } from "@apollo/client";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { Modal } from "components";
import * as Yup from "yup";
import { AddParticipantsForm } from "components/forms";
import { useMatch } from "react-location";
import { LocationGenerics } from "router/location";
import _ from "lodash";
import { AddReinsurerToEndorsement, AddReinsurerToEndorsementVariables } from "_graphql/mutation/__generated__/AddReinsurerToEndorsement";
import { addReinsurersToEndorsement } from "_graphql/mutation/endorsement";
import { useEndorsement } from "hooks/data/use-endorsement";


export const valiadationSchema = Yup.object().shape({
    requests: Yup.array().of(Yup.object().shape({
        participant_id: Yup.object().required("Participant is required"),
    })).min(1).required("At least one participant is required"),
});

export default function AddParticipantsContainer({
    open,
    setOpen,
    refetch,
}: {
    open: boolean;
    setOpen: (val: boolean) => void;
    refetch?: () => void;
}) {
    const { params } = useMatch<LocationGenerics>();
    const { endorsement, loading: fetchingEndorsement } = useEndorsement({
        offerEndorsementId: params.endorsement || "",
    })
    const [create, { loading }] = useMutation<AddReinsurerToEndorsement, AddReinsurerToEndorsementVariables>(addReinsurersToEndorsement, {
        refetchQueries: ["GetEndorsement"],
    });

    const form = useFormik<any>({
        initialValues: {
            requests: [],
        },
        validationSchema: valiadationSchema,
        onSubmit: async () => {
            const items = _.groupBy(form.values.requests, "participant_id.reinsurersreinsurer_id");
            await create({
                variables: {
                    offerEndorsementId: params.endorsement || "",
                    reinsurerIds: Object.keys(items)?.map((item: any) => Object.values(items[item])?.map((rep: any) => rep?.participant_id?.reinsurer_representative_id),
                    ).flat(),
                    offerId: endorsement?.offer?.offer_id
                },
            }).then(({ data }) => {
                if (data?.addReinsurerToEndorsement) {
                    toast.success("Distribution list Created Successfully");
                    refetch?.();
                    form.resetForm();
                } else {
                    toast.error("Could not create Distribution list");
                }
            });
        },
        onReset: () => {
            setOpen(false);
        },
    });

    return (
        <Modal
            open={open}
            setOpen={setOpen}
            loading={fetchingEndorsement}
            title="Add Participant(s)"
            description="Add participants to this Endorsement"
            renderActions={() => (
                <>
                    <button
                        type="button"
                        disabled={loading}
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={wrapClick(form.handleSubmit)}
                    >
                        {loading ? "Saving changes..." : "Save changes"}
                    </button>
                </>
            )}
        >
            <AddParticipantsForm form={form} />
        </Modal>
    );
}
