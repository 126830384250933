import { GetReinsurerRep_getReinsurerRep } from '_graphql/queries/__generated__/GetReinsurerRep'
import { Insurer_insurer } from '_graphql/queries/__generated__/Insurer'
import { Reinsurer_reinsurer } from '_graphql/queries/__generated__/Reinsurer'
import { Avatar } from 'components/core'
import _ from 'lodash'
import React, { FC } from 'react'

type Props = {
    reInsurer: GetReinsurerRep_getReinsurerRep | null | undefined
}

const ReinsurerRepView: FC<Props> = ({ reInsurer }) => {
    return (
        <div className="space-y-6 divide-y divide-gray-200">
            <div>
                <span className='text-xs font-light'>Company Information</span>
                <div className='grid grid-cols-3 gap-6 mt-2'>
                    <div className='row-span-2 flex flex-col space-y-3 items-center justify-center'>
                        <Avatar
                            alt={reInsurer?.rep_first_name + " " + reInsurer?.rep_last_name || "N A"}
                            size="xl"
                        />
                    </div>
                    <div className='col-span-3'>
                        <span className="block text-sm font-light text-gray-700">
                            Full Name
                        </span>
                        <div className="mt-1 block w-full sm:text-sm">
                            {reInsurer?.rep_first_name || "N/A"} {reInsurer?.rep_last_name || "N/A"}
                        </div>
                    </div>
                </div>
            </div>
            <div className='pt-6'>
                <span className='text-xs font-light'>Contact Information</span>
                <div className='grid grid-cols-2 gap-6 mt-2'>
                    <div>
                        <span className="block text-sm font-light text-gray-700">
                            Email Address
                        </span>
                        <div className="mt-1 block w-full sm:text-sm">
                            {reInsurer?.rep_email || "N/A"}
                        </div>
                    </div>

                    <div className=''>
                        <span className="block text-sm font-light text-gray-700">
                            Phone Number
                        </span>
                        <div className="mt-1 block w-full sm:text-sm">
                            {[reInsurer?.rep_primary_phonenumber, reInsurer?.rep_secondary_phonenumber].join(", ") || "N/A"}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ReinsurerRepView