import { useMutation } from "@apollo/client";
import toast from "react-hot-toast";
import { PopUp } from "components";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { RemoveReinsurersParticipationPercentage, RemoveReinsurersParticipationPercentageVariables } from "_graphql/mutation/__generated__/RemoveReinsurersParticipationPercentage";
import { removetreatyReinsurer } from "_graphql/mutation/treaty";



export default function RemoveReinsurerContainer({
    open,
    setOpen,
    refetch,
}: {
    open: boolean;
    setOpen: (val: boolean) => void;
    refetch?: () => void;
}) {
    const searchParams = useSearch<LocationGenerics>()
    const [remove, { loading }] = useMutation<RemoveReinsurersParticipationPercentage, RemoveReinsurersParticipationPercentageVariables>(removetreatyReinsurer, {
        refetchQueries: ["GetTreatyReinsurerParticipants"]
    });


    const handleSubmit = async () => {
        await remove({
            variables: {
                treatyParticipationId: searchParams?.id || ""
            },
        }).then(({ data }) => {
            if (data?.removeReinsurersParticipationPercentage) {
                toast.remove("Reinsurer Removed");
                refetch?.();
                setOpen?.(false);
            } else {
                toast.error("Failed to remove Reinsurer");
            }
        });
    }

    return (
        <PopUp
            open={open}
            setOpen={setOpen}
            title="Remove Re-insurer"
            cancelText="No, Don't Remove Re-insurer"
            description="This action would completely remove reinsurer details from system"
            renderActions={() => (
                <>
                    <button
                        type="button"
                        disabled={loading}
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={handleSubmit}
                    >
                        {loading ? "Removing Re-insurer..." : "Remove Re-insurer"}
                    </button>
                </>
            )}
        />
    );
}
