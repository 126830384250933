import { UserRole, currentUserVar } from "_graphql/cache/auth";


export default function withRoles<T = any>(roles: UserRole[]) {
  const currentUser = currentUserVar();
  return (val: T, alt?: T): T | null => {
    if (roles.length > 0) {
      if (roles.includes(currentUser?.position as UserRole)) {
        return val;
      }
      return alt ?? null;
    }
    return val;
  };
}