import { FC } from "react";

type Props = {
  url: string;
  height?: number | string;
};

const PDFRenderer: FC<Props> = ({ url, height }) => {
  return (
    <div className="flex-1 w-full">
      <iframe
        src={`${url}#view=FitH`}
        width={"100%"}
        height={height || 800}
        frameBorder={0}
      ></iframe>
    </div>
  );
};

export default PDFRenderer;
