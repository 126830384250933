import { wrapClick } from "utils";
import { useMutation } from "@apollo/client";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { Modal } from "components";
import * as Yup from "yup";
import { ReInsurerRepForm } from "components/forms";
import { createReInsurerRep } from "_graphql/mutation/re-insurer";
import {
  CreateReinsurerRep,
  CreateReinsurerRepVariables,
} from "_graphql/mutation/__generated__/CreateReinsurerRep";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { useAppLocation } from "_graphql/cache/auth";

export const insurerRepValidationSchema = Yup.object().shape({
  rep_first_name: Yup.string().required("First name is required"),
  rep_last_name: Yup.string().required("Last name is required"),
  rep_primary_phonenumber: Yup.string().required("Phone number is required"),
  rep_secondary_phonenumber: Yup.string().notRequired(),
  rep_email: Yup.string().required("Email is required"),
  position: Yup.string().required("Position is required"),
});

export default function CreateInsurerManagerContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const { params } = useAppLocation();

  const [create, { loading }] = useMutation<
    CreateReinsurerRep,
    CreateReinsurerRepVariables
  >(createReInsurerRep);

  const form = useFormik<CreateReinsurerRepVariables["reinsurerRep"]>({
    initialValues: {
      reinsurersreinsurer_id: params?.reInsurer || searchParams?.id || "",
      rep_first_name: "",
      rep_last_name: "",
      rep_primary_phonenumber: "",
      rep_secondary_phonenumber: "",
      rep_email: "",
      position: "",
    },
    validationSchema: insurerRepValidationSchema,
    onSubmit: async (variables) => {
      await create({
        variables: {
          reinsurerRep: {
            ...variables,
            reinsurersreinsurer_id: params?.reInsurer || searchParams?.id || "",
          },
        },
      }).then(({ data }) => {
        if (data?.createReinsurerRep?.reinsurersreinsurer_id) {
          toast.success("Re-Insurer Representative Created Successfully");
          refetch?.();
          form.resetForm();
        } else {
          toast.error("Failed to create Re-Insurer Representative");
        }
      });
    },
    onReset: () => {
      setOpen(false);
    },
  });

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title="Add New Re-Insurer Representative"
      description="Provide the details to add a new re-insurer rep"
      renderActions={() => (
        <>
          <button
            type="button"
            disabled={loading}
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(form.handleSubmit)}
          >
            {loading ? "Adding Re-Insurer Rep..." : "Add Re-Insurer Rep"}
          </button>
        </>
      )}
    >
      <ReInsurerRepForm form={form} />
    </Modal>
  );
}
