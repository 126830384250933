import { gql } from "@apollo/client";

export const updateTreatyProgram = gql`
  mutation UpdateTreatyProgram($program: TreatyProgramData, $programId: ID) {
    updateTreatyProgram(program: $program, program_id: $programId)
  }
`;

export const createTreatyProgram = gql`
  mutation CreateTreatyProgram($program: TreatyProgramData) {
    createTreatyProgram(program: $program)
  }
`;

export const removeTreatyProgram = gql`
  mutation DeleteTreatyProgram($programId: ID) {
    deleteTreatyProgram(program_id: $programId)
  }
`;


export const createAssociateDeduction = gql`
mutation CreateTreatyProgramAssociateDeductions($deductions: TreatyAssociateDeductionData) {
  createTreatyProgramAssociateDeductions(deductions: $deductions)
}`;

export const updateAssociateDeduction = gql`
 mutation UpdateTreatyProgramAssociateDeductions(
    $deductions: TreatyAssociateDeductionData
    $deduction_id: ID
  ) {
    updateTreatyProgramAssociateDeductions(
      deductions: $deductions
      deduction_id: $deduction_id
    )
  }`;

export const removeAssociateDeduction = gql`
  mutation DeleteTreatyProgramAssociateDeductions($id: ID) {
    deleteTreatyProgramAssociateDeductions(deduction_id: $id)
  }`;