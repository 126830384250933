import { wrapClick } from 'utils';
import { gql, useQuery } from '@apollo/client';
import { BusinessView, Modal } from 'components';
import { useSearch, useNavigate } from 'react-location';
import { LocationGenerics } from 'router/location';
import { useClassOfBusiness } from 'hooks/data/use-class-of-business';


export default function ViewContainer({ open, setOpen }: { open: boolean; setOpen: (val: boolean) => void }) {

    const searchParams = useSearch<LocationGenerics>();
    const navigate = useNavigate<LocationGenerics>();
    const { loading, singleClassOfBusiness } = useClassOfBusiness({
        classOfBusinessId: searchParams.id || ""
    })

    return (
        <Modal
            open={open}
            setOpen={setOpen}
            loading={loading}
            title="Business Information"
            description="Details of business are shown below"
        >
            <BusinessView
                business={singleClassOfBusiness}
            />
        </Modal>
    )
}