import { useMutation, useQuery } from "@apollo/client";
import {
  DeleteOfferFromSystem,
  DeleteOfferFromSystemVariables,
} from "_graphql/mutation/__generated__/DeleteOfferFromSystem";
import {
  RemoveReInsurerFromList,
  RemoveReInsurerFromListVariables,
} from "_graphql/mutation/__generated__/RemoveReInsurerFromList";
import {
  ReopenOffer,
  ReopenOfferVariables,
} from "_graphql/mutation/__generated__/ReopenOffer";
import {
  deleteOffer,
  reOpenOffer,
  removeReInsurerFromParticipation,
} from "_graphql/mutation/offers";
import { GetEndorsementParticipantVariables, GetEndorsementParticipant } from "_graphql/queries/__generated__/GetEndorsementParticipant";
import {
  GetOfferAssociates,
  GetOfferAssociatesVariables,
} from "_graphql/queries/__generated__/GetOfferAssociates";
import {
  GetOfferParticipant,
  GetOfferParticipantVariables,
} from "_graphql/queries/__generated__/GetOfferParticipant";
import {
  GetReinsurerOffers,
  GetReinsurerOffersVariables,
} from "_graphql/queries/__generated__/GetReinsurerOffers";
import { Offer, OfferVariables } from "_graphql/queries/__generated__/Offer";
import { OfferFleet, OfferFleetVariables } from "_graphql/queries/__generated__/OfferFleet";
import { Offers, OffersVariables } from "_graphql/queries/__generated__/Offers";
import {
  getEndorsementParticipant,
  getOffer,
  getOfferAssociates,
  getOfferFleet,
  getOfferParticipant,
  getOffers,
  getReInsurerOffers,
} from "_graphql/queries/offers";

export type iVariables = {
  page: number;
  pageSize: number;
  search: string;
};

export const useOffers = (variables: OffersVariables & iVariables) => {
  const { page, pageSize, ...offerVariables } = variables;
  const start = (page - 1) * pageSize;
  const { data, ...rest } = useQuery<Offers, OffersVariables>(getOffers, {
    variables: {
      args: {
        ...offerVariables.args,
        search: offerVariables.search,
        offset: start,
        limit: pageSize,
      },
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });

  const offers = data?.rows || [];
  const count = data?.count || 0;

  return { offers, count, ...rest };
};

export const useReInsurerOffers = (
  variables: GetReinsurerOffersVariables & iVariables
) => {
  const { page, pageSize, search, ...offerVariables } = variables;
  const start = (page - 1) * pageSize;
  const { data, ...rest } = useQuery<
    GetReinsurerOffers,
    GetReinsurerOffersVariables
  >(getReInsurerOffers, {
    variables: {
      args: {
        ...offerVariables.args,
        search,
        offset: start,
        limit: pageSize,
      },
      reinsurer_id: offerVariables.args?.reinsurer_id || "",
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });

  return {
    offers: data?.getReinsurerOffers || [],
    count: data?.getReinsurerOffersCount,
    ...rest,
  };
};

export const useOffer = (variables: OfferVariables) => {
  const { data, ...rest } = useQuery<Offer, OfferVariables>(getOffer, {
    variables,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });
  return {
    offer: data?.findSingleOffer,
    ...rest,
  };
};

export const useOfferFleet = (variables: OfferFleetVariables) => {
  const { data, ...rest } = useQuery<OfferFleet, OfferFleetVariables>(getOfferFleet, {
    variables,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-first",
  });
  return {
    offerFleet: data?.getOfferFleet || [],
    ...rest,
  };
};
export const useOfferParticipant = (
  variables: GetOfferParticipantVariables
) => {
  const { data, ...rest } = useQuery<
    GetOfferParticipant,
    GetOfferParticipantVariables
  >(getOfferParticipant, {
    variables,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });
  return {
    participant: data?.getOfferParticipant,
    ...rest,
  };
};
export const useEndorsementParticipant = (
  variables: GetEndorsementParticipantVariables
) => {
  const { data, ...rest } = useQuery<
    GetEndorsementParticipant,
    GetEndorsementParticipantVariables
  >(getEndorsementParticipant, {
    variables,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });
  return {
    participant: data?.getEndorsementParticipant,
    ...rest,
  };
};
export const useOfferParticipantAssociates = (
  variables: GetOfferAssociatesVariables
) => {
  const { data, ...rest } = useQuery<
    GetOfferAssociates,
    GetOfferAssociatesVariables
  >(getOfferAssociates, {
    variables,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });
  return {
    associates: data?.getOfferAssociates || [],
    ...rest,
  };
};

export const useRemoveParticipantFromParticipation = () => {
  const [removeParticipant, rest] = useMutation<
    RemoveReInsurerFromList,
    RemoveReInsurerFromListVariables
  >(removeReInsurerFromParticipation, {
    refetchQueries: ["Offer"],
  });
  return {
    removeParticipant,
    ...rest,
  };
};
export const useRemoveOffer = () => {
  const [removeOffer, rest] = useMutation<
    DeleteOfferFromSystem,
    DeleteOfferFromSystemVariables
  >(deleteOffer, {
    refetchQueries: ["Offers"],
  });
  return {
    removeOffer,
    ...rest,
  };
};
export const useReOpenOffer = () => {
  const [reopen, rest] = useMutation<ReopenOffer, ReopenOfferVariables>(
    reOpenOffer,
    {
      refetchQueries: ["Offers"],
    }
  );
  return {
    reopen,
    ...rest,
  };
};
