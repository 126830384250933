import { DocumentPlusIcon, LockClosedIcon, RectangleStackIcon } from '@heroicons/react/24/outline';
import { Broker_broker } from '_graphql/queries/__generated__/Broker';
import _ from 'lodash';
import numeral from 'numeral';

type Props = {
    broker: Broker_broker | null | undefined
}

const stats = [
    {
        id: 1,
        name: "All Treaties",
        icon: RectangleStackIcon,
        valueKey: "total_offers",
    },
    {
        id: 2,
        name: "Unpaid Treaties",
        icon: DocumentPlusIcon,
        valueKey: "total_pending_offers",
    },
    {
        id: 3,
        name: "Paid Treaties",
        valueKey: "total_closed_offers",
        icon: LockClosedIcon,
    },
    {
        id: 4,
        name: "Proportional Treaties",
        valueKey: "total_closed_offers",
        icon: LockClosedIcon,
    },
    {
        id: 5,
        name: "Nonproportional Treaties",
        valueKey: "total_closed_offers",
        icon: LockClosedIcon,
    },

];

const OverviewTab = ({ broker }: Props) => {
    const overview = {}
    const value = {}
    return (
        <div className='w-full p-3 space-y-5'>
            <div className='w-full grid items-start grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4'>
                <div className="overflow-hidden bg-card-500 sm:col-span-2 lg:col-span-1 shadow sm:rounded-lg">
                    <div className="px-4 py-6 sm:px-6">
                        <h3 className="text-base font-semibold leading-7 text-gray-300">Broker Information</h3>
                        <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">Details of reinsurance broker.</p>
                    </div>
                    <div className="border-t border-gray-600">
                        <dl className="divide-y divide-gray-600">
                            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-gray-400">Region</dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-100 sm:col-span-2 sm:mt-0">{broker?.re_broker_address?.region}</dd>
                            </div>
                            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-gray-400">City</dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-100 sm:col-span-2 sm:mt-0">{broker?.re_broker_address?.city}</dd>
                            </div>
                            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-gray-400">Email address</dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-100 sm:col-span-2 sm:mt-0">{broker?.re_broker_email}</dd>
                            </div>
                            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-gray-400">Website</dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-100 sm:col-span-2 sm:mt-0">{broker?.re_broker_website || "N/A"}</dd>
                            </div>
                        </dl>
                    </div>
                </div>
                <div className='col-span-3 grid grid-cols-3 gap-5'>
                    {stats.map((item) => (
                        <div
                            key={item.id}
                            className="relative overflow-hidden rounded-lg bg-card-500 px-4 pt-5 shadow sm:px-6 sm:pt-6"
                        >
                            <dt>
                                <div className="absolute rounded-md bg-card-200 p-3">
                                    <item.icon
                                        className="h-6 w-6 text-card-500"
                                        aria-hidden="true"
                                    />
                                </div>
                                <p className="ml-16 truncate text-sm font-medium text-gray-300">
                                    {item.name}
                                </p>
                            </dt>
                            <dd className="ml-16 flex justify-between items-end pb-6 sm:pb-7">
                                <p className="text-2xl font-semibold text-gray-200">
                                    {numeral(_.get(overview, item.valueKey, 0)).format(
                                        "0,0"
                                    )}
                                </p>
                            </dd>
                        </div>
                    ))}
                    <div className="space-y-3 col-span-3">
                        <span className="font-medium text-gray-300 text-sm">Total Administrative Charges</span>
                        <div className="rounded-lg bg-card-500 p-4 shadow sm:p-6 grid grid-cols-4">
                            <div className="flex flex-col space-y-2 items-start">
                                <span className="text-xs text-gray-300">Total GHS</span>
                                <span className="text-lg font-bold text-gray-200">
                                    {_.get(overview, `${value}.total_cedis`, 0)}
                                </span>
                            </div>
                            <div className="flex flex-col space-y-2 items-start">
                                <span className="text-xs text-gray-300">Total USD</span>
                                <span className="text-lg font-bold text-gray-200">
                                    {_.get(overview, `${value}.total_dollar`, 0)}
                                </span>
                            </div>
                            <div className="flex flex-col space-y-2 items-start">
                                <span className="text-xs text-gray-300">Total EUR</span>
                                <span className="text-lg font-bold text-gray-200">
                                    {_.get(overview, `${value}.total_euros`, 0)}
                                </span>
                            </div>
                            <div className="flex flex-col space-y-2 items-start">
                                <span className="text-xs text-gray-300">Total GBP</span>
                                <span className="text-lg font-bold text-gray-200">
                                    {_.get(overview, `${value}.total_pounds`, 0)}
                                </span>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div className="space-y-3 col-span-3">
                <span className="font-medium text-gray-300 text-sm">Total Revenue</span>
                <div className="rounded-lg bg-card-500 p-4 shadow sm:p-6 grid grid-cols-4">
                    <div className="flex flex-col space-y-2 items-start">
                        <span className="text-xs text-gray-300">Total GHS</span>
                        <span className="text-lg font-bold text-gray-200">
                            {_.get(overview, `${value}.total_cedis`, 0)}
                        </span>
                    </div>
                    <div className="flex flex-col space-y-2 items-start">
                        <span className="text-xs text-gray-300">Total USD</span>
                        <span className="text-lg font-bold text-gray-200">
                            {_.get(overview, `${value}.total_dollar`, 0)}
                        </span>
                    </div>
                    <div className="flex flex-col space-y-2 items-start">
                        <span className="text-xs text-gray-300">Total EUR</span>
                        <span className="text-lg font-bold text-gray-200">
                            {_.get(overview, `${value}.total_euros`, 0)}
                        </span>
                    </div>
                    <div className="flex flex-col space-y-2 items-start">
                        <span className="text-xs text-gray-300">Total GBP</span>
                        <span className="text-lg font-bold text-gray-200">
                            {_.get(overview, `${value}.total_pounds`, 0)}
                        </span>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default OverviewTab