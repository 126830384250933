import { OfficeHeader } from 'components'
import { FC } from 'react'
import { Link } from 'react-location'
import { classNames, useUrlState } from 'utils'
import { BrokerageStatementTab, ReportingTab } from './tabs'
import SOAReportTab from './tabs/soa-report'
import MonthlyReportTab from './tabs/monthly-report'

type Props = {}

const tabs = [{ name: "Brokerage Statement ", href: "brokerageStatement" }, { name: "SOA Reports ", href: "soa_reports" }, { name: "Monthly Reports ", href: "monthly_reports" }];


const ReportsPage: FC<Props> = (props) => {
    const [activeTab] = useUrlState("orderStatus");

    return (
        <main className="flex-1 flex flex-col overflow-hidden bg-shade-500">
            <OfficeHeader />
            <div className="block">
                <div className="border-b border-shade-500 bg-card-500 px-6">
                    <nav className="-mb-px flex space-x-8 items-center" aria-label="Tabs">
                        <Link
                            search={(old) => ({
                                ...old,
                                orderStatus: undefined,
                            })}
                            className={classNames(
                                !activeTab
                                    ? "border-gray-200 text-gray-200"
                                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200",
                                "whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm"
                            )}
                            aria-current={!activeTab ? "page" : undefined}
                        >
                            Reporting
                        </Link>
                        {tabs.map((tab) => (
                            <Link
                                key={tab.name}
                                search={(old) => ({
                                    ...old,
                                    orderStatus: tab.href,
                                })}
                                className={classNames(
                                    activeTab === tab.href
                                        ? "border-gray-200 text-gray-200"
                                        : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200",
                                    "whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm"
                                )}
                                aria-current={
                                    activeTab === tab.href ? "page" : undefined
                                }
                            >
                                {tab.name}
                            </Link>
                        ))}
                    </nav>
                </div>
            </div>
            <div className="flex flex-1 overflow-y-auto">
                <div className="flex-1 mx-auto min-w-0  p-4 sm:py-6 overflow-hidden overflow-y-auto light flex">
                    {!activeTab && <ReportingTab />}
                    {activeTab === "brokerageStatement" && (
                        <BrokerageStatementTab />
                    )}
                    {activeTab === "soa_reports" && (
                        <SOAReportTab />
                    )}
                    {activeTab === "monthly_reports" && (
                        <MonthlyReportTab />
                    )}
                </div>
            </div>
        </main>
    )
}

export default ReportsPage