import {
  CurrencyPicker,
  InsurerPicker,
  OfficeButton,
  PDFRenderer,
  ReInsurerPicker,
  SelectInput,
  TextInput,
} from "components";
import config from "config";
import { useFormik } from "formik";
import { useStatementPaidCheques } from "hooks/data/use-statement-paid-cheques";
import _ from "lodash";
import { useEffect, useMemo } from "react";
import { wrapClick } from "utils";
import * as Yup from "yup";

type Props = {};

const SOAReportTab = (props: Props) => {
  const form = useFormik<any>({
    initialValues: {
      insurer_id: "",
      reinsurer_id: "",
      from: "",
      to: "",
    },
    onSubmit: () => {
      form.setFormikState((state) => ({ ...state, status: "SUBMIITED" }));
      form.setSubmitting(false);
      return;
    },
    validationSchema: Yup.object().shape({
      insurer_id: Yup.string().required("Insurer is required"),
      reinsurer_id: Yup.string().required("Re-Insurer is required"),
      from: Yup.date().required("From is required"),
      to: Yup.date().required("To  is required"),
    }),
    initialStatus: "NOT_SUBMIITED",
  });
  const { loadingCheques, cheques } = useStatementPaidCheques();
  const loading = loadingCheques;

  const data = useMemo(
    () =>
      btoa(
        JSON.stringify({
          insurer_id: form?.values?.insurer_id,
          reinsurer_id: form?.values?.reinsurer_id,
          from: form?.values?.from,
          to: form?.values?.to,
        })
      ),
    [
      form.values.insurer_id,
      form.values.from,
      form.values.to,
      form.values.reinsurer_id,
    ]
  );

  useEffect(() => {
    form.setFormikState((state) => ({ ...state, status: "NOT_SUBMIITED" }));
    form.setSubmitting(false);
  }, [form.values.currency, form.values.date, form.values.cheque]);

  return (
    <div className="w-full">
      <div className="py-3 flex justify-between">
        <h1 className="font-bold text-gray-300 text-lg">SOA Report</h1>
        <OfficeButton
          disabled={form.isSubmitting}
          onClick={wrapClick(form.handleSubmit)}
        >
          {form.isSubmitting ? "Generating ..." : "Generate Report"}
        </OfficeButton>
      </div>
      <form className="bg-white p-6 space-y-6">
        <div className=" grid gap-3  grid-cols-2 shadow-sm">
          <div>
            <InsurerPicker
              id="insurer_id"
              label="Insurer"
              placeholder="e.g. Afro Asian"
              rawId
              required={true}
              {...form}
            />
          </div>
          <div>
            <ReInsurerPicker
              id={"reinsurer_id"}
              rawId
              required
              placeholder="select Reisnurer"
              label={"Re-Insurer"}
              {...form}
            />
          </div>
          <div>
            <TextInput
              id="from"
              label="From"
              type="date"
              required={true}
              {...form}
            />
          </div>
          <div>
            <TextInput
              id="to"
              label="To"
              type="date"
              required={true}
              {...form}
            />
          </div>
        </div>
        {/* <OfficeButton
          disabled={form.isSubmitting}
          onClick={wrapClick(form.handleSubmit)}
        >
          {form.isSubmitting ? "Generating ..." : "Generate Report"}
        </OfficeButton> */}
        {form.status === "SUBMIITED" && form.isValid && (
          <a
            target="_blank"
            className="bg-teal-500 text-white p-3 rounded-md shadow-sm"
            href={`${config.asset.uri}export-soa-data-to-excel/${data}`}
          >
            Download Excel Report
          </a>
        )}
      </form>
      {form.status === "SUBMIITED" && form.isValid && (
        <PDFRenderer
          url={`${config.asset.uri}soa_report/${btoa(
            JSON.stringify({
              insurer_id: form?.values?.insurer_id,
              reinsurer_id: form?.values?.reinsurer_id,
              from: form?.values?.from,
              to: form?.values?.to,
            })
          )}`}
        />
      )}
    </div>
  );
};

export default SOAReportTab;
