import { AccountPeriodsNames, wrapClick } from "utils";
import { useMutation } from "@apollo/client";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { Modal } from "components";
import * as Yup from "yup";
import { QuarterForm } from "components/forms";
import { useMatch, useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { useTreaty, useTreatyAccounts } from "hooks/data/use-treaties";
import { updateQuarter } from "_graphql/mutation/treaty";
import _ from "lodash";
import { v4 } from "uuid";
import { useEffect } from "react";
import { TreatyAccountPeriod } from "_graphql/__generated__/globalTypes";
import {
  UpdateQuarterForTreaty,
  UpdateQuarterForTreatyVariables,
} from "_graphql/mutation/__generated__/UpdateQuarterForTreaty";

const validationSchema = Yup.object().shape({
  currency: Yup.string().required("Currency is required"),
  account_periods: Yup.string().required("Quarter is required"),
  account_year: Yup.string().required("Account Year is required"),
  surpluses: Yup.array()
    .of(
      Yup.object().shape({
        gross_premium: Yup.string().required("Gross Premium is required"),
        cash_loss: Yup.number()
          .min(0, "Number must be greater than 0")
          .required("Cash Loss is required"),
        claim_settled: Yup.number()
          .min(0, "Number must be greater than 0")
          .required("Claim Settled is required"),
      })
    )
    .min(1, "At least one surplus is required"),
});

const noteOptions = [
  {
    label: "1st Quarter",
    value: TreatyAccountPeriod.FIRSTQUARTER,
  },
  {
    label: "2nd Quarter",
    value: TreatyAccountPeriod.SECONDQUARTER,
  },
  {
    label: "3rd Quarter",
    value: TreatyAccountPeriod.THIRDQUARTER,
  },
  {
    label: "4th Quarter",
    value: TreatyAccountPeriod.FOURTHQUARTER,
  },
];

export default function UpdateQuarterContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const { params } = useMatch<LocationGenerics>();
  const searchParams = useSearch<LocationGenerics>();
  const { treaty, loading: fetching } = useTreaty({
    treatyId: params.treaty || "",
  });

  const { accounts, loading: loadingAccounts } = useTreatyAccounts({
    insurer_id: treaty?.insurer?.insurer_id || "",
    treaty_period_from: treaty?.treaty_deduction?.treaty_period_from || "",
    treaty_period_to: treaty?.treaty_deduction?.treaty_period_to || "",
    type: true,
    treaty_program_name: treaty?.treaty_program?.treaty_name || "",
  });

  const [create, { loading }] = useMutation<
    UpdateQuarterForTreaty,
    UpdateQuarterForTreatyVariables
  >(updateQuarter, {
    refetchQueries: ["Treaty"],
  });

  const form = useFormik<any>({
    initialValues: {
      currency: "",
      account_periods: "",
      account_year: "",
      surpluses: [],
    },
    validationSchema: validationSchema,
    onSubmit: async (variables) => {
      await create({
        variables: {
          treatyAccount: {
            account_periods: _.get(
              _.find(
                noteOptions,
                (el) => el.label === variables.account_periods
              ),
              "value",
              undefined
            ) as any,
            surpulus_data:
              variables.surpluses?.map((el: any) => ({
                ...el,
                surpulus_uuid: v4(),
                gross_premium: parseFloat(el.gross_premium),
                cash_loss: parseFloat(el.cash_loss),
                claim_settled: parseFloat(el.claim_settled),
              })) || [],
            account_year: variables.account_year,
            participant_deduction_id: searchParams.id || "",
            treatiestreaty_id: params.treaty || "",
          },
          treatyAccountId: searchParams?.treaty_account_id,
        },
      }).then(({ data }) => {
        if (data?.updateQuarterForTreaty) {
          toast.success("Quarter updated successfully");
          refetch?.();
          setOpen?.(false);
          form.resetForm();
        } else {
          toast.error("Quarter could not be updated");
        }
      });
    },
    onReset: () => {
      setOpen(false);
    },
  });

  useEffect(() => {
    if (accounts?.length) {
      const account = accounts?.find((el) => el?.treaty_id === params?.treaty);
      form.setFieldValue("currency", account?.treaty_id);
    }

    if (treaty?.treaty_accounts?.length) {
      const account = treaty?.treaty_accounts?.find(
        (el) => el?.treaty_account_id == searchParams?.treaty_account_id
      );
      form.setFieldValue(
        "account_periods",
        _.get(AccountPeriodsNames, account?.account_periods || "", "")
      );
      form.setFieldValue("account_year", account?.account_year || "2021");
      form.setFieldValue(
        "surpluses",
        account?.treaty_account_deduction?.map((el) => {
          return {
            gross_premium: el?.gross_premium,
            cash_loss: el?.cash_loss,
            claim_settled: el?.claim_settled,
            surpulus_uuid: el?.surpulus_uuid,
            account_deduction_id: el?.treaty_account_deduction_id,
          };
        }) || []
      );
    }
  }, [searchParams?.treaty_account_id, accounts, treaty?.treaty_accounts]);

  return (
    <Modal
      open={open}
      setOpen={() => {
        form.resetForm();
        setOpen(false);
      }}
      title={"Update Quarter"}
      loading={fetching || loadingAccounts}
      description="Update Quarterly Statement"
      renderActions={() => (
        <>
          <button
            type="button"
            disabled={loading || loadingAccounts}
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(form.handleSubmit)}
          >
            {loading ? "Saving changes..." : "Save changes"}
          </button>
        </>
      )}
    >
      <QuarterForm update form={form} treaty={treaty} accounts={accounts} />
    </Modal>
  );
}
