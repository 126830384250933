import { useInsurers } from "hooks/data/use-insurers";
import _ from "lodash";
import { FC, useMemo, useState } from "react";
import { SearchSelectInput } from ".";

interface Option {
  label: {
    title: string;
    imageUrl?: string;
  };
  value: string | object;
}

interface RegularInsurerPickerProps {
  id: string;
  label: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  labelHidden?: boolean;
  values: any;
  errors?: any;
  touched?: any
  optionsLoading?: boolean;
  setFieldValue: any
  setFieldTouched: any
  setFieldError: any
  rounded?: boolean
  rawId?: boolean;
}

const RegularInsurerPicker: FC<RegularInsurerPickerProps> = ({ id, label, rawId, ...form }) => {
  const { insurers, loading } = useInsurers({
    page: 1,
    pageSize: 500,
    search: "",
    searchFields: ["code", "batchCode"],
    sort: "",
  })
  const [query] = useState('');
  const options = useMemo(() => insurers.map(({ ...rest }) => ({ label: rest.insurer_company_name, value: rawId ? rest?.insurer_id : { ...rest } })), [insurers]);

  const filteredOptions =
    query === ''
      ? options
      : options.filter((option) => {
        return option.label?.toLowerCase().includes(query.toLowerCase())
      })

  return (
    <SearchSelectInput
      id={id ?? "round"}
      label={label ?? "Plot"}
      placeholder={loading ? "Loading please wait ..." : "Search Insurer"}
      optionsLoading={loading}
      rawId
      options={(filteredOptions || [])?.map((item: any) => ({
        label: {
          title: item.label as string
        },
        value: item?.value?.toString()
      }))}
      {...form}
    />
  )
}

export default RegularInsurerPicker;