import { CreateInsurerVariables } from "_graphql/mutation/__generated__/CreateInsurer";
import { CountryPicker, ReInsurerPicker, TextInput } from "components/core";
import { FormikProps } from "formik";
import { FC, useState } from "react";

export interface InsurerFormProps {
  form: FormikProps<CreateInsurerVariables["insurer"]>;
}

const InsurerForm: FC<InsurerFormProps> = ({ form }) => {
  const [reinsurer] = useState(null)
  return (
    <div className="space-y-6 divide-y divide-gray-200">
      <div>
        <span className="text-xs font-light">Add Insurer From existing reinsurers</span>
        <div>
          <ReInsurerPicker id={"reinsurer"} label={"Reinsurer"} values={{ reinsurer }} setFieldValue={(key: string, value: any) => {
            form.setFieldValue("insurer_company_name", value?.re_company_name)
            form.setFieldValue("insurer_company_email", value?.re_company_email)
            form.setFieldValue("insurer_company_website", value?.re_company_website)
            form.setFieldValue("country", value?.reinsurer_address?.country)
            form.setFieldValue("region", value?.reinsurer_address?.region)
            form.setFieldValue("suburb", value?.reinsurer_address?.suburb)
            form.setFieldValue("street", value?.reinsurer_address?.street)
          }} setFieldTouched={() => { }} setFieldError={() => { }} />
        </div>
      </div>
      <div className="pt-6">
        <span className="text-xs font-light">Insurer Details</span>
        <div className="grid grid-cols-2 gap-2">
          <div className="">
            <TextInput
              id="insurer_company_name"
              label="Company Name"
              type="text"
              placeholder="e.g. Visal Re-insurance"
              required={true}
              {...form}
            />
          </div>

          <div className="space-y-1 ">
            <TextInput
              id="insurer_company_email"
              label="Email"
              type="text"
              placeholder="e.g. visal@organization.com"
              required={true}
              {...form}
            />
          </div>

          <div className="space-y-1 col-span-2">
            <TextInput
              id="insurer_company_website"
              label="Webiste"
              type="text"
              placeholder="e.g. visalbrokers.org"
              // required={true}
              {...form}
            />
          </div>
        </div>
      </div>
      <div className="pt-6">
        <span className="text-xs font-light">Insurer Address Details</span>
        <div className="grid grid-cols-2 gap-2">
          <div className="">
            <CountryPicker
              id="country"
              label="Country"
              placeholder="e.g. Ghana"
              required
              {...form}
            />
          </div>

          <div className="space-y-1 ">
            <TextInput
              id="region"
              label="Region/State"
              type="text"
              placeholder="e.g. Greater Accra"
              required
              {...form}
            />
          </div>

          <div className="space-y-1 ">
            <TextInput
              id="suburb"
              label="Suburb/city"
              type="text"
              placeholder="e.g. Accra"
              required
              {...form}
            />
          </div>
          <div className="space-y-1 ">
            <TextInput
              id="street"
              label="Street Address"
              type="text"
              placeholder="e.g. Antelope Street"
              required
              {...form}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InsurerForm;
