import { useQuery } from "@apollo/client";
import {
  GetTreatyProgramDeduction,
  GetTreatyProgramDeductionVariables,
} from "_graphql/queries/__generated__/GetTreatyProgramDeduction";
import {
  GetTreatyProgramDeductions,
  GetTreatyProgramDeductionsVariables,
} from "_graphql/queries/__generated__/GetTreatyProgramDeductions";
import {
  TreatyProgram,
  TreatyProgramVariables,
} from "_graphql/queries/__generated__/TreatyProgram";
import {
  TreatyPrograms,
  TreatyProgramsVariables,
} from "_graphql/queries/__generated__/TreatyPrograms";
import {
  getTreatyProgram,
  getTreatyProgramDeduction,
  getTreatyProgramDeductions,
  getTreatyPrograms,
} from "_graphql/queries/treaty-programs";

export const useTreatyPrograms = (variables?: TreatyProgramsVariables) => {
  const { data, ...rest } = useQuery<TreatyPrograms, TreatyProgramsVariables>(
    getTreatyPrograms,
    {
      variables: variables,
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
    }
  );
  const items = data?.rows || [];
  return {
    treatyPrograms: items || [],
    count: data?.count || 0,
    ...rest,
  };
};

export const useTreatyProgram = (variables?: TreatyProgramVariables) => {
  const { data, ...rest } = useQuery<TreatyProgram, TreatyProgramVariables>(
    getTreatyProgram,
    {
      variables: variables,
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
    }
  );
  return {
    treatyProgram: data?.treatyProgram,
    ...rest,
  };
};
export const useTreatyProgramDeductions = (
  variables?: GetTreatyProgramDeductionsVariables
) => {
  const { data, ...rest } = useQuery<
    GetTreatyProgramDeductions,
    GetTreatyProgramDeductionsVariables
  >(getTreatyProgramDeductions, {
    variables: variables,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "no-cache",
  });
  return {
    deductions: data?.getTreatyProgramDeductions || [],
    ...rest,
  };
};

export const useTreatyProgramDeduction = (
  variables?: GetTreatyProgramDeductionVariables
) => {
  const { data, ...rest } = useQuery<
    GetTreatyProgramDeduction,
    GetTreatyProgramDeductionVariables
  >(getTreatyProgramDeduction, {
    variables: variables,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "no-cache",
  });
  return {
    deduction: data?.getTreatyProgramDeduction,
    ...rest,
  };
};
