import { validators, wrapClick } from "utils";
import { gql, useMutation } from "@apollo/client";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { Modal } from "components";
import * as Yup from "yup";
import { createEmployee, updateEmployee } from "_graphql/mutation/employee";
import { CreateEmployeeAccount, CreateEmployeeAccountVariables } from "_graphql/mutation/__generated__/CreateEmployeeAccount";
import { EmployeeForm } from "components/forms";
import { employeeValidationSchema } from "./create";
import { UpdateEmployeeAccountDetails, UpdateEmployeeAccountDetailsVariables } from "_graphql/mutation/__generated__/UpdateEmployeeAccountDetails";
import { LocationGenerics } from "router/location";
import { useSearch } from "react-location";
import { useEmployee } from "hooks/data/use-employees";
import { useEffect } from "react";




export default function UpdateEmployeeContainer({
    open,
    setOpen,
    refetch,
}: {
    open: boolean;
    setOpen: (val: boolean) => void;
    refetch?: () => void;
}) {
    const searchParams = useSearch<LocationGenerics>();
    const [update, { loading }] = useMutation<UpdateEmployeeAccountDetails, UpdateEmployeeAccountDetailsVariables>(updateEmployee, {
        refetchQueries: ["Employees"]
    });
    const { employee, loading: fetching } = useEmployee({ employeeId: searchParams?.id || "" })
    const form = useFormik<UpdateEmployeeAccountDetailsVariables["employee"]>({
        initialValues: {
            employee_email: "",
            employee_first_name: "",
            employee_last_name: "",
            employee_phonenumber: "",
            employee_position: "",
        },
        validationSchema: employeeValidationSchema,
        onSubmit: async (variables) => {
            await update({
                variables: {
                    employee: variables,
                    employeeId: searchParams?.id || "",
                },
            }).then(({ data }) => {
                if (data?.updateEmployeeAccountDetails) {
                    toast.success("Employee updated successfully");
                    refetch?.();
                    form.resetForm();
                } else {
                    toast.error("Failed to update employee");
                }
            });
        },
        onReset: () => {
            setOpen(false);
        },
    });

    useEffect(() => {
        if (employee) {
            form.setValues({
                employee_email: employee?.employee_email,
                employee_first_name: employee?.employee_first_name,
                employee_last_name: employee?.employee_last_name,
                employee_phonenumber: employee?.employee_phonenumber,
                employee_position: employee?.user?.position,
            })
        }
    }, [employee]);

    return (
        <Modal
            open={open}
            setOpen={setOpen}
            loading={fetching}
            title="Update  Employee"
            description="Provide the details to update employee"
            renderActions={() => (
                <>
                    <button
                        type="button"
                        disabled={loading}
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={wrapClick(form.handleSubmit)}
                    >
                        {loading ? "Updating Employee..." : "Update Employee"}
                    </button>
                </>
            )}
        >
            <EmployeeForm form={form} />
        </Modal>
    );
}
