import { gql } from "@apollo/client";

export const createPayment = gql`
  mutation MakePaymentOnOffer($paymentInfo: Offer_payment_data!) {
    makePaymentOnOffer(payment_info: $paymentInfo)
  }
`;


export const makeProportionalPayment = gql`
mutation MakeProportionalPayment($payment: PaymentDetails) {
  makeProportionalPayment(payment: $payment)
}
`;


export const makeNonProportionalPayment = gql`
mutation MakeNonProportionalPayment($payment: PaymentDetails) {
  makeNonProportionalPayment(payment: $payment)
}
`;


export const updateNonProportionalPayment = gql`
mutation UpdateNonProportionalPayment($payment: PaymentDetails) {
  updateNonProportionalPayment(payment: $payment)
}

`;


export const takeReinsurerClaimPayment = gql`
 mutation CreateOfferClaimPayment(
  $claimPayments: OfferClaimParticipantPaymentInput!
) {
  createOfferClaimPayment(claim_payments: $claimPayments)
}
`;
export const updatePayment = gql`
  mutation UpdatePaymentInsurer($data: Offer_payment_update_data!) {
    updatePaymentOnofferFromInsurer(payment_info: $data)
  }
`;

export const removeInsurerPayment = gql`
  mutation DeleteOfferPaymentFromInsurer($offerPaymentId: ID!) {
    deleteOfferPaymentFromInsurer(offer_payment_id: $offerPaymentId)
  }
`;
export const removeNonProportionalPayment = gql`
mutation RemoveNonProportionalPayment(
  $paymentId: ID
  $treatyId: ID
  $uuid: String
) {
  removeNonProportionalPayment(
    payment_id: $paymentId
    treaty_id: $treatyId
    uuid: $uuid
  )
}
`;
export const removeProportionalPayment = gql`
mutation RemoveProportionalPayment($paymentId: ID) {
  removeProportionalPayment(payment_id: $paymentId)
}
`;

export const distributePaymentMade = gql`
  mutation DistributePayments($data: Offer_participant_payment_update!) {
    updateOfferParticipantPayment(participant_payment_details: $data)
  }
`;