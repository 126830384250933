import { gql } from "@apollo/client";

export const getDashboardOverview = gql`
  query GetDashboardOverview($dashboardInput: DashboardOverviewInput) {
    getDashboardOverview(dashboardInput: $dashboardInput) {
      total_offers
      total_pending_offers
      total_open_offers
      total_closed_offers
      total_claim_offers
      total_brokers
      total_reinsurers
      total_insurers
      brokerage_earnings {
        total_dollar
        total_pounds
        total_euros
        total_cedis
      }
      total_fac_premium {
        total_dollar
        total_pounds
        total_euros
        total_cedis
      }
      total_fac_sum_insured {
        total_dollar
        total_pounds
        total_euros
        total_cedis
      }
      total_premium {
        total_dollar
        total_pounds
        total_euros
        total_cedis
      }
      total_sum_insured {
        total_dollar
        total_pounds
        total_euros
        total_cedis
      }
      total_unclaim_offers
    }
  }
`;
