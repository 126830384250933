import {
  BusinessPicker,
  CurrencyPicker,
  InsurerPicker,
  RichEditor,
  TextInput,
} from "components/core";
import { FormikProps } from "formik";
import { FC, useEffect } from "react";
import "@mantine/tiptap/styles.css";
import _ from "lodash";
import numeral from "numeral";

export interface OfferFormProps {
  form: FormikProps<any>;
}

const OfferForm: FC<OfferFormProps> = ({ form }) => {
  const details = JSON.parse(
    _.get(form.values, "class_of_business_id.business_details") || "[]"
  );

  useEffect(() => {
    form.setFieldValue(
      "offer_details",
      form?.values?.old_class_of_business_id &&
        form?.values?.old_class_of_business_id ===
        form?.values?.class_of_business_id?.class_of_business_id
        ? form?.values?.old_offer_detail
        : JSON.parse(
          _.get(form.values, "class_of_business_id.business_details") || "[]"
        ).map((detail: any) => ({ keydetail: detail.keydetail, value: "" }))
    );
  }, [form.values.class_of_business_id]);

  useEffect(() => {
    if (form?.values?.premium && form.values?.sum_insured) {
      form.setFieldValue(
        "rate",
        numeral(
          _.multiply(
            _.divide(form.values.premium, form.values.sum_insured),
            100
          )
        ).format("0.00")
      );
    }
  }, [form.values]);

  return (
    <div className="space-y-6 divide-y divide-gray-200">
      {/* {JSON.stringify(form.values, null, 2)} */}
      <div>
        <span className="text-xs font-light">Offer</span>
        <div className="grid grid-cols-2 gap-2">
          <div className="">
            <InsurerPicker
              id="insurer_id"
              label="Insurer"
              placeholder="e.g. Select Insurer"
              rawId
              required={true}
              {...form}
            />
          </div>
          <div className="">
            <BusinessPicker
              id="class_of_business_id"
              label="Class of business"
              placeholder="e.g. Select class of business"
              required={true}
              {...form}
            />
          </div>
        </div>
      </div>
      {details?.length > 0 && (
        <>
          <div className="pt-6">
            <span className="text-xs font-light">Business Details</span>
            <div className="grid grid-cols-2 gap-2">
              {details?.map((detail: any, index: number) => (
                <div className="last-of-type:odd:col-span-2">
                  <TextInput
                    type="text"
                    id={`offer_details.${index}.value`}
                    label={detail?.keydetail}
                    placeholder={`${detail?.keydetail}`}
                    required={
                      !detail?.required ? false : detail?.required === "Yes"
                    }
                    {...form}
                  />
                </div>
              ))}
            </div>
          </div>
        </>
      )}
      <div className="pt-6">
        <span className="text-xs font-light">Offer Details</span>
        <div className="grid grid-cols-2 gap-2">
          <div>
            <TextInput
              type="text"
              id="policy_number"
              label="Policy Number"
              placeholder="e.g. SGS-11288/12334"
              required={true}
              {...form}
            />
          </div>
          <div>
            <TextInput
              type="text"
              id="insured_by"
              label="Insured"
              placeholder="e.g. ECG Ghana"
              required={true}
              {...form}
            />
          </div>
          <div>
            <TextInput
              type="text"
              id="sum_insured"
              label="Sum Insured"
              placeholder="e.g. ECG Ghana"
              required={true}
              {...form}
            />
          </div>
          <div>
            <TextInput
              type="text"
              id="premium"
              label="Premium"
              placeholder="e.g. 1200"
              required={true}
              {...form}
            />
          </div>
          <div>
            <TextInput
              type="text"
              id="rate"
              label="Rate (%)"
              placeholder="e.g. 12.5"
              disabled={true}
              required={true}
              {...form}
            />
          </div>
          <div>
            <TextInput
              type="text"
              id="facultative_offer"
              label="Facultative Offer (%)"
              placeholder="e.g. 12.5"
              required={true}
              {...form}
            />
          </div>
          <div>
            <TextInput
              type="text"
              id="commission"
              label="Commission (%)"
              placeholder="e.g. 12.5"
              required={true}
              {...form}
            />
          </div>
          <div>
            <TextInput
              type="text"
              id="brokerage"
              label="Preliminary Brokerage (%)"
              placeholder="e.g. 12.5"
              required={true}
              {...form}
            />
          </div>
          <div className="col-span-2">
            <CurrencyPicker
              id="currency"
              label="Currency"
              placeholder="e.g. USD"
              required={true}
              {...form}
            />
          </div>
        </div>
      </div>
      <div className="pt-6">
        <span className="text-xs font-light">Period</span>
        <div className="grid grid-cols-2 gap-2">
          <div>
            <TextInput
              type="date"
              id="period_of_insurance_from"
              label="From"
              placeholder=""
              required={true}
              {...form}
            />
          </div>
          <div>
            <TextInput
              type="date"
              id="period_of_insurance_to"
              label="To"
              required={true}
              {...form}
            />
          </div>
          <div className="col-span-2 mt-6">
            <RichEditor
              id="comment"
              label="Comment"
              placeholder="e.g. Comment goes here ..."
              {...form}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OfferForm;
