import { gql } from "@apollo/client";

export const getPayments = gql`
query GetOfferPayments($args: OfferPaymentInput) {
  getOfferPayments(args: $args) {
    offer_payment_id
    payment_amount
    offer_payment_comment
    payment_details
    created_at
    updated_at
    offer {
      offer_id
      rate
      commission
      commission_amount
      brokerage
      facultative_offer
      sum_insured
      fac_sum_insured
      premium
      fac_premium
      offer_status
      payment_status
      claim_status
      created_at
      updated_at
    }
    distribute_payment
  }
}
`;
export const getPayment = gql`
  query GetOfferPayment($id: ID) {
    getOfferPayment(payment_id: $id) {
      offer_payment_id
      payment_amount
      offer_payment_comment
      payment_details
      created_at
      updated_at
      offer {
        offer_id
        rate
        commission
        commission_amount
        brokerage
        facultative_offer
        sum_insured
        fac_sum_insured
        premium
        fac_premium
        offer_status
        payment_status
        claim_status
        created_at
        updated_at
      }
    }
  }
`;

export const getInsurerOfferDeductions = gql`
  query OfferparticipantDeductions($id: ID!, $offer_id: ID, $payment_id: ID!) {
    getOfferparticipantDeductions(
      insurer_id: $id
      offer_id: $offer_id
      payment_id: $payment_id
    )
  }
`;
