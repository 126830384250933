import {
  ActionButton,
  OfficeHeader,
  TableComponent,
} from "components";
import { Action } from "components/buttons/action-button";
import moment from "moment";
import numeral from "numeral";
import { FC } from "react";
import { useMatch, useNavigate, useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import CreateInsurerPaymentContainer from "./create";
import { useUrlState } from "utils";
import ViewInsurerPaymentReceiptContainer from "./view-receipt";
import { useToast } from "hooks/ui/use-toast";
import { useReInsurerOfferPayments } from "hooks/data/use-re-insurers";
import { GetReinsurerParticipantPayments_getReinsurerParticipantPayments } from "_graphql/queries/__generated__/GetReinsurerParticipantPayments";
import UpdateInsurerPaymentContainer from "./update";

type Props = {};

const ReInsurerPaymentsPage: FC<Props> = (props) => {
  const { toast } = useToast();
  const [modal, setModal] = useUrlState("modal");
  const { params } = useMatch<LocationGenerics>();
  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const dispatchAction =
    (id: string, action: Exclude<Action, "expand" | "goto" | "clone">) =>
      () => {
        navigate({
          search: (old) => ({
            ...old,
            id,
            modal: action,
          }),
        });
      };
  const { refetch, payments, loading } = useReInsurerOfferPayments({
    offerParticipantId: params?.offer_participant,
  });
  return (
    <main className="flex-1 flex flex-col overflow-hidden bg-shade-500">
      <OfficeHeader />
      <div className="flex flex-1 overflow-y-auto">
        <div className="flex-1 mx-auto min-w-0  py-4 sm:py-6 overflow-hidden overflow-y-auto light flex">
          <TableComponent
            loading={loading}
            title={"payments"}
            hasSearch
            columns={[
              {
                name: "Payment Type",
                accessor: "type",
                render: (value: GetReinsurerParticipantPayments_getReinsurerParticipantPayments) => (
                  <span>
                    {JSON.parse(value.paid_details || "{}")?.payment_type ||
                      "N/A"}
                    {JSON.parse(value.paid_details || "{}")?.payment_type ===
                      "Cheque"
                      ? " - "
                      : ""}
                    {JSON.parse(value.paid_details || "{}")?.payment_from
                      ?.cheque_number || ""}
                  </span>
                ),
              },
              {
                name: "Bank Name",
                accessor: "bank_name",
                render: (value: GetReinsurerParticipantPayments_getReinsurerParticipantPayments) => (
                  <span>
                    {JSON.parse(value.paid_details || "{}")?.payment_from
                      ?.bank_name || "N/A"}
                  </span>
                ),
              },
              {
                name: "Beneficiary Bank Name",
                accessor: "beneficiary_bank",
                render: (value: GetReinsurerParticipantPayments_getReinsurerParticipantPayments) => (
                  <span>
                    {JSON.parse(value.paid_details || "{}")?.payment_to ||
                      "N/A"}
                  </span>
                ),
              },
              {
                name: "Payment Amt",
                accessor: "payment_amount",
                render: (value: GetReinsurerParticipantPayments_getReinsurerParticipantPayments) => (
                  <span>{numeral(value.offer_payment_amount).format("0,0.00")}</span>
                ),
              },
              {
                name: "Payment Date",
                accessor: "created_at",
                render: (value: GetReinsurerParticipantPayments_getReinsurerParticipantPayments) => (
                  <span>{moment(value.created_at).format("DD-MM-YYYY")}</span>
                ),
              },
              {
                name: "Last Payment Upate",
                accessor: "updated_at",
                render: (value: GetReinsurerParticipantPayments_getReinsurerParticipantPayments) => (
                  <span>{moment(value.updated_at).format("DD-MM-YYYY")}</span>
                ),
              },
              {
                name: "Actions",
                accessor: "actions",
                render: (value: GetReinsurerParticipantPayments_getReinsurerParticipantPayments) => (
                  <>
                    <ActionButton
                      action={"update"}
                      tooltip="Update payment details"
                      onClick={dispatchAction(value.offer_participant_payment_id, "update")}
                    />
                    <ActionButton
                      action={"resolve"}
                      tooltip="Payment Letter"
                      onClick={dispatchAction(value.offer_participant_payment_id, "view")}
                    />
                    {/* <ActionButton
                      action={"remove"}
                      tooltip="Remove payment"
                      onClick={dispatchAction(value.offer_participant_payment_id, "view")}
                    /> */}
                  </>
                ),
              },
            ]}
            data={payments}
            refetch={refetch}
          />
        </div>
      </div>
      <CreateInsurerPaymentContainer
        open={modal === "create"}
        setOpen={(val: boolean) => setModal(val ? "create" : undefined)}
        refetch={refetch}
      />
      {searchParams.id && (
        <>
          <ViewInsurerPaymentReceiptContainer
            open={modal === "view"}
            setOpen={(val: boolean) => setModal(val ? "view" : undefined)}
          />
          <UpdateInsurerPaymentContainer
            open={modal === "update"}
            setOpen={(val: boolean) => setModal(val ? "update" : undefined)}
          />
        </>
      )}
    </main>
  );
};

export default ReInsurerPaymentsPage;
