import { FC } from "react";
import { ArrowRightOnRectangleIcon, BellAlertIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import { Link, Navigate, Outlet } from "react-location";
import { useReactiveVar } from "@apollo/client";
import _ from "lodash";
import routes from "router/routes";
import {
  clearAuth,
  currentTokenVar,
  currentUserVar,
} from "_graphql/cache/auth";
import { classNames, wrapClick } from "utils";
import { Avatar } from "components/core";
import { withRoutesRoles } from "router/filter";
import { Disclosure, Transition } from "@headlessui/react";
import React from "react";
import Push from "push.js";
import config from "config";
import Pusher from "pusher-js";


const NAV_SECTIONS = [
  "General",
  "Facultative Businesses",
  "Treaty",
  "Clients",
  "Others",
];

const AppLayout: FC = () => {
  const currentUser = useReactiveVar(currentUserVar);
  const currentToken = useReactiveVar(currentTokenVar);
  const [currentSection, setCurrentSection] = React.useState("General");

  React.useEffect(() => {
    if (!Push.Permission.has()) {
      Push.Permission.request(onGranted, onDenied);
      Push.Permission.get();
    }
  }, []);


  React.useEffect(() => {
    var pusher = new Pusher(config.pusher.key, {
      cluster: config.pusher.cluster,
    });
    pusher.subscribe(config.pusher.channel);
    pusher.bind(config.pusher.event, (notif: any) => {
      // const newNotif = {
      //   employee_notification_id: JSON.parse(notif.system_data)
      //     .employee_notification_id[currentUser?.employee?.employee_id || ""],
      //   system_notification: {
      //     ...JSON.parse(notif.system_data),
      //   },
      // };
      // setNotifications([newNotif, ...all_notifs]);
      // all_notifs = [newNotif, ...all_notifs];
      if (
        JSON.parse(notif.system_data).owner_id !=
        currentUser?.employee?.employee_id
      ) {
        Push.create("Visal-Re Notification", {
          body: notif.message,
          icon: require("assets/logo.png"),
          timeout: 4000,
          onClick: function () {
            // history.push({
            //   pathname: "/admin/notifications",
            //   state: { notification: JSON.parse(notif.system_data) },
            // });
            // this.close();
          },
        });
        // settotal((prev) => prev + 1);
      }
    });
    return () => {
      pusher.unbind_all();
      pusher.unsubscribe("LgzULSz64aed7WJ7YwDq3qmHAeX786dZNZ3Ko4Bisf0");
    };
  }, []);

  const onGranted = () => {
    Push.create("Visal-Re Notification", {
      body: "Welcome to Visal Reinsurance",
      icon: require("assets/logo.png"),
      timeout: 4000,
      onClick: () => {
        window.focus();
      },
    })
  };
  const onDenied = () => { };

  const navigation = _.chain(routes)
    .filter((route) => _.get(route, "meta.layout") === "App")
    .thru(withRoutesRoles)
    .filter((item) => !!item.sidebar)
    .value();

  if (!currentToken) {
    return (
      <Navigate
        to={"/cpanel-login"}
        // search={{ redirect: location.current.href }}
        replace
      />
    );
  }

  return (
    <div className="h-screen">
      <div className="h-full flex">
        {/* Static sidebar for desktop */}
        <div className="hidden lg:flex lg:flex-shrink-0">
          <div className="flex flex-col border-r border-card-600 bg-card-500 w-64">
            <div className="flex flex-shrink-0 items-center border-b border-card-600 p-4 flex-row">
              <div className="flex-shrink-0 h-10 w-10 p-1 bg-gray-200">
                <img
                  className="h-full w-full aspect-h-1 aspect-w-1 border-white border p-1"
                  src={require("assets/logo.png")}
                  alt="SUBS"
                />
              </div>
              <div className="ml-3">
                <p className="text-sm text-white font-mono">
                  Visal Reinsurance Dashboard
                </p>
                {/* <p className="text-xs font-medium text-gray-500 group-hover:text-gray-700">View profile</p> */}
              </div>
            </div>
            <div className="py-4 flex flex-grow flex-col overflow-y-auto no-scrollbar">
              <nav className="flex-1 space-y-6 px-2" aria-label="Sidebar">
                {NAV_SECTIONS.map((section) => (
                  <Disclosure defaultOpen={currentSection === section}>
                    {({ open }) => (
                      <div key={section} className="space-y-1">
                        <Disclosure.Button onClick={() => { setCurrentSection(section) }} className="flex w-full justify-between rounded-lg pr-2   py-2 text-left text-sm font-medium focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75">
                          <h3
                            className="px-3 text-sm font-light text-gray-300"
                            id={section}
                          >
                            {section}
                          </h3>
                          <ChevronUpIcon
                            className={`${currentSection !== section ? "rotate-180 transform" : ""
                              } h-4 w-4 text-gray-300`}
                          />
                        </Disclosure.Button>
                        <Transition
                          show={currentSection === section}
                          className="overflow-hidden"
                          enter="transition-all ease-in-out duration-[600ms] "
                          enterFrom="transform  max-h-0"
                          enterTo="transform  max-h-[1000px]"
                          leave="transition-all ease-in-out duration-[400ms]"
                          leaveFrom="transform  max-h-[1000px]"
                          leaveTo="transform  max-h-0"
                        >
                          <Disclosure.Panel static className="space-y-1">
                            <div
                              className="space-y-1"
                              role="group"
                              aria-labelledby={section}
                            >
                              {_.filter(
                                navigation,
                                (item) =>
                                  _.get(item, "meta.section") === section
                              ).map((item) => {
                                const isCurrent = false;
                                return (
                                  <Link
                                    key={item.sidebar?.label}
                                    to={item.path}
                                    getActiveProps={() => ({
                                      className: "bg-primary-800 text-white",
                                    })}
                                    getInactiveProps={() => ({
                                      className:
                                        "text-gray-300 hover:bg-primary-800 hover:text-white",
                                    })}
                                    className="group flex items-center px-2 py-2 text-sm rounded-md"
                                  >
                                    {item.sidebar?.icon && (
                                      <item.sidebar.icon
                                        className={classNames(
                                          isCurrent
                                            ? "text-gray-900"
                                            : "text-gray-200 group-hover:text-gray-50",
                                          "mr-3 flex-shrink-0 h-6 w-6"
                                        )}
                                        aria-hidden="true"
                                      />
                                    )}
                                    {item.sidebar?.label}
                                  </Link>
                                );
                              })}
                            </div>
                          </Disclosure.Panel>
                        </Transition>
                      </div>
                    )}
                  </Disclosure>
                ))}
                {/* {NAV_SECTIONS.map((section) => (
                  <div key={section} className="space-y-1">
                    <h3
                      className="px-3 text-xs font-light text-gray-300"
                      id={section}
                    >
                      {section}
                    </h3>
                    <div
                      className="space-y-1"
                      role="group"
                      aria-labelledby={section}
                    >
                      {_.filter(
                        navigation,
                        (item) => _.get(item, "meta.section") === section
                      ).map((item) => {
                        const isCurrent = false;
                        return (
                          <Link
                            key={item.sidebar?.label}
                            to={item.path}
                            getActiveProps={() => ({
                              className: "bg-card-800 text-white",
                            })}
                            getInactiveProps={() => ({
                              className:
                                "text-gray-300 hover:bg-card-800 hover:text-white",
                            })}
                            className="group flex items-center px-2 py-2 text-sm rounded-md"
                          >
                            {item.sidebar?.icon && (
                              <item.sidebar.icon
                                className={classNames(
                                  isCurrent
                                    ? "text-gray-900"
                                    : "text-gray-200 group-hover:text-gray-50",
                                  "mr-3 flex-shrink-0 h-6 w-6"
                                )}
                                aria-hidden="true"
                              />
                            )}
                            {item.sidebar?.label}
                          </Link>
                        );
                      })}
                    </div>
                  </div>
                ))} */}
              </nav>
              <nav className="space-y-1 px-2">
                <Link to={"/notifications"} className="group w-full flex items-center px-2 py-2 text-sm rounded-md text-gray-300 hover:bg-card-800 hover:text-white">
                  <BellAlertIcon
                    className={classNames(
                      "text-gray-200 group-hover:text-gray-50",
                      "mr-3 flex-shrink-0 h-6 w-6"
                    )}
                    aria-hidden="true"
                  />
                  Notifications
                </Link>
                <button
                  onClick={wrapClick(clearAuth)}
                  className="group w-full flex items-center px-2 py-2 text-sm rounded-md text-gray-300 hover:bg-card-800 hover:text-white"
                >
                  <ArrowRightOnRectangleIcon
                    className={classNames(
                      "text-gray-200 group-hover:text-gray-50",
                      "mr-3 flex-shrink-0 h-6 w-6"
                    )}
                    aria-hidden="true"
                  />
                  Logout
                </button>
              </nav>
            </div>

            <div className="flex flex-shrink-0 border-t border-card-600 p-4 overflow-hidden">
              <div className="flex-1 flex items-center space-x-3 overflow-hidden">
                <div className="flex-shrink-0">
                  <Avatar alt={currentUser?.employee?.emp_abbrv || "N A"} />
                </div>
                <div className="flex-1 overflow-hidden">
                  <p className="text-sm font-medium text-gray-200 group-hover:text-white line-clamp-1">
                    {[
                      currentUser?.employee?.employee_first_name || "",
                      currentUser?.employee?.employee_last_name || "",
                    ]
                      .join(" ")
                      .trim()}
                  </p>
                  <p className="text-xs font-medium text-gray-300 group-hover:text-gray-100 line-clamp-1 text-ellipsis overflow-hidden">
                    {currentUser?.employee?.employee_email}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex-1 min-w-0 flex bg-shade-500 flex-col overflow-hidden">
          {/* Display content here */}
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default AppLayout;
