import { wrapClick } from "utils";
import { useMutation } from "@apollo/client";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { Modal } from "components";
import * as Yup from "yup";
import { CloseOfferForm } from "components/forms";
import { closeOffer } from "_graphql/mutation/offers";
import { useMatch, useNavigate } from "react-location";
import { LocationGenerics } from "router/location";
import { useOffer } from "hooks/data/use-offers";
import { useEffect } from "react";
import {
  CreateClosingForOffer,
  CreateClosingForOfferVariables,
} from "_graphql/mutation/__generated__/CreateClosingForOffer";

export const valiadationSchema = Yup.object().shape({
  requests: Yup.array()
    .of(
      Yup.object().shape({
        withholdingTax: Yup.number().min(0, "Number must be greater than 0")
          .typeError("Should be a number")
          .required("Withholding Tax is required"),
        nicLevy: Yup.number().min(0, "Number must be greater than 0")
          .typeError("Should be a number")
          .required("NIC Levy is required"),
        agreedBrokerage: Yup.number().min(0, "Number must be greater than 0")
          .typeError("Should be a number")
          .required("Agreed Brokerage Tax is required"),
        agreedCommission: Yup.number().min(0, "Number must be greater than 0")
          .typeError("Should be a number")
          .required("Agreed Commission is required"),
      })
    )
    .min(1)
    .required("At least one participant is required"),
});

export default function CloseOfferContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const navigate = useNavigate<LocationGenerics>();
  const { params } = useMatch<LocationGenerics>();
  const { offer, loading: fetching } = useOffer({
    offer_id: params.offer || "",
  });

  const isFleet = offer?.classofbusiness?.business_name === "Motor Comprehensive Fleet";


  const [create, { loading }] = useMutation<
    CreateClosingForOffer,
    CreateClosingForOfferVariables
  >(closeOffer, {
    refetchQueries: ["Offer"],
  });

  const form = useFormik<any>({
    initialValues: {
      requests: [],
    },
    validationSchema: valiadationSchema,
    onSubmit: async (variables) => {
      await create({
        variables: {
          extraCharges: {
            offer_id: params.offer || "",
            reinsurer_data: variables.requests?.map((item: any) => ({
              reinsurer_id: item.reinsurer?.reinsurer_id,
              reinsurer_participant_id: item.offer_participant_id,
              withholding_tax: item.withholdingTax,
              agreed_brokerage_percentage: item.agreedBrokerage,
              agreed_commission: item.agreedCommission,
              nic_levy: item.nicLevy,
            })),
          },
        },
      }).then(({ data }) => {
        if (data?.createClosingForOffer) {
          toast.success("Offer Closed Successfully");
          refetch?.();
          form.resetForm();
          if (!isFleet) {
            navigate({ to: "/create-closing" });
          }

        } else {
          toast.error("Could not close Offer");
        }
      });
    },
    onReset: () => {
      setOpen(false);
    },
  });

  useEffect(() => {
    if (offer) {
      console.log("offer", offer);
      form.setFieldValue(
        "requests",
        offer?.offer_participant?.map((item) => ({
          ...item,
          withholdingTax: 0,
          nicLevy: 0,
          agreedBrokerage: 0,
          agreedCommission: 0,
        }))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offer]);

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      loading={fetching}
      title="Close Offer"
      size="7xl"
      description="Provide the percentage of the offer to be distributed to each reinsurer."
      renderActions={() => (
        <>
          <button
            type="button"
            disabled={loading}
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(form.handleSubmit)}
          >
            {loading ? "Closing Offer..." : "Close Offer"}
          </button>
        </>
      )}
    >
      {/* {JSON.stringify(form.values, null, 2)} */}
      <CloseOfferForm form={form} />
    </Modal>
  );
}
