import { Modal, PDFRenderer } from "components";
import { useMatch, useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import config from "config";
import _ from "lodash";

export default function ViewTreatyDebitNoteContainer({
    open,
    setOpen,
}: {
    open: boolean;
    setOpen: (val: boolean) => void;
}) {
    const searchParams = useSearch<LocationGenerics>();
    useMatch<LocationGenerics>();

    return (
        <Modal
            open={open}
            setOpen={setOpen}
            size="6xl"
            title={"Debit Note"}
            hidePadding
            description={`Details of Debit Note are shown below`}
        >
            <PDFRenderer
                url={`${config.asset.uri}treaty_debit_note/${btoa(
                    JSON.stringify({
                        treaty_account_id: searchParams?.treaty_account_id,
                        default: true,
                    })
                )}`}
            />
        </Modal>
    );
}
