import { gql } from "@apollo/client";

export const createReInsurer = gql`
  mutation CreateReinsurer($reinsurer: Reinsurer_data!) {
    createReinsurer(reinsurer: $reinsurer) {
      reinsurer_id
    }
  }
`;

export const updateReInsurer = gql`
  mutation UpdateReinsurer($reinsurerId: ID!, $reinsurer: Reinsurer_data!) {
    updateReinsurer(reinsurer_id: $reinsurerId, reinsurer: $reinsurer)
  }
`;

export const removeReInsurer = gql`
  mutation RemoveReinsurer($reinsurerId: ID!) {
    removeReinsurer(reinsurer_id: $reinsurerId) {
      reinsurer_id
    }
  }
`;

export const createReInsurerRep = gql`
  mutation CreateReinsurerRep($reinsurerRep: Reinsurer_rep_data!) {
    createReinsurerRep(reinsurer_rep: $reinsurerRep) {
      reinsurersreinsurer_id
    }
  }
`;

export const updateReinsurerRep = gql`
  mutation UpdateReinsurerRep(
    $reinsurerRepresentativeId: ID!
    $reinsurerRep: Reinsurer_rep_data!
  ) {
    updateReinsurerRep(
      reinsurer_representative_id: $reinsurerRepresentativeId
      reinsurer_rep: $reinsurerRep
    )
  }
`;

export const removeReinsurerRep = gql`
  mutation RemoveReinsuereRep($reinsurerRepresentativeId: ID!) {
    removeReinsuereRep(
      reinsurer_representative_id: $reinsurerRepresentativeId
    ) {
      reinsurersreinsurer_id
    }
  }
`;

export const UPDATE_EXTRA_CHARGE = gql`
mutation UpdateReinsurerExtraCharges(
  $extraChargeId: ID!
  $participantId: ID!
  $extraCharge: Offer_edit_extra_charge_data!
) {
  updateReinsurerExtraCharges(
    extra_charge_id: $extraChargeId
    participant_id: $participantId
    extra_charge: $extraCharge
  )
}
`;
