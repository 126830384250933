import { wrapClick } from "utils";
import { useMutation } from "@apollo/client";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { Modal } from "components";
import * as Yup from "yup";
import { assignCedantToStaff } from "_graphql/mutation/employee";
import AssignCedantForm from "components/forms/assign-cedants";
import { AssignCedantToStaff, AssignCedantToStaffVariables } from "_graphql/mutation/__generated__/AssignCedantToStaff";
import { useAppSearch } from "_graphql/cache/auth";


export const employeeValidationSchema = Yup.object().shape({
    cedants: Yup.array()
        .of(
            Yup.string().required("Cedant is required")
        )
        .min(1)
        .required("At least one cedant is required"),
});

export default function AssignCedantContainer({
    open,
    setOpen,
    refetch,
}: {
    open: boolean;
    setOpen: (val: boolean) => void;
    refetch?: () => void;
}) {
    const [create, { loading }] = useMutation<AssignCedantToStaff, AssignCedantToStaffVariables>(assignCedantToStaff);
    const searchParams = useAppSearch()
    const form = useFormik<any>({
        initialValues: {
            cedants: []
        },
        validationSchema: employeeValidationSchema,
        onSubmit: async (variables) => {
            await create({
                variables: {
                    cedants: variables.cedants,
                    staffId: searchParams?.id || ""
                },
            }).then(({ data }) => {
                if (data?.assignCedantToStaff) {
                    toast.success("Cedant assigned successfully");
                    refetch?.();
                    form.resetForm();
                } else {
                    toast.error("Failed to assign cedant");
                }
            });
        },
        onReset: () => {
            setOpen(false);
        },
    });

    return (
        <Modal
            open={open}
            setOpen={setOpen}
            title="Assign Cedant"
            description="Select cedant(s) to assign to this employee"
            renderActions={() => (
                <>
                    <button
                        type="button"
                        disabled={loading}
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={wrapClick(form.handleSubmit)}
                    >
                        {loading ? "Assigning Cedant..." : "Assign Cedant"}
                    </button>
                </>
            )}
        >
            <AssignCedantForm form={form} />
        </Modal>
    );
}
