import { useUrlState, wrapClick } from "utils";
import { useMutation } from "@apollo/client";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { Modal } from "components";
import * as Yup from "yup";
import { ClaimPaymentForm } from "components/forms";
import { useMatch, useNavigate, useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { useOffer } from "hooks/data/use-offers";
import { useEffect, useMemo } from "react";
import { useOfferPayments } from "hooks/data/use-payments";
import numeral from "numeral";
import { takeReinsurerClaimPayment } from "_graphql/mutation/payment";
import { useCurrentUser } from "_graphql/cache/auth";
import { CreateOfferClaimPayment, CreateOfferClaimPaymentVariables } from "_graphql/mutation/__generated__/CreateOfferClaimPayment";
import moment from "moment";

export const paymentValidationSchema = (amountToPay: number) =>
    Yup.object().shape({
        payment_type: Yup.string()
            .oneOf(["Bank Transfer", "Cheque"])
            .required("Invalid Payment Type"),
        payment_from: Yup.object()
            .when("payment_type", {
                is: "Cheque",
                then: (schema) =>
                    schema.shape({
                        bank_name: Yup.string().required("Bank Name is required"),
                        cheque_number: Yup.string().required("Cheque Number is required"),
                        date_on_cheque: Yup.string().required("Date on cheque is required"),
                    }),
                otherwise: (schema) =>
                    schema
                        .shape({
                            bank_name: Yup.string().required("Bank Name is required"),
                        })
                        .required(),
            })
            .required(),
        payment_to: Yup.string().required("Beneficiary Bank Name is required"),
        payment_details: Yup.object().shape({
            conversion: Yup.object().shape({
                rate: Yup.number().min(0,"Number must be greater than 0")
                    .min(0.0001, "Rate cannot be less than 1")
                    .required("Rate is required"),
                currency: Yup.string().required("Currency is required"),
            }),
            payment_amount: Yup.number().min(0,"Number must be greater than 0")
                .min(1, "Cannot make payment less than 1")
                .max(
                    numeral(amountToPay).value() || 0,
                    `Amount cannot be more than ${numeral(amountToPay).value() || 0}`
                )
                .required("Payment Amount is required"),
            currency: Yup.string().required("Currency is required"),
        }),
        offer_payment_comment: Yup.string().required("Payment Comment is required"),
    });

export default function CreateReinusrerClaimPaymentContainer({
    open,
    setOpen,
    refetch,
}: {
    open: boolean;
    setOpen: (val: boolean) => void;
    refetch?: () => void;
}) {
    const currentUser = useCurrentUser();
    const [rate, setRate] = useUrlState("rate");
    const { params } = useMatch<LocationGenerics>();
    const navigate = useNavigate<LocationGenerics>();
    const searchParams = useSearch<LocationGenerics>();
    const [create, { loading }] = useMutation<
        CreateOfferClaimPayment,
        CreateOfferClaimPaymentVariables
    >(takeReinsurerClaimPayment);
    const { loading: fetchigOffer, offer } = useOffer({
        offer_id: params.offer || "",
    });


    const form = useFormik<any>({
        initialValues: {
            payment_type: "",
            payment_from: {
                bank_name: "",
                cheque_number: "",
                date_on_cheque: "",
            },
            offer_payment_comment: "",
            payment_to: "",
            payment_details: {
                payment_amount: 0,
                currency: offer?.offer_detail?.currency || "USD",
                conversion: {
                    rate: 1,
                    currency: offer?.offer_detail?.currency || "USD",
                    addExchangeRate: false,
                },
            },
        },
        validationSchema: paymentValidationSchema(
            numeral(searchParams?.amount)
                .multiply(rate || 1)
                .value() || 0
        ),
        onSubmit: async (variables) => {
            await create({
                variables: {
                    claimPayments: {
                        payment_date: moment().format("YYYY-MM-DD"),
                        payment_comment: variables.offer_payment_comment,
                        offer_claim_participant: searchParams?.id?.toString() || "",
                        claim_share_payment_amount: variables.payment_details.payment_amount,
                        payment_details: JSON.stringify({
                            employee_id: currentUser?.employee?.employee_id,
                            payment_type: variables.payment_type,
                            payment_from: variables.payment_from,
                            payment_to: variables.payment_to,
                            conversion: variables.payment_details.conversion,
                        }),
                    },
                },
            }).then(({ data }) => {
                if (data?.createOfferClaimPayment) {
                    toast.success("Payment Added Successfully");
                    refetch?.();
                    form.resetForm();
                } else {
                    toast.error("Could not add payment");
                }
            });
        },
        onReset: () => {
            setOpen(false);
        },
    });

    useEffect(() => {
        if (offer?.offer_detail?.currency) {
            form.setFieldValue(
                "payment_details.currency",
                offer?.offer_detail?.currency
            );
            form.setFieldValue(
                "payment_details.conversion.currency",
                offer?.offer_detail?.currency
            );
        }
    }, [offer?.offer_detail?.currency]);

    useEffect(() => {
        if (form.values.payment_details.conversion.rate) {
            setRate(form.values.payment_details.conversion.rate);
        } else {
            setRate(1);
        }
    }, [form.values.payment_details.conversion.rate, setRate]);

    return (
        <Modal
            open={open}
            setOpen={() => {
                setOpen(false);
                navigate({
                    search(prev) {
                        return {
                            ...prev,
                            rate: undefined,
                            modal: undefined,
                        };
                    },
                });
            }}
            title="Add Payment"
            loading={fetchigOffer}
            description="Provide the details to add a new payment record"
            renderActions={() => (
                <>
                    <button
                        type="button"
                        disabled={loading}
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={wrapClick(form.handleSubmit)}
                    >
                        {loading ? "Adding payment..." : "Add Payment"}
                    </button>
                </>
            )}
        >
            {/* {JSON.stringify(form.errors, null, 2)} */}
            <ClaimPaymentForm form={form} reinsurer={searchParams?.reinsurer} offer={offer} amountToPay={numeral(searchParams?.amount).value() || 0} />
        </Modal>
    );
}



