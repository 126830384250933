import { useMutation } from "@apollo/client";
import toast from "react-hot-toast";
import { PopUp } from "components";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { deleteOfferEndorsementParticipant } from "_graphql/mutation/offers";
import { DeleteOfferEndorsementParticipant, DeleteOfferEndorsementParticipantVariables } from "_graphql/mutation/__generated__/DeleteOfferEndorsementParticipant";

// Lil Lyrics

export default function RemoveContainer({
    open,
    setOpen,
    refetch,
}: {
    open: boolean;
    setOpen: (val: boolean) => void;
    refetch?: () => void;
}) {
    const searchParams = useSearch<LocationGenerics>();
    const [remove, { loading }] = useMutation<DeleteOfferEndorsementParticipant, DeleteOfferEndorsementParticipantVariables>(deleteOfferEndorsementParticipant, {
        refetchQueries: ["GetEndorsement", "GetEndorsementParticipant", "GetEndorsementParticipants"],
    });

    const handleSubmit = async () => {
        await remove({
            variables: {
                offerEndParticipantId: searchParams?.id || "",

            },
        }).then(({ data }) => {
            if (data?.deleteOfferEndorsementParticipant) {
                toast.success("Participant Removed Successfully");
                refetch?.();
                setOpen?.(false);
            } else {
                toast.error("Could not remove participant");
            }
        });
    }

    return (
        <PopUp
            open={open}
            setOpen={setOpen}
            title="Remove Participant"
            cancelText="No, Cancel"
            description="This action would completely remove participant from endorsement details from system"
            renderActions={() => (
                <>
                    <button
                        type="button"
                        disabled={loading}
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={handleSubmit}
                    >
                        {loading ? "Removing Participant..." : "Remove Participant"}
                    </button>
                </>
            )}
        />
    );
}
