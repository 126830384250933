import React from 'react'
import { Link } from 'react-location'

type Props = {
    notification: any
    parsedNotification: any
}

const ClaimNotificationComponent = ({ notification, parsedNotification }: Props) => {
    const particiupants = parsedNotification?.claim_participants || [];
    const claim = parsedNotification?.claim_data;
    return (
        <div className="container mx-auto px-4">
            <div className="bg-yellow-200 p-4 mb-4">
                <p>{JSON.parse(notification?.system_notification?.notification_content || "{}").message}</p>
            </div>

            <div className="mb-4 flex flex-wrap">
                <p className="mr-4">
                    Offer: <strong>{parsedNotification?.policy_number}</strong>
                </p>
                <p className="mx-3">
                    Offer status:{" "}
                    <span
                        style={{ letterSpacing: 3 }}
                        className={`p-2 ${parsedNotification?.offer_status === "OPEN"
                            ? "bg-blue-500"
                            : parsedNotification?.offer_status === "PENDING"
                                ? "bg-yellow-500"
                                : "bg-green-500"
                            } text-white`}
                    >
                        {parsedNotification?.offer_status}
                    </span>
                </p>
            </div>

            <div className="mb-4">
                <div className="mb-2">
                    <p className="font-bold uppercase">Claim</p>
                </div>
                <table className="w-full border-collapse">
                    <tbody>
                        <tr>
                            <td className="py-2">Claim amount</td>
                            <td className="py-2">
                                {parsedNotification?.currency} {claim?.claim_amount}
                            </td>
                        </tr>
                        <tr>
                            <td className="py-2">Claim date</td>
                            <td className="py-2">{new Date(claim?.claim_date).toDateString()}</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            {particiupants.length > 0 && (
                <div className="mb-4">
                    <div className="mb-2">
                        <p className="font-bold uppercase">Participants share</p>
                    </div>
                    <table className="w-full border border-gray-300">
                        <thead className="bg-gray-200">
                            <tr>
                                <th className="text-left py-2 px-4">Reinsurer</th>
                                <th className="text-left py-2 px-4">Share</th>
                            </tr>
                        </thead>
                        <tbody>
                            {particiupants.map((payment: any, key: number) => (
                                <tr key={key} className="border-t border-gray-300">
                                    <td className="py-2 px-4">{payment.reinsurer}</td>
                                    <td className="py-2 px-4">
                                        {parsedNotification?.currency} {payment.claim_share}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}

            <div className="mb-4">
                <table className="w-full border-collapse">
                    <tbody>
                        <tr>
                            <td className="py-2">Created By</td>
                            <td className="py-2">
                                {parsedNotification?.employee_first_name} {parsedNotification?.employee_first_name}
                            </td>
                        </tr>
                        <tr>
                            <td className="py-2">Created on</td>
                            <td className="py-2">{parsedNotification?.offer_created_date}</td>
                        </tr>
                        <tr>
                            <td className="py-2">Class of Business</td>
                            <td className="py-2">{parsedNotification?.business_name}</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className="mb-4">
                <div className="mb-2">
                    <p className="font-bold uppercase">Offer Details</p>
                </div>
                <table className="w-full border border-gray-300">
                    <tbody>
                        <tr>
                            <th className="text-left py-2 px-4">Policy #</th>
                            <td className="py-2 px-4">{parsedNotification?.policy_number}</td>
                            <th className="text-left py-2 px-4">Insured By</th>
                            <td className="py-2 px-4">{parsedNotification?.insured_by}</td>
                        </tr>
                        <tr>
                            <th className="text-left py-2 px-4">Period of Insurance</th>
                            <td className="py-2 px-4">{parsedNotification?.period_of_insurance}</td>
                            <th className="text-left py-2 px-4">Reinsured</th>
                            <td className="py-2 px-4">{parsedNotification?.insurer_company_name}</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className="mb-4">
                <div className="mb-2">
                    <p className="font-bold uppercase">Offer</p>
                </div>
                <table className="w-full border border-gray-300">
                    <tbody>
                        <tr>
                            <th className="text-left py-2 px-4">Fac. Premium</th>
                            <td className="py-2 px-4">
                                {parsedNotification?.currency} {parsedNotification?.fac_premium}
                            </td>
                            <th className="text-left py-2 px-4">Brokerage</th>
                            <td className="py-2 px-4">{parsedNotification?.brokerage} %</td>
                        </tr>
                        <tr>
                            <th className="text-left py-2 px-4">Fac. Sum Insured</th>
                            <td className="py-2 px-4">
                                {parsedNotification?.currency} {parsedNotification?.fac_sum_insured}
                            </td>
                            <th className="text-left py-2 px-4">Commission</th>
                            <td className="py-2 px-4">{parsedNotification?.commission} %</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div>
                <Link
                    // to="/admin/claims"
                    className="btn bg-green-500 text-white py-2 px-4 rounded"
                >
                    Go to Claims
                </Link>
            </div>
        </div>
    )

}

export default ClaimNotificationComponent