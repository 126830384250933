import { wrapClick } from 'utils';
import { BrokerView, Modal, ReInsurerView } from 'components';
import { useSearch, useNavigate } from 'react-location';
import { LocationGenerics } from 'router/location';
import { Action } from 'components/buttons/action-button';
import { useReInsurer } from 'hooks/data/use-re-insurers';
import { useBroker } from 'hooks/data/use-brokers';


export default function ViewBrokerContainer({ open, setOpen }: { open: boolean; setOpen: (val: boolean) => void }) {

    const searchParams = useSearch<LocationGenerics>();
    const navigate = useNavigate<LocationGenerics>();
    const { broker, loading } = useBroker(searchParams?.id || "");
    const dispatchAction =
        (id: string, action: Exclude<Action, "expand" | "goto" | "clone">) =>
            () => {
                navigate({
                    search: (old) => ({
                        ...old,
                        id,
                        modal: action,
                    }),
                });
            };
    const expandItem = () => {
        navigate({
            to: `./${searchParams.id}`,
        });
    };

    return (
        <Modal
            open={open}
            setOpen={setOpen}
            loading={loading}
            title="Reinsurance Broker Information"
            description="Details of broker are shown below"
            renderActions={() => (
                <>
                    <button
                        type="button"
                        onClick={dispatchAction(broker?.re_broker_id || "", "update")}
                        className="ml-3 inline-flex items-center rounded-md border border-transparent bg-primary-600 px-3 py-2 text-sm leading-4 text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                    >
                        Update Broker Details
                    </button>
                    <button
                        type="button"
                        onClick={wrapClick(expandItem)}
                        className="ml-3 inline-flex items-center rounded-md border border-transparent bg-primary-600 px-3 py-2 text-sm leading-4 text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                    >
                        View More
                    </button>
                </>
            )}
        >
            <BrokerView
                broker={broker}
            />
        </Modal>
    )
}