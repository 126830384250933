import { GetOfferClaims_getOfferClaims } from "_graphql/queries/__generated__/GetOfferClaims";
import { ActionButton, OfficeHeader, TableComponent, TableLoader } from "components";
import { Action } from "components/buttons/action-button";
import { useOfferClaims } from "hooks/data/use-offer-claims";
import moment from "moment";
import { FC } from "react";
import { useMatch, useNavigate, useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import UpdateContainer from "./update";
import { useUrlState } from "utils";
import ViewClaimContainer from "./view";
import RemoveClaimContainer from "./remove";

type Props = {};

const ViewClaimsPage: FC<Props> = (props) => {
  const [modal, setModal] = useUrlState("modal");
  const navigate = useNavigate<LocationGenerics>();
  const searchParams = useSearch<LocationGenerics>();
  const { params } = useMatch<LocationGenerics>();
  const { claims, loading, refetch } = useOfferClaims({
    args: {
      offer_id: params.offer || "",

    }
  })


  const dispatchAction =
    (id: string, action: Exclude<Action, "expand" | "goto" | "clone">) =>
      () => {
        navigate({
          search: (old) => ({
            ...old,
            id,
            modal: action,
          }),
        });
      };

  const expandItem = (id: string) => () => {
    navigate({
      to: `./${id}/view`,
    });
  };


  return (
    <main className="flex-1 flex flex-col overflow-hidden bg-shade-500">
      <OfficeHeader />
      <div className="flex flex-1 overflow-y-auto">
        <div className="flex-1 mx-auto min-w-0  py-4 sm:py-6 overflow-hidden overflow-y-auto light flex">
          <TableComponent
            title={""}
            loading={loading}
            columns={[
              {
                name: "Claim Amount (100%)",
                accessor: "claim_amount",
                render: (value: GetOfferClaims_getOfferClaims) => <span>{value?.claim_amount} {value?.offer?.offer_detail?.currency}</span>,
              },
              {
                name: "Claim Date",
                accessor: "claim_date",
                render: (value: GetOfferClaims_getOfferClaims) => <span>{moment(value?.claim_date).format("DD/MM/YYYY")}</span>,
              },
              {
                name: "Created at",
                accessor: "created_at",
                render: (value: GetOfferClaims_getOfferClaims) => <span>{"N/A"}</span>,
              },
              {
                name: "Actions",
                accessor: "actions",
                render: (value: GetOfferClaims_getOfferClaims) => (
                  <>
                    <ActionButton action="view" onClick={expandItem(value?.offer_claim_id)} tooltip="View Claim's Share" />
                    <ActionButton action="update" onClick={() => {
                      navigate({
                        search: (old) => ({
                          ...old,
                          id: value.offer_claim_id,
                          offer_id: params.offer || "",
                          amount: value.claim_amount?.toString() || "",
                          from: value.claim_date?.toString() || "",
                          modal: "update",
                        }),
                      });
                    }} tooltip="Modify Claim" />
                    <ActionButton action="remove" onClick={dispatchAction(value?.offer_claim_id, "remove")} tooltip="Delete Claim" />
                  </>
                )
              },
            ]}
            data={claims}
            refetch={refetch}
            renderLoader={() => <TableLoader items={[1, 1, 1, 1]} />}
          />
        </div>
      </div>
      {searchParams.id && (
        <>
          <UpdateContainer
            open={modal === "update"}
            setOpen={(val: boolean) => {
              setModal(val ? "update" : undefined)
              navigate({
                search: (old) => ({
                  ...old,
                  id: undefined,
                  offer_id: undefined,
                  amount: undefined,
                  from: undefined,
                  modal: undefined,
                }),
              })
            }}
            refetch={refetch}
          />
          <ViewClaimContainer
            open={modal === "view"}
            setOpen={(val: boolean) => {
              setModal(val ? "view" : undefined)
              navigate({
                search: (old) => ({
                  ...old,
                  id: undefined,
                  modal: undefined,
                }),
              })
            }}
            refetch={refetch}
          />
          <RemoveClaimContainer
            open={modal === "remove"}
            setOpen={(val: boolean) => {
              setModal(val ? "remove" : undefined)
              navigate({
                search: (old) => ({
                  ...old,
                  id: undefined,
                  modal: undefined,
                }),
              })
            }}
            refetch={refetch}
          />
        </>
      )}
    </main>
  );
};

export default ViewClaimsPage;
