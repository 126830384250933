import { useMutation } from "@apollo/client";
import toast from "react-hot-toast";
import { PopUp } from "components";
import { useMatch, useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { removeReInsurerFromParticipation } from "_graphql/mutation/offers";
import { RemoveReInsurerFromList, RemoveReInsurerFromListVariables } from "_graphql/mutation/__generated__/RemoveReInsurerFromList";



export default function RemoveContainer({
    open,
    setOpen,
    refetch,
}: {
    open: boolean;
    setOpen: (val: boolean) => void;
    refetch?: () => void;
}) {
    const searchParams = useSearch<LocationGenerics>();
    const { params } = useMatch<LocationGenerics>();
    const [remove, { loading }] = useMutation<RemoveReInsurerFromList, RemoveReInsurerFromListVariables>(removeReInsurerFromParticipation, {
        refetchQueries: ["Offer"]
    });


    const handleSubmit = async () => {
        await remove({
            variables: {
                offer_id: params.offer || "",
                reinsurer_id: searchParams.id || "",
                offer_participating_id: searchParams.reinsurer || ""
            },
        }).then(({ data }) => {
            if (data?.removeParticipantByReinsurer) {
                toast.success("Participant Removed Successfully")
                refetch?.();
                setOpen?.(false);
            } else {
                toast.error("Could not remove participant");
            }
        }).catch((err) => {
            toast.error("Could not remove participant");
        });
    }

    return (
        <PopUp
            open={open}
            setOpen={setOpen}
            title="Delete Participant"
            cancelText="No, Don't Delete Participant"
            description="This action would completely remove participant completely from offer participation along with all the data associated with it. This action cannot be undone. Are you sure you want to continue?"
            renderActions={() => (
                <>
                    <button
                        type="button"
                        disabled={loading}
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={handleSubmit}
                    >
                        {loading ? "Deleting Participant..." : "Delete Participant"}
                    </button>
                </>
            )}
        />
    );
}
