import { wrapClick } from "utils";
import { useMutation } from "@apollo/client";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { Modal } from "components";
import * as Yup from "yup";
import { InsurerRepForm } from "components/forms";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { useEffect } from "react";
import { useInsurerManager } from "hooks/data/use-insurers";
import { updateInsurerRep } from "_graphql/mutation/insurer";
import { UpdateInsurerRep, UpdateInsurerRepVariables } from "_graphql/mutation/__generated__/UpdateInsurerRep";

export const insurerRepValidationSchema = Yup.object().shape({
    rep_first_name: Yup.string().required("First name is required"),
    rep_last_name: Yup.string().required("Last name is required"),
    rep_primary_phonenumber: Yup.string().required("Phone number is required"),
    rep_secondary_phonenumber: Yup.string().notRequired(),
    rep_email: Yup.string().required("Email is required"),
    position: Yup.string().required("Position is required"),
});

export default function UpdateManagerContainer({
    open,
    setOpen,
    refetch,
}: {
    open: boolean;
    setOpen: (val: boolean) => void;
    refetch?: () => void;
}) {
    const searchParams = useSearch<LocationGenerics>();
    const { insurer, loading: fetching } = useInsurerManager(searchParams?.id || "");
    const [create, { loading }] = useMutation<
        UpdateInsurerRep,
        UpdateInsurerRepVariables
    >(updateInsurerRep, {
        refetchQueries: ["GetInsurerAssociates", "Insurers", "GetInsurerAssociate"],
    });

    const form = useFormik<any>({
        initialValues: {
            insurersinsurer_id: searchParams?.id || "",
            rep_first_name: "",
            rep_last_name: "",
            rep_primary_phonenumber: "",
            rep_secondary_phonenumber: "",
            rep_email: "",
            position: "",
        },
        validationSchema: insurerRepValidationSchema,
        onSubmit: async (variables) => {
            await create({
                variables: {
                    insurerRep: {
                        insurersinsurer_id: insurer?.insurer_associate_id || "",
                        position: variables.position,
                        rep_email: variables.rep_email,
                        rep_first_name: variables.rep_first_name,
                        rep_last_name: variables.rep_last_name,
                        rep_primary_phonenumber: variables.rep_primary_phonenumber,
                        rep_secondary_phonenumber: variables.rep_secondary_phonenumber,
                    },
                    insurerRepId: searchParams?.id || "",
                },
            }).then(({ data }) => {
                if (data?.updateInsurerRep) {
                    toast.success("Insurer manager updated successfully");

                    refetch?.();
                    form.resetForm();
                } else {
                    toast.error("Failed to update Insurer manager");
                }
            });
        },
        onReset: () => {
            setOpen(false);
        },
    });

    useEffect(() => {
        if (insurer) {
            form.setValues({
                insurersinsurer_id: insurer.insurer_associate_id,
                rep_first_name: insurer.assoc_first_name,
                rep_last_name: insurer.assoc_last_name,
                rep_primary_phonenumber: insurer.assoc_primary_phonenumber,
                rep_secondary_phonenumber: insurer.assoc_secondary_phonenumber,
                rep_email: insurer.assoc_email,
                position: insurer.position,
            })
        }
    }, [insurer])

    return (
        <Modal
            open={open}
            setOpen={setOpen}
            loading={fetching}
            title="Update Insurer Manager"
            description=""
            renderActions={() => (
                <>
                    <button
                        type="button"
                        disabled={loading}
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={wrapClick(form.handleSubmit)}
                    >
                        {loading ? "Updating Re-Insurer Rep..." : "Update Re-Insurer Rep"}
                    </button>
                </>
            )}
        >
            {/* {JSON} */}
            <InsurerRepForm form={form} />
        </Modal>
    );
}
