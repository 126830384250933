import { } from '_graphql/queries/__generated__/GetOfferAssociates';
import { GetTreatyReinsurerParticipants_getTreatyReinsurerParticipants } from '_graphql/queries/__generated__/GetTreatyReinsurerParticipants';
import { ActionButton, Avatar, OfficeButton, Show, TableComponent, TableLoader } from 'components';
import { Action } from 'components/buttons/action-button';
import { useTreaty, useTreatyReinsurers } from 'hooks/data/use-treaties';
import { Link, useMatch, useNavigate, useSearch } from 'react-location';
import { LocationGenerics } from 'router/location';
import { classNames, useUrlState } from 'utils';
import _ from 'lodash';
import AddParticipantsPercentageContainer from './add-reinsurer-percentage';
import ViewTreatyClosingContainer from './view-reinsurer-closings';
import RemoveReinsurerContainer from './remove-reinsurer';
import ViewNonProportionalTreatyClosingContainer from './view-reinsurer-non-proportional-closing';

export default function TreatyReinsurers() {
    const [modal, setModal] = useUrlState("modal");
    const [activeTab] = useUrlState("layer");
    const { params } = useMatch<LocationGenerics>();
    const searchParams = useSearch<LocationGenerics>();
    const navigate = useNavigate<LocationGenerics>();
    const { loading, reinsurers, refetch } = useTreatyReinsurers({
        treatyId: params.treaty || "",
    });

    const { treaty, loading: featchingTreaty } = useTreaty({
        treatyId: params.treaty || "",
    })

    const dispatchAction =
        (id: string, action: Exclude<Action, "expand" | "goto">) =>
            () => {
                navigate({
                    search: (old) => ({
                        ...old,
                        id,
                        modal: action,
                    }),
                });
            };




    return (
        <div className=' flex flex-col flex-1'>
            <Show if={searchParams?.treaty_type === "NONPROPORTIONAL"}>
                <div className="block">
                    <div className="border-b border-shade-500 bg-card-500 px-6">
                        <nav className="-mb-px flex space-x-8 items-center" aria-label="Tabs">
                            {JSON.parse(treaty?.layer_limit || "[]").map((_activeTab: any, index: number) => (
                                <Link
                                    key={_activeTab.name}
                                    search={(old) => ({
                                        ...old,
                                        layer: (index + 1).toString(),
                                    })}
                                    className={classNames(
                                        activeTab === index + 1
                                            ? "border-gray-200 text-gray-300"
                                            : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200",
                                        "whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm"
                                    )}
                                    aria-current={
                                        activeTab === index + 1 ? "page" : undefined
                                    }
                                >
                                    Layer {index + 1}
                                </Link>
                            ))}
                            {/* <div className="h-6 w-px bg-gray-300" /> */}

                        </nav>
                    </div>
                </div>
            </Show>
            <div className="grid grid-cols-3 sm:py-6 items-start px-3 flex-1">
                <div className='col-span-3'>
                    <TableComponent
                        title={'reinsurers'}
                        hasSearch
                        loading={loading || featchingTreaty}
                        columns={[
                            {
                                accessor: "reinsurer_representative.reinsurer.re_company_name",
                                name: "Company Name",
                                render: (row: GetTreatyReinsurerParticipants_getTreatyReinsurerParticipants) => (
                                    <div className='flex space-x-2 items-start'>
                                        <Avatar alt={row?.reinsurer?.re_company_name || ""} />
                                        <div className='flex flex-col'>
                                            <span className='font-bold text-xs'>{row?.reinsurer?.re_company_name}</span>
                                            <span className='font-light text-xs'>{row?.reinsurer?.re_company_website}</span>
                                        </div>
                                    </div>
                                )
                            },
                            {
                                accessor: "reinsurer_representative.rep_first_name",
                                name: "Company Email",
                                render: (row: GetTreatyReinsurerParticipants_getTreatyReinsurerParticipants) => (
                                    <div className='flex flex-col'>
                                        <span className='font-bold text-xs'>{row?.reinsurer?.re_company_email}</span>
                                    </div>
                                )
                            },
                            {
                                accessor: "treaty_participation_percentage",
                                name: "Participation (%)",
                                render: (row: GetTreatyReinsurerParticipants_getTreatyReinsurerParticipants) => (
                                    <div className='flex flex-col'>
                                        <span className='font-bold text-xs'>{row?.treaty_participation_percentage ?? "0"}</span>
                                    </div>
                                )
                            },
                            {
                                accessor: "",
                                name: "Action",
                                render: (row: GetTreatyReinsurerParticipants_getTreatyReinsurerParticipants) => (
                                    <>
                                        <ActionButton action={'assignPercentage'} tooltip={"Assign Percentage"} onClick={dispatchAction(row?.treaty_participation_id || "", "assignPercentage")} />
                                        <Show if={!_.isEmpty(row?.treaty_participant_deductions)}>
                                            <ActionButton action={'clone'} tooltip={"View Treaty Closings"} onClick={dispatchAction(row?.treaty_participation_id || "", "clone")} />
                                        </Show>
                                        <ActionButton action={'remove'} tooltip={"Remove associate"} onClick={dispatchAction(row?.treaty_participation_id || "", "remove")} />
                                    </>
                                )
                            }
                        ]}
                        data={searchParams.treaty_type === "PROPORTIONAL" ? reinsurers : _.get(_.groupBy(reinsurers, "layer_number"), parseInt(searchParams.layer || "1"), [])}
                        renderLoader={() => <TableLoader items={[1, 1, 1, 1]} />}
                        renderHeaderItems={() => (
                            <OfficeButton size='sm' onClick={() => setModal("modify-deduction")}>
                                <span className="hidden sm:inline">Modify Deduction</span>
                            </OfficeButton>
                        )}
                        refetch={refetch} />
                </div>

                {searchParams.id &&
                    <>
                        <AddParticipantsPercentageContainer
                            open={modal === "assignPercentage"}
                            setOpen={(val: boolean) => {
                                setModal(val ? "assignPercentage" : undefined)
                                dispatchAction("", "assignPercentage")()
                                refetch?.()
                            }
                            } />
                        <ViewTreatyClosingContainer
                            open={modal === "clone" && searchParams?.treaty_type === "PROPORTIONAL"}
                            setOpen={(val: boolean) => {
                                setModal(val ? "assignPercentage" : undefined)
                                dispatchAction("", "assignPercentage")()
                                refetch?.()
                            }
                            } />
                        <ViewNonProportionalTreatyClosingContainer
                            open={modal === "clone" && searchParams?.treaty_type === "NONPROPORTIONAL"}
                            setOpen={(val: boolean) => {
                                setModal(val ? "assignPercentage" : undefined)
                                dispatchAction("", "assignPercentage")()
                                refetch?.()
                            }
                            } />
                    </>
                }
                <RemoveReinsurerContainer
                    open={modal === "remove"}
                    setOpen={(val: boolean) =>
                        setModal(val ? "remove" : undefined)
                    } />
            </div>
        </div >

    )
}
