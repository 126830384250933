import { GetOfferAssociates_getOfferAssociates } from '_graphql/queries/__generated__/GetOfferAssociates';
import { Offer_findSingleOffer_offer_participant } from '_graphql/queries/__generated__/Offer';
import { message } from 'antd';
import clsx from 'clsx';
import { ActionButton, Avatar, TableComponent, TableLoader } from 'components';
import { useOfferParticipantAssociates } from 'hooks/data/use-offers';
import { useMatch, useNavigate, useSearch } from 'react-location';
import { LocationGenerics } from 'router/location';
import RemoveAssociateContainer from './remove-participant-associate';
import { useUrlState } from 'utils';
import { Action } from 'components/buttons/action-button';

export default function OfferAssociates() {
    const [modal, setModal] = useUrlState("modal");
    const { params } = useMatch<LocationGenerics>();
    const searchParams = useSearch<LocationGenerics>()
    const navigate = useNavigate<LocationGenerics>();
    const { loading, associates, refetch } = useOfferParticipantAssociates({
        offerId: params.offer || "",
    });

    const dispatchAction =
        (id: string, action: Exclude<Action, "expand" | "goto" | "clone">, participation_id?: string) =>
            () => {
                navigate({
                    search: (old) => ({
                        ...old,
                        id,
                        reinsurer: participation_id,
                        modal: action,
                    }),
                });
            };

    return (
        <div className="grid grid-cols-3 items-start px-3 flex-1">
            <div className='col-span-3'>
                <TableComponent
                    title={'associates'}
                    hasSearch
                    loading={loading}
                    columns={[
                        {
                            accessor: "reinsurer_representative.reinsurer.re_company_name",
                            name: "Company",
                            render: (row: GetOfferAssociates_getOfferAssociates) => (
                                <div className='flex space-x-2 items-start'>
                                    <Avatar alt={row?.reinsurer_representative?.reinsurer?.re_company_name || ""} />
                                    <div className='flex flex-col'>
                                        <span className='font-bold text-xs'>{row?.reinsurer_representative?.reinsurer?.re_company_name}</span>
                                        <span className='font-light text-xs'>{row?.reinsurer_representative?.reinsurer?.re_company_email}</span>
                                    </div>
                                </div>
                            )
                        },
                        {
                            accessor: "reinsurer_representative.rep_first_name",
                            name: "Associate",
                            render: (row: GetOfferAssociates_getOfferAssociates) => (
                                <div className='flex flex-col'>
                                    <span className='font-bold text-xs'>{row?.reinsurer_representative?.rep_first_name} {row?.reinsurer_representative?.rep_last_name}</span>
                                    <span className='font-light text-xs'>{row?.reinsurer_representative?.rep_email}</span>
                                </div>
                            )
                        },

                        {
                            accessor: "sent_status",
                            name: "Email Status",
                            render: (row: GetOfferAssociates_getOfferAssociates) => (
                                <>
                                    <span className={clsx("p-0.5 px-2 text-xs rounded-full", {
                                        "bg-red-100 text-red-500": row?.sent_status === "UNSENT"
                                    })}>{row?.sent_status || "N/A"}</span>
                                </>
                            )

                        },
                        {
                            accessor: "",
                            name: "Actions",
                            render: (row: GetOfferAssociates_getOfferAssociates) => (
                                <>
                                    <ActionButton action={'remove'} tooltip={"Remove associate"} onClick={dispatchAction(row?.offer_to_associate_id || "", "remove")} />
                                </>
                            )
                        }
                    ]}
                    data={associates || []}
                    renderLoader={() => <TableLoader items={[1, 1, 1, 1]} />}
                    refetch={refetch} />
            </div>

            {searchParams?.id && (
                <RemoveAssociateContainer
                    open={modal === "remove"}
                    setOpen={(val: boolean) =>
                        setModal(val ? "remove" : undefined)
                    }
                />
            )}
        </div>
    )
}
