import { FC } from "react";
import BarLoader from "react-spinners/BarLoader";

interface LoaderProps {
  color?: string;
}

const Loader: FC<LoaderProps> = ({ color = "#2563EB" }) => {
  return (
    <div className="flex-1 flex flex-col space-y-6 items-center justify-center">
      <span className="text-gray-600 text-sm">Loading details...</span>
      <BarLoader
        color={color}
        loading={true}
        cssOverride={{ width: "30%" }}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
  );
};

export default Loader;
