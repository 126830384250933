import { wrapClick } from "utils";
import { useMutation } from "@apollo/client";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { Modal } from "components";
import * as Yup from "yup";
import { PercentageForm } from "components/forms";
import { addReInsurerPercentage } from "_graphql/mutation/offers";
import { useMatch, useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { useOffer, useOfferParticipant } from "hooks/data/use-offers";
import { useEffect } from "react";
import {
  AddPercentage,
  AddPercentageVariables,
} from "_graphql/mutation/__generated__/AddPercentage";
import { min } from "lodash";

export const valiadationSchema = Yup.object().shape({
  expected: Yup.number().min(0).required(),
  percentage: Yup.number().min(0, "Percentage must be greater than 0").max(Yup.ref("expected"), "Percentage must be less than or same percentage left")
    .typeError("Must be a number")
    .required("Percentage is required"),
});

export default function AddParticipantsPercentageContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const { params } = useMatch<LocationGenerics>();
  const searchParams = useSearch<LocationGenerics>();
  const { participant, loading: fetching } = useOfferParticipant({
    participantId: searchParams.id || "",
  });
  const { loading: loadingOffer, offer } = useOffer({
    offer_id: params?.offer || ''
  });

  const [create, { loading }] = useMutation<
    AddPercentage,
    AddPercentageVariables
  >(addReInsurerPercentage, {
    refetchQueries: ["Offer"],
  });

  const form = useFormik<any>({
    initialValues: {
      percentage: 0,
      expected: 0
    },
    validationSchema: valiadationSchema,
    onSubmit: async (variables) => {
      await create({
        variables: {
          offer_id: params.offer || "",
          offer_participant_id: searchParams.id || "",
          percentage: variables.percentage,
        },
      }).then(({ data }) => {
        toast.success("Percentage updated Successfully");
        refetch?.();
        form.resetForm();
      });
    },
    onReset: () => {
      setOpen(false);
    },
  });

  useEffect(() => {
    if (participant && searchParams?.reinsurer) {
      form.setFieldValue(
        "percentage",
        participant?.offer_participant_percentage
      );
      // const remainingPercentages =
      form.setFieldValue("expected", parseFloat(searchParams?.reinsurer || "0.00"))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [participant, searchParams.reinsurer]);

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title={"Add Percentage to " + participant?.reinsurer?.re_company_name}
      loading={fetching || loadingOffer}
      description="Add percentage for this ReInsurer"
      renderActions={() => (
        <>
          <button
            type="button"
            disabled={loading}
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(form.handleSubmit)}
          >
            {loading ? "Saving changes..." : "Save changes"}
          </button>
        </>
      )}
    >
      <PercentageForm form={form} />
    </Modal>
  );
}
