import { FormItem } from "components/core/step-form-builder";
import OfferForm from "./offer-form";
import OfferFormSummary from "./offer-form-summary";
import EndorsementForm from "./endorsement-form";
import EndorsementFormSummary from "./endorsement-form-summary";


export const createTreatyFormItems: FormItem[] = [
    {
        accessor: "offer",
        description: "Select offer to add new endorsement",
        name: "Offer Details",
        FormComponent: OfferForm,
        SummaryComponent: OfferFormSummary,
    },
    {
        accessor: "details",
        description: "Provide details to add new endorsement",
        name: "Endorsement Details",
        FormComponent: EndorsementForm,
        SummaryComponent: EndorsementFormSummary,
    },

];