import { gql } from "@apollo/client";

export const getTreaties = gql`
  query GetTreaties($args: TreatyFilters) {
    rows: getTreaties(args: $args) {
      treaty_id
      treaty_reference
      kek_reference
      treaty_details
      currency
      order_hereon
      treaty_payment_status
      layer_limit
      insurer {
        insurer_id
        insurer_company_name
        insurer_company_email
        insurer_company_website
        insurer_abbrv
      }
      treaty_program {
        treaty_name
        treaty_type
      }
      treaty_deduction {
        treaty_period_from
        treaty_period_to
      }
      employee {
        employee_first_name
        employee_last_name
      }
    }
    count: getTreatiesCount(args: $args)
  }
`;
export const getNonProportionalTreatyPayments = gql`
  query GetNonProportionalTreatyPayments($filter: TreatyPaymentFilter!) {
    getNonProportionalTreatyPayments(filter: $filter) {
      uuid
      installment_type
      treaty_payment_amount
      treaty_payment_details
      treaty_payment_comment
      created_at
      updated_at
      treaty_n_p_payment_id
    }
  }
`;
export const getNonProportionalTreatyPayment = gql`
query GetNonProportionalTreatyPayment($treatyNpPaymentId: ID!) {
  getNonProportionalTreatyPayment(treaty_np_payment_id: $treatyNpPaymentId) {
    uuid
    installment_type
    treaty_payment_amount
    treaty_payment_details
    treaty_payment_comment
    created_at
    updated_at
    treaty_n_p_payment_id
  }
}

`;
export const getProportionalTreatyPayments = gql`
  query GetProportionalTreatyPayments($filter: TreatyPaymentFilter!) {
    getProportionalTreatyPayments(filter: $filter) {
      treaty_p_payment_id
      treaty_payment_amount
      treaty_payment_details
      treaty_payment_comment
      created_at
      updated_at
      treaty_accountstreaty_account_id
      surpulus_uuid
    }
  }
`;

export const getTreaty = gql`
  query Treaty($treatyId: ID) {
  treaty(treaty_id: $treatyId) {
    treaty_id
    treaty_reference
    kek_reference
    treaty_details
    currency
    order_hereon
    treaty_payment_status
    layer_limit
    insurer {
      insurer_company_name
      insurer_id
      insurer_abbrv
    }
    treaty_program {
      treaty_name
      treaty_program_id
      treaty_type
    }
    treaty_deduction {
      treaty_associate_deduction_id
      commission
      brokerage
      nic_levy
      withholding_tax
      treaty_period_from
      treaty_period_to
    }
    treaty_to_associates {
      treaty_to_associate_id
      sent_status
      layer_number
      reinsurer_representative {
        rep_first_name
        rep_last_name
        rep_primary_phonenumber
        rep_secondary_phonenumber
        rep_email
        position
      }
    }
    treaty_participants {
      treaty_participation_id
      treaty_participation_percentage
      layer_number
      reinsurer {
        re_company_email
        re_company_name
        re_company_website
        reinsurer_id
      }
      treaty_participant_deductions {
        treaty_participant_deduction_id
        treaty_accountstreaty_account_id
        treaty_participationstreaty_participation_id
        uuid
        brokerage
        brokerage_amount
        commission_amount
        nic_levy_amount
        withholding_tax_amount
        treaty_participation_share
        nic_levy
        withholding_tax
        commission
      }
    }
    re_broker_treaties_participation {
      re_broker_treaties_participation_id
      share_percentage
      admin_percentage
      payment_status
      re_broker {
        re_broker_id
        re_broker_email
        re_broker_name
        re_broker_website
      }
    }
    treaty_accounts {
      treaty_account_id
      account_periods
      gross_premium
      claim_settled
      cash_loss
      exchange_rate
      account_year
      payment_status
      treaty_account_deduction {
        treaty_account_deduction_id
        surpulus_uuid
        gross_premium
        commission_amount
        brokerage_amount
        claim_settled
        cash_loss
        withholding_tax_amount
        nic_levy_amount
      }
    }
    treaty_np_detail {
      treaty_np_id
      egrnpi
      burning_cost_rate
      loaded_burning_cost
      pure_burning_cost
      adjustment_created
      outstanding_payment
      claims_paid
      created_at
    }
    treaty_proportional_detail {
      treaty_proportional_detail_id
      profit_commission
      re_mgmt_expense
      portfolio_entry
      ernpi
      overall_gross_premium
      losses_outstanding
      created_at
    }
  }
}


`;

export const getTreatyBrokersParticipants = gql`
  query GetTreatyBrokersParticipants($treatyId: ID) {
    getTreatyBrokersParticipants(treaty_id: $treatyId) {
      re_broker_treaties_participation_id
      share_percentage
      admin_percentage
      payment_status
      re_broker {
        re_broker_id
        re_broker_name
        re_broker_email
        re_broker_website
      }
    }
  }
`;

export const getTreatyReinsurerParticipants = gql`
 query GetTreatyReinsurerParticipants($treatyId: ID) {
  getTreatyReinsurerParticipants(treaty_id: $treatyId) {
    treaty_participation_id
    treaty_participation_percentage
    layer_number
    reinsurer {
      re_company_email
      re_abbrv
      re_company_name
      re_company_website
      reinsurer_id
    }
    treaty_participant_deductions {
      treaty_participant_deduction_id
      treaty_accountstreaty_account_id
      treaty_participationstreaty_participation_id
      uuid
      brokerage
      brokerage_amount
      commission_amount
      nic_levy_amount
      withholding_tax_amount
      treaty_participation_share
      nic_levy
      withholding_tax
      commission
    }
  }
}

`;

export const getTreatyReinsurerAssociates = gql`
  query GetTreatyReinsurerAssociates($treatyId: ID) {
    getTreatyReinsurerAssociates(treaty_id: $treatyId) {
      treaty_to_associate_id
      sent_status
      layer_number
      reinsurer_representative {
        reinsurer_representative_id
        reinsurersreinsurer_id
        rep_first_name
        rep_last_name
        rep_primary_phonenumber
        rep_secondary_phonenumber
        rep_email
        position
      }
      reinsurer {
        re_company_name
        re_company_email
      }
    }
  }
`;
export const getTreatyReinsurerParticipation = gql`
  query GetTreatyReinsurerParticipation($participationId: ID) {
    getTreatyReinsurerParticipation(participation_id: $participationId) {
      treaty_participation_id
      treaty_participation_percentage
      layer_number
      reinsurer {
        re_company_name
        reinsurer_id
      }
    }
  }
`;

export const getTreatyBrokerParticipation = gql`
  query GetTreatyBrokerParticipation($reBrokerTreatiesParticipationId: ID) {
    getTreatyBrokerParticipation(
      re_broker_treaties_participation_id: $reBrokerTreatiesParticipationId
    ) {
      re_broker_treaties_participation_id
      share_percentage
      admin_percentage
      payment_status
      re_broker {
        re_broker_name
        re_broker_id
      }
    }
  }
`;
export const getTreatyBrokerAssociates = gql`
  query GetTreatyBrokerAssociates($treatyId: ID) {
    getTreatyBrokerAssociates(treaty_id: $treatyId) {
      participation_to_broker_associate_id
      sent_status
      message_content
      broker_associate {
        re_broker_associate_id
        re_broker_assoc_first_name
        re_broker_assoc_last_name
        re_broker_assoc_primary_phone
        re_broker_assoc_secondary_phone
        re_broker_assoc_position
        re_broker_assoc_email
      }
    }
  }
`;

export const fetchTreatyAccounts = gql`
  query FetchTreatyAccounts(
    $insurer_id: ID
    $treaty_program_name: String
    $treaty_period_from: Date
    $treaty_period_to: Date
    $type: Boolean
    $quarter: String
  ) {
    fetchTreatyAccounts(
      insurer_id: $insurer_id
      treaty_period_to: $treaty_period_to
      treaty_period_from: $treaty_period_from
      type: $type
      treaty_program_name: $treaty_program_name
      quarter: $quarter
    ) {
      treaty_id
      currency
      quarters
      quarter
      currency
      account_year
      layer_limit
      surpluses {
        gross_premium
        claim_settled
        cash_loss
      }
    }
  }
`;
