import { Insurer_insurer } from "_graphql/queries/__generated__/Insurer";
import { Offer_findSingleOffer } from "_graphql/queries/__generated__/Offer";
import { SingleClassOfBusiness_singleClassOfBusiness } from "_graphql/queries/__generated__/SingleClassOfBusiness";
import { Avatar } from "components/core";
import _ from "lodash";
import React, { FC } from "react";

type Props = {
  business: SingleClassOfBusiness_singleClassOfBusiness | null | undefined;
};

const BusinessView: FC<Props> = ({ business }) => {
  const offerDetails = JSON.parse(business?.business_details || "[]") || [];
  return (
    <div className="space-y-6 divide-y divide-gray-200">
      <div>
        <span className="text-xs font-light">Offer Information</span>
        <div className="grid grid-cols-3 gap-6 mt-2">
          <div className="col-span-2">
            <span className="block text-sm font-light text-gray-700">
              Cedant Name
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {business?.business_name || "N/A"}
            </div>
          </div>
          <div className=" gap-6 mt-2">
            <div>
              <span className="block text-sm font-light text-gray-700">
                Business Name
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {business?.business_category || "N/A"}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pt-6">
        <span className="text-xs font-light">Business Class Details</span>
        <div className="mt-1 w-full ">
          <table className="min-w-full divide-y divide-gray-300 border border-gray-300 rounded-md">
            <thead className="bg-gray-50">
              <tr className="divide-x divide-gray-200">
                <th
                  scope="col"
                  className="whitespace-nowrap px-2 py-2 text-left text-sm font-semibold text-gray-900"
                >
                  Detail
                </th>
                <th
                  scope="col"
                  className="whitespace-nowrap px-2 py-2 text-right text-sm font-semibold text-gray-900"
                >
                  Type
                </th>
                <th
                  scope="col"
                  className="whitespace-nowrap px-2 py-2 text-left text-sm font-semibold text-gray-900"
                >
                  Required
                </th>
              </tr>
            </thead>
            {/* {JSON.stringify(offerDetails)} */}
            <tbody className="divide-y divide-gray-200 bg-white">
              {offerDetails?.map((step: any, idx: string) => (
                <tr key={idx} className="divide-x divide-gray-200">
                  <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-700">
                    {step?.keydetail}
                  </td>
                  <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 text-right">
                    {step?.type || "Text"}
                  </td>
                  <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                    {step?.required || "Yes"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default BusinessView;
