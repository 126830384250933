import { Modal, PDFRenderer } from "components";
import { useMatch } from "react-location";
import { LocationGenerics } from "router/location";
import config from "config";
import _ from "lodash";
import { useTreaty, useTreatyReinsurer } from "hooks/data/use-treaties";
import { classNames, wrapClick } from "utils";
import { useEffect, useMemo, useState } from "react";
import { useAppSearch } from "_graphql/cache/auth";

export default function ViewNonProportionalTreatyClosingContainer({
    open,
    setOpen,
}: {
    open: boolean;
    setOpen: (val: boolean) => void;
}) {
    const searchParams = useAppSearch();
    const [orderTab, setOrderTab] = useState("");

    const { params } = useMatch<LocationGenerics>();
    useMatch<LocationGenerics>();
    // const { reInsurer, loading: fetchingLoading } = useReInsurer(searchParams?.id || "");
    const { participant, loading: fetchingParticipant } = useTreatyReinsurer({ participationId: searchParams?.id || "" });
    const { treaty, loading } = useTreaty({
        treatyId: params?.treaty
    });

    const __setOrderTab = (tab: string) => () => {
        setOrderTab(tab);
    };

    useEffect(() => {
        if (treaty) {
            const layerLimit: any[] = JSON.parse(treaty?.layer_limit || "[]");
            setOrderTab(_.first(layerLimit)?.uuid || "")
        }
    }, [treaty, treaty?.layer_limit])

    const layer = useMemo(() => JSON.parse(treaty?.layer_limit || '[]')?.find((el: any) => el?.uuid === orderTab), [orderTab, treaty?.layer_limit])
    // const layer_number = useMemo(() => JSON.parse(treaty?.layer_limit || '[]')?.findIndex((el: any) => el?.uuid === orderTab), [orderTab])

    return (
        <Modal
            open={open}
            setOpen={setOpen}
            size="6xl"
            hidePadding
            loading={loading || fetchingParticipant}
            title={"Treaty Closings"}
            description={`Details of Debit Note are shown below`}
        >
            <div className='block'>
                <div className='border-b border-gray-200 bg-white px-6'>
                    <nav className='-mb-px flex space-x-8' aria-label='Tabs'>
                        {JSON.parse(treaty?.layer_limit || "[]")?.map((_orderTab: any, index: number) => (
                            <button
                                key={_orderTab?.uuid}
                                onClick={wrapClick(__setOrderTab(_orderTab?.uuid || ""))}
                                className={classNames(
                                    orderTab === _orderTab?.uuid
                                        ? "border-primary-500 text-primary-600"
                                        : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200",
                                    "whitespace-nowrap disabled:cursor-not-allowed flex py-4 px-1 border-b-2 font-medium text-sm"
                                )}
                                aria-current={
                                    orderTab === _orderTab?.uuid ? "page" : undefined
                                }
                            >
                                Layer {index + 1}
                            </button>
                        ))}
                    </nav>
                </div>
            </div>
            {/* {JSON.stringify(layer, null, 2)} */}
            {orderTab && (
                <div className="p-3">
                    <PDFRenderer
                        url={`${config.asset.uri}treaty_np_credit_note/${btoa(
                            JSON.stringify({
                                // treaty_id: treaty?.treaty_id,
                                limit: layer?.limit,
                                layer: participant?.layer_number,
                                m_and_d_premium: layer?.m_and_d_premium,
                                installment_type: layer?.installment_type,
                                participant_id: participant?.treaty_participation_id,
                                uuid: layer?.uuid,
                                total_participation_percentage:
                                    participant?.treaty_participation_percentage,
                            })
                        )}`}
                    />
                </div>
            )}

        </Modal>
    );
}
