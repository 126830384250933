import { gql } from "@apollo/client";


export const getClassOfBusinesses = gql`
query ClassOfBusinesses($category: String) {
  rows: classOfBusinesses(category: $category) {
    class_of_business_id
    business_name
    business_details
    business_category
    created_at
  }
  count: getClassOfBusinessCount
}
`;

export const getBusinessCategories = gql`
  query GetBusinessCategories {
    getBusinessCategories {
    name
  }
}
`;

export const getClassOfBusiness = gql`
query SingleClassOfBusiness($classOfBusinessId: ID!) {
  singleClassOfBusiness(class_of_business_id: $classOfBusinessId) {
    class_of_business_id
    business_name
    business_details
    business_category
    created_at
  }
}
`;