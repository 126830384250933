import { useMutation } from '@apollo/client';
import { useAppLocation, useAppSearch } from '_graphql/cache/auth';
import { MakeProportionalPayment, MakeProportionalPaymentVariables } from '_graphql/mutation/__generated__/MakeProportionalPayment';
import { makeProportionalPayment } from '_graphql/mutation/payment';
import { Modal } from 'components';
import { ProportionalPaymentForm } from 'components/forms';
import { useFormik } from 'formik';
import { useProportionalPayments, useTreaty } from 'hooks/data/use-treaties';
import _ from 'lodash';
import numeral from 'numeral';
import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { getSumOFPPayments, wrapClick } from 'utils';
import * as yup from 'yup';



const formSchema = yup.object().shape({
    payment_type: yup.string().required("Payment type is required"),
    auto_payment_receipt: yup.boolean(),
    layer_limit: yup.string().required("Layer limit is required"),
    payment_from: yup.object().shape({
        bank_name: yup.string().required("Bank name is required"),
        cheque_number: yup.string().when("$payment_type", {
            is: (val: string) => val === "Cheque",
            then(schema) {
                return schema.required("Cheque number is required");
            },
            otherwise(schema) {
                return schema.notRequired();
            },
        }),
        date_on_cheque: yup.string().when("$payment_type", {
            is: (val: string) => val === "Cheque",
            then(schema) {
                return schema.required("Date on cheque is required");
            },
            otherwise(schema) {
                return schema.notRequired();
            },
        }),
    }),
    offer_payment_comment: yup.string(),
    payment_to: yup.string().required("Payment to is required"),
    payment_details: yup.object().shape({
        payment_amount: yup.number().required("Payment amount is required")
            .min(0, "Payment amount should not be less than 0")
            .test('max-payment', 'Payment amount should not be more than amount to pay', function (value) {
                const { amountToPay, payment_details: { conversion: { rate } } } = this.options.context as any;
                return value <= amountToPay * rate;
            }),
        currency: yup.string().required("Currency is required"),
        conversion: yup.object().shape({
            addExchangeRate: yup.boolean(),
            rate: yup.number().required("Rate is required"),
            currency: yup.string().when("addExchangeRate", {
                is: true,
                then(schema) {
                    return schema.required("Currency is required");
                },
                otherwise(schema) {
                    return schema.notRequired();
                },
            })
        }),
        // amountToPay: yup.number().required("Amount to pay is required")
    }),
});


type Props = {
    open: boolean;
    setOpen: (val: boolean) => void;
    refetch?: () => void;
}

const CreateNonProportionalPaymentCOntainer = ({ open, setOpen }: Props) => {
    const { params } = useAppLocation();
    const searchParams = useAppSearch()

    const { loading: fetchingTreaty, treaty } = useTreaty({
        treatyId: params?.treaty,
    })
    const { payments, loading: fetchingPayments } = useProportionalPayments({
        filter: {
            treaty_id: params?.treaty,
        }
    })
    const [create, { loading }] = useMutation<MakeProportionalPayment, MakeProportionalPaymentVariables>(makeProportionalPayment, {
        refetchQueries: ["GetProportionalTreatyPayments"]
    })
    const form = useFormik({
        initialValues: {
            payment_type: "",
            auto_payment_receipt: false,
            amountToPay: 2300,
            layer_limit: "",
            payment_from: {
                bank_name: "",
                cheque_number: "",
                date_on_cheque: "",
            },
            offer_payment_comment: "",
            payment_to: "",
            payment_details: {
                payment_amount: 0,
                currency: "",
                conversion: {
                    rate: 1,
                    currency: "",
                    addExchangeRate: false
                },
            },
        },
        validationSchema: formSchema,
        onSubmit: value => {
            create({
                variables: {
                    payment: {
                        payment_amount: value.payment_details.payment_amount,
                        payment_status: value.amountToPay > value.payment_details.payment_amount ? "UNPAID" : " PAID",
                        auto_payment_receipt: value.auto_payment_receipt,
                        // installment_type: parseInt(value.installment_type),
                        payment_comment: value.offer_payment_comment,
                        treaty_account_id: value.layer_limit,
                        treaty_id: params?.treaty,
                        // uuid: value.layer_limit,
                        payment_details: JSON.stringify({
                            payment_type: value.payment_type,
                            payment_from: {
                                cheque_number: value.payment_from.cheque_number
                                    ? value.payment_from.cheque_number
                                    : "N/A",
                                bank_name: value.payment_from.bank_name,
                                date_on_cheque: value.payment_from.date_on_cheque
                                    ? value.payment_from.date_on_cheque
                                    : "N/A",
                            },
                            payment_to:
                                value.payment_type === "Bank Transfer"
                                    ? value.payment_to
                                    : "",
                            conversion: {
                                rate: value.payment_details.conversion.rate,
                                currency: value.payment_details.conversion.currency,
                                addExchangeRate: value.payment_details.conversion.addExchangeRate,
                            },
                        })
                    }
                }
            }).then(res => {
                if (res.data?.makeProportionalPayment) {
                    toast.success("Payment recorded successfully");
                    form.resetForm();
                    setOpen?.(false);
                } else {
                    toast.error("Payment could not be recorded")

                }
            }).catch(error => {
                console.log("ERROR", error)
                toast.error("Payment could not be recorded")
            })
        }
    })


    useEffect(() => {
        form.setFieldValue("payment_details.payment_amount", 0);
        if (form.values.layer_limit) {
            const oldPayments = getSumOFPPayments({
                treaty_np_payments: payments,
                treaty_p_payment_id: searchParams.payment_id,
                treaty_account_id: form.values.layer_limit
            });
            const layer = treaty?.treaty_accounts?.find(el => el?.treaty_account_id === form.values.layer_limit);
            const claim_settled_amount = layer?.treaty_account_deduction?.reduce((acc, curr) => {
                if (curr?.claim_settled) {
                    return acc + curr.claim_settled;
                }
                return acc;
            }, 0);
            const keyed1 = _.keyBy(layer?.treaty_account_deduction, 'surpulus_uuid');
            const keyed2 = _.keyBy(JSON.parse(treaty?.layer_limit || '[]'), 'surpulus_uuid');

            const surpluses = _.values(_.merge(keyed1, keyed2));
            console.log("Surpluses", surpluses, layer, treaty?.layer_limit)
            const value = surpluses.reduce((acc, curr) => acc + numeral(curr?.gross_premium).subtract(curr?.commission_amount).subtract(claim_settled_amount).subtract(curr?.cash_loss).value() || 0, 0)
            const amountToPay = numeral(value).subtract(oldPayments).value() || 0;
            console.log("Amount to be paid", amountToPay, value, oldPayments, claim_settled_amount);
            form.setFieldValue("amountToPay", amountToPay <= 0 ? 0 : numeral(amountToPay).multiply(form.values.payment_details.conversion.rate).value());
        }
    }, [
        form.values.layer_limit,
        form.values.payment_details.conversion.addExchangeRate,
        form.values.payment_details.conversion.rate,
    ]);


    return (
        <Modal
            open={open}
            setOpen={setOpen}
            loading={fetchingTreaty || fetchingPayments}
            title="Make payment"
            description="Make a payment to the treaty."
            renderActions={() => (
                <>
                    <button
                        type="button"
                        disabled={loading}
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={wrapClick(form.handleSubmit)}
                    >
                        {loading ? "Please wait ..." : "Make Payment"}
                    </button>
                </>
            )}
        >
            <ProportionalPaymentForm form={form} treaty={treaty} amountToPay={form.values.amountToPay} />
        </Modal>
    )
}

export default CreateNonProportionalPaymentCOntainer