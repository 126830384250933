import { Modal, ReinsurerRepView } from "components";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { useReInsurerRep } from "hooks/data/use-re-insurers";

export default function ViewReinsurerAssociateContainer({
    open,
    setOpen,
}: {
    open: boolean;
    setOpen: (val: boolean) => void;
}) {
    const searchParams = useSearch<LocationGenerics>();
    const { associate, loading } = useReInsurerRep({
        repId: searchParams.id as string,
    });

    return (
        <Modal
            open={open}
            setOpen={setOpen}
            loading={loading}
            title={"Reinsurer Associate Information - " + associate?.rep_first_name + " " + associate?.rep_last_name}
            description="Details of reinsurer associate are shown below"
        >
            <ReinsurerRepView reInsurer={associate} />
        </Modal>
    );
}
