import { useQuery } from "@apollo/client";
import { GetPaidChequesForStatement } from "_graphql/queries/__generated__/GetPaidChequesForStatement";
import { getStatementPaymennts } from "_graphql/queries/reports";

export const useStatementPaidCheques = () => {
  const { data, loading, error } = useQuery<GetPaidChequesForStatement>(
    getStatementPaymennts
  );
  return {
    cheques: data?.getPaidChequesForStatement ?? [],
    loadingCheques: loading,
    error,
  };
};
