import { useQuery } from "@apollo/client";
import { Broker, BrokerVariables } from "_graphql/queries/__generated__/Broker";
import { GetNotifications, GetNotificationsVariables } from "_graphql/queries/__generated__/GetNotifications";
import { getBroker } from "_graphql/queries/brokers";
import { getNotifications } from "_graphql/queries/notifications";

export const useNotifications = (variables?: GetNotificationsVariables) => {
  const { data, ...rest } = useQuery<GetNotifications, GetNotificationsVariables>(getNotifications, {
    variables,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });
  return {
    notifications: data?.getNotifications || [],
    length: data?.getNotificationsCount || 0,
    ...rest,
  };
};

export const useBroker = (id: string) => {
  const { data, ...rest } = useQuery<Broker, BrokerVariables>(getBroker, {
    variables: {
      brokerId: id ?? "",
    },
    notifyOnNetworkStatusChange: true,
  });
  return {
    notifications: data?.broker,
    ...rest,
  };
};
