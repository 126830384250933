import { gql } from "@apollo/client";

export const createInsurer = gql`
  mutation CreateInsurer($insurer: Insurer_data!) {
    createInsurer(insurer: $insurer) {
      insurer_id
    }
  }
`;
export const updateReinsurerPayment = gql`
  mutation UpdateReinsurerPayment(
    $offer_participant_payment_id: ID!
    $paid_details: String
    $Offer_payment_comment: String
  ) {
    updateReinsurerPaymentDetails(
      offer_participant_payment_id: $offer_participant_payment_id
      paid_details: $paid_details
      offer_participant_payment_comment: $Offer_payment_comment
    )
  }
`;

export const updateInsurer = gql`
  mutation UpdateInsurer($insurerId: ID!, $insurer: Insurer_data!) {
    updateInsurer(insurer_id: $insurerId, insurer: $insurer)
  }
`;

export const removeInsurer = gql`
  mutation RemoveInsurer($insurerId: ID!) {
    removeInsurer(insurer_id: $insurerId)
  }
`;

export const createInsurerRep = gql`
  mutation CreateInsurerRep($insurerRep: Insurer_associate_data!) {
    createInsurerRep(insurer_rep: $insurerRep) {
      insurer_associate_id
    }
  }
`;

export const updateInsurerRep = gql`
mutation UpdateInsurerRep(
  $insurerRepId: ID!
  $insurerRep: Insurer_associate_data!
) {
  updateInsurerRep(insurer_rep_id: $insurerRepId, insurer_rep: $insurerRep)
}

`;
export const removeInsurerRep = gql`
mutation RemoveInsurerRep($insurerRepId: ID!) {
  removeInsurerRep(insurer_rep_id: $insurerRepId)
}
`;


export const updateReBrokerAssociates = gql`
mutation UpdateReBrokerAssociates(
  $reBrokerAssocId: ID
  $reBrokerAssociate: ReBrokerAssociateInput
) {
  updateReBrokerAssociates(
    re_broker_assoc_id: $reBrokerAssocId
    re_broker_associate: $reBrokerAssociate
  )
}

`;

