import { OfficeHeader } from "components";
import { useInsurer } from "hooks/data/use-insurers";
import _ from "lodash";
import { FC } from "react";
import { Link, useMatch, useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { classNames, useUrlState } from "utils";
import ViewInsurerOfferContainer from "./view";
import InsurerOffers from "./offers";
import Associates from "./associates";
import ViewInsurerAssociateContainer from "./view-associate";
import UpdateManagerContainer from "./update-associates";
import RemoveContainer from "./remove-associate";

type Props = {};
const tabs = [{ name: "Associates", href: "associates" }];

const InsurerPage: FC<Props> = () => {
  const { params } = useMatch<LocationGenerics>();
  const searchParams = useSearch<LocationGenerics>();
  const [modal, setModal] = useUrlState("modal");
  const [orderStatus] = useUrlState("orderStatus");

  const { insurer, loading: loadingInsurer } = useInsurer(
    params.insurer as string
  );

  return (
    <main className="flex-1 flex flex-col overflow-hidden bg-shade-500">
      <OfficeHeader />
      <header>
        {/* Secondary navigation */}
        {/* Heading */}
        <div className="flex flex-col items-start justify-between gap-x-8 gap-y-4 bg-shade-500 px-4 py-4 sm:flex-row sm:items-center sm:px-6 lg:px-8">
          <div>
            <div className="flex items-center gap-x-3">
              <div className="flex-none rounded-full bg-green-400/10 p-1 text-green-400">
                <div className="h-2 w-2 rounded-full bg-current" />
              </div>
              <h1 className="flex gap-x-3 text-base leading-7">
                <span className="font-semibold text-white">
                  {loadingInsurer
                    ? "loading ..."
                    : insurer?.insurer_company_name}
                </span>
                <span className="text-gray-600">/</span>
                <span className="font-semibold text-white">
                  {loadingInsurer ? "..." : insurer?.insurer_abbrv}
                </span>
              </h1>
            </div>
            <p className="mt-2 text-xs leading-6 text-gray-400">
              {insurer?.insurer_company_email} /{" "}
              {insurer?.insurer_company_website}
            </p>
          </div>
          <div className="order-first flex-none rounded-full bg-indigo-400/10 px-2 py-1 text-xs font-medium text-indigo-400 ring-1 ring-inset ring-indigo-400/30 sm:order-none">
            Active
          </div>
        </div>
      </header>
      <div className="block">
        <div className="border-b border-shade-500 bg-card-500 px-6">
          <nav className="-mb-px flex space-x-8 items-center" aria-label="Tabs">
            <Link
              search={(old) => ({
                ...old,
                orderStatus: undefined,
              })}
              className={classNames(
                !orderStatus
                  ? "border-gray-200 text-gray-100"
                  : "border-transparent text-gray-100 hover:text-gray-700 hover:border-gray-200",
                "whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm"
              )}
              aria-current={!orderStatus ? "page" : undefined}
            >
              Participation (Offers)
            </Link>
            {tabs.map((_orderStatus) => (
              <Link
                key={_orderStatus.name}
                search={(old) => ({
                  ...old,
                  orderStatus: _orderStatus.href,
                })}
                className={classNames(
                  orderStatus === _orderStatus.href
                    ? "border-gray-200 text-gray-100"
                    : "border-transparent text-gray-100 hover:text-gray-700 hover:border-gray-200",
                  "whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm"
                )}
                aria-current={
                  orderStatus === _orderStatus.href ? "page" : undefined
                }
              >
                {_orderStatus.name}
              </Link>
            ))}
          </nav>
        </div>
      </div>
      {/* Activity list */}
      <>
        {orderStatus === "associates" && <Associates />}
        {!orderStatus && <InsurerOffers />}
      </>
      {searchParams.id && (
        <>
          <ViewInsurerOfferContainer
            open={modal === "view"}
            setOpen={(val: boolean) => setModal(val ? "view" : undefined)}
          />
          <ViewInsurerAssociateContainer
            open={modal === "view-associate"}
            setOpen={(val: boolean) => setModal(val ? "view-associate" : undefined)}
          />
          <UpdateManagerContainer
            open={modal === "update-associate"}
            setOpen={(val: boolean) => setModal(val ? "update-associate" : undefined)}
          />
          <RemoveContainer
            open={modal === "remove-associate"}
            setOpen={(val: boolean) => setModal(val ? "remove-associate" : undefined)}
          />
        </>
      )}
    </main>
  );
};

export default InsurerPage;
