import React, { FC } from 'react'

type Props = {
    label: string,
    value?: string
    loading?: boolean
}

const TextLoader: FC<Props> = ({ label, value, loading }) => {
    return (
        <div className=" border-gray-600 px-4 py-6 sm:col-span-1 sm:px-0">
            <dt className="text-sm font-light leading-6 text-gray-400">
                {label}
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-200 font-medium sm:mt-2">
                {loading ? "loading ..." : value || "N/A"}
            </dd>
        </div>
    )
}

export default TextLoader