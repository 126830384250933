import { wrapClick } from "utils";
import { InsurerView, Modal, PDFRenderer } from "components";
import { useSearch, useNavigate, useMatch } from "react-location";
import { LocationGenerics } from "router/location";
import { Action } from "components/buttons/action-button";
import { useInsurer } from "hooks/data/use-insurers";
import config from "config";

export default function ViewInsurerPaymentReceiptContainer({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const { params } = useMatch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const { loading } = useInsurer(searchParams?.id || "");

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      loading={loading}
      size="6xl"
      title="Payment Letter"
      description="Details of payment letter are shown below"
    >
      <PDFRenderer
        url={`${config.asset.uri}generate_payment_letter/${btoa(
          JSON.stringify({
            reinsurer_id: params?.reInsurer,
            offer_participant_payment_id: searchParams?.id,
          })
        )}`}
      />
    </Modal>
  );
}
