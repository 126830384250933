import { Modal, PDFRenderer } from "components";
import { useMatch, useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import config from "config";
import _ from "lodash";
import { useTreaty } from "hooks/data/use-treaties";
import { AccountPeriodsNames, classNames, wrapClick } from "utils";
import { useState } from "react";
import { TreatyType } from "_graphql/__generated__/globalTypes";

export default function ViewBrokerTreatyClosingContainer({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const [orderTab, setOrderTab] = useState("");

  const { params } = useMatch<LocationGenerics>();
  useMatch<LocationGenerics>();

  const { treaty, loading } = useTreaty({
    treatyId: params?.treaty,
  });

  const __setOrderTab = (tab: string) => () => {
    setOrderTab(tab);
  };

  //   const layers: any[] = JSON.parse(treaty?.layer_limit || "[]");

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      loading={loading}
      size="6xl"
      hidePadding
      title={"Treaty Closings"}
      description={`Details of Debit Note are shown below`}
    >
      {searchParams?.treaty_type === TreatyType.PROPORTIONAL && (
        <div className="block">
          <div className="border-b border-gray-200 bg-white px-6">
            <nav className="-mb-px flex space-x-8" aria-label="Tabs">
              {treaty?.treaty_accounts?.map((_orderTab) => (
                <button
                  key={_orderTab?.treaty_account_id}
                  onClick={wrapClick(
                    __setOrderTab(_orderTab?.treaty_account_id || "")
                  )}
                  className={classNames(
                    orderTab === _orderTab?.treaty_account_id
                      ? "border-primary-500 text-primary-600"
                      : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200",
                    "whitespace-nowrap disabled:cursor-not-allowed flex py-4 px-1 border-b-2 font-medium text-sm"
                  )}
                  aria-current={
                    orderTab === _orderTab?.treaty_account_id
                      ? "page"
                      : undefined
                  }
                >
                  {_.get(AccountPeriodsNames, _orderTab?.account_periods || "")}
                </button>
              ))}
            </nav>
          </div>
        </div>
      )}

      {orderTab && searchParams?.treaty_type === TreatyType.PROPORTIONAL && (
        <div className="p-3">
          <PDFRenderer
            url={`${config.asset.uri}treaty_broker_closings/${btoa(
              JSON.stringify({
                re_broker_treaties_participation_id: searchParams?.id,
                treaty_account_id: orderTab,
                treaty_id: params.treaty,
              })
            )}`}
          />
        </div>
      )}
      {searchParams?.treaty_type === TreatyType.NONPROPORTIONAL && (
        <div className="p-3">
          <PDFRenderer
            url={`${config.asset.uri}treaty_broker_closings/${btoa(
              JSON.stringify({
                re_broker_treaties_participation_id: searchParams?.id,
                treaty_account_id: orderTab,
                treaty_id: params.treaty,
              })
            )}`}
          />
        </div>
      )}
    </Modal>
  );
}
