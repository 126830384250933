import React, { FC, ReactElement } from "react";

interface ListProps<T = any> {
    data: T[];
    renderItem: (data: T) => ReactElement;
}

const List: FC<ListProps> = ({ data, renderItem }) => {
    return <>{data?.map((item) => renderItem(item))}</>;
};

export default List;
