import { Insurer_insurer } from '_graphql/queries/__generated__/Insurer'
import { Reinsurer_reinsurer } from '_graphql/queries/__generated__/Reinsurer'
import { Avatar } from 'components/core'
import _ from 'lodash'
import React, { FC } from 'react'

type Props = {
    reInsurer: Reinsurer_reinsurer | null | undefined
}

const EmployeeView: FC<Props> = ({ reInsurer }) => {


    const brokerageBreakdown: Record<string, number> = JSON.parse(reInsurer?.reinsurer_overview?.total_brokerage_amt || "{}")
    const facPremiumBreakdown: Record<string, number> = JSON.parse(reInsurer?.reinsurer_overview?.total_fac_premium || "{}")
    const wthBreakdown: Record<string, number> = JSON.parse(reInsurer?.reinsurer_overview?.total_withholding_tax || "{}")

    return (
        <div className="space-y-6 divide-y divide-gray-200">
            <div>
                <span className='text-xs font-light'>Company Information</span>
                <div className='grid grid-cols-3 gap-6 mt-2'>
                    <div className='row-span-2 flex flex-col space-y-3 items-center justify-center'>
                        <Avatar
                            alt={reInsurer?.re_abbrv || "N A"}
                            size="xl"
                        />
                    </div>
                    <div className='col-span-3'>
                        <span className="block text-sm font-light text-gray-700">
                            Company Name
                        </span>
                        <div className="mt-1 block w-full sm:text-sm">
                            {reInsurer?.re_company_name || "N/A"}
                        </div>
                    </div>
                </div>
            </div>
            <div className='pt-6'>
                <span className='text-xs font-light'>Contact Information</span>
                <div className='grid grid-cols-3 gap-6 mt-2'>
                    <div>
                        <span className="block text-sm font-light text-gray-700">
                            Email Address
                        </span>
                        <div className="mt-1 block w-full sm:text-sm">
                            {reInsurer?.re_company_email || "N/A"}
                        </div>
                    </div>

                    <div>
                        <span className="block text-sm font-light text-gray-700">
                            Website
                        </span>
                        <div className="mt-1 block w-full sm:text-sm">
                            {reInsurer?.re_company_website || "N/A"}
                        </div>
                    </div>
                </div>
            </div>
            <div className='pt-6'>
                <span className='text-xs font-light'>Address Information</span>
                <div className='grid grid-cols-3 gap-6 mt-2'>
                    <div>
                        <span className="block text-sm font-light text-gray-700">
                            County
                        </span>
                        <div className="mt-1 block w-full sm:text-sm">
                            {reInsurer?.reinsurer_address?.country || "N/A"}
                        </div>
                    </div>

                    <div>
                        <span className="block text-sm font-light text-gray-700">
                            Region
                        </span>
                        <div className="mt-1 block w-full sm:text-sm">
                            {reInsurer?.reinsurer_address?.region || "N/A"}
                        </div>
                    </div>
                    <div>
                        <span className="block text-sm font-light text-gray-700">
                            Sub-urb
                        </span>
                        <div className="mt-1 block w-full sm:text-sm">
                            {reInsurer?.reinsurer_address?.suburb || "N/A"}
                        </div>
                    </div>

                    <div>
                        <span className="block col-span-3 text-sm font-light text-gray-700">
                            Street
                        </span>
                        <div className="mt-1 block w-full sm:text-sm">
                            {reInsurer?.reinsurer_address?.street || "N/A"}
                        </div>
                    </div>
                </div>
            </div>
            <div className='pt-6'>
                <span className='text-xs font-light'>Offers OverView</span>
                <div className='grid grid-cols-3 gap-6 mt-2'>
                    <div>
                        <span className="block text-sm font-light text-gray-700">
                            Total Offers
                        </span>
                        <div className="mt-1 block w-full sm:text-sm">
                            {_.sum([reInsurer?.reinsurer_overview?.total_closed, reInsurer?.reinsurer_overview?.total_pending]) || "0"}
                        </div>
                    </div>

                    <div>
                        <span className="block text-sm font-light text-gray-700">
                            Total Pending Offers
                        </span>
                        <div className="mt-1 block w-full sm:text-sm">
                            {reInsurer?.reinsurer_overview?.total_pending || "0"}
                        </div>
                    </div>
                    <div>
                        <span className="block text-sm font-light text-gray-700">
                            Total Closed Offers
                        </span>
                        <div className="mt-1 block w-full sm:text-sm">
                            {reInsurer?.reinsurer_overview?.total_closed || "0"}
                        </div>
                    </div>
                </div>
            </div>
            <div className='pt-6'>
                <span className='text-xs font-light'>Brokerage OverView</span>
                <div className='grid grid-cols-3 gap-6 mt-2'>
                    <div>
                        <span className="block text-sm font-light text-gray-700">
                            USD
                        </span>
                        <div className="mt-1 block w-full sm:text-sm">
                            {brokerageBreakdown?.total_dollar || "0"}
                        </div>
                    </div>
                    <div>
                        <span className="block text-sm font-light text-gray-700">
                            GBP
                        </span>
                        <div className="mt-1 block w-full sm:text-sm">
                            {brokerageBreakdown?.total_pounds || "0"}
                        </div>
                    </div>
                    <div>
                        <span className="block text-sm font-light text-gray-700">
                            GHS
                        </span>
                        <div className="mt-1 block w-full sm:text-sm">
                            {brokerageBreakdown?.total_cedis || "0"}
                        </div>
                    </div>
                    <div>
                        <span className="block text-sm font-light text-gray-700">
                            EUR
                        </span>
                        <div className="mt-1 block w-full sm:text-sm">
                            {brokerageBreakdown?.total_euros || "0"}
                        </div>
                    </div>
                </div>
            </div>
            <div className='pt-6'>
                <span className='text-xs font-light'>Facultative Premium OverView</span>
                <div className='grid grid-cols-3 gap-6 mt-2'>
                    <div>
                        <span className="block text-sm font-light text-gray-700">
                            USD
                        </span>
                        <div className="mt-1 block w-full sm:text-sm">
                            {facPremiumBreakdown?.total_dollar || "0"}
                        </div>
                    </div>
                    <div>
                        <span className="block text-sm font-light text-gray-700">
                            GBP
                        </span>
                        <div className="mt-1 block w-full sm:text-sm">
                            {facPremiumBreakdown?.total_pounds || "0"}
                        </div>
                    </div>
                    <div>
                        <span className="block text-sm font-light text-gray-700">
                            GHS
                        </span>
                        <div className="mt-1 block w-full sm:text-sm">
                            {facPremiumBreakdown?.total_cedis || "0"}
                        </div>
                    </div>
                    <div>
                        <span className="block text-sm font-light text-gray-700">
                            EUR
                        </span>
                        <div className="mt-1 block w-full sm:text-sm">
                            {facPremiumBreakdown?.total_euros || "0"}
                        </div>
                    </div>
                </div>
            </div>
            <div className='pt-6'>
                <span className='text-xs font-light'>Withholding Tax OverView</span>
                <div className='grid grid-cols-3 gap-6 mt-2'>
                    <div>
                        <span className="block text-sm font-light text-gray-700">
                            USD
                        </span>
                        <div className="mt-1 block w-full sm:text-sm">
                            {wthBreakdown?.total_dollar || "0.0"}
                        </div>
                    </div>
                    <div>
                        <span className="block text-sm font-light text-gray-700">
                            GBP
                        </span>
                        <div className="mt-1 block w-full sm:text-sm">
                            {wthBreakdown?.total_pounds || "0.0"}
                        </div>
                    </div>
                    <div>
                        <span className="block text-sm font-light text-gray-700">
                            GHS
                        </span>
                        <div className="mt-1 block w-full sm:text-sm">
                            {wthBreakdown?.total_cedis || "0.0"}
                        </div>
                    </div>
                    <div>
                        <span className="block text-sm font-light text-gray-700">
                            EUR
                        </span>
                        <div className="mt-1 block w-full sm:text-sm">
                            {wthBreakdown?.total_euros || "0.0"}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EmployeeView