import { OfficeButton, OfficeHeader } from "components";
import { FC } from "react";
import { Link } from "react-location";
import { classNames, useUrlState } from "utils";
import OfferOverView from "./overview";
import { UserPlus } from "lucide-react";
import { PaperAirplaneIcon } from "@heroicons/react/24/outline";
import AddParticipantsContainer from "./add-participants";
import OfferParticipation from "./participation";
import OfferAssociates from "./associates";
import SendEmailsContainer from "./send-emails";

type Props = {};

const tabs = [
  { name: "Endorsement Participants", href: "participants" },
  { name: "Participant's Associates", href: "associates" },
];

const OfferPage: FC<Props> = () => {
  const [modal, setModal] = useUrlState("modal");
  const [orderStatus] = useUrlState("orderStatus");

  return (
    <main className="flex-1 flex flex-col overflow-hidden bg-shade-500">
      <OfficeHeader
        renderActions={() => (
          <>
            <OfficeButton onClick={() => setModal("create")}>
              <UserPlus className="w-5 h-5 mr-2" />
              <span className="hidden sm:inline">Add Participant</span>
            </OfficeButton>
            <OfficeButton onClick={() => setModal("send")}>
              <PaperAirplaneIcon className="w-5 h-5 mr-2" />
              <span className="hidden sm:inline">Send Emails</span>
            </OfficeButton>
          </>
        )}
      />
      <div className="block">
        <div className="border-b border-shade-500 bg-card-500 px-6">
          <nav className="-mb-px flex space-x-8 items-center" aria-label="Tabs">
            <Link
              search={(old) => ({
                ...old,
                orderStatus: undefined,
              })}
              className={classNames(
                !orderStatus
                  ? "border-gray-200 text-gray-100"
                  : "border-transparent text-gray-100 hover:text-gray-700 hover:border-gray-200",
                "whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm"
              )}
              aria-current={!orderStatus ? "page" : undefined}
            >
              Overview
            </Link>
            {tabs.map((_orderStatus) => (
              <Link
                key={_orderStatus.name}
                search={(old) => ({
                  ...old,
                  orderStatus: _orderStatus.href,
                })}
                className={classNames(
                  orderStatus === _orderStatus.href
                    ? "border-gray-200 text-gray-100"
                    : "border-transparent text-gray-100 hover:text-gray-700 hover:border-gray-200",
                  "whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm"
                )}
                aria-current={
                  orderStatus === _orderStatus.href ? "page" : undefined
                }
              >
                {_orderStatus.name}
              </Link>
            ))}
            {/* <div className="h-6 w-px bg-gray-300" /> */}
          </nav>
        </div>
      </div>
      <div className="flex flex-1 overflow-y-auto">
        <div className="flex-1 mx-auto min-w-0   sm:py-6 overflow-hidden overflow-y-auto light flex">
          {!orderStatus && <OfferOverView />}
          {orderStatus === "participants" && <OfferParticipation />}
          {orderStatus === "associates" && <OfferAssociates />}
        </div>
      </div>
      <AddParticipantsContainer
        open={modal === "create"}
        setOpen={(val: boolean) => setModal(val ? "create" : undefined)}
      />
      <SendEmailsContainer
        open={modal === "send"}
        setOpen={(val: boolean) => setModal(val ? "send" : undefined)}
      />
    </main>
  );
};

export default OfferPage;
