export { default as classNames } from "./classnames";
export { default as wrapClick } from "./wrap-click";
export { default as wrapImage } from "./wrap-image";
export { default as wrapOnchange } from "./wrap-onchange";
export { default as withPermissions } from "./with-permissions";
export { default as useDownloadFile } from "./use-download-file";
export { default as useUrlState } from "./use-url-state";
export { default as useTableData } from "./use-table-data";
export { default as withRoles } from "./with-roles";

export * as validators from "./regex";

export const AccountPeriodsNames = {
  FIRSTQUARTER: "1st Quarter",
  SECONDQUARTER: "2nd Quarter",
  THIRDQUARTER: "3rd Quarter",
  FOURTHQUARTER: "4th Quarter",
};

export const extractVehicleNumberFromDetails = (details: any) => {
  return details?.find(
    (el: { [x: string]: string }) =>
      el["keydetail"] === "Vehicle Reg No" ||
      el["keydetail"] === "Vehicle Reg No."
  )?.value;
};

export const getSumOFNPPayments = ({
  treaty_np_payments = [],
  uuid,
  treaty_np_payment_id,
  installment_type,
}: {
  treaty_np_payments: any[];
  uuid: string;
  treaty_np_payment_id?: any;
  installment_type?: string;
}) => {
  console.log("Treaty NP Payments", treaty_np_payments);
  const sum = treaty_np_payments.reduce((acc, curr) => {
    const details = JSON.parse(curr.treaty_payment_details || "{}");
    if (curr.uuid === uuid) {
      //if payment has already been made and the payment is about to be updated
      if (
        treaty_np_payment_id &&
        treaty_np_payment_id === curr.treaty_np_payment_id
      ) {
        return acc;
      }

      //if the payment was made with a different currency and the exchange rate was added
      if (details?.conversion.addExchangeRate) {
        console.log("Conversion", details?.conversion.rate);
        return (
          acc +
          parseFloat(curr.treaty_payment_amount) /
            parseFloat(details?.conversion.rate)
        );
      }
      return acc + parseFloat(curr.treaty_payment_amount);
    }
    return acc;
  }, 0);
  return sum;
};
export const getSumOFPPayments = ({
  treaty_np_payments = [],
  treaty_p_payment_id,
  treaty_account_id,
}: {
  treaty_np_payments: any[];
  treaty_p_payment_id?: any;
  treaty_account_id: string;
}) => {
  const sum = treaty_np_payments
    ?.filter((el) => el?.treaty_accountstreaty_account_id === treaty_account_id)
    .reduce((acc, curr) => {
      // console.log("Cuerret", curr);
      const details = JSON.parse(curr.treaty_payment_details);
      const { conversion } = details;
      if (treaty_p_payment_id === curr.treaty_p_payment_id) {
        return acc;
      }
      if (conversion && conversion.addExchangeRate) {
        return (
          acc +
          parseFloat(curr.treaty_payment_amount) / parseFloat(conversion.rate)
        );
      }

      return acc + parseFloat(curr.treaty_payment_amount);
    }, 0);

  return sum;
};
