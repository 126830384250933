import { Modal } from "components";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { useInsurerManager } from "hooks/data/use-insurers";
import InsurerRepView from "components/cards/insurer-rep-view";

export default function ViewInsurerAssociateContainer({
    open,
    setOpen,
}: {
    open: boolean;
    setOpen: (val: boolean) => void;
}) {
    const searchParams = useSearch<LocationGenerics>();
    const { insurer, loading } = useInsurerManager(searchParams.id as string);

    return (
        <Modal
            open={open}
            setOpen={setOpen}
            loading={loading}
            title={"Insurer Manager Information - " + insurer?.assoc_first_name + " " + insurer?.assoc_last_name}
            description="Details of reinsurer manager are shown below"
        >
            <InsurerRepView insurer={insurer} />
        </Modal>
    );
}
