import { useQuery } from "@apollo/client";
import {
  GetReinsurerAssociates,
  GetReinsurerAssociatesVariables,
} from "_graphql/queries/__generated__/GetReinsurerAssociates";
import { GetReinsurerParticipantPayment, GetReinsurerParticipantPaymentVariables } from "_graphql/queries/__generated__/GetReinsurerParticipantPayment";
import {
  GetReinsurerParticipantPayments,
  GetReinsurerParticipantPaymentsVariables,
} from "_graphql/queries/__generated__/GetReinsurerParticipantPayments";
import {
  GetReinsurerRep,
  GetReinsurerRepVariables,
} from "_graphql/queries/__generated__/GetReinsurerRep";
import { GetReinsurerReps } from "_graphql/queries/__generated__/GetReinsurerReps";
import {
  Reinsurer,
  ReinsurerVariables,
} from "_graphql/queries/__generated__/Reinsurer";
import { Reinsurers } from "_graphql/queries/__generated__/Reinsurers";
import {
  getReInsurers,
  getReInsurer,
  getReinsurerReps,
  getReinsurerAssociates,
  getReinsurerOfferPayments,
  getReInsurerRep,
  getReinsurerOfferPayment,
} from "_graphql/queries/re-insurers";

export const useReInsurers = (variables?: any) => {
  // console.log("variables", variables);
  const { page, pageSize, search } = variables || {
    page: 1,
    pageSize: 10,
    search: "",
  };
  const start = (parseInt(page) - 1) * pageSize;
  const end = start + pageSize;
  const { data, ...rest } = useQuery<Reinsurers>(getReInsurers, {
    variables: undefined,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });
  const items = data?.rows
    ?.filter((el) => {
      return (
        el?.re_company_name?.toLowerCase().includes(search.toLowerCase()) ||
        el?.re_company_email?.toLowerCase().includes(search.toLowerCase()) ||
        el?.re_abbrv?.toLowerCase().includes(search.toLowerCase()) ||
        el?.re_company_website?.toLowerCase().includes(search.toLowerCase())
      );
    })
    .slice(start, end);
  return {
    reInsurers: items || [],
    count: data?.count || 0,
    ...rest,
  };
};

export const useReInsurer = (id: string) => {
  const { data, ...rest } = useQuery<Reinsurer, ReinsurerVariables>(
    getReInsurer,
    {
      variables: {
        reinsurerId: id ?? "",
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache"
    }
  );
  return {
    reInsurer: data?.reinsurer,
    ...rest,
  };
};
export const useReInsurerReps = () => {
  const { data, ...rest } = useQuery<GetReinsurerReps>(getReinsurerReps, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });
  return {
    associates: data?.getReinsurerReps || [],
    ...rest,
  };
};

export const useReInsurerRep = (variables?: GetReinsurerRepVariables) => {
  const { data, ...rest } = useQuery<GetReinsurerRep, GetReinsurerRepVariables>(
    getReInsurerRep,
    {
      notifyOnNetworkStatusChange: true,
      variables,
      fetchPolicy: "network-only",
    }
  );
  return {
    associate: data?.getReinsurerRep,
    ...rest,
  };
};

export const useReInsurerAssociates = (
  variables: GetReinsurerAssociatesVariables
) => {
  const { data, ...rest } = useQuery<
    GetReinsurerAssociates,
    GetReinsurerAssociatesVariables
  >(getReinsurerAssociates, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    variables,
  });
  return {
    associates: data?.getReinsurerAssociates || [],
    ...rest,
  };
};

export const useReInsurerOfferPayments = (
  variables: GetReinsurerParticipantPaymentsVariables
) => {
  const { data, ...rest } = useQuery<
    GetReinsurerParticipantPayments,
    GetReinsurerParticipantPaymentsVariables
  >(getReinsurerOfferPayments, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    variables,
  });
  return {
    payments: data?.getReinsurerParticipantPayments || [],
    ...rest,
  };
};
export const useReInsurerOfferPayment = (
  variables: GetReinsurerParticipantPaymentVariables
) => {
  const { data, ...rest } = useQuery<
    GetReinsurerParticipantPayment,
    GetReinsurerParticipantPaymentVariables
  >(getReinsurerOfferPayment, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    variables,
  });
  return {
    payment: data?.getReinsurerParticipantPayment,
    ...rest,
  };
};
