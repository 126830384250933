import { wrapClick } from "utils";
import { useMutation } from "@apollo/client";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { Modal } from "components";
import * as Yup from "yup";
import { PercentageForm } from "components/forms";
import { useMatch, useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { useEffect, useMemo } from "react";
import { useTreaty, useTreatyReinsurer } from "hooks/data/use-treaties";
import {
  AddReinsurersParticipationPercentage,
  AddReinsurersParticipationPercentageVariables,
} from "_graphql/mutation/__generated__/AddReinsurersParticipationPercentage";
import { updateTreatyReinsurerPercentage } from "_graphql/mutation/treaty";
import _ from "lodash";

export const valiadationSchema = (remainingPercentage: number) =>
  Yup.object().shape({
    percentage: Yup.number()
      .min(0, "Number must be greater than 0")
      .typeError("Must be a number")
      .test(
        "percentage",
        `Percentage must be less than or equal to remaining percentage:${remainingPercentage}`,
        (value) => {
          return value ? value <= remainingPercentage : true;
        }
      )
      .required("Percentage is required"),
  });

export default function AddParticipantsPercentageContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const { params } = useMatch<LocationGenerics>();
  const searchParams = useSearch<LocationGenerics>();
  // const navigate = useNavigate<LocationGenerics>();
  const { treaty, loading: fetchingTreaty } = useTreaty({
    treatyId: params.treaty || "",
  });
  const { participant, loading: fetching } = useTreatyReinsurer({
    participationId: searchParams.id || "",
  });

  const [create, { loading }] = useMutation<
    AddReinsurersParticipationPercentage,
    AddReinsurersParticipationPercentageVariables
  >(updateTreatyReinsurerPercentage, {
    refetchQueries: ["GetTreatyReinsurerParticipants", "Treaty"],
  });

  const getNonProportionalPercentage = (totalPercentage_: number) => {
    const groupedItems = _.groupBy(
      treaty?.treaty_participants || [],
      "layer_number"
    );
    console.log("GROUPED", groupedItems, searchParams?.layer || 1);
    const totalAssigned =
      _.get(groupedItems, searchParams?.layer || 1)
        ?.filter((el) => {
          console.log(el?.treaty_participation_id, searchParams?.id);
          return el?.treaty_participation_id !== searchParams.id;
        })
        ?.reduce(
          (acc, item) => acc + (item?.treaty_participation_percentage || 0),
          0
        ) || 0;
    console.log("TOTAL ASSIGNED", totalAssigned);
    console.log("TOTAL PERCENTAGE", totalPercentage_, treaty?.order_hereon);
    return totalPercentage_ - totalAssigned;
  };

  const totalPercentage = treaty?.order_hereon || 100;
  const remainingPercentage = useMemo(
    () =>
      searchParams?.treaty_type === "PROPORTIONAL"
        ? totalPercentage -
        [
          ...(treaty?.treaty_participants?.filter(
            (el) => el?.treaty_participation_id !== searchParams.id
          ) || []),
        ]?.reduce(
          (acc, participant) =>
            acc + (participant?.treaty_participation_percentage || 0),
          0
        ) || 0
        : getNonProportionalPercentage(totalPercentage),
    [
      searchParams?.treaty_type,
      treaty?.order_hereon,
      treaty?.treaty_participants,
      totalPercentage,
    ]
  );

  const form = useFormik<any>({
    initialValues: {
      percentage: 0,
      expected: treaty?.order_hereon,
    },
    validationSchema: valiadationSchema(remainingPercentage),
    onSubmit: async (variables) => {
      await create({
        variables: {
          treatyId: params.treaty || "",
          treatyParticipationId: searchParams.id?.toString() || "",
          layerNumber: Number(searchParams.layer),
          participationPercentage: variables.percentage,
          associateDeductionId:
            treaty?.treaty_deduction?.treaty_associate_deduction_id || "",
        },
      }).then(({ data }) => {
        if (data?.addReinsurersParticipationPercentage) {
          toast.success("Percentage added successfully");
          refetch?.();
          setOpen?.(false);
          form.resetForm();
        } else {
          toast.error("Failed to add percentage");
        }
      });
    },
    onReset: () => {
      setOpen(false);
    },
  });

  useEffect(() => {
    form.setFieldValue("expected", remainingPercentage);
  }, [remainingPercentage]);

  useEffect(() => {
    if (participant) {
      form.setFieldValue(
        "percentage",
        participant?.treaty_participation_percentage
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [participant]);

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title={"Add Percentage to " + participant?.reinsurer?.re_company_name}
      loading={fetching || fetchingTreaty}
      description="Add percentage for this ReInsurer"
      renderActions={() => (
        <>
          <button
            type="button"
            disabled={loading}
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(form.handleSubmit)}
          >
            {loading ? "Saving changes..." : "Save changes"}
          </button>
        </>
      )}
    >
      {/* {JSON.stringify(
        {
          order_hereon: treaty?.order_hereon,
          remainingPercentage,
          id: searchParams.id,
        },
        null,
        2
      )} */}
      <PercentageForm form={form} />
    </Modal>
  );
}
