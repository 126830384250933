import { wrapClick } from "utils";
import { useMutation } from "@apollo/client";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { Modal } from "components";
import * as Yup from "yup";
import { SendEmailForm } from "components/forms";
import { useMatch, useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import _ from "lodash";
import { sendNPTreatyDebitNote } from "_graphql/mutation/treaty";
import {
  SendNPTreatyDebitNote,
  SendNPTreatyDebitNoteVariables,
} from "_graphql/mutation/__generated__/SendNPTreatyDebitNote";
import { useTreaty } from "hooks/data/use-treaties";

export const valiadationSchema = Yup.object().shape({
  message: Yup.string().required("Message is required"),
  subject: Yup.string().required("Subject is required"),
});

export default function SendLayerDebitNoteContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const { params } = useMatch<LocationGenerics>();
  const searchParams = useSearch<LocationGenerics>();
  const { treaty, loading: fetching } = useTreaty({ treatyId: params?.treaty });
  const [create, { loading }] = useMutation<
    SendNPTreatyDebitNote,
    SendNPTreatyDebitNoteVariables
  >(sendNPTreatyDebitNote, {
    refetchQueries: ["Treaty"],
  });

  const layer: any = JSON.parse(treaty?.layer_limit || "[]")?.at(
    Number(searchParams?.layer) - 1
  );

  const form = useFormik<any>({
    initialValues: {
      copied_emails: [],
      subject: "",
      message: "",
      include_attachment: false,
      should_send: 0,
      attachments: [],
    },
    validationSchema: valiadationSchema,
    onSubmit: async (variables) => {
      await create({
        variables: {
          nptreatydata: {
            email_component: {
              attachments:
                variables.attachments?.map((el: any) => el?.file_path) || [],
              copied_email: variables.copied_emails?.map((el: any) => el) || [],
              subject: variables.subject,
              email_content: variables.message,
            },
            installment_type: layer?.installment_type,
            layer: Number(searchParams?.layer) || 0,
            limit: parseFloat(layer?.limit),
            m_and_d_premium: parseFloat(layer?.m_and_d_premium),
            // participant_id: params?.treaty || "",
            total_participation_percentage: 100 - 30,
            treaty_id: parseInt(params?.treaty || ""),
            uuid: layer?.uuid,
          },
        },
      }).then(({ data }) => {
        if (data?.sendNPTreatyDebitNote) {
          toast.success("Emails sent successfully");
          refetch?.();
          form.resetForm();
        } else {
          toast.error("Failed to send emails");
        }
      });
    },
    onReset: () => {
      setOpen(false);
    },
  });

  return (
    <Modal
      open={open}
      loading={fetching}
      setOpen={setOpen}
      title={`Send Debit Note`}
      description="Send Debit Note to participants"
      renderActions={() => (
        <>
          <button
            type="button"
            disabled={loading}
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(form.handleSubmit)}
          >
            {loading ? "Sending emails..." : "Send Email"}
          </button>
        </>
      )}
    >
      {/* {JSON.stringify(form.values, null, 2)} */}
      <SendEmailForm form={form} />
    </Modal>
  );
}
