import { validators, wrapClick } from "utils";
import { gql, useMutation } from "@apollo/client";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { Modal } from "components";
import * as Yup from "yup";
import { createInsurer, updateInsurer } from "_graphql/mutation/insurer";
import { InsurerForm } from "components/forms";
import { CreateInsurer, CreateInsurerVariables } from "_graphql/mutation/__generated__/CreateInsurer";
import { useInsurer } from "hooks/data/use-insurers";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { useEffect } from "react";
import { UpdateInsurer, UpdateInsurerVariables } from "_graphql/mutation/__generated__/UpdateInsurer";


export const valiadationSchema = Yup.object().shape({
    insurer_company_name: Yup.string().required("Company name is required"),
    insurer_company_website: Yup.string().notRequired(),
    insurer_company_email: Yup.string().email("Invalid Email address").required("Email is required"),
    country: Yup.string().required("Country is required"),
    region: Yup.string().required("Region/State is required"),
    street: Yup.string().required("Street Address is required"),
    suburb: Yup.string().required("Suburb/City is required"),
});

export default function UpdateInsurerContainer({
    open,
    setOpen,
    refetch,
}: {
    open: boolean;
    setOpen: (val: boolean) => void;
    refetch?: () => void;
}) {
    const searchParams = useSearch<LocationGenerics>();
    const [update, { loading }] = useMutation<UpdateInsurer, UpdateInsurerVariables>(updateInsurer);
    const { insurer, loading: fetching } = useInsurer(searchParams.id || "")
    const form = useFormik<UpdateInsurerVariables["insurer"]>({
        initialValues: {
            insurer_company_email: "",
            insurer_company_name: "",
            insurer_company_website: "",
            country: "",
            region: "",
            street: "",
            suburb: ""
        },
        validationSchema: valiadationSchema,
        onSubmit: async (variables) => {
            await update({
                variables: {
                    insurer: variables,
                    insurerId: searchParams.id || "",
                },
            }).then(({ data }) => {
                if (data?.updateInsurer) {
                    toast.success("Insurer Updated Successfully");
                    refetch?.();
                    form.resetForm();
                } else {
                    toast.error("Failed to update Insurer");
                }
            });
        },
        onReset: () => {
            setOpen(false);
        },
    });

    useEffect(() => {
        if (insurer) {
            form.setFieldValue("insurer_company_email", insurer.insurer_company_email);
            form.setFieldValue("insurer_company_name", insurer.insurer_company_name);
            form.setFieldValue("insurer_company_website", insurer.insurer_company_website);
            form.setFieldValue("country", insurer.insurer_address?.country);
            form.setFieldValue("region", insurer.insurer_address?.region);
            form.setFieldValue("street", insurer.insurer_address?.street);
            form.setFieldValue("suburb", insurer.insurer_address?.suburb)
        }
    }, [insurer])

    return (
        <Modal
            open={open}
            setOpen={setOpen}
            title="Update Insurer"
            loading={fetching}
            description="Provide the details to update insurer"
            renderActions={() => (
                <>
                    <button
                        type="button"
                        disabled={loading}
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={wrapClick(form.handleSubmit)}
                    >
                        {loading ? "Updating Insurer..." : "Update Insurer"}
                    </button>
                </>
            )}
        >
            <InsurerForm form={form} />
        </Modal>
    );
}
