import { Modal, PDFRenderer } from "components";
import { useMatch, useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { useInsurer } from "hooks/data/use-insurers";
import config from "config";
import _ from "lodash";
import { useTreaty } from "hooks/data/use-treaties";

export default function ViewLayerLimitDebitNoteContainer({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const { params } = useMatch<LocationGenerics>();
  const { loading, treaty } = useTreaty({ treatyId: params?.treaty });

  const layer: any = JSON.parse(treaty?.layer_limit || "[]")?.at(
    Number(searchParams?.layer) - 1
  );
  console.log(layer);

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      loading={loading}
      hidePadding
      size="6xl"
      title="Debit Note"
      description={`Details of debit note shown below`}
    >
      <PDFRenderer
        url={`${config.asset.uri}treaty_np_debit_note/${btoa(
          JSON.stringify({
            treaty_id: params?.treaty,
            limit: layer?.limit,
            layer: layer?.no - 1,
            m_and_d_premium: layer?.m_and_d_premium,
            // total_participation_percentage: 100 - 30,
            installment_type: layer?.installment_type,
          })
        )}`}
      />
    </Modal>
  );
}
