import { wrapClick } from "utils";
import { useMutation } from "@apollo/client";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { Modal } from "components";
import * as Yup from "yup";
import { ModifyTreatyDeductionForm } from "components/forms";
import { useMatch, useNavigate } from "react-location";
import { LocationGenerics } from "router/location";
import { useEffect } from "react";
import { useTreaty } from "hooks/data/use-treaties";
import { UpdateReinsurerDeductions, UpdateReinsurerDeductionsVariables } from "_graphql/mutation/__generated__/UpdateReinsurerDeductions";
import { updateTreatyReinsurersDeductions } from "_graphql/mutation/treaty";
import { useAppSearch } from "_graphql/cache/auth";
import _ from "lodash";

export const valiadationSchema = Yup.object().shape({
    requests: Yup.array()
        .of(
            Yup.object().shape({
                withholdingTax: Yup.number().min(0, "Number must be greater than 0")
                    .typeError("Should be a number")
                    .required("Withholding Tax is required"),
                nicLevy: Yup.number().min(0, "Number must be greater than 0")
                    .typeError("Should be a number")
                    .required("NIC Levy is required"),
                agreedBrokerage: Yup.number().min(0, "Number must be greater than 0")
                    .typeError("Should be a number")
                    .required("Agreed Brokerage Tax is required"),
                agreedCommission: Yup.number().min(0, "Number must be greater than 0")
                    .typeError("Should be a number")
                    .required("Agreed Commission is required"),
            })
        )
        .min(1)
        .required("At least one participant is required"),
});

export default function ModifyDeductionContainer({
    open,
    setOpen,
    refetch,
}: {
    open: boolean;
    setOpen: (val: boolean) => void;
    refetch?: () => void;
}) {
    const navigate = useNavigate<LocationGenerics>();
    const { params } = useMatch<LocationGenerics>();
    const searchParams = useAppSearch();
    const { treaty, loading: fetching } = useTreaty({
        treatyId: params.treaty || "",
    });

    const [create, { loading }] = useMutation<
        UpdateReinsurerDeductions,
        UpdateReinsurerDeductionsVariables
    >(updateTreatyReinsurersDeductions, {
        refetchQueries: ["Treaty"],
    });


    const form = useFormik<any>({
        initialValues: {
            requests: [],
        },
        validationSchema: valiadationSchema,
        onSubmit: async (variables) => {
            await create({
                variables: {
                    account_ids: variables.requests?.map((item: any) => item.reinsurer?.reinsurer_id),
                    data: variables.requests?.map((item: any) => ({
                        deductions: {
                            brokerage: item.agreedBrokerage,
                            commission: item.agreedCommission,
                            nic_levy: item.nicLevy,
                            withholding_tax: item.withholdingTax,
                            // treaty_participation_percentage: item.treaty_participation_percentage,
                        },
                        participant_id: item.treaty_participation_id,
                        treaty_participant_deduction_id: item?.treaty_participant_deductions?.map((el: any) => el?.treaty_participationstreaty_participation_id || "")
                    })),
                    isProp: searchParams?.treaty_type === "PROPORTIONAL",
                    layer: searchParams?.layer,
                },
            }).then(({ data }) => {
                if (data?.updateReinsurerDeductions) {
                    toast.success("Deduction updated Successfully");
                    // navigate({ to: "/create-closing" });
                    refetch?.();
                    form.resetForm();
                } else {
                    toast.error("Deduction could not be updated");
                }
            });
        },
        onReset: () => {
            setOpen(false);
        },
    });

    useEffect(() => {
        if (treaty) {
            form.setFieldValue(
                "requests",
                treaty?.treaty_participants?.filter(el => el?.layer_number === parseInt(searchParams?.layer || "1"))?.map((item) => ({
                    ...item,
                    withholdingTax: _.first(item?.treaty_participant_deductions)?.withholding_tax || 0,
                    nicLevy: _.first(item?.treaty_participant_deductions)?.nic_levy || 0,
                    agreedBrokerage: _.first(item?.treaty_participant_deductions)?.brokerage || 0,
                    agreedCommission: _.first(item?.treaty_participant_deductions)?.commission || 0,
                }))
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [treaty]);

    return (
        <Modal
            open={open}
            setOpen={setOpen}
            loading={fetching}
            title="Modify Deduction"
            size="7xl"
            description="Provide the percentage of the offer to be distributed to each reinsurer."
            renderActions={() => (
                <>
                    <button
                        type="button"
                        disabled={loading}
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={wrapClick(form.handleSubmit)}
                    >
                        {loading ? "Modifying ..." : "Modify Deductions"}
                    </button>
                </>
            )}
        >
            {/* {JSON.stringify(form.values, null, 2)} */}
            <ModifyTreatyDeductionForm form={form} />
        </Modal>
    );
}
