import { UserPlusIcon } from '@heroicons/react/24/outline';
import { useAppNavigate } from '_graphql/cache/auth';
import { GetInsurerAssociates_getInsurerAssociates } from '_graphql/queries/__generated__/GetInsurerAssociates';
import { ActionButton, OfficeButton, TableComponent, TableLoader } from 'components';
import { Action } from 'components/buttons/action-button';
import { useInsurerManagers } from 'hooks/data/use-insurers'
import CreateInsurerManagerContainer from 'pages/insurers/add-manager';
import { FC } from 'react'
import { useMatch } from 'react-location';
import { LocationGenerics } from 'router/location';
import { useUrlState } from 'utils';

type Props = {}

const Associates: FC<Props> = (props) => {
    const { params } = useMatch<LocationGenerics>();
    const navigate = useAppNavigate()
    const [modal, setModal] = useUrlState("modal");
    const { managers, refetch, loading } = useInsurerManagers({
        page: 1,
        pageSize: 12,
        search: "",
        insurerId: params?.insurer as string
    })

    const dispatchAction =
        (id: string, action: Exclude<Action, "expand" | "goto" | "clone">) =>
            () => {
                navigate({
                    search: (old) => ({
                        ...old,
                        id,
                        modal: action,
                    }),
                });
            };

    return (
        <div className='p-4'>
            <TableComponent hasSearch title={'managers'} columns={[
                {
                    label: 'Name',
                    accessor: 'assoc_first_name',
                    name: 'Name',
                    render: (value: GetInsurerAssociates_getInsurerAssociates) => (
                        <span>
                            {value.assoc_first_name} {value.assoc_last_name}
                        </span>
                    )
                },
                {
                    name: "Email",
                    accessor: "assoc_email",
                },
                {
                    name: "Position",
                    accessor: "position",
                },
                {
                    name: "Primary Phone Number",
                    accessor: "assoc_primary_phonenumber",
                },
                {
                    name: "Secondary Phone Number",
                    accessor: "assoc_secondary_phonenumber",
                },
                {
                    name: "Actions",
                    accessor: "insurer_associate_id",
                    render: (value: GetInsurerAssociates_getInsurerAssociates) => (
                        <>
                            <ActionButton action='view-associate' onClick={dispatchAction(value?.insurer_associate_id, "view-associate")} />
                            <ActionButton action='update-associate' onClick={dispatchAction(value?.insurer_associate_id, "update-associate")} />
                            <ActionButton action='remove-associate' onClick={dispatchAction(value?.insurer_associate_id, "remove-associate")} />
                        </>
                    )
                }
                //Add actions
            ]} data={managers} refetch={refetch}
                loading={loading}
                renderHeaderItems={() => (
                    <OfficeButton onClick={dispatchAction("", "create")}>
                        <span>Add Manager</span>
                        <UserPlusIcon className="h-6 w-6" />
                    </OfficeButton>
                )}
                renderLoader={() => <TableLoader items={[1, 1, 1, 1, 1]} showAction actionsCount={3} />}
            />


            <CreateInsurerManagerContainer
                open={modal === "create"}
                setOpen={(val: boolean) => setModal(val ? "create" : undefined)}
            />

        </div>
    )
}

export default Associates