import { Modal, PDFRenderer } from "components";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { useInsurer } from "hooks/data/use-insurers";
import config from "config";
import { classNames } from "utils";
import { useState } from "react";



const tabs: any[] = [
  {
    name: "Offer Slip",
    href: "Placing"
  },
  {
    name: "Cover Note",
    href: "Cover"
  },
  {
    name: "Debit Note",
    href: "Debit"
  },
  {
    name: "Contract Changes",
    href: "Contract"
  },

]

export default function ViewOfferSlipContainer({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
}) {
  const [orderStatus, setOrderStatus] = useState("Placing");
  const searchParams = useSearch<LocationGenerics>();
  const { loading } = useInsurer(searchParams?.id || "");

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      loading={loading}
      hidePadding
      size="6xl"
      title="Placing Slip"
      description={"Details of placing slip are shown below"}
    >
      <div className="block">
        <div className="border-b border-shade-500 bg-card-500 px-6">
          <nav className="-mb-px flex space-x-8 items-center" aria-label="Tabs">
            {tabs.map((_orderStatus) => (
              <button
                key={_orderStatus.name}
                onClick={() => setOrderStatus(_orderStatus.href)}
                className={classNames(
                  orderStatus === _orderStatus.href
                    ? "border-gray-200 text-gray-600"
                    : "border-transparent text-gray-400 hover:text-gray-700 hover:border-gray-200",
                  "whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm"
                )}
                aria-current={
                  orderStatus === _orderStatus.href ? "page" : undefined
                }
              >
                {_orderStatus.name}
              </button>
            ))}
          </nav>
        </div>
      </div>
      {orderStatus === "Placing" && <PDFRenderer
        url={`${config.asset.uri}contract_changes/${btoa(
          JSON.stringify({
            offer_endorsement_id: searchParams?.id,
            doc_number: -1,
            type: "Cover",
            hasEndOfferSlip: true,
          })
        )}`}
      />}
      {orderStatus === "Cover" && <PDFRenderer
        url={`${config.asset.uri}contract_changes/${btoa(
          JSON.stringify({
            offer_endorsement_id: searchParams?.id,
            type: "Cover",
            doc_number: -1,
          })
        )}`}
      />}
      {orderStatus === "Debit" && <PDFRenderer
        url={`${config.asset.uri}endorsement_debit_note/${btoa(
          JSON.stringify({
            offer_endorsement_id: searchParams?.id,
            doc_number: -1,
          })
        )}`}
      />}
      {orderStatus === "Contract" && <PDFRenderer
        url={`${config.asset.uri}contract_changes/${btoa(
          JSON.stringify({
            offer_endorsement_id: searchParams?.id,
            doc_number: -1,
            type: "Contract",
          })
        )}`}
      />}
    </Modal>
  );
}
