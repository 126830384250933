import { gql } from "@apollo/client";

export const LOGIN = gql`
  mutation Login($username: String!, $password: String!) {
    login(input: { username: $username, password: $password }) {
      access_token
      refresh_token
      expires_in
      token_type
      user {
        user_id
        position
        first_time_signin
        employee {
          emp_abbrv
          employee_id
          employee_email
          employee_last_name
          employee_first_name
          employee_phonenumber
        }
      }
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation ChangePassword($id: ID!, $password: String!) {
    changePasswordByEmployee(employee_id: $id, new_password: $password)
  }
`;
