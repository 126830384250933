import clsx from "clsx";
import { Loader } from "components";
import { useEndorsement } from "hooks/data/use-endorsement";
import { useOffer } from "hooks/data/use-offers";
import moment from "moment";
import numeral from "numeral";
import { useMatch } from "react-location";
import { LocationGenerics } from "router/location";

export default function OfferOverView() {
  const { params } = useMatch<LocationGenerics>();
  const { loading, endorsement } = useEndorsement({
    offerEndorsementId: params.endorsement || "",
  });

  if (loading) return <Loader />;

  return (
    <div className="items-start px-3 flex-1">
      <div className="bg-card-500 p-3 ">
        <div className="px-4 sm:px-0 flex justify-between items-center">
          <div>
            <h3 className="text-base font-semibold leading-7 text-gray-300">
              Endorsement Information
            </h3>
            <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">
              Details and information about the offer endorsement
            </p>
          </div>
          <div className="px-11">
            <div
              className={clsx(
                "order-first flex-none rounded-full  px-2 py-1 text-xs font-medium  ring-1 ring-inset  sm:order-none",
                {
                  "text-indigo-400 bg-indigo-400/10 ring-indigo-400/30":
                    endorsement?.endorsement_status === "OPEN",
                  "text-yellow-400 bg-yellow-400/10 ring-yellow-400/30":
                    endorsement?.endorsement_status === "PENDING",
                  "text-teal-400 bg-teal-400/10 ring-teal-400/30":
                    endorsement?.endorsement_status === "CLOSED",
                }
              )}
            >
              {endorsement?.endorsement_status}
            </div>
          </div>
        </div>
        <div className="mt-6">
          <dl className="grid grid-cols-1 sm:grid-cols-4">
            <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
              <dt className="text-sm font-light leading-6 text-gray-300">
                Class of Business
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-100 font-medium sm:mt-2">
                {endorsement?.classofbusiness?.business_name || "N/A"}
              </dd>
            </div>
            <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
              <dt className="text-sm font-light leading-6 text-gray-300">
                Policy No.
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-100 font-medium sm:mt-2">
                {endorsement?.offer_endorsement_detail?.policy_number || "N/A"}
              </dd>
            </div>
            <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
              <dt className="text-sm font-light leading-6 text-gray-300">
                Reinsured
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-100 font-medium sm:mt-2">
                {endorsement?.insurer?.insurer_company_name || "N/A"}
              </dd>
            </div>
            <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
              <dt className="text-sm font-light leading-6 text-gray-300">
                Insured
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-100 font-medium sm:mt-2">
                {endorsement?.offer_endorsement_detail?.insured_by || "N/A"}
              </dd>
            </div>
            <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
              <dt className="text-sm font-light leading-6 text-gray-300">
                Period Of Insurance
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-100 font-medium sm:mt-2">
                {moment(endorsement?.offer_endorsement_detail?.period_of_insurance_from).format(
                  "DD/MM/YYYY"
                ) || "N/A"}{" "}
                to{" "}
                {moment(endorsement?.offer_endorsement_detail?.period_of_insurance_to).format(
                  "DD/MM/YYYY"
                ) || "N/A"}
              </dd>
            </div>
            <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
              <dt className="text-sm font-light leading-6 text-gray-300">
                Rate
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-100 font-medium sm:mt-2">
                {endorsement?.rate || "N/A"}%
              </dd>
            </div>
            <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
              <dt className="text-sm font-light leading-6 text-gray-300">
                Comission
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-100 font-medium sm:mt-2">
                {endorsement?.commission || "N/A"}%
              </dd>
            </div>
            <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
              <dt className="text-sm font-light leading-6 text-gray-300">
                Fac. Offer
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-100 font-medium sm:mt-2">
                {endorsement?.facultative_offer || "N/A"}%
              </dd>
            </div>
            <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
              <dt className="text-sm font-light leading-6 text-gray-300">
                Brokerage
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-100 font-medium sm:mt-2">
                {endorsement?.brokerage || "N/A"}%
              </dd>
            </div>
            <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
              <dt className="text-sm font-light leading-6 text-gray-300">
                Premium
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-100 font-medium sm:mt-2">
                {endorsement?.offer_endorsement_detail?.currency}{" "}
                {numeral(endorsement?.premium).format("0,0.00") || "N/A"}
              </dd>
            </div>
            <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
              <dt className="text-sm font-light leading-6 text-gray-300">
                Sum Insured
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-100 font-medium sm:mt-2">
                {endorsement?.offer_endorsement_detail?.currency}{" "}
                {numeral(endorsement?.sum_insured).format("0,0.00") || "N/A"}
              </dd>
            </div>
            <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
              <dt className="text-sm font-light leading-6 text-gray-300">
                Fac. Sum Insured
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-100 font-medium sm:mt-2">
                {endorsement?.offer_endorsement_detail?.currency}{" "}
                {numeral(endorsement?.fac_sum_insured).format("0,0.00") || "N/A"}
              </dd>
            </div>
            <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
              <dt className="text-sm font-light leading-6 text-gray-300">
                Fac. Premium
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-100 font-medium sm:mt-2">
                {endorsement?.offer_endorsement_detail?.currency}{" "}
                {numeral(endorsement?.fac_premium).format("0,0.00") || "N/A"}
              </dd>
            </div>
            <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0" />
            <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0" />
            <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0" />

          </dl>
        </div>
      </div>
    </div>
  );
}
