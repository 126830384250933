import { useMutation } from '@apollo/client'
import { UpdateTreatyForPlaingAndCover, UpdateTreatyForPlaingAndCoverVariables } from '_graphql/mutation/__generated__/UpdateTreatyForPlaingAndCover'
import { updateTreatyForPlaingAndCover } from '_graphql/mutation/treaty'
import { CurrencyPicker, Loader, OfficeButton, OfficeHeader, PDFRenderer, RichEditor } from 'components'
import config from 'config'
import { useFormik } from 'formik'
import { useTreaty } from 'hooks/data/use-treaties'
import _ from 'lodash'
import React, { FC, useEffect, useMemo } from 'react'
import toast from 'react-hot-toast'
import { useMatch } from 'react-location'
import { LocationGenerics } from 'router/location'
import { wrapClick } from 'utils'

type Props = {}

const ManageTreatyPage: FC<Props> = (props) => {
    const { params } = useMatch<LocationGenerics>();
    const { loading, treaty } = useTreaty({
        treatyId: params?.treaty
    });
    const [update, { loading: updating }] = useMutation<UpdateTreatyForPlaingAndCover, UpdateTreatyForPlaingAndCoverVariables>(updateTreatyForPlaingAndCover, {
        onCompleted: () => { }
    })

    const form = useFormik({
        initialValues: {
            treaty_details: []
        },
        onSubmit: () => { }
    })

    const treatyDetails = useMemo(() => JSON.parse(treaty?.treaty_details || '[]'), [treaty?.treaty_details]);
    // console.log(treatyDetails)
    useEffect(() => {
        if (treatyDetails.length) {
            form.setFieldValue('treaty_details', treatyDetails?.map((detail: any) => detail.value))
        }
    }, [treatyDetails]);


    const handleSubmit = () => {
        update({
            variables: {
                treaty_id: treaty?.treaty_id,
                treaty_details: JSON.stringify(treatyDetails?.map((el: any, index: number) => ({
                    ...el,
                    value: form.values.treaty_details[index]
                })) || [])
            }
        }).then(() => {
            toast.success('Treaty details updated successfully')
        }).catch((err) => {
            toast.error('Failed to update treaty details')
        })
    }


    if (loading || updating) return <Loader />

    return (
        <main className="flex-1 flex flex-col overflow-hidden bg-shade-500">
            <OfficeHeader renderActions={() => (
                <OfficeButton onClick={wrapClick(handleSubmit)} >
                    Apply Changes
                </OfficeButton>
            )} />
            <div className="flex flex-1 overflow-y-auto">
                {/* {JSON.stringify(form.values, null, 2)} */}
                <div className="flex-1 py-4 sm:py-6 overflow-hidden light">
                    <div className="grid gap-4 h-full grid-cols-2">
                        <div className="p-3 space-y-3 h-full overflow-y-auto no-scrollbar">
                            {treatyDetails?.map((detail: any, index: number) =>
                                index === 0 ? (
                                    <CurrencyPicker key={detail.keydetail} id={`treaty_details.${index}`} placeholder={detail?.keydetail} label={_.startCase(detail.keydetail)} {...form} />
                                ) : (
                                    <>
                                        {detail.keydetail === "Additional Information" ? (
                                            <div className="bg-red-100 p-2 text-red-600 rounded-lg">
                                                <p>
                                                    All other information (Special Exclusions, Articles,
                                                    Other data) should be placed here. This will be
                                                    appended as extra pages after the signatory page of
                                                    the treaty document" in an alert above it
                                                </p>
                                            </div>
                                        ) : null}
                                        <RichEditor key={detail.keydetail} id={`treaty_details.${index}`} placeholder={detail?.keydetail} label={detail.keydetail} {...form} />
                                    </>
                                )
                            )}
                        </div>
                        <div className="flex-1 h-[100%] overflow-y-auto">
                            <PDFRenderer url={`${config.asset.uri}treaty_placing_cover_note/${btoa(JSON.stringify({
                                treaty_id: treaty?.treaty_id,
                                doc_type: "PLACING",
                                treaty_type: treaty?.treaty_program?.treaty_type,
                                treaty_reference: treaty?.treaty_reference,
                            }))
                                }`} height={window.screen.availHeight} />
                        </div>
                    </div>
                </div>
            </div>

        </main>
    )
}

export default ManageTreatyPage