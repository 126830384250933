import { DocumentPlusIcon } from "@heroicons/react/24/outline";
import { Offers_rows } from "_graphql/queries/__generated__/Offers";
import clsx from "clsx";
import {
  ActionButton,
  InsurerPicker,
  OfficeButton,
  OfficeHeader,
  Paginator,
  RegularInsurerPicker,
  TableComponent,
  TableLoader,
} from "components";
import { Action } from "components/buttons/action-button";
import { useOffers } from "hooks/data/use-offers";
import numeral from "numeral";
import { FC, useEffect, useMemo } from "react";
import { Link, useNavigate, useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { classNames, useUrlState } from "utils";
import ViewOfferSlipContainer from "./view-slip";
import CreateOfferContainer from "./create";
import UpdateOfferContainer from "./update";
import { usePagination } from "hooks";
import { useFormik } from "formik";
import RemoveContainer from "./remove";

type Props = {};

const tabs = [
  { name: "Open Offers", href: "OPEN" },
  { name: "Pending Offers", href: "PENDING" },
];

const CreateSlipPage: FC<Props> = (props) => {
  const [modal, setModal] = useUrlState("modal");
  const [orderStatus] = useUrlState("orderStatus");
  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const { offset, limit, setPage } = usePagination();
  const form = useFormik<any>({
    initialValues: {
      insurer: "",
    },
    onSubmit: (values) => {
      console.log("values", values);
    },
  });

  const filter = useMemo(
    () => ({
      page: searchParams.page || 1,
      pageSize: searchParams.pageSize || 10,
      search: (searchParams.search || "").toString(),
      args: {
        status: orderStatus ? [orderStatus] : ["PENDING", "OPEN"],
        insurer_id: form.values.insurer || "",
        payment_status: [],
        claim_status: [],
        offset,
        limit,
      },
    }),
    [
      form.values.insurer,
      limit,
      offset,
      orderStatus,
      searchParams.page,
      searchParams.pageSize,
      searchParams.search,
    ]
  );

  const { offers, loading, refetch, count } = useOffers(filter);

  const dispatchAction =
    (id: string, action: Exclude<Action, "expand" | "goto" | "clone">) =>
      () => {
        navigate({
          search: (old) => ({
            ...old,
            id,
            modal: action,
          }),
        });
      };

  const expandItem = (id: string) => {
    navigate({
      to: `./${id}/view`,
    });
  };

  useEffect(() => {
    if (orderStatus || form.values.insurer) {
      setPage(1);
    }
  }, [orderStatus, setPage, form.values.insurer]);

  return (
    <main className="flex-1 flex flex-col overflow-hidden bg-shade-500">
      <OfficeHeader
        renderActions={() => (
          <OfficeButton onClick={() => setModal("create")}>
            <DocumentPlusIcon className="w-5 h-5 mr-2" />
            Create New Slip
          </OfficeButton>
        )}
      />
      <div className="block">
        <div className="border-b border-shade-500 bg-card-500 px-6">
          <nav className="-mb-px flex space-x-8 items-center" aria-label="Tabs">
            <Link
              search={(old) => ({
                ...old,
                orderStatus: undefined,
              })}
              className={classNames(
                !orderStatus
                  ? "border-gray-200 text-gray-100"
                  : "border-transparent text-gray-100 hover:text-gray-700 hover:border-gray-200",
                "whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm"
              )}
              aria-current={!orderStatus ? "page" : undefined}
            >
              All Offers
            </Link>
            {tabs.map((_orderStatus) => (
              <Link
                key={_orderStatus.name}
                search={(old) => ({
                  ...old,
                  orderStatus: _orderStatus.href,
                })}
                className={classNames(
                  orderStatus === _orderStatus.href
                    ? "border-gray-200 text-gray-100"
                    : "border-transparent text-gray-100 hover:text-gray-700 hover:border-gray-200",
                  "whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm"
                )}
                aria-current={
                  orderStatus === _orderStatus.href ? "page" : undefined
                }
              >
                {_orderStatus.name}
              </Link>
            ))}
          </nav>
        </div>
      </div>
      <div className="flex flex-1 overflow-y-auto">
        <div className="flex-1 mx-auto min-w-0  py-4 sm:py-6 overflow-hidden overflow-y-auto light flex">
          {/* {JSON.stringify(form.values)} */}
          <TableComponent
            title={"offers"}
            loading={loading}
            hasSearch
            renderHeaderItems={() => (
              <>
                <RegularInsurerPicker
                  placeholder="Filter by Cedant"
                  id={"insurer"}
                  label={""}
                  rawId
                  labelHidden
                  {...form}
                />
              </>
            )}
            pagination={() => (
              <Paginator
                offset={offset}
                limit={limit}
                totalItems={count || 0}
                currentSize={offers.length}
                setOffset={setPage}
              />
            )}
            columns={[
              {
                name: "Policy #",
                accessor: "offer_detail.policy_number",
                render: (value: Offers_rows) => (
                  <span className="font-bold text-xs px-2">
                    {value.offer_detail?.policy_number || "N/A"}
                  </span>
                ),
              },
              {
                name: "Insurance Company",
                accessor: "insurer.insurer_company_name",
                render: (value: Offers_rows) => (
                  <div className="flex flex-col text-xs">
                    <span className="font-medium">
                      {value.insurer?.insurer_company_name || "N/A"}
                    </span>
                    <span className="font-light">
                      {value.insurer?.insurer_company_email || "N/A"}
                    </span>
                  </div>
                ),
              },
              {
                name: "Insured",
                accessor: "offer_detail.insured_by",
                render: (value: Offers_rows) => {
                  const details = JSON.parse(
                    value?.offer_detail?.offer_details || "[]"
                  );
                  return (
                    <div className="flex flex-col text-xs ">
                      <span className="font-medium">
                        {value.offer_detail?.insured_by || "N/A"}
                      </span>
                      <span className="font-light">
                        {[
                          "Motor Comprehensive Fleet",
                          "Motor Comprehensive",
                          "Motor Comprehensive (Automobile Fac Facility)",
                        ].includes(value?.classofbusiness?.business_name || "")
                          ? details?.find(
                            (el: { [x: string]: string }) =>
                              el["keydetail"] === "Vehicle Reg No" ||
                              el["keydetail"] === "Vehicle Reg No."
                          )?.value
                          : ""}
                      </span>
                    </div>
                  );
                },
              },
              {
                name: "Class Of Business",
                accessor: "classofbusiness.business_name",
                render: (value: Offers_rows) => (
                  <div className="flex flex-col text-xs">
                    <span className="font-medium">
                      {value.classofbusiness?.business_name || "N/A"}
                    </span>
                  </div>
                ),
              },
              {
                name: "Sum Insured",
                accessor: "sum_insured",
                render: (value: Offers_rows) => (
                  <div className="flex flex-col text-xs">
                    <span className="font-medium">
                      {value?.offer_detail?.currency}{" "}
                      {numeral(value.sum_insured).format("0,0 ") || "N/A"}
                    </span>
                  </div>
                ),
              },
              {
                name: "Rate",
                accessor: "rate",
              },
              {
                name: "Status",
                accessor: "offer_status",
                render: ({ offer_status }: Offers_rows) => (
                  <span
                    className={clsx("text-xs", {
                      "text-blue-600": offer_status === "OPEN",
                      "text-yellow-600": offer_status === "PENDING",
                    })}
                  >
                    {offer_status}
                  </span>
                ),
              },
              {
                name: "Offer Date",
                accessor: "created_at",
                render: (value: Offers_rows) => (
                  <span>{new Date(value.created_at).toLocaleDateString()}</span>
                ),
              },
              {
                name: "Created By",
                accessor: "employee.employee_first_name",
                render: (value: Offers_rows) => (
                  <div className="flex flex-row leading-0">
                    <span>
                      {value?.employee?.employee_first_name}{" "}
                      {value?.employee?.employee_last_name}
                    </span>
                  </div>
                ),
              },
              {
                name: "Actions",
                accessor: "actions",
                render: (value: Offers_rows) => (
                  <>
                    <ActionButton
                      action="view"
                      tooltip="View Placing Slip"
                      onClick={dispatchAction(value.offer_id, "resolve")}
                    />
                    <ActionButton
                      action="update"
                      tooltip="Edit Offer"
                      onClick={dispatchAction(value.offer_id, "update")}
                    />
                    <ActionButton
                      action="resolve"
                      tooltip="View Offer"
                      onClick={() => expandItem(value?.offer_id)}
                    />
                    <ActionButton
                      action="remove"
                      tooltip="Delete Offer"
                      extraClassName="text-red-500"
                      onClick={dispatchAction(value?.offer_id, "remove")}
                    />
                  </>
                ),
              },
            ]}
            data={offers}
            refetch={refetch}
            renderLoader={() => (
              <TableLoader items={[1, 2, 1, 1, 1, 1, 1, 1, 1, 1]} />
            )}
          />
        </div>
      </div>
      <CreateOfferContainer
        open={modal === "create"}
        setOpen={(val: boolean) => setModal(val ? "create" : undefined)}
        refetch={refetch}
      />
      {searchParams.id && (
        <>
          <ViewOfferSlipContainer
            open={modal === "resolve"}
            setOpen={(val: boolean) => setModal(val ? "resolve" : undefined)}
          />
          <RemoveContainer
            open={modal === "remove"}
            setOpen={(val: boolean) => setModal(val ? "remove" : undefined)}
          />
          <UpdateOfferContainer
            open={modal === "update"}
            setOpen={(val: boolean) => setModal(val ? "update" : undefined)}
            refetch={refetch}
          />
        </>
      )}
    </main>
  );
};

export default CreateSlipPage;
