import React from 'react'
import ProportionalPayments from './tabs/proportional-payments'
import NonProportionalPayments from './tabs/non-proportional-payments'

type Props = {
    treaty_type: string
}

const Payments = ({ treaty_type }: Props) => {
    return treaty_type === 'PROPORTIONAL' ? <ProportionalPayments /> : <NonProportionalPayments />
}

export default Payments