import { gql } from "@apollo/client";

export const getOfferEndorsements = gql`
  query GetEndorsements($args: EndorsementFilters) {
    count: getEndorsementsCount(args: $args)
    rows: getEndorsements(args: $args) {
      rate
      commission
      commission_amount
      brokerage
      facultative_offer
      sum_insured
      fac_sum_insured
      premium
      fac_premium
      offer {
        offer_id
      }
      insurer {
        insurer_id
        insurer_company_name
        insurer_company_email
        insurer_company_website
        insurer_address {
          suburb
          region
          country
        }
      }
      classofbusiness {
        class_of_business_id
        business_name
        business_details
        created_at
      }
      created_at
      offer_endorsement_detail {
        offer_endorsement_detail_id
        policy_number
        insured_by
        period_of_insurance_to
        period_of_insurance_from
        currency
        offer_comment
        information_comment
        offer_detail
      }
      offer_endorsement_participants {
        offer_end_participant_id
        offer_end_participant_percentage
        offer_end_amount
        offer_end_participant_fac_premium
        offer_end_participant_fac_sum_insured
        offer_endorsement_extra_charges {
          end_nic_levy
          end_nic_levy_amount
          end_withholding_tax
          end_agreed_brokerage_percentage
          end_brokerage_amount
          end_withholding_tax_amount
          end_agreed_commission
          end_agreed_commission_amount
        }
        reinsurer {
        reinsurer_id
        re_company_name
        re_company_email
        re_company_website
        re_abbrv
      }
      }
      approval_status
      co_insurance_share
      endorsement_status
      updated_at
      offer_endorsement_id
    }
  }
`;


export const getEndorsement = gql`
query GetEndorsement($offerEndorsementId: ID) {
  singleEndorsement(offer_endorsement_id: $offerEndorsementId) {
    rate
    commission
    commission_amount
    brokerage
    facultative_offer
    sum_insured
    fac_sum_insured
    premium
    fac_premium
    offer {
      offer_id
      rate
      commission
      commission_amount
      brokerage
      facultative_offer
      sum_insured
      fac_sum_insured
      premium
      fac_premium
      offer_status
      payment_status
      claim_status
      offer_detail {
        offer_detail_id
        offersoffer_id
        policy_number
        insured_by
        period_of_insurance_to
        period_of_insurance_from
        currency
        offer_comment
        offer_details
      }
      insurer {
        insurer_id
        insurer_company_name
        insurer_company_email
        insurer_company_website
        insurer_address {
          suburb
          region
          country
        }
      }
      classofbusiness {
        class_of_business_id
        business_name
        business_details
        created_at
      }
      created_at
    }
    insurer {
      insurer_id
      insurer_company_name
      insurer_company_email
      insurer_company_website
      insurer_address {
        suburb
        region
        country
      }
    }
    classofbusiness {
      class_of_business_id
      business_name
      business_details
      created_at
    }
    created_at
    offer_endorsement_detail {
      offer_endorsement_detail_id
      policy_number
      insured_by
      period_of_insurance_to
      period_of_insurance_from
      currency
      offer_comment
      information_comment
      offer_detail
    }
    offer_endorsement_participants {
      offer_end_participant_id
      offer_end_participant_percentage
      offer_end_amount
      offer_end_participant_fac_premium
      offer_end_participant_fac_sum_insured
      offer_endorsement_extra_charges {
        end_nic_levy
        end_nic_levy_amount
        end_withholding_tax
        end_agreed_brokerage_percentage
        end_brokerage_amount
        end_withholding_tax_amount
        end_agreed_commission
        end_agreed_commission_amount
      }
      reinsurer {
        reinsurer_id
        re_company_name
        re_company_email
        re_company_website
        re_abbrv
      }
    }
    approval_status
    co_insurance_share
    endorsement_status
    updated_at
    offer_endorsement_id
  }
}
`