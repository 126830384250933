import { gql } from "@apollo/client";

export const createBroker = gql`
  mutation CreateReBroker($reBroker: ReBrokerInput) {
    createReBroker(re_broker: $reBroker)
  }
`;

export const updateBroker = gql`
  mutation UpdateReBroker($reBroker: ReBrokerInput, $reBrokerId: ID) {
    updateReBroker(re_broker: $reBroker, re_broker_id: $reBrokerId)
  }
`;

export const removeBroker = gql`
  mutation DeleteReBroker($reBrokerId: ID) {
    deleteReBroker(re_broker_id: $reBrokerId)
  }
`;

export const createBrokerRep = gql`
  mutation CreateReBrokerAssociates(
    $reBrokerAssociate: ReBrokerAssociateInput
  ) {
    createReBrokerAssociates(re_broker_associate: $reBrokerAssociate)
  }
`;
