import { FormItem } from "components/core/step-form-builder";
import TreatyForm from "./treaty-form";
import TreatyFormSummary from "./treaty-form-summary";
import TreatyDeductionForm from "./treaty-deduction-form";
import TreatyDeductionFormSummary from "./treaty-deduction-form-summary";
import TreatySurplusForm from "./treaty-surplus-form";
import TreatySurplusFormSummary from "./treaty-surplus-form-summary";

export const createTreatyFormItems: FormItem[] = [
  {
    accessor: "treaty",
    description: "Provide details to add new treaty",
    name: "Treaty Details",
    FormComponent: TreatyForm,
    SummaryComponent: TreatyFormSummary,
  },
  {
    accessor: "deductions",
    description: "Provide details to add new treaty deduction",
    name: "Treaty Layers",
    FormComponent: TreatyDeductionForm,
    SummaryComponent: TreatyDeductionFormSummary,
  },
  {
    accessor: "surplus",
    description: "Provide details to add new treaty surplus",
    name: "Treaty Surpluses",
    FormComponent: TreatySurplusForm,
    SummaryComponent: TreatySurplusFormSummary,
  },
];
