import { useMutation } from "@apollo/client";
import toast from "react-hot-toast";
import { PopUp } from "components";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { removeASsociateFromParticipation } from "_graphql/mutation/offers";
import {
  RemoveAssociateFromParticipation,
  RemoveAssociateFromParticipationVariables,
} from "_graphql/mutation/__generated__/RemoveAssociateFromParticipation";

export default function RemoveAssociateContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const [remove, { loading }] = useMutation<
    RemoveAssociateFromParticipation,
    RemoveAssociateFromParticipationVariables
  >(removeASsociateFromParticipation, {
    refetchQueries: ["GetOfferAssociates"],
  });

  const handleSubmit = async () => {
    await remove({
      variables: {
        offer_to_associate_id: searchParams.id || "",
      },
    })
      .then(({ data }) => {
        if (data?.removeParticipantByParticipant) {
          toast.success("Participant Associate Removed Successfully");
          refetch?.();
          setOpen?.(false);
        } else {
          toast.error("Could not remove participant associate");
        }
      })
      .catch(() => {
        toast.error("Could not remove participant associate");
      });
  };

  return (
    <PopUp
      open={open}
      setOpen={setOpen}
      title="Delete Participant Associate"
      cancelText="No, Don't Delete Participant Associate"
      description="This action would completely remove associate completely from offer participation along with all the data associated with it. This action cannot be undone. Are you sure you want to continue?"
      renderActions={() => (
        <>
          <button
            type="button"
            disabled={loading}
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={handleSubmit}
          >
            {loading
              ? "Deleting Participant Associate..."
              : "Delete Participant Associate"}
          </button>
        </>
      )}
    />
  );
}
