import { wrapClick } from "utils";
import { useMutation } from "@apollo/client";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { Modal } from "components";
import { ProgramForm } from "components/forms";
import { LocationGenerics } from "router/location";
import { useSearch } from "react-location";
import { useEffect } from "react";
import { treatyValidationSchema } from "./create";
import { useTreatyProgram } from "hooks/data/use-treaty-programs";
import {
  UpdateTreatyProgram,
  UpdateTreatyProgramVariables,
} from "_graphql/mutation/__generated__/UpdateTreatyProgram";
import { updateTreatyProgram } from "_graphql/mutation/treaty-programs";

export default function UpdateContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const { loading: fetching, treatyProgram } = useTreatyProgram({
    programId: searchParams.id || "",
  });
  const [create, { loading }] = useMutation<
    UpdateTreatyProgram,
    UpdateTreatyProgramVariables
  >(updateTreatyProgram, {
    refetchQueries: ["TreatyProgram", "TreatyPrograms"],
  });

  const form = useFormik<any>({
    initialValues: {
      treaty_name: "",
      insurer_id: "",
      treaty_type: "",
      requests: [],
    },
    validationSchema: treatyValidationSchema,
    onSubmit: async (variables) => {
      await create({
        variables: {
          program: {
            treaty_details: JSON.stringify(
              variables.requests?.map((item: any) => ({
                keydetail: item.keyDetail,
                required: item.required,
                type: item.type,
              }))
            ),
            treaty_name: variables.treaty_name,
            treaty_type: variables.treaty_type,
            insurersinsurer_id: variables.insurer_id,
          },
          programId: searchParams.id || "",
        },
      }).then(({ data }) => {
        if (data?.updateTreatyProgram) {
          toast.success("Program updated Successfully");
          refetch?.();
          form.resetForm();
        } else {
          toast.error("Failed to update program");
        }
      });
    },
    onReset: () => {
      setOpen(false);
    },
  });

  useEffect(() => {
    if (treatyProgram) {
      form.setFieldValue("treaty_name", treatyProgram.treaty_name);
      form.setFieldValue("treaty_type", treatyProgram.treaty_type);
      form.setFieldValue("insurer_id", treatyProgram.insurer?.insurer_id);
      form.setFieldValue(
        "requests",
        JSON.parse(treatyProgram.treaty_details || "[]")?.map((item: any) => ({
          keyDetail: item.keydetail,
          required: item.required || "Yes",
          type: item.type || "Text",
        }))
      );
    }
  }, [treatyProgram]);

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      loading={fetching}
      title="Update Treaty Program"
      description="Provide the details to update treaty program"
      renderActions={() => (
        <>
          <button
            type="button"
            disabled={loading}
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(form.handleSubmit)}
          >
            {loading ? "Updating Treaty Program..." : "Update Treaty Program"}
          </button>
        </>
      )}
    >
      <ProgramForm form={form} />
    </Modal>
  );
}
