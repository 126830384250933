import { RegularSelectInput, TextInput } from "components/core";
import { FormikProps } from "formik";
import _ from "lodash";
import { FC } from "react";

export interface LimitLayerFormProps {
  form: FormikProps<any>;
}

const LimitLayerForm: FC<LimitLayerFormProps> = ({ form }) => {
  return (
    <>
      <div className="space-y-6 divide-y divide-gray-200">
        <div className="grid grid-cols-2 gap-6 mt-2">
          <div className="">
            <TextInput
              id={"limit.limit"}
              label="Limit"
              required
              placeholder="Eg. 10"
              {...form}
            />
          </div>
          <div className="">
            <TextInput
              id={"limit.deductible"}
              label="Deductible"
              required
              placeholder="Eg. 10"
              {...form}
            />
          </div>
          <div className="">
            <TextInput
              id={"limit.m_and_d_premium"}
              label="M&D Premium"
              required
              placeholder="Eg. 10"
              {...form}
            />
          </div>
          <div className="">
            <RegularSelectInput
              options={[
                { label: "1", value: 1 },
                { label: "2", value: 2 },
                { label: "3", value: 3 },
                { label: "4", value: 4 },
              ]}
              id={"limit.installment_type"}
              label="Installments"
              required
              placeholder="Eg. 10"
              {...form}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default LimitLayerForm;
