import { CurrencyPicker, RichEditor, Show, TextInput } from "components/core";
import { FormikProps } from "formik";
import { FC, useEffect } from "react";
import "@mantine/tiptap/styles.css";
import { Offer_findSingleOffer } from "_graphql/queries/__generated__/Offer";
import numeral from "numeral";
import { Switch } from "@headlessui/react";
import { classNames } from "utils";

export interface PaymentFormProps {
  form: FormikProps<any>;
  offer: Offer_findSingleOffer | undefined;
  claimableAmount: number;
}

const PaymentForm: FC<PaymentFormProps> = ({
  form,
  offer,
  claimableAmount,
}) => {
  useEffect(() => {
    if (!form.values.anotherCurrency) {
      form.setFieldValue("currency", offer?.offer_detail?.currency);
      form.setFieldValue("rate", 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.values.anotherCurrency]);

  return (
    <div className="space-y-6 divide-y divide-gray-200">
      <div className="mt-6 border border-gray-300 border-dashed bg-gray-100 divide-y divide-dashed divide-gray-300 rounded-md">
        <div className="grid grid-cols-3 gap-4 p-4">
          <div className="">
            <span className="block text-sm font-light text-gray-700">
              Policy Number
            </span>
            <div className="mt-1 block w-full sm:text-sm font-medium">
              {offer?.offer_detail?.policy_number || "N/A"}
            </div>
          </div>
          <div className="col-span-2">
            <span className="block text-sm font-light text-gray-700">
              Insurer
            </span>
            <div className="mt-1 block w-full sm:text-sm font-medium">
              {offer?.insurer?.insurer_company_name || "N/A"}
            </div>
          </div>
          <div className="">
            <span className="block text-sm font-light text-gray-700">
              Facultative Offer <b>(%)</b>
            </span>
            <div className="mt-1 block w-full sm:text-sm font-medium">
              {offer?.facultative_offer || "N/A"}
            </div>
          </div>
          <div className="">
            <span className="block text-sm font-light text-gray-700">
              Facultative Sum Insured
            </span>
            <div className="mt-1 block w-full sm:text-sm font-medium">
              {form.values.currency}{" "}
              {numeral(offer?.fac_sum_insured)
                .multiply(form.values.rate)
                .format("#,#.##") || "N/A"}
            </div>
          </div>
          <div className="">
            <span className="block text-sm font-light text-gray-700">
              Claimable Amount
            </span>
            <div className="mt-1 block w-full sm:text-sm font-medium">
              {form.values.currency}{" "}
              {numeral(form.values.amountToClaim).multiply(form.values.rate).format("#,#.##") || "N/A"}
            </div>
          </div>
        </div>
        <div className="col-span-2 p-3">
          <Switch.Group as="div">
            <span className="flex flex-grow flex-col">
              <Switch.Label
                as="span"
                className="text-sm font-medium text-gray-900"
                passive
              >
                Pay in another currency
              </Switch.Label>
            </span>
            <div className="flex items-center justify-between h-[38px]">
              <Switch.Description as="span" className="text-sm text-gray-500">
                Supply your own exchange rate and currency
              </Switch.Description>
              <Switch
                checked={form.values.anotherCurrency}
                onChange={(val: boolean) =>
                  form.setFieldValue("anotherCurrency", val)
                }
                className={classNames(
                  form.values.anotherCurrency
                    ? "bg-primary-600"
                    : "bg-gray-200",
                  "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                )}
              >
                <span
                  aria-hidden="true"
                  className={classNames(
                    form.values.anotherCurrency
                      ? "translate-x-5"
                      : "translate-x-0",
                    "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                  )}
                />
              </Switch>
            </div>
          </Switch.Group>
        </div>
      </div>
      {/* {JSON.stringify(form.values, null, 2)} */}
      <div className="pt-6">
        <span className="text-xs font-light">Claim Details</span>
        <div className="grid grid-cols-2 gap-2">
          <Show if={form.values.anotherCurrency}>
            <>
              <div>
                <TextInput
                  type="number"
                  id="rate"
                  label="Rate"
                  placeholder="e.g. 5.7"
                  required={true}
                  {...form}
                />
              </div>
              <div>
                <CurrencyPicker
                  id="currency"
                  label="Currency"
                  placeholder="e.g. 5.7"
                  required={true}
                  {...form}
                />
              </div>
            </>
          </Show>
          <div>
            <TextInput
              type="text"
              id="amount"
              label="Claim Amount"
              placeholder="e.g. 30000"
              max={claimableAmount}
              required={true}
              {...form}
            />
          </div>
          <div>
            <TextInput
              type="date"
              id="claim_date"
              label="Claim Date"
              placeholder="e.g. ECG Ghana"
              required={true}
              {...form}
            />
          </div>

          <div className="col-span-2">
            <RichEditor
              id="claim_comment"
              label="Comment"
              placeholder="e.g. type comment here"
              {...form}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentForm;
