import { useMutation } from "@apollo/client";
import toast from "react-hot-toast";
import { PopUp } from "components";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { RemoveInsurer, RemoveInsurerVariables } from "_graphql/mutation/__generated__/RemoveInsurer";
import { removeInsurer } from "_graphql/mutation/insurer";



export default function RemoveContainer({
    open,
    setOpen,
    refetch,
}: {
    open: boolean;
    setOpen: (val: boolean) => void;
    refetch?: () => void;
}) {
    const searchParams = useSearch<LocationGenerics>()
    const [remove, { loading }] = useMutation<RemoveInsurer, RemoveInsurerVariables>(removeInsurer, {
        refetchQueries: ["Insurers"]
    });


    const handleSubmit = async () => {
        await remove({
            variables: {
                insurerId: searchParams?.id || ""
            },
        }).then(({ data }) => {
            if (data?.removeInsurer) {
                toast.success("Insurer Removed Successfully");
                refetch?.();
                setOpen?.(false);
            } else {
                toast.error("Failed to remove Insurer");
            }
        });
    }

    return (
        <PopUp
            open={open}
            setOpen={setOpen}
            title="Remove Insurer"
            cancelText="No, Don't Remove Insurer"
            description="This action would completely remove insurer details from system"
            renderActions={() => (
                <>
                    <button
                        type="button"
                        disabled={loading}
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={handleSubmit}
                    >
                        {loading ? "Removing Insurer..." : "Remove Insurer"}
                    </button>
                </>
            )}
        />
    );
}
