import { useQuery } from "@apollo/client";
import {
  GetInsurerAssociates,
  GetInsurerAssociatesVariables,
} from "_graphql/queries/__generated__/GetInsurerAssociates";
import {
  Insurer,
  InsurerVariables,
} from "_graphql/queries/__generated__/Insurer";
import { Insurers } from "_graphql/queries/__generated__/Insurers";
import {
  getInsurer,
  getInsurerAssociate,
  getInsurerAssociates,
  getInsurers,
} from "_graphql/queries/insurers";
import { iVariables } from "./use-offers";
import _ from "lodash";
import { GetInsurerAssociate, GetInsurerAssociateVariables } from "_graphql/queries/__generated__/GetInsurerAssociate";

export const useInsurers = (variables?: any) => {
  const { page, pageSize, search } = variables || {
    page: 1,
    pageSize: 10,
    search: "",
  };
  const start = (parseInt(page) - 1) * pageSize;
  const end = start + pageSize;
  const { data, ...rest } = useQuery<Insurers>(getInsurers, {
    variables: undefined,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });
  const items = data?.rows
    ?.filter((el) => {
      return (
        el?.insurer_company_name
          ?.toLowerCase()
          .includes(search.toLowerCase()) ||
        el?.insurer_company_email
          ?.toLowerCase()
          .includes(search.toLowerCase()) ||
        el?.insurer_abbrv?.toLowerCase().includes(search.toLowerCase()) ||
        el?.insurer_company_website
          ?.toLowerCase()
          .includes(search.toLowerCase())
      );
    })
    .slice(start, end);
  return {
    insurers: items || [],
    count: data?.count || 0,
    ...rest,
  };
};

export const useInsurerManagers = (
  variables?: GetInsurerAssociatesVariables & iVariables
) => {
  const { page, pageSize, search, ...remainingVariables } = variables || {
    page: 1,
    pageSize: 10,
    search: "",
  };
  const start = (page - 1) * pageSize;
  const end = start + pageSize;
  const { data, ...rest } = useQuery<
    GetInsurerAssociates,
    GetInsurerAssociatesVariables
  >(getInsurerAssociates, {
    variables: remainingVariables,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });
  const items = data?.getInsurerAssociates
    ?.filter((el) => {
      return (
        el?.assoc_first_name?.toLowerCase().includes(search.toLowerCase()) ||
        el?.assoc_last_name?.toLowerCase().includes(search.toLowerCase()) ||
        el?.assoc_email?.toLowerCase().includes(search.toLowerCase()) ||
        el?.assoc_primary_phonenumber
          ?.toLowerCase()
          .includes(search.toLowerCase())
      );
    })
    .slice(start, end);
  return {
    managers: items || [],
    ...rest,
  };
};

export const useInsurer = (id: string) => {
  const { data, ...rest } = useQuery<Insurer, InsurerVariables>(getInsurer, {
    variables: {
      insurerId: _.isInteger(parseInt(id)) ? id : "1",
    },
    notifyOnNetworkStatusChange: false,
    fetchPolicy: "no-cache",
  });
  return {
    insurer: data?.insurer,
    ...rest,
  };
};

export const useInsurerManager = (id: string) => {
  const { data, ...rest } = useQuery<GetInsurerAssociate, GetInsurerAssociateVariables>(getInsurerAssociate, {
    variables: {
      insurerAssociateId: _.isInteger(parseInt(id)) ? id : "1",
    },
    notifyOnNetworkStatusChange: false,
    fetchPolicy: "no-cache",
  });
  return {
    insurer: data?.getInsurerAssociate,
    ...rest,
  };
};
