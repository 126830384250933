import { validators, wrapClick } from "utils";
import { gql, useMutation } from "@apollo/client";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { Modal } from "components";
import * as Yup from "yup";
import { createInsurer } from "_graphql/mutation/insurer";
import { InsurerForm } from "components/forms";
import { CreateInsurer, CreateInsurerVariables } from "_graphql/mutation/__generated__/CreateInsurer";
import { error } from "console";


export const valiadationSchema = Yup.object().shape({
    insurer_company_name: Yup.string().required("Company name is required"),
    insurer_company_website: Yup.string().notRequired(),
    insurer_company_email: Yup.string().email("Invalid Email address").required("Email is required"),
    country: Yup.string().required("Country is required"),
    region: Yup.string().required("Region/State is required"),
    street: Yup.string().required("Street Address is required"),
    suburb: Yup.string().required("Suburb/City is required"),
});

export default function CreateInsurerContainer({
    open,
    setOpen,
    refetch,
}: {
    open: boolean;
    setOpen: (val: boolean) => void;
    refetch?: () => void;
}) {
    const [create, { loading }] = useMutation<CreateInsurer, CreateInsurerVariables>(createInsurer);

    const form = useFormik<CreateInsurerVariables["insurer"]>({
        initialValues: {
            insurer_company_email: "",
            insurer_company_name: "",
            insurer_company_website: "",
            country: "",
            region: "",
            street: "",
            suburb: ""
        },
        validationSchema: valiadationSchema,
        onSubmit: async (variables) => {
            await create({
                variables: {
                    insurer: variables,
                },
            }).then(({ data }) => {
                if (data?.createInsurer.insurer_id) {
                    toast.success("Insurer Created Successfully");
                    refetch?.();
                    form.resetForm();
                } else {
                    toast.error("Failed to create Insurer");
                }
            });
        },
        onReset: () => {
            setOpen(false);
        },
    });

    return (
        <Modal
            open={open}
            setOpen={setOpen}
            title="Add New Insurer"
            description="Provide the details to add a new insurer"
            renderActions={() => (
                <>
                    <button
                        type="button"
                        disabled={loading}
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={wrapClick(form.handleSubmit)}
                    >
                        {loading ? "Adding Insurer..." : "Add Insurer"}
                    </button>
                </>
            )}
        >
            <InsurerForm form={form} />
        </Modal>
    );
}
