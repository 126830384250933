import { CreateReinsurerRepVariables } from "_graphql/mutation/__generated__/CreateReinsurerRep";
import { RegularSelectInput, SelectInput, TextInput } from "components/core";
import { FormikProps } from "formik";
import { FC } from "react";

export interface InsurerRepProps {
  form: FormikProps<CreateReinsurerRepVariables["reinsurerRep"]>;
}

const InsurerRep: FC<InsurerRepProps> = ({ form }) => {
  return (
    <div className="space-y-6 divide-y divide-gray-200">
      {/* {JSON.stringify(form.errors, null, 2)} */}
      <div>
        <span className="text-xs font-light">Representative Information</span>
        <div className="grid grid-cols-2 gap-2">
          <div>
            <TextInput
              id="rep_first_name"
              label="First Name"
              type="text"
              placeholder="e.g. Emmanuel"
              required={true}
              {...form}
            />
          </div>

          <div className="space-y-1 ">
            <TextInput
              id="rep_last_name"
              label="Last Name"
              type="text"
              placeholder="e.g. Baidoo"
              required={true}
              {...form}
            />
          </div>
          <div>
            <TextInput
              id="rep_email"
              label="Email"
              type="text"
              placeholder="e.g. ebaidoo79@gmail.com"
              required={true}
              {...form}
            />
          </div>

          <div className="space-y-1 ">
            <TextInput
              id="rep_primary_phonenumber"
              label="Primary Phone Number"
              type="text"
              placeholder="e.g. 0506339153"
              required={true}
              {...form}
            />
          </div>
          <div className="space-y-1 ">
            <TextInput
              id="rep_secondary_phonenumber"
              label="Secondary Phone Number"
              type="text"
              placeholder="e.g. 0506339153"
              {...form}
            />
          </div>
          <div className="space-y-1  ">
            <RegularSelectInput
              id="position"
              label="Position"
              placeholder=""
              options={[
                { label: "Manager", value: "Manager" },
                { label: "Underwriter", value: "Underwriter" },
              ]}
              required={true}
              {...form}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InsurerRep;
