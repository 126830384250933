import { useMutation } from "@apollo/client";
import { Switch } from "@headlessui/react";
import {
  ReportOnQuery,
  ReportOnQueryVariables,
} from "_graphql/queries/__generated__/ReportOnQuery";
import { generateReport } from "_graphql/queries/reports";
import {
  BusinessGroupPicker,
  BusinessPicker,
  InsurerPicker,
  OfficeButton,
  ReInsurerPicker,
  SelectInput,
  TableComponent,
  TableLoader,
  TextInput,
} from "components";
import { useFormik } from "formik";
import { classNames, wrapClick } from "utils";
import * as Yup from "yup";

const initialValues = {
  business_category: [],
  business_id: [],
  client_type: "",
  insurer_id: [],
  reinsurer_id: [],
  from: "",
  to: "",
  period_status: [],
  offer_status: [],
  payment_status: [],
  showAdvancedFilters: false,
  deduction: "",
  convert_to: "",
  convert_from: [],
  queryOnPayment: false,
};

const validationSchema = Yup.object().shape({
  showAdvancedFilters: Yup.boolean().notRequired(),
  business_category: Yup.array()
    .min(1, "Select business group")
    .required("Business category is required"),
  business_id: Yup.array()
    .min(1, "Select one business")
    .required("Class of business is required"),
  client_type: Yup.string().required("Client type is required"),
  // inurer_id or reinsurer_id is required when client type is selected and value is either insurer or reinsurer
  insurer_id: Yup.array().when("client_type", {
    is: (val: string) => val === "Insurer",
    then: (schema) =>
      schema
        .min(1, "At least one Insurer is Required")
        .required("Insurer is required"),
    otherwise: (schema) => schema.notRequired(),
  }),
  reinsurer_id: Yup.array().when("client_type", {
    is: (val: string) => val === "Reinsurer",
    then: (schema) =>
      schema
        .min(1, "At least one Reinsurer is Required")
        .required("Reinsurer is required"),
    otherwise: (schema) => schema.notRequired(),
  }),
  deduction: Yup.string().when(["client_type", "showAdvancedFilters"], {
    is: (val: string, val2: boolean) => val === "Reinsurer" && val2 === true,
    then: (schema) => schema.required("Deduction is required"),
    otherwise: (schema) => schema.notRequired(),
  }),
  from: Yup.string().required("From date is required"),
  to: Yup.string().required("To date is required"),
  period_status: Yup.array()
    .min(1, "Select offer period")
    .required("Offer period is required"),
  offer_status: Yup.array()
    .min(1, "Select offer status")
    .required("Offer status is required"),
});

const ReportingTab = () => {
  const [generate, { loading, data }] = useMutation<
    ReportOnQuery,
    ReportOnQueryVariables
  >(generateReport);
  const form = useFormik<any>({
    initialValues,
    validationSchema,
    onSubmit: () => {
      generate({
        variables: {
          data: {
            ...form.values,
            deduction:
              form.values.client_type === "Reinsurer"
                ? form.values.deduction
                : undefined,
            business_category: undefined,
            showAdvancedFilters: undefined,
            insurer_id:
              form.values.client_type === "Insurer"
                ? form.values.insurer_id
                : undefined,
            reinsurer_id:
              form.values.client_type === "Reinsurer"
                ? form.values.reinsurer_id
                : undefined,
            payment_from: form.values.from,
            payment_to: form.values.to,
          },
        },
      })
        .then(({ data }) => {
          console.log("data", data);
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
  });
  return (
    <div className="w-full">
      <div className="py-3 flex justify-between">
        <div>
          <h1 className="font-bold text-gray-300 text-lg">Reporting</h1>
        </div>
        <div className="flex items-center space-x-2">
          <OfficeButton
            disabled={loading}
            onClick={wrapClick(form.handleSubmit)}
          >
            {loading ? "Generating ..." : "Generate Report"}
          </OfficeButton>
          {JSON.parse(data?.reportOnQuery || "{}")?.downloadable_xlsx_link && (
            <a
              target="_blank"
              className="bg-teal-500 text-white p-2 rounded-md shadow-sm"
              href={`${JSON.parse(data?.reportOnQuery || "{}")?.downloadable_xlsx_link
                }`}
            >
              Download Excel Report
            </a>
          )}</div>
      </div>
      <form className="space-y-6 divide-y shadow-sm rounded-md bg-white p-6 divide-gray-200">
        <div>
          <span className="text-xs font-light">Reporting Parameters</span>
          <div className="grid grid-cols-2 gap-2">
            <div>
              <BusinessGroupPicker
                isMulti
                id={"business_category"}
                placeholder="select business category"
                label={"Business Group"}
                {...form}
              />
            </div>
            <div>
              <BusinessPicker
                addAllOption
                filter={form.values.business_category}
                isMulti
                rawId
                id={"business_id"}
                placeholder="select class of business"
                label={"Class of Business"}
                {...form}
              />
            </div>
            <div>
              <SelectInput
                id={"client_type"}
                label={"Client Type"}
                options={[
                  { label: "Reinsurer", value: "Reinsurer" },
                  { label: "Insurer", value: "Insurer" },
                ]}
                {...form}
              />
            </div>
            {form.values.client_type === "Insurer" ? (
              <div>
                <InsurerPicker
                  addAllOption
                  isMulti
                  id={"insurer_id"}
                  rawId
                  label={"Insurer"}
                  placeholder="select Insurer"
                  {...form}
                />
              </div>
            ) : (
              <div>
                <ReInsurerPicker
                  addAllOption
                  isMulti
                  rawId
                  id={"reinsurer_id"}
                  placeholder="select reinsurer"
                  label={"Reinsurer"}
                  {...form}
                />
                <div className="flex justify-end pt-1">
                  <span
                    onClick={() =>
                      form.setFieldValue(
                        "showAdvancedFilters",
                        !form.values.showAdvancedFilters
                      )
                    }
                    className=" text-xs text-primary-500 text-right cursor-pointer"
                  >
                    Show Advanced Filters
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
        {form.values.showAdvancedFilters && (
          <div className="pt-6">
            <span className="text-xs font-light">Advanced Filter</span>
            <div className="grid grid-cols-2 gap-2">
              <div>
                <SelectInput
                  id="deduction"
                  label="Filter by"
                  options={[
                    { label: "NIC Levy", value: "NIC LEVY" },
                    { label: "Withholding Tax", value: "WHT" },
                  ]}
                  required={true}
                  {...form}
                />
              </div>
            </div>
          </div>
        )}
        <div className="pt-6">
          <span className="text-xs font-light">Offer Activity</span>
          <div className="grid grid-cols-2 gap-2">
            <div>
              <SelectInput
                id="period_status"
                label="Filter by offer period"
                multiple
                options={[
                  { label: "ACTIVE", value: "ACTIVE" },
                  { label: "EXPIRED", value: "EXPIRED" },
                ]}
                required={true}
                {...form}
              />
            </div>
            <div>
              <SelectInput
                id="offer_status"
                label="Filter by offer status"
                multiple
                options={getOfferStatuses(form.values.offer_status)}
                required={true}
                {...form}
              />
            </div>
            {(form.values.offer_status.includes("CLOSED") ||
              form.values.offer_status.includes("All")) && (
                <div>
                  <SelectInput
                    id="payment_status"
                    label="Filter by Payment status"
                    multiple
                    options={[
                      { label: "PAID", value: "PAID" },
                      { label: "PART PAYMENT", value: "PART PAYMENT" },
                      // { label: "PART PAID", value: "PAID PAID" },
                      { label: "UNPAID", value: "UNPAID" },
                    ]}
                    required={true}
                    {...form}
                  />
                </div>
              )}
          </div>
        </div>
        <div className="col-span-2 p-3">
          <Switch.Group as="div">
            <span className="flex flex-grow flex-col">
              <Switch.Label
                as="span"
                className="text-sm font-medium text-gray-900"
                passive
              >
                Query on payment
              </Switch.Label>
            </span>
            <div className="flex items-center justify-between h-[38px]">
              <Switch.Description as="span" className="text-sm text-gray-500">
                Query based on premium payment date
              </Switch.Description>
              <Switch
                checked={form.values.queryOnPayment}
                onChange={(val: boolean) =>
                  form.setFieldValue("queryOnPayment", val)
                }
                className={classNames(
                  form.values.queryOnPayment ? "bg-primary-600" : "bg-gray-200",
                  "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                )}
              >
                <span
                  aria-hidden="true"
                  className={classNames(
                    form.values.queryOnPayment
                      ? "translate-x-5"
                      : "translate-x-0",
                    "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                  )}
                />
              </Switch>
            </div>
          </Switch.Group>
        </div>
        <div className="pt-6">
          <span className="text-xs font-light">Query Date Range</span>
          <div className="grid grid-cols-2 gap-2">
            <div>
              <TextInput
                id="from"
                label={form.values.queryOnPayment ? "Payment from" : "From"}
                type="date"
                placeholder=""
                required={true}
                {...form}
              />
            </div>
            <div>
              <TextInput
                id="to"
                label={form.values.queryOnPayment ? "Payment to" : "To"}
                type="date"
                placeholder=""
                required={true}
                {...form}
              />
            </div>
          </div>
        </div>

      </form>

      <div className="flex flex-1 overflow-y-auto">
        <div className="flex-1 mx-auto min-w-0  py-4 sm:py-6 overflow-hidden overflow-y-auto light flex">
          <TableComponent
            loading={loading}
            title={"reports"}
            columns={[
              {
                name: "Policy #",
                accessor: "policy_number",
              },
              {
                name: "Insurer",
                accessor: "insurer_company_name",
              },
              {
                name: "Insured",
                accessor: "insured_by",
              },
              {
                name: "Business Name",
                accessor: "business_name",
              },
              {
                name: "Currency",
                accessor: "currency",
              },
              {
                name: "Fac. Sum Insured",
                accessor: "fac_sum_insured",
              },
              {
                name: "Fac. Premium",
                accessor: "fac_premium",
              },
              {
                name: "Brokerage",
                accessor: "brokerage_amount",
              },
              {
                name: "Amount Due",
                accessor: "amount_due",
              },
              {
                name: "Period of Insurance",
                accessor: "period",
              },
              {
                name: "Offer Status",
                accessor: "offer_status",
              },
              {
                name: "Payment Status",
                accessor: "payment_status",
              },
              {
                name: "Offer Date",
                accessor: "offer_date",
              },
            ]}
            data={JSON.parse(data?.reportOnQuery || "{}")?.query_output || []}
            refetch={form.handleSubmit}
            renderLoader={() => (
              <TableLoader items={[1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1]} />
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default ReportingTab;

const getOfferStatuses = (selected: string[]) => {
  // if the selected array contains all, don't show the rest
  if (selected.includes("ALL")) {
    return [{ label: "ALL", value: "ALL" }];
  }
  // if the selected array contains open, don't show the all as well
  if (
    selected.includes("OPEN") ||
    selected.includes("PENDING") ||
    selected.includes("CLOSED")
  ) {
    return [
      { label: "OPEN", value: "OPEN" },
      { label: "PENDING", value: "PENDING" },
      { label: "CLOSED", value: "CLOSED" },
    ];
  }
  return [
    { label: "ALL", value: "ALL" },
    { label: "OPEN", value: "OPEN" },
    { label: "PENDING", value: "PENDING" },
    { label: "CLOSED", value: "CLOSED" },
  ];
};
