import { CountryPicker, TextInput } from "components/core";
import { FormikProps } from "formik";
import { FC } from "react";
import { ReBrokerInput } from "_graphql/__generated__/globalTypes";

export interface BrokerFormProps {
    form: FormikProps<ReBrokerInput>;
}

const BrokerForm: FC<BrokerFormProps> = ({ form }) => {
    return (
        <div className="space-y-6 divide-y divide-gray-200">
            {/* {JSON.stringify(form.errors, null, 2)} */}
            <div>
                <span className="text-xs font-light">Representative Information</span>
                <div className="grid grid-cols-2 gap-4">
                    <div>
                        <TextInput
                            id="re_broker_name"
                            label="Company Name"
                            type="text"
                            placeholder="e.g. Afro Asian"
                            required={true}
                            {...form}
                        />
                    </div>
                    <div>
                        <TextInput
                            id="re_broker_email"
                            label="Email"
                            type="text"
                            placeholder="e.g. ebaidoo79@gmail.com"
                            required={true}
                            {...form}
                        />
                    </div>
                    <div className="col-span-2">
                        <TextInput
                            id="re_broker_website"
                            label="Website"
                            type="text"
                            // preText="https://"
                            placeholder="e.g. afroasian.com"
                            required={true}
                            {...form}
                        />
                    </div>

                    <div className="space-y-1 ">
                        <TextInput
                            id="re_broker_address.re_primary_phone"
                            label="Primary Phone Number"
                            type="text"
                            placeholder="e.g. 0506339153"
                            required={true}
                            {...form}
                        />
                    </div>
                    <div className="space-y-1">
                        <TextInput
                            id="re_broker_address.re_secondary_phone"
                            label="Secondary Phone Number"
                            type="text"
                            placeholder="e.g. 0506339153"
                            {...form}
                        />
                    </div>
                    <div className="space-y-1 ">
                        <CountryPicker
                            id="re_broker_address.country"
                            label="Country"
                            required
                            placeholder="e.g. GHana"
                            {...form}
                        />
                    </div>
                    <div className="space-y-1 ">
                        <TextInput
                            id="re_broker_address.region"
                            label="Region/State"
                            type="text"
                            required
                            placeholder="e.g. Greater Accra"
                            {...form}
                        />
                    </div>
                    <div className="space-y-1 ">
                        <TextInput
                            id="re_broker_address.street"
                            label="Street Address"
                            type="text"
                            required
                            placeholder="e.g. Antelope Street"
                            {...form}
                        />
                    </div>
                    <div className="space-y-1 ">
                        <TextInput
                            id="re_broker_address.city"
                            label="City"
                            type="text"
                            required
                            placeholder="e.g. Accra"
                            {...form}
                        />
                    </div>


                </div>
            </div>
        </div>
    );
};

export default BrokerForm;
