import { wrapClick } from 'utils';
import { gql, useQuery } from '@apollo/client';
import { EmployeeView, Modal } from 'components';
import { useSearch, useNavigate } from 'react-location';
import { LocationGenerics } from 'router/location';
import { getEmployee } from '_graphql/queries/employees';
import { Employee, EmployeeVariables } from '_graphql/queries/__generated__/Employee';


export default function ViewEmployeeContainer({ open, setOpen }: { open: boolean; setOpen: (val: boolean) => void }) {

    const searchParams = useSearch<LocationGenerics>();
    const navigate = useNavigate<LocationGenerics>();
    const { data, loading } = useQuery<Employee, EmployeeVariables>(getEmployee, {
        variables: {
            employeeId: searchParams.id
        },
        notifyOnNetworkStatusChange: false
    });
    // const updateItem = () => {
    //     navigate({
    //         search: (old) => ({
    //             ...old,
    //             modal: "update"
    //         })
    //     })
    // }

    return (
        <Modal
            open={open}
            setOpen={setOpen}
            loading={loading}
            title="Employee Information"
            description="Details of employee are shown below"
        >
            <EmployeeView
                employee={data?.employee}
            />
        </Modal>
    )
}