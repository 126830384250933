import {
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";
import { FetchTreatyAccounts_fetchTreatyAccounts } from "_graphql/queries/__generated__/FetchTreatyAccounts";
import { Treaty_treaty } from "_graphql/queries/__generated__/Treaty";
import { CurrencyPicker } from "components/core";
import { FormikProps } from "formik";
import _ from "lodash";
import { FC, useEffect, useState } from "react";

export interface DuplicateTreatyCurrencyFormProps {
  form: FormikProps<any>;
  treaty?: Treaty_treaty | null;
  accounts?: (FetchTreatyAccounts_fetchTreatyAccounts | null)[];
}

const DuplicateTreatyCurrencyForm: FC<DuplicateTreatyCurrencyFormProps> = ({
  form,
  accounts,
}) => {
  const [selectedCurrencies, setSelectedCurrencies] = useState<any[]>([]);
  useEffect(() => {
    form.setFieldValue("currency", selectedCurrencies);
  }, [selectedCurrencies])
  return (
    <>
      {/* {JSON.stringify(form.values, null, 2)} */}
      <div className="space-y-6 divide-y divide-gray-200">
        <div className="border-l-4 border-yellow-400 bg-yellow-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <ExclamationTriangleIcon
                className="h-11 w-11 text-yellow-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3 prose prose-base">
              <p className="text-yellow-700 text-sm">
                Duplication already made for the following currencies.
                {" "}
                <ul>
                  {accounts?.map((el) => (
                    <li>{el?.currency}</li>
                  ))}
                </ul>
                <p>To enable effective cloning of Test Proportional to various selected currencies, Ensure:</p>
                <ul>
                  <li>1. You have created all re insurers list and allocated their respective quotas.</li>
                  <li>2. You have created all intermediaries and allocated their respective quotas.</li>
                </ul>
              </p>
              <span className="text-yellow-700 text-sm">
                <b className="text-yellow-500">NOTE:</b>
                <p>
                  This event will automate creation of Test Proportional for all currency choices.
                </p>
              </span>
            </div>
          </div>
        </div>
        <div>
          <div className="grid grid-cols-2 gap-2">
            <div className="col-span-3">
              <div className="pt-6">
                <div className="grid grid-cols-2 gap-6 mt-2">
                  <div className="col-span-2">
                    <CurrencyPicker
                      id={"interest"}
                      // options={currencies}
                      label="Currency"
                      required
                      placeholder="Eg. 10"
                      {...form}
                      onChange={(e) => {
                        setSelectedCurrencies(prev => [...prev, e])
                        form.setFieldValue("currency", selectedCurrencies);
                        form.setFieldValue("interest", "");
                      }}
                    />
                  </div>
                  <div className="gap-2 flex">
                    {accounts?.map((el) => (
                      <span className="inline-flex items-center gap-x-0.5 rounded-md bg-gray-300 px-2 py-1 text-xs font-medium text-gray-600">
                        {el?.currency}
                      </span>
                    ))}
                    {selectedCurrencies.map((el, index) => (
                      <span className="inline-flex items-center gap-x-0.5 rounded-md bg-gray-300 px-2 py-1 text-xs font-medium text-gray-600">
                        {el}
                        <button onClick={() => {
                          setSelectedCurrencies(prev => prev.filter((_, i) => i !== index))
                        }} type="button" className="group relative -mr-1 h-3.5 w-3.5 rounded-sm hover:bg-gray-500/20">
                          <span className="sr-only">Remove</span>
                          <svg viewBox="0 0 14 14" className="h-3.5 w-3.5 stroke-gray-700/50 group-hover:stroke-gray-700/75">
                            <path d="M4 4l6 6m0-6l-6 6" />
                          </svg>
                          <span className="absolute -inset-1" />
                        </button>
                      </span>
                    ))}

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DuplicateTreatyCurrencyForm;
