import {
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";
import { FormikProps } from "formik";
import _ from "lodash";
import { FC } from "react";
import { classNames } from "utils";

export interface ModifyTreatyDeductionFormProps {
  form: FormikProps<any>;
}

const ModifyTreatyDeductionForm: FC<ModifyTreatyDeductionFormProps> = ({ form }) => {

  return (
    <div className="space-y-6 divide-y divide-gray-200">
      <div className="rounded-md bg-yellow-50 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <ExclamationTriangleIcon
              className="h-5 w-5 text-yellow-400"
              aria-hidden="true"
            />
          </div>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-yellow-800">
              Attention needed
            </h3>
            <ul className="mt-2 text-sm text-yellow-700 list-disc">
              <li>
                Modify each reinsurer's deduction as agreed.
              </li>
              <li>
                This button allows you to specify deductions specific to reinsurers participating on this treaty
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* {JSON.stringify(form.errors, null, 2)} */}
      <div>
        <div className="col-span-2">
          <div className="">
            <div className="grid grid-cols-3 gap-6 mt-2">
              <div className="col-span-3">
                <span className="block text-sm font-medium text-gray-700">
                  Details
                </span>
                <div className="mt-1 w-full ">
                  <table className="min-w-full divide-y divide-gray-300 border border-gray-300 rounded-md">
                    <thead className="bg-gray-50">
                      <tr className="divide-x divide-gray-200">
                        <th
                          scope="col"
                          className="whitespace-nowrap px-2 py-2 text-left text-sm font-semibold text-gray-900"
                        >
                          Re-insurer
                        </th>
                        <th
                          scope="col"
                          className="whitespace-nowrap px-2 py-2 text-left text-sm font-semibold text-gray-900"
                        >
                          Withholding Tax
                        </th>
                        <th
                          scope="col"
                          className="whitespace-nowrap px-2 py-2 text-left text-sm font-semibold text-gray-900"
                        >
                          NIC Levy
                        </th>
                        <th
                          scope="col"
                          className="whitespace-nowrap px-2 py-2 text-left text-sm font-semibold text-gray-900"
                        >
                          Agreed Brokerage
                        </th>
                        <th
                          scope="col"
                          className="whitespace-nowrap px-2 py-2 text-left text-sm font-semibold text-gray-900"
                        >
                          Agreed Commission
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {_.times(form.values.requests?.length + 1, (idx) => {
                        const id = `requests.${idx}`;

                        return (
                          <tr key={idx} className="divide-x divide-gray-200">
                            <td className="p-2 truncate text-ellipsis bg-gray-50">
                              {_.get(
                                form.values,
                                `${id}.reinsurer.re_company_name`,
                                ""
                              )}
                            </td>
                            <td className="p-0 relative">
                              <input
                                key={idx}
                                type={"text"}
                                id={`${id}.withholdingTax`}
                                name={`${id}.withholdingTax`}
                                onChange={form.handleChange}
                                onBlur={form.handleBlur}
                                value={_.get(
                                  form.values,
                                  `${id}.withholdingTax`,
                                  ""
                                )}
                                placeholder={"eg. 12.3"}
                                className={classNames(
                                  _.get(form.errors, `${id}.withholdingTax`) &&
                                    _.get(form.touched, `${id}.withholdingTax`)
                                    ? "focus:ring-primary-500 focus:border-gray-200 ring-red-500 border-red-600 border"
                                    : "focus:ring-primary-500 focus:border-gray-200 border-gray-200 border-0 focus:border",
                                  "outline-none relative pl-[54px] block w-full sm:text-sm placeholder:font-light placeholder:text-xs h-[38px]"
                                )}
                              />
                            </td>
                            <td className="p-0 relative">
                              <input
                                key={idx}
                                type={"text"}
                                id={`${id}.nicLevy`}
                                name={`${id}.nicLevy`}
                                onChange={form.handleChange}
                                onBlur={form.handleBlur}
                                value={_.get(form.values, `${id}.nicLevy`, "")}
                                placeholder={"eg. 12.3"}
                                className={classNames(
                                  _.get(form.errors, `${id}.nicLevy`) &&
                                    _.get(form.touched, `${id}.nicLevy`)
                                    ? "focus:ring-primary-500 focus:border-gray-200 ring-red-500 border-red-600 border"
                                    : "focus:ring-primary-500 focus:border-gray-200 border-gray-200 border-0 focus:border",
                                  "outline-none relative pl-[54px] block w-full sm:text-sm placeholder:font-light placeholder:text-xs h-[38px]"
                                )}
                              />
                            </td>
                            <td className="p-0 relative">
                              <input
                                key={idx}
                                type={"text"}
                                id={`${id}.agreedBrokerage`}
                                name={`${id}.agreedBrokerage`}
                                onChange={form.handleChange}
                                onBlur={form.handleBlur}
                                value={_.get(
                                  form.values,
                                  `${id}.agreedBrokerage`,
                                  ""
                                )}
                                placeholder={"eg. 12.3"}
                                className={classNames(
                                  _.get(form.errors, `${id}.agreedBrokerage`) &&
                                    _.get(form.touched, `${id}.agreedBrokerage`)
                                    ? "focus:ring-primary-500 focus:border-gray-200 ring-red-500 border-red-600 border"
                                    : "focus:ring-primary-500 focus:border-gray-200 border-gray-200 border-0 focus:border",
                                  "outline-none relative pl-[54px] block w-full sm:text-sm placeholder:font-light placeholder:text-xs h-[38px]"
                                )}
                              />
                            </td>
                            <td className="p-0 relative">
                              <input
                                key={idx}
                                type={"text"}
                                id={`${id}.agreedCommission`}
                                name={`${id}.agreedCommission`}
                                onChange={form.handleChange}
                                onBlur={form.handleBlur}
                                value={_.get(
                                  form.values,
                                  `${id}.agreedCommission`,
                                  ""
                                )}
                                placeholder={"eg. 12.3"}
                                className={classNames(
                                  _.get(
                                    form.errors,
                                    `${id}.agreedCommission`
                                  ) &&
                                    _.get(
                                      form.touched,
                                      `${id}.agreedCommission`
                                    )
                                    ? "focus:ring-primary-500 focus:border-gray-200 ring-red-500 border-red-600 border"
                                    : "focus:ring-primary-500 focus:border-gray-200 border-gray-200 border-0 focus:border",
                                  "outline-none relative pl-[54px] block w-full sm:text-sm placeholder:font-light placeholder:text-xs h-[38px]"
                                )}
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModifyTreatyDeductionForm;
