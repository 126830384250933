import { useMutation } from "@apollo/client";
import toast from "react-hot-toast";
import { PopUp } from "components";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { removeQuarterForTreaty } from "_graphql/mutation/treaty";
import {
  RemoveQuarterForTreaty,
  RemoveQuarterForTreatyVariables,
} from "_graphql/mutation/__generated__/RemoveQuarterForTreaty";

export default function RemoveQuarterContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const [remove, { loading }] = useMutation<
    RemoveQuarterForTreaty,
    RemoveQuarterForTreatyVariables
  >(removeQuarterForTreaty, {
    refetchQueries: ["Treaty"],
  });

  const handleSubmit = async () => {
    await remove({
      variables: {
        treatyAccountId: searchParams?.treaty_account_id || "",
      },
    }).then(({ data }) => {
      if (data?.removeQuarterForTreaty) {
        toast.success("Quarter removed successfully");
        refetch?.();
        setOpen?.(false);
      } else {
        toast.error("Failed to remove Quarter");
      }
    });
  };

  return (
    <PopUp
      open={open}
      setOpen={setOpen}
      title="Remove Quarter"
      cancelText="No, Don't Remove Quarter"
      description="This action would completely remove Quarter details from system"
      renderActions={() => (
        <>
          <button
            type="button"
            disabled={loading}
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={handleSubmit}
          >
            {loading ? "Removing Quarter..." : "Remove Quarter"}
          </button>
        </>
      )}
    />
  );
}
