

import React from 'react'

type Props = {
    data: any
}

const TreatyFormSummary = ({ data }: Props) => {
    return (
        <div className="border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-md p-3">
            {/* {JSON.stringify(data)} */}

            <div>
                <div className="grid grid-cols-3 gap-6">
                    <div>
                        <span className="block text-sm font-light text-gray-700">
                            Policy Number
                        </span>
                        <div className="mt-1 block w-full sm:text-sm">
                            {data?.exisitingOffer?.offer_detail?.policy_number || "N/A"}
                        </div>
                    </div>
                    <div className="col-span-2">
                        <span className="block text-sm font-light text-gray-700">
                            Insured
                        </span>
                        <div className="mt-1 block w-full sm:text-sm">
                            {data?.exisitingOffer?.offer_detail?.insured_by || "N/A"}
                        </div>
                    </div>
                    <div className='col-span-3'>
                        <span className="block text-sm font-light text-gray-700">
                            Cedant
                        </span>
                        <div className="mt-1 block w-full sm:text-sm">
                            {data?.exisitingOffer?.insurer?.insurer_company_name || "N/A"}
                        </div>
                    </div>
                    <div>
                        <span className="block text-sm font-light text-gray-700">
                            Facultative Offer
                        </span>
                        <div className="mt-1 block w-full sm:text-sm">
                            {data?.exisitingOffer?.facultative_offer || "N/A"}
                        </div>
                    </div>
                    <div>
                        <span className="block text-sm font-light text-gray-700">Commission</span>
                        <div className="mt-1 block w-full sm:text-sm">
                            {data?.exisitingOffer?.commission || "N/A"}
                        </div>
                    </div>
                    <div>
                        <span className="block text-sm font-light text-gray-700">
                            Currency
                        </span>
                        <div className="mt-1 block w-full sm:text-sm">
                            {data?.exisitingOffer?.offer_detail?.currency || "N/A"}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TreatyFormSummary