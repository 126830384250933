import { wrapClick } from "utils";
import { useMutation } from "@apollo/client";
import { useFormik } from "formik";
import { Modal } from "components";
import { useMatch, useNavigate, useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { useOfferParticipantDeductions } from "hooks/data/use-payments";
import { distributePaymentMade } from "_graphql/mutation/payment";
import DistributePaymentForm from "components/forms/distribute-payment";
import { DistributePayments, DistributePaymentsVariables } from "_graphql/mutation/__generated__/DistributePayments";
import toast from "react-hot-toast";


export default function DistributePaymentContainer({
    open,
    setOpen,
}: {
    open: boolean;
    setOpen: (val: boolean) => void;
    refetch?: () => void;
}) {
    const { params } = useMatch<LocationGenerics>();
    const searchParams = useSearch<LocationGenerics>();
    const navigate = useNavigate<LocationGenerics>();
    const [distribute, { loading }] = useMutation<
        DistributePayments,
        DistributePaymentsVariables
    >(distributePaymentMade, {
        refetchQueries: ["GetOfferPayments"],
    });
    const form = useFormik<any>({
        initialValues: {
            deductions: [],
        },
        onSubmit: async (variables) => {
            await distribute({
                variables: {
                    data: {
                        offer_id: params.offer || "",
                        offer_payment_id: searchParams.id || "",
                        offer_participants: variables.deductions.map((d: any) => ({
                            offer_participant_payment_id: d.offer_participant_id,
                            paid_details: JSON.stringify({
                                payment_type: d.payment_type,
                                payment_from: {
                                    cheque_number: d.cheque_number ? d.cheque_number : "N/A",
                                    bank_name: d.bank_name,
                                },
                                payment_to: d.b_bank_name ? d.b_bank_name : "N/A",
                            }),
                            payment_comment: d.comment,
                        })),
                    }
                }
            }).then((data) => {
                if (data.data?.updateOfferParticipantPayment) {
                    toast.success("Payment distributed successfully");
                    setOpen(false);
                    navigate({
                        search(prev) {
                            return {
                                ...prev,
                            };
                        },
                    });
                } else {
                    toast.error("Failed to distribute payment");
                }
            }).catch((e) => {
                console.log(e);
            });
        },
        onReset: () => {
            setOpen(false);
        },
    });
    const { deductions, loading: fetchingDeductions } = useOfferParticipantDeductions({
        id: params.insurer || "",
        offer_id: params.offer || "",
        payment_id: searchParams.id || "",
    }, (data) => {
        const deductions = JSON.parse(data.getOfferparticipantDeductions || "[]") || [];
        form.setFieldValue("deductions", deductions.map((d: any) => ({
            ...d,
        })) || []);
    });

    return (
        <Modal
            open={open}
            setOpen={() => {
                setOpen(false);
                navigate({
                    search(prev) {
                        return {
                            ...prev,
                        };
                    },
                });
            }}
            size="5xl"
            title="Dsitribute Payment"
            loading={fetchingDeductions}
            description="Distribute payment to the offer participants."
            renderActions={() => (
                <>
                    <button
                        type="button"
                        disabled={loading}
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={wrapClick(form.handleSubmit)}
                    >
                        {loading ? "Distributing payment..." : "Distribute Payment"}
                    </button>
                </>
            )}
        >
            <DistributePaymentForm form={form} deductions={deductions} />
        </Modal>
    );
}
