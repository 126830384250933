import {
  CCPicker,
  NewSelect,
  RichEditor,
  TextInput,
  UploadArea,
  UploadBox,
  UploadItem,
} from "components/core";
import { FormikProps } from "formik";
import { FC, useState } from "react";
import "@mantine/tiptap/styles.css";
import _ from "lodash";

export interface SendEmailFormProps {
  form: FormikProps<any>;
}

const SendEmailForm: FC<SendEmailFormProps> = ({ form }) => {
  const [uploadedFiles, setUploadedFiles] = useState<any[]>([
    // {
    //   file_name: "4_5767021565237529601 (1).pdf",
    //   file_path:
    //     "/2024-05-15-b57c1c59-93e1-47c9-a877-9e0fe60e6c48/4_5767021565237529601 (1).pdf",
    // },
  ]);
  return (
    <div className="space-y-6 divide-y divide-gray-200">
      {/* {JSON.stringify(form.values.attachments, null, 2)} */}
      <div>
        <TextInput
          id="subject"
          label="Subject"
          placeholder="e.g. Subject goes here ..."
          required={true}
          {...form}
        />
      </div>
      <div className="pt-6">
        <CCPicker
          id="copied_emails"
          label="CC"
          placeholder="e.g. John Doe"
          required={true}
          rawId
          accessor="employee_email"
          {...form}
        />
        {/* <NewSelect id="copied_emails"
          label="CC"
          placeholder="e.g. John Doe"
          required={true}
          {...form} /> */}
      </div>
      <div className="pt-6">
        <span className="text-xs font-light">Message</span>
        <div className="col-span-2">
          <RichEditor
            id="message"
            label="Message"
            placeholder="e.g. Comment goes here ..."
            required={true}
            {...form}
          />
        </div>
      </div>
      {/* TODO: work on attachment flow */}
      <div className="pt-6">
        <div className="col-span-2">
          <UploadItem
            id="attachments"
            label="Attachment"
            multiple={true}
            accept={{
              "pdf/*": [".pdf"],
              // "doc/*": [".docx", ".doc"],
              // "image/*": [".png", ".jpeg", ".jpg"],
              "xlsx/*": [".xlsx", ".xls", ".csv"],
            }}
            required={true}
            {...form}
            setFieldValue={(field: string, value: any) => {
              console.log("field", field);
              console.log("value", value);
              setUploadedFiles((prev) => [...prev, _.last(value)]);
              form.setFieldValue(field, [...uploadedFiles, _.last(value)]);
            }}
            count={form.values?.attachments?.length || 0}
          // values={{ attachments: uploadedFiles }}
          />
        </div>
      </div>
    </div>
  );
};

export default SendEmailForm;
