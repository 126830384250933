import _ from 'lodash'
import numeral from 'numeral'
import { useMemo } from 'react'
import { Link } from 'react-location'

type Props = {
    notification: any
    parsedNotification: any
}

const OfferNotificationComponent = ({ notification, parsedNotification }: Props) => {

    const paymentDetails = useMemo(() => JSON.parse(parsedNotification?.payment_details?.payment_details || "{}"), [parsedNotification])

    return (
        <div className="px-4">
            <div className="bg-yellow-200 p-4 mb-4">
                <p>{JSON.parse(notification?.system_notification?.notification_content || "{}")?.message}</p>
                {/* <p>{parsedNotification}</p> */}
            </div>

            <div className="mb-4">
                <p>
                    Offer: <strong>{parsedNotification?.policy_number}</strong>
                </p>
                <p className="">
                    Offer status:{" "}
                    <span
                        style={{ letterSpacing: 3 }}
                        className={`badge ${parsedNotification?.offer_status === "OPEN"
                            ? "bg-blue-500"
                            : parsedNotification?.offer_status === "PENDING"
                                ? "bg-yellow-500"
                                : "bg-green-500"
                            } text-white p-0.5 px-2 rounded`}
                    >
                        {parsedNotification?.offer_status}
                    </span>
                </p>
            </div>

            <div className="mb-2">
                <table className="w-full border-collapse">
                    <tbody>
                        <tr>
                            <td className="py-2">Created By</td>
                            <td className="py-2">{parsedNotification?.employee_name}</td>
                        </tr>
                        <tr>
                            <td className="py-2">Created On</td>
                            <td className="py-2">{new Date(parsedNotification?.created_at).toDateString()}</td>
                        </tr>
                        <tr>
                            <td className="py-2">Class of Business</td>
                            <td className="py-2">{parsedNotification?.class_of_business}</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className="mb-2">
                <p>Offer Details</p>
                <table className="w-full border-collapse">
                    <tbody>
                        <tr>
                            <th className="text-left py-2">Policy #</th>
                            <td className="py-2">{parsedNotification?.policy_number}</td>
                            <th className="text-left py-2">Insured</th>
                            <td className="py-2">{parsedNotification?.insured_by}</td>
                        </tr>
                        <tr>
                            <th className="text-left py-2">Period of Insurance</th>
                            <td className="py-2">{parsedNotification?.period_of_insurance}</td>
                            <th className="text-left py-2">Reinsured</th>
                            <td className="py-2">{parsedNotification?.reinsured}</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className="mb-2">
                <p>Offer</p>
                <table className="w-full border-collapse">
                    <tbody>
                        <tr>
                            <th className="text-left py-2">Fac. Premium</th>
                            <td className="py-2">
                                {parsedNotification?.currency} {numeral(parsedNotification?.fac_premium).format("#,#.##")}
                            </td>
                            <th className="text-left py-2">Brokerage</th>
                            <td className="py-2">{parsedNotification?.brokerage} %</td>
                        </tr>
                        <tr>
                            <th className="text-left py-2">Fac. Sum Insured</th>
                            <td className="py-2">
                                {parsedNotification?.currency} {numeral(parsedNotification?.fac_sum_insured).format("#,#.##")}
                            </td>
                            <th className="text-left py-2">Commission</th>
                            <td className="py-2">{parsedNotification?.commission} %</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            {!_.isEmpty(paymentDetails) && (
                <div className="mb-2">
                    <p>Payment Details</p>
                    <table className="w-full border-collapse">
                        <tbody>
                            <tr>
                                <th className="text-left py-2">Amount Paid</th>
                                <td className="py-2">
                                    {parsedNotification?.currency} {numeral(parsedNotification?.payment_details?.amount_paid).format("#,#.##")}
                                </td>
                                <th className="text-left py-2">Payment Date</th>
                                <td className="py-2">
                                    {new Date(parsedNotification?.payment_details?.payment_date).toDateString()}
                                </td>
                            </tr>
                            <tr>
                                <th className="text-left py-2">Payment Comment</th>
                                <td className="py-2">{parsedNotification?.payment_details?.offer_payment_comment}</td>
                                <th className="text-left py-2">Payment Type</th>
                                <td className="py-2">{paymentDetails?.payment_type}</td>
                            </tr>
                            <tr>
                                <th className="text-left py-2">Payment From</th>
                                <td className="py-2">{paymentDetails?.payment_from?.bank_name}</td>
                                {paymentDetails?.payment_type === "Cheque" && (
                                    <>
                                        <th className="text-left py-2">Cheque Number</th>
                                        <td className="py-2">{paymentDetails?.payment_from?.cheque_number || "Not Specified"}</td>
                                    </>
                                )}
                            </tr>
                            <tr>
                                <th className="text-left py-2">Payment to</th>
                                <td className="py-2">{paymentDetails?.payment_to}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            )}

            {parsedNotification?.offer_deductions && (
                <div className="mb-2">
                    <p>Offer Deductions</p>
                    <table className="w-full border-collapse">
                        <thead>
                            <tr>
                                <th className="text-left py-2">Reinsurer</th>
                                <th className="text-left py-2">Withholding Tax</th>
                                <th className="text-left py-2">Nic Levy</th>
                                <th className="text-left py-2">Agreed Brokerage</th>
                                <th className="text-left py-2">Agreed Commission</th>
                            </tr>
                        </thead>
                        <tbody>
                            {parsedNotification?.offer_deductions.map((deduction: any, key: number) => (
                                <tr key={key}>
                                    <td className="py-2">{deduction.reinsurer_name}</td>
                                    <td className="py-2">{deduction.deductions.withholding_tax}</td>
                                    <td className="py-2">{deduction.deductions.nic_levy}</td>
                                    <td className="py-2">{deduction.deductions.brokerage}</td>
                                    <td className="py-2">{deduction.deductions.commission}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}

            <div className="flex space-x-2">
                {["PENDING", "OPEN"].includes(parsedNotification?.offer_status) && (
                    <Link
                        to={`/create-slip/${parsedNotification?.offer_id}/view`}
                        className="btn bg-green-500 text-white py-2 px-4 rounded"
                    >
                        Go to Offer
                    </Link>
                )}
                {parsedNotification?.offer_status === "CLOSED" && (
                    <Link
                        to="/create-closing"
                        className="btn bg-green-500 text-white py-2 px-4 rounded"
                    >
                        Go to Closing list
                    </Link>
                )}
                {paymentDetails && (
                    <Link
                        to={`/insurers/${parsedNotification?.insurer_id}/view`}
                        className="btn bg-yellow-500 text-white py-2 px-4 rounded"
                    >
                        Go to insurer
                    </Link>
                )}
            </div>
        </div>


    )
}

export default OfferNotificationComponent