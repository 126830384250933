import { useMutation } from "@apollo/client";
import toast from "react-hot-toast";
import { PopUp } from "components";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { deleteOffer, deleteTreaty } from "_graphql/mutation/offers";
import { DeleteTreaty, DeleteTreatyVariables } from "_graphql/mutation/__generated__/DeleteTreaty";
import { TreatyType } from "_graphql/__generated__/globalTypes";

export default function RemoveContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const [remove, { loading }] = useMutation<
    DeleteTreaty,
    DeleteTreatyVariables
  >(deleteTreaty, {
    refetchQueries: ["GetTreaties"],
  });

  const handleSubmit = async () => {
    await remove({
      variables: {
        treatyId: searchParams?.id || "",
        treatyType: searchParams?.treaty_type as TreatyType || "",
      },
    }).then(({ data }) => {
      if (data?.deleteTreaty) {
        toast.success("Treaty Removed Successfully");
        refetch?.();
        setOpen?.(false);
      } else {
        toast.error("Could not remove Treaty");
      }
    });
  };

  return (
    <PopUp
      open={open}
      setOpen={setOpen}
      title="Delete treaty"
      cancelText="No, Don't Delete treaty"
      description="This action would completely remove treaty details from system"
      renderActions={() => (
        <>
          <button
            type="button"
            disabled={loading}
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={handleSubmit}
          >
            {loading ? "Removing treaty..." : "Remove treaty"}
          </button>
        </>
      )}
    />
  );
}
