import { wrapClick } from "utils";
import { useMutation } from "@apollo/client";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { Modal } from "components";
import * as Yup from "yup";
import { BusinessForm } from "components/forms";
import { CreateReBroker, CreateReBrokerVariables } from "_graphql/mutation/__generated__/CreateReBroker";
import { createBroker } from "_graphql/mutation/broker";
import { CreateClassOfBusiness, CreateClassOfBusinessVariables } from "_graphql/mutation/__generated__/CreateClassOfBusiness";
import { createClassOfBusiness } from "_graphql/mutation/class-of-business";


export const businessValidationSchema = Yup.object().shape({
    business_name: Yup.string().required("Business name is required"),
    business_group: Yup.string().required("Business group is required"),
    requests: Yup.array().of(Yup.object().shape({
        keyDetail: Yup.string().required("Detail is required"),
        required: Yup.string().required("Check Required is status"),
        type: Yup.string().required("Type of is required"),
    })),
});

export default function CreateContainer({
    open,
    setOpen,
    refetch,
}: {
    open: boolean;
    setOpen: (val: boolean) => void;
    refetch?: () => void;
}) {
    const [create, { loading }] = useMutation<CreateClassOfBusiness, CreateClassOfBusinessVariables>(createClassOfBusiness);

    const form = useFormik<any>({
        initialValues: {
            business_name: "",
            business_group: "",
            requests: []
        },
        validationSchema: businessValidationSchema,
        onSubmit: async (variables) => {
            await create({
                variables: {
                    businessDetails: JSON.stringify(variables.requests?.map((item: any) => ({
                        keydetail: item.keyDetail,
                        required: item.required,
                        type: item.type,
                    }))),
                    businessName: variables.business_name,
                    businessCategory: variables.business_group
                },
            }).then(({ data }) => {
                if (data?.createClassOfBusiness?.class_of_business_id) {
                    toast.success("Business Created Successfully");
                    refetch?.();
                    form.resetForm();
                } else {
                    toast.error("Failed to create Business");
                }
            });
        },
        onReset: () => {
            setOpen(false);
        },
    });

    return (
        <Modal
            open={open}
            setOpen={setOpen}
            title="Add New Business"
            description="Provide the details to add a new business"
            renderActions={() => (
                <>
                    <button
                        type="button"
                        disabled={loading}
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={wrapClick(form.handleSubmit)}
                    >
                        {loading ? "Adding Business..." : "Add Business"}
                    </button>
                </>
            )}
        >
            <BusinessForm form={form} />
        </Modal>
    );
}
