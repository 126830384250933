import { GetInsurerAssociate_getInsurerAssociate } from '_graphql/queries/__generated__/GetInsurerAssociate'
import { Avatar } from 'components/core'
import _ from 'lodash'
import { FC } from 'react'

type Props = {
    insurer: GetInsurerAssociate_getInsurerAssociate | null | undefined
}

const InsurerRepView: FC<Props> = ({ insurer }) => {
    return (
        <div className="space-y-6 divide-y divide-gray-200">
            <div>
                <span className='text-xs font-light'>Company Information</span>
                <div className='grid grid-cols-3 gap-6 mt-2'>
                    <div className='row-span-2 flex flex-col space-y-3 items-center justify-center'>
                        <Avatar
                            alt={insurer?.assoc_first_name + " " + insurer?.assoc_last_name || "N A"}
                            size="xl"
                        />
                    </div>
                    <div className='col-span-3'>
                        <span className="block text-sm font-light text-gray-700">
                            Full Name
                        </span>
                        <div className="mt-1 block w-full sm:text-sm">
                            {insurer?.assoc_first_name || "N/A"} {insurer?.assoc_last_name || "N/A"}
                        </div>
                    </div>
                </div>
            </div>
            <div className='pt-6'>
                <span className='text-xs font-light'>Contact Information</span>
                <div className='grid grid-cols-2 gap-6 mt-2'>
                    <div>
                        <span className="block text-sm font-light text-gray-700">
                            Email Address
                        </span>
                        <div className="mt-1 block w-full sm:text-sm">
                            {insurer?.assoc_email || "N/A"}
                        </div>
                    </div>

                    <div className=''>
                        <span className="block text-sm font-light text-gray-700">
                            Phone Number
                        </span>
                        <div className="mt-1 block w-full sm:text-sm">
                            {[insurer?.assoc_primary_phonenumber, insurer?.assoc_secondary_phonenumber].join(", ") || "N/A"}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InsurerRepView