import { Insurer_insurer } from "_graphql/queries/__generated__/Insurer";
import { Offer_findSingleOffer } from "_graphql/queries/__generated__/Offer";
import { Avatar } from "components/core";
import _ from "lodash";
import React, { FC } from "react";

type Props = {
  offer: Offer_findSingleOffer | null | undefined;
};

const OfferView: FC<Props> = ({ offer }) => {
  const offerDetails =
    JSON.parse(offer?.offer_detail?.offer_details || "[]") || [];
  return (
    <div className="space-y-6 divide-y divide-gray-200">
      <div>
        <span className="text-xs font-light">Offer Information</span>
        <div className="grid grid-cols-3 gap-6 mt-2">
          <div className="col-span-2">
            <span className="block text-sm font-light text-gray-700">
              Cedant Name
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {offer?.insurer?.insurer_company_name || "N/A"}
            </div>
          </div>
          <div className=" gap-6 mt-2">
            <div>
              <span className="block text-sm font-light text-gray-700">
                Business Name
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {offer?.classofbusiness?.business_name || "N/A"}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pt-6">
        <span className="text-xs font-light">Business Class Details</span>
        <div className="grid grid-cols-3 gap-6 mt-2">
          {offerDetails?.map((detail: any, idx: number) => (
            <div>
              <span className="block text-sm font-light text-gray-700">
                {detail.keydetail}
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {detail?.value || "N/A"}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="pt-6">
        <span className="text-xs font-light">Offer Details</span>
        <div className="grid grid-cols-3 gap-6 mt-2">
          <div>
            <span className="block text-sm font-light text-gray-700">
              Policy Number
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {offer?.offer_detail?.policy_number || "N/A"}
            </div>
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Insured
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {offer?.offer_detail?.insured_by || "N/A"}
            </div>
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">Rate</span>
            <div className="mt-1 block w-full sm:text-sm">
              {offer?.rate || "N/A"}
            </div>
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Premium
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {offer?.premium || "N/A"}
            </div>
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Facultative Offer
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {offer?.facultative_offer || "N/A"} %
            </div>
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Commission
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {offer?.commission || "N/A"} %
            </div>
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Brokerage
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {offer?.brokerage || "N/A"} %
            </div>
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Currency
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {offer?.offer_detail?.currency || "N/A"}
            </div>
          </div>
        </div>
      </div>
      <div className="pt-6">
        <span className="text-xs font-light">Period of Insurance</span>
        <div className="grid grid-cols-3 gap-6 mt-2">
          <div>
            <span className="block text-sm font-light text-gray-700">From</span>
            <div className="mt-1 block w-full sm:text-sm">
              {offer?.offer_detail?.period_of_insurance_from || "N/A"}
            </div>
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">To</span>
            <div className="mt-1 block w-full sm:text-sm">
              {offer?.offer_detail?.period_of_insurance_to || "N/A"}
            </div>
          </div>
        </div>
      </div>
      <div className="pt-6">
        <span className="text-xs font-light">Comment</span>
        <div className="grid grid-cols-3 gap-6 mt-2">
          <div>
            <div
              className="mt-1 block w-full sm:text-sm"
              dangerouslySetInnerHTML={{
                __html: offer?.offer_detail?.offer_comment || "N/A",
              }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OfferView;
