export default [
    {
        "code": "USD",
        "name_plural": "US dollars",
        "symbol_native": "$"
    },
    {
        "code": "Le",
        "name_plural": "Sierra Leonean leone",
        "symbol_native": "Le"
    },
    {
        "code": "CAD",
        "name_plural": "Canadian dollars",
        "symbol_native": "$"
    },
    {
        "code": "D",
        "name_plural": "Gambian dalasi",
        "symbol_native": "D"
    },
    {
        "code": "L",
        "name_plural": "Liberian dollar",
        "symbol_native": "L$"
    },
    {
        "code": "EUR",
        "name_plural": "euros",
        "symbol_native": "€"
    },
    {
        "code": "AED",
        "name_plural": "UAE dirhams",
        "symbol_native": "د.إ.‏"
    },
    {
        "code": "AFN",
        "name_plural": "Afghan Afghanis",
        "symbol_native": "؋"
    },
    {
        "code": "ALL",
        "name_plural": "Albanian lekë",
        "symbol_native": "Lek"
    },
    {
        "code": "AMD",
        "name_plural": "Armenian drams",
        "symbol_native": "դր."
    },
    {
        "code": "ARS",
        "name_plural": "Argentine pesos",
        "symbol_native": "$"
    },
    {
        "code": "AUD",
        "name_plural": "Australian dollars",
        "symbol_native": "$"
    },
    {
        "code": "AZN",
        "name_plural": "Azerbaijani manats",
        "symbol_native": "ман."
    },
    {
        "code": "BAM",
        "name_plural": "Bosnia-Herzegovina convertible marks",
        "symbol_native": "KM"
    },
    {
        "code": "BDT",
        "name_plural": "Bangladeshi takas",
        "symbol_native": "৳"
    },
    {
        "code": "BGN",
        "name_plural": "Bulgarian leva",
        "symbol_native": "лв."
    },
    {
        "code": "BHD",
        "name_plural": "Bahraini dinars",
        "symbol_native": "د.ب.‏"
    },
    {
        "code": "BIF",
        "name_plural": "Burundian francs",
        "symbol_native": "FBu"
    },
    {
        "code": "BND",
        "name_plural": "Brunei dollars",
        "symbol_native": "$"
    },
    {
        "code": "BOB",
        "name_plural": "Bolivian bolivianos",
        "symbol_native": "Bs"
    },
    {
        "code": "BRL",
        "name_plural": "Brazilian reals",
        "symbol_native": "R$"
    },
    {
        "code": "BWP",
        "name_plural": "Botswanan pulas",
        "symbol_native": "P"
    },
    {
        "code": "BYN",
        "name_plural": "Belarusian rubles",
        "symbol_native": "руб."
    },
    {
        "code": "BZD",
        "name_plural": "Belize dollars",
        "symbol_native": "$"
    },
    {
        "code": "CDF",
        "name_plural": "Congolese francs",
        "symbol_native": "FrCD"
    },
    {
        "code": "CHF",
        "name_plural": "Swiss francs",
        "symbol_native": "CHF"
    },
    {
        "code": "CLP",
        "name_plural": "Chilean pesos",
        "symbol_native": "$"
    },
    {
        "code": "CNY",
        "name_plural": "Chinese yuan",
        "symbol_native": "CN¥"
    },
    {
        "code": "COP",
        "name_plural": "Colombian pesos",
        "symbol_native": "$"
    },
    {
        "code": "CRC",
        "name_plural": "Costa Rican colóns",
        "symbol_native": "₡"
    },
    {
        "code": "CVE",
        "name_plural": "Cape Verdean escudos",
        "symbol_native": "CV$"
    },
    {
        "code": "CZK",
        "name_plural": "Czech Republic korunas",
        "symbol_native": "Kč"
    },
    {
        "code": "DJF",
        "name_plural": "Djiboutian francs",
        "symbol_native": "Fdj"
    },
    {
        "code": "DKK",
        "name_plural": "Danish kroner",
        "symbol_native": "kr."
    },
    {
        "code": "DOP",
        "name_plural": "Dominican pesos",
        "symbol_native": "RD$"
    },
    {
        "code": "DZD",
        "name_plural": "Algerian dinars",
        "symbol_native": "د.ج.‏"
    },
    {
        "code": "EGP",
        "name_plural": "Egyptian pounds",
        "symbol_native": "ج.م.‏"
    },
    {
        "code": "ERN",
        "name_plural": "Eritrean nakfas",
        "symbol_native": "Nfk"
    },
    {
        "code": "ETB",
        "name_plural": "Ethiopian birrs",
        "symbol_native": "Br"
    },
    {
        "code": "GBP",
        "name_plural": "British pounds",
        "symbol_native": "£"
    },
    {
        "code": "GEL",
        "name_plural": "Georgian laris",
        "symbol_native": "GEL"
    },
    {
        "code": "GHS",
        "name_plural": "Ghanaian cedis",
        "symbol_native": "₵"
    },
    {
        "code": "GTQ",
        "name_plural": "Guatemalan quetzals",
        "symbol_native": "Q"
    },
    {
        "code": "HKD",
        "name_plural": "Hong Kong dollars",
        "symbol_native": "$"
    },
    {
        "code": "HNL",
        "name_plural": "Honduran lempiras",
        "symbol_native": "L"
    },
    {
        "code": "HRK",
        "name_plural": "Croatian kunas",
        "symbol_native": "kn"
    },
    {
        "code": "HUF",
        "name_plural": "Hungarian forints",
        "symbol_native": "Ft"
    },
    {
        "code": "IDR",
        "name_plural": "Indonesian rupiahs",
        "symbol_native": "Rp"
    },
    {
        "code": "ILS",
        "name_plural": "Israeli new shekels",
        "symbol_native": "₪"
    },
    {
        "code": "INR",
        "name_plural": "Indian rupees",
        "symbol_native": "₹"
    },
    {
        "code": "IQD",
        "name_plural": "Iraqi dinars",
        "symbol_native": "د.ع.‏"
    },
    {
        "code": "IRR",
        "name_plural": "Iranian rials",
        "symbol_native": "﷼"
    },
    {
        "code": "ISK",
        "name_plural": "Icelandic krónas",
        "symbol_native": "kr."
    },
    {
        "code": "JMD",
        "name_plural": "Jamaican dollars",
        "symbol_native": "J$"
    },
    {
        "code": "JOD",
        "name_plural": "Jordanian dinars",
        "symbol_native": "د.ا.‏"
    },
    {
        "code": "JPY",
        "name_plural": "Japanese yen",
        "symbol_native": "¥"
    },
    {
        "code": "KES",
        "name_plural": "Kenyan shillings",
        "symbol_native": "Ksh"
    },
    {
        "code": "KGS",
        "name_plural": "Kyrgystani soms",
        "symbol_native": "сом"
    },
    {
        "code": "KHR",
        "name_plural": "Cambodian riels",
        "symbol_native": "៛"
    },
    {
        "code": "KRW",
        "name_plural": "South Korean won",
        "symbol_native": "₩"
    },
    {
        "code": "KWD",
        "name_plural": "Kuwaiti dinars",
        "symbol_native": "د.ك.‏"
    },
    {
        "code": "KZT",
        "name_plural": "Kazakhstani tenges",
        "symbol_native": "₸"
    },
    {
        "code": "LBP",
        "name_plural": "Lebanese pounds",
        "symbol_native": "ل.ل.‏"
    },
    {
        "code": "LKR",
        "name_plural": "Sri Lankan rupees",
        "symbol_native": "රු."
    },
    {
        "code": "MAD",
        "name_plural": "Moroccan dirhams",
        "symbol_native": "د.م.‏"
    },
    {
        "code": "MDL",
        "name_plural": "Moldovan lei",
        "symbol_native": "MDL"
    },
    {
        "code": "MGA",
        "name_plural": "Malagasy ariaries",
        "symbol_native": "Ar"
    },
    {
        "code": "MKD",
        "name_plural": "Macedonian denari",
        "symbol_native": "ден."
    },
    {
        "code": "MMK",
        "name_plural": "Myanmar kyats",
        "symbol_native": "K"
    },
    {
        "code": "MOP",
        "name_plural": "Macanese patacas",
        "symbol_native": "MOP$"
    },
    {
        "code": "MUR",
        "name_plural": "Mauritian rupees",
        "symbol_native": "₨"
    },
    {
        "code": "MVR",
        "name_plural": "Maldivian Rufiyaa",
        "symbol_native": "MVR"
    },
    {
        "code": "MWK",
        "name_plural": "Malawian kwachas",
        "symbol_native": "MK"
    },
    {
        "code": "MXN",
        "name_plural": "Mexican pesos",
        "symbol_native": "$"
    },
    {
        "code": "MYR",
        "name_plural": "Malaysian ringgits",
        "symbol_native": "RM"
    },
    {
        "code": "NAD",
        "name_plural": "Namibian dollars",
        "symbol_native": "$"
    },
    {
        "code": "NGN",
        "name_plural": "Nigerian nairas",
        "symbol_native": "₦"
    },
    {
        "code": "NIO",
        "name_plural": "Nicaraguan córdobas",
        "symbol_native": "C$"
    },
    {
        "code": "NOK",
        "name_plural": "Norwegian kroner",
        "symbol_native": "kr"
    },
    {
        "code": "NPR",
        "name_plural": "Nepalese rupees",
        "symbol_native": "रू"
    },
    {
        "code": "NZD",
        "name_plural": "New Zealand dollars",
        "symbol_native": "$"
    },
    {
        "code": "OMR",
        "name_plural": "Omani rials",
        "symbol_native": "﷼"
    },
    {
        "code": "PEN",
        "name_plural": "Peruvian nuevos soles",
        "symbol_native": "S/."
    },
    {
        "code": "PGK",
        "name_plural": "Papua New Guinean kina",
        "symbol_native": "K"
    },
    {
        "code": "PHP",
        "name_plural": "Philippine pesos",
        "symbol_native": "₱"
    },
    {
        "code": "PKR",
        "name_plural": "Pakistani rupees",
        "symbol_native": "₨"
    },
    {
        "code": "PLN",
        "name_plural": "Polish zlotys",
        "symbol_native": "zł"
    },
    {
        "code": "PYG",
        "name_plural": "Paraguayan guaraníes",
        "symbol_native": "₲"
    },
    {
        "code": "QAR",
        "name_plural": "Qatari riyals",
        "symbol_native": "﷼"
    },
    {
        "code": "RON",
        "name_plural": "Romanian lei",
        "symbol_native": "lei"
    },
    {
        "code": "RSD",
        "name_plural": "Serbian dinars",
        "symbol_native": "дин."
    },
    {
        "code": "RUB",
        "name_plural": "Russian rubles",
        "symbol_native": "₽"
    },
    {
        "code": "RWF",
        "name_plural": "Rwandan francs",
        "symbol_native": "FR"
    },
    {
        "code": "SAR",
        "name_plural": "Saudi riyals",
        "symbol_native": "﷼"
    },
    {
        "code": "SEK",
        "name_plural": "Swedish kronor",
        "symbol_native": "kr"
    },
    {
        "code": "SGD",
        "name_plural": "Singapore dollars",
        "symbol_native": "$"
    },
    {
        "code": "SOS",
        "name_plural": "Somali shillings",
        "symbol_native": "Sh.so."
    },
    {
        "code": "SRD",
        "name_plural": "Surinamese dollars",
        "symbol_native": "$"
    },
    {
        "code": "SYP",
        "name_plural": "Syrian pounds",
    },
    {
        "code": "THB",
        "name_plural": "Thai baht",
        "symbol_native": "฿"
    },
    {
        "code": "TND",
        "name_plural": "Tunisian dinars",
    },
    {
        "code": "TRY",
        "name_plural": "Turkish Lira",
        "symbol_native": "₺"
    },
    {
        "code": "TTD",
        "name_plural": "Trinidad and Tobago dollars",
        "symbol_native": "TT$"
    },
    {
        "code": "TWD",
        "name_plural": "New Taiwan dollars",
        "symbol_native": "NT$"
    },
    {
        "code": "TZS",
        "name_plural": "Tanzanian shillings",
        "symbol_native": "TSh"
    },
    {
        "code": "UAH",
        "name_plural": "Ukrainian hryvnias",
        "symbol_native": "₴"
    },
    {
        "code": "UGX",
        "name_plural": "Ugandan shillings",
        "symbol_native": "USh"
    },
    {
        "code": "UYU",
        "name_plural": "Uruguayan pesos",
        "symbol_native": "$U"
    },
    {
        "code": "UZS",
        "name_plural": "Uzbekistani soms",
        "symbol_native": "so'm"
    },
    {
        "code": "VES",
        "name_plural": "Venezuelan bolívars",
        "symbol_native": "Bs.S."
    },
    {
        "code": "VND",
        "name_plural": "Vietnamese dong",
        "symbol_native": "₫"
    },
    {
        "code": "XAF",
        "name_plural": "Central African CFA francs",
        "symbol_native": "FCFA"
    },
    {
        "code": "XOF",
        "name_plural": "West African CFA francs",
        "symbol_native": "CFA"
    },
    {
        "code": "YER",
        "name_plural": "Yemeni rials",
        "symbol_native": "﷼"
    },
    {
        "code": "ZAR",
        "name_plural": "South African rand",
        "symbol_native": "R"
    },
    {
        "code": "ZMW",
        "name_plural": "Zambian kwachas",
        "symbol_native": "ZK"
    }
] as const;