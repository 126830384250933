import { wrapClick } from "utils";
import { useMutation } from "@apollo/client";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { Modal } from "components";
import { BrokerForm, ReInsurerForm } from "components/forms";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { useEffect } from "react";
import { updateReInsurer } from "_graphql/mutation/re-insurer";
import { useReInsurer } from "hooks/data/use-re-insurers";
import { brokerValidationSchema } from "./create";
import { UpdateReBroker, UpdateReBrokerVariables } from "_graphql/mutation/__generated__/UpdateReBroker";
import { useBroker } from "hooks/data/use-brokers";
import { CreateReBrokerVariables } from "_graphql/mutation/__generated__/CreateReBroker";
import { ReBrokerInput } from "_graphql/__generated__/globalTypes";
import { updateBroker } from "_graphql/mutation/broker";




export default function UpdateInsurerContainer({
    open,
    setOpen,
    refetch,
}: {
    open: boolean;
    setOpen: (val: boolean) => void;
    refetch?: () => void;
}) {
    const searchParams = useSearch<LocationGenerics>();
    const [update, { loading }] = useMutation<UpdateReBroker, UpdateReBrokerVariables>(updateBroker, {
        refetchQueries: ["Brokers", "Broker"]
    });
    const { broker, loading: fetching } = useBroker(searchParams.id || "")
    const form = useFormik<ReBrokerInput>({
        initialValues: {
            re_broker_email: "",
            re_broker_name: "",
            re_broker_website: "",
            re_broker_address: {
                city: "",
                country: "",
                re_primary_phone: "",
                re_secondary_phone: "",
                region: "",
                street: ""
            }
        },
        validationSchema: brokerValidationSchema,
        onSubmit: async (variables) => {
            await update({
                variables: {
                    reBroker: variables,
                    reBrokerId: searchParams.id || "",
                },
            }).then(({ data }) => {
                if (data?.updateReBroker) {
                    toast.success("Broker Updated Successfully");
                    refetch?.();
                    form.resetForm();
                } else {
                    toast.error("Failed to update Broker");
                }
            });
        },
        onReset: () => {
            setOpen(false);
        },
    });

    useEffect(() => {
        if (broker) {
            form.setFieldValue("re_broker_email", broker.re_broker_email);
            form.setFieldValue("re_broker_name", broker.re_broker_name);
            form.setFieldValue("re_broker_website", broker.re_broker_website);
            form.setFieldValue("re_broker_address.re_primary_phone", broker.re_broker_address?.re_primary_phone);
            form.setFieldValue("re_broker_address.re_secondary_phone", broker.re_broker_address?.re_secondary_phone);
            form.setFieldValue("re_broker_address.country", broker?.re_broker_address?.country);
            form.setFieldValue("re_broker_address.region", broker.re_broker_address?.region);
            form.setFieldValue("re_broker_address.street", broker.re_broker_address?.street);
            form.setFieldValue("re_broker_address.city", broker.re_broker_address?.city);
        }
    }, [broker])

    return (
        <Modal
            open={open}
            setOpen={setOpen}
            title="Update Reinsurance Broker"
            loading={fetching}
            description="Provide the details to update reinsurerance broker"
            renderActions={() => (
                <>
                    <button
                        type="button"
                        disabled={fetching || loading}
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={wrapClick(form.handleSubmit)}
                    >
                        {loading ? "Updating Broker..." : "Update Broker"}
                    </button>
                </>
            )}
        >
            <BrokerForm form={form} />
        </Modal>
    );
}
