import { useQuery } from "@apollo/client";
import { GetBusinessCategories } from "_graphql/queries/__generated__/GetBusinessCategories";
import { getBusinessCategories } from "_graphql/queries/class-of-business";


export const useBusinessGroups = () => {
    const { data, ...rest } = useQuery<GetBusinessCategories>(getBusinessCategories, {});
    return {
        businessCategories: data?.getBusinessCategories ?? [],
        ...rest,
    };
};