import { FC, useMemo, useState } from "react";
import Select from "react-select";
import { CheckIcon } from "@heroicons/react/24/solid";
import { useBusinessGroups } from "hooks/data/use-business-group";
import _ from "lodash";

interface Option {
  label: string;
  value: string | object;
}

interface BusinessGroupPickerProps {
  id: string;
  label: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  labelHidden?: boolean;
  values: any;
  errors?: any;
  touched?: any;
  optionsLoading?: boolean;
  setFieldValue: any;
  setFieldTouched: any;
  setFieldError: any;
  isMulti?: boolean;
  hideAll?: boolean;
}

const BusinessGroupPicker: FC<BusinessGroupPickerProps> = ({
  id,
  values,
  setFieldValue,
  setFieldTouched,
  placeholder,
  label,
  errors,
  touched,
  required,
  labelHidden,
  disabled,
  isMulti = false,
  hideAll = false,
}) => {
  const { businessCategories, loading } = useBusinessGroups();
  const [query] = useState('');
  const options = useMemo(
    () =>
      [...hideAll ? [] : [{ label: "All", value: "ALL" }], ...businessCategories?.map(({ name }: any) => ({
        label: name,
        value: name,
      }))] || [],
    [businessCategories]
  );

  const filteredOptions =
    query === ''
      ? options
      : options.filter((option) => {
        return option.label.toLowerCase().includes(query.toLowerCase());
      });

  const selectedValue = isMulti
    ? (values[id] || []).map((val: string) =>
      options.find((o) => o.value === val)
    )
    : options.find((o) => o.value === values[id]);

  const handleChange = (
    selectedOptions: any
  ): void => {
    if (isMulti && Array.isArray(selectedOptions)) {
      const selectedValues = selectedOptions.map((option) => option.value);
      setFieldTouched(id, true);
      setFieldValue(id, selectedValues);
    } else {
      const selectedValue = selectedOptions ? selectedOptions.value : null;
      setFieldTouched(id, true);
      setFieldValue(id, selectedValue);
    }
  };

  return (
    <div>
      {!labelHidden && (
        <label className="block text-sm font-medium text-gray-700">
          {label} {required && <span className="text-red-500">*</span>}
        </label>
      )}
      <Select
        options={filteredOptions}
        value={selectedValue}
        onChange={handleChange}
        placeholder={placeholder}
        isLoading={loading}
        isDisabled={disabled}
        isMulti={isMulti}
        className="w-full mt-1"
      />
      {_.get(errors, id) && _.get(touched, id) && (
        <p className="mt-2 text-sm text-red-600" id={`${id}-error`}>
          {_.get(errors, id)}
        </p>
      )}
    </div>
  );
};

export default BusinessGroupPicker;
