import { useMutation } from "@apollo/client";
import toast from "react-hot-toast";
import { PopUp } from "components";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { removeClassOfBusiness } from "_graphql/mutation/class-of-business";
import {
  RemoveSingleClassOfBusiness,
  RemoveSingleClassOfBusinessVariables,
} from "_graphql/mutation/__generated__/RemoveSingleClassOfBusiness";
import {
  DeleteOfferFromSystem,
  DeleteOfferFromSystemVariables,
} from "_graphql/mutation/__generated__/DeleteOfferFromSystem";
import { useRemoveOffer } from "hooks/data/use-offers";
import { deleteOffer } from "_graphql/mutation/offers";

export default function RemoveContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const [remove, { loading }] = useMutation<
    DeleteOfferFromSystem,
    DeleteOfferFromSystemVariables
  >(deleteOffer, {
    refetchQueries: ["Offers"],
  });

  const handleSubmit = async () => {
    await remove({
      variables: {
        offerId: searchParams?.id || "",
      },
    }).then(({ data }) => {
      if (data?.deleteOfferFromSystem) {
        toast.success("Offer Removed Successfully");
        refetch?.();
        setOpen?.(false);
      } else {
        toast.error("Could not remove offer");
      }
    });
  };

  return (
    <PopUp
      open={open}
      setOpen={setOpen}
      title="Delete Offer"
      cancelText="No, Don't Delete Offer"
      description="This action would completely remove offer details from system"
      renderActions={() => (
        <>
          <button
            type="button"
            disabled={loading}
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={handleSubmit}
          >
            {loading ? "Removing Offer..." : "Remove Offer"}
          </button>
        </>
      )}
    />
  );
}
