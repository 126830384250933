
import { useFormik } from "formik";
import { useMutation, useReactiveVar } from "@apollo/client";
import toast from "react-hot-toast";
import { StepFormBuilder } from "../..";
import { createTreatyFormItems } from "./form-steps";
import _ from "lodash";
import { updateEndorsement } from "_graphql/mutation/endorsement";
import { currentUserVar } from "_graphql/cache/auth";
import { useEffect } from "react";
import { GetEndorsement_singleEndorsement } from "_graphql/queries/__generated__/GetEndorsement";
import { UpdateEndorsementOffer, UpdateEndorsementOfferVariables } from "_graphql/mutation/__generated__/UpdateEndorsementOffer";

type Props = {
  setClose?: (value: boolean) => void;
  endorsement?: GetEndorsement_singleEndorsement | null
};

const UpdateEndorsementFormContainer = ({ setClose, endorsement }: Props) => {
  const user = useReactiveVar(currentUserVar);
  const [initiateAddMeeting] = useMutation<UpdateEndorsementOffer, UpdateEndorsementOfferVariables>(
    updateEndorsement,
    {
      refetchQueries: ["GetEndorsements"],
    }
  );

  const form = useFormik<any>({
    initialValues: {
      offer: {
        offer: "",
        exisitingOffer: null
      },
      details: {
        class_of_business_id: "",
        offer_details: [],
        insurer_id: "",
        premium: "",
        policy_number: "",
        insured_by: "",
        sum_insured: "",
        rate: "",
        facultative_offer: "",
        commission: "",
        brokerage: "",
        currency: "",
        period_of_insurance_from: "",
        period_of_insurance_to: "",
        comment: "",
      },
    },
    onSubmit: async (values) => {
      await initiateAddMeeting({
        variables: {
          offerInput: {
            offer_comment: values?.comment || "",
            offer_details: JSON.stringify(values.details.old_offer_detail),
            ..._.omit(values.details, ["old_class_of_business_id", "old_offer_detail", "comment", "offer_details"]) as any,
            employee_id: user?.employee?.employee_id ?? "",
          },
          offerId: values?.offer?.exisitingOffer?.offer_id,
          offerEndorsementDetailId: endorsement?.offer_endorsement_detail?.offer_endorsement_detail_id || "",
          offerEndorsementId: endorsement?.offer_endorsement_id || "",
        }
      }).then((res) => {
        if (res.data?.updateEndorsementOffer) {
          toast.success("Endorsement Updated Successfully");
          setClose?.(false);
        }
      });
    },
  });

  useEffect(() => {
    if (endorsement) {
      form.setFieldValue("details", {
        ...endorsement,
        old_class_of_business_id: endorsement?.classofbusiness,
        class_of_business_id: endorsement?.classofbusiness?.class_of_business_id,
        offer_details: JSON.parse(endorsement.offer_endorsement_detail?.offer_detail || "[]"),
        old_offer_detail: JSON.parse(endorsement.offer_endorsement_detail?.offer_detail || "[]"),
        insurer_id: endorsement.insurer?.insurer_id,
        premium: endorsement.premium,
        policy_number: endorsement?.offer_endorsement_detail?.policy_number,
        insured_by: endorsement?.offer_endorsement_detail?.insured_by,
        sum_insured: endorsement.sum_insured,
        rate: endorsement.rate,
        facultative_offer: endorsement.facultative_offer,
        commission: endorsement.commission,
        brokerage: endorsement.brokerage,
        currency: endorsement.offer_endorsement_detail?.currency,
        period_of_insurance_from: endorsement.offer_endorsement_detail?.period_of_insurance_from,
        period_of_insurance_to: endorsement.offer_endorsement_detail?.period_of_insurance_to,
        comment: endorsement?.offer_endorsement_detail?.offer_comment,
      });

      form.setFieldValue("offer.offer", endorsement.offer?.offer_id);
      form.setFieldValue("offer.exisitingOffer", endorsement.offer);
    }
  }, [endorsement]);

  return <StepFormBuilder initialStep={0} formSteps={createTreatyFormItems} form={form} setOpen={setClose} />;
};

export default UpdateEndorsementFormContainer;



