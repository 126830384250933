import { FC } from "react";
import { useMutation } from "@apollo/client";
import { setAuth } from "_graphql/cache/auth";
import { useNavigate, useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import * as Yup from "yup";
import { TextInput } from "components/core";
import { useFormik } from "formik";
import { LOGIN } from "_graphql/mutation/auth";
import { Login, LoginVariables, Login_login_user } from "_graphql/mutation/__generated__/Login";



const LoginPage: FC = () => {
  const search = useSearch<LocationGenerics>();
  const navigate = useNavigate();
  const [login, { loading }] = useMutation<Login, LoginVariables>(LOGIN);

  const { handleSubmit, ...form } = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: Yup.object().shape({
      username: Yup.string().email().required(),
      password: Yup.string().required(),
    }),
    onSubmit: async (values) => {
      await login({
        variables: {
          username: values.username.toLowerCase(),
          password: values.password,
        },
      }).then(({ data }) => {
        if (data?.login) {
          if (data.login?.user?.first_time_signin) {
            return navigate({
              replace: true,
              to: `/cpanel-reset-password?id=${data?.login?.user?.employee?.employee_id}&redirect=${search?.redirect ?? "/"}`,
            });
          } else {
            setAuth({
              user: data?.login?.user as Login_login_user,
              token: data.login.access_token ?? "",
            });
            return navigate({
              replace: true,
              to: search?.redirect ?? "/",
            });
          }
        }
      });
    },
  });

  return (
    <div className="mx-auto w-full max-w-sm lg:w-96 ">
      <div>
        <div className="flex items-end flex-row">
          <div className="h-16 w-16 flex-shrink-0 p-1 bg-gray-300">
            <img
              className="h-14 w-14 border-white border p-1"
              src={require("assets/logo.png")}
              alt="SUBS"
            />
          </div>
          <div className="ml-3">
            <span className="text-lg text-gray-300 font-mono">
              Visal Reinsurance
              <br />
              Dashboard
            </span>
          </div>
        </div>
        <h2 className="mt-6 text-3xl font-extrabold text-gray-200">
          Sign in to your account
        </h2>
        <p className="mt-2 text-sm text-gray-400">
          Enter your credentials below
        </p>
        {search?.from === "reset_success" && (
          <div className="bg-teal-100 px-2 rounded-md py-1 mt-3">
            <p className="text-sm text-teal-600">
              Password Changed SuccessFully. Login to access the Visal Reinsurance Dashboard
            </p>
          </div>
        )}

      </div>

      <div className="mt-8">
        <div className="mt-6">
          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <TextInput
                id="username"
                label="Email address"
                type="email"
                placeholder="e.g. ebaidoo79@gmail.com"
                {...form}
              />
            </div>

            <div className="space-y-1">
              <TextInput
                id="password"
                label="Password"
                type="password"
                placeholder="e.g.  **************"
                {...form}
              />
            </div>

            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <input
                  id="remember-me"
                  name="remember-me"
                  type="checkbox"
                  className="h-4 w-4 text-primary-600 focus:ring-primary-500 border-gray-300 rounded"
                />
                <label
                  htmlFor="remember-me"
                  className="ml-2 block text-sm text-gray-200"
                >
                  Remember me
                </label>
              </div>
            </div>

            <div>
              <button
                type="submit"
                disabled={loading}
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-card-400 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
              >
                {loading ? "Signing in..." : "Sign in"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
