import { gql } from "@apollo/client";



export const getNotifications = gql`
query GetNotifications($filter: NotificationFilter, $pagination: Pagination) {
  getNotifications(filter: $filter, pagination: $pagination) {
    employee_notification_id
    created_at
    system_notification {
      system_notification_id
      notification_type
      notification_content
      created_at
    }
  }
  getNotificationsCount(filter: $filter)
}
`