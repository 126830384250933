import { Employees_rows } from '_graphql/queries/__generated__/Employees'
import { Avatar } from 'components/core'
import React, { FC } from 'react'

type Props = {
    employee: Employees_rows | null | undefined
}

const EmployeeView: FC<Props> = ({ employee }) => {
    return (
        <div className="space-y-6 divide-y divide-gray-200">
            <div>
                <span className='text-xs font-light'>Staff Information</span>
                <div className='grid grid-cols-3 gap-6 mt-2'>
                    <div className='row-span-2 flex flex-col space-y-3 items-center justify-center'>
                        <Avatar
                            alt={employee?.emp_abbrv || "N A"}
                            size="xl"
                        />
                    </div>
                    <div>
                        <span className="block text-sm font-light text-gray-700">
                            Last Name
                        </span>
                        <div className="mt-1 block w-full sm:text-sm">
                            {employee?.employee_last_name || "N/A"}
                        </div>
                    </div>

                    <div>
                        <span className="block text-sm font-light text-gray-700">
                            Other Names
                        </span>
                        <div className="mt-1 block w-full sm:text-sm">
                            {employee?.employee_first_name || "N/A"}
                        </div>
                    </div>
                    <div>
                        <span className="block text-sm font-light text-gray-700">
                            Position
                        </span>
                        <div className="mt-1 block w-full sm:text-sm">
                            {employee?.user?.position || "N/A"}
                        </div>
                    </div>
                </div>
            </div>
            <div className='pt-6'>
                <span className='text-xs font-light'>Contact Information</span>
                <div className='grid grid-cols-3 gap-6 mt-2'>

                    <div>
                        <span className="block text-sm font-light text-gray-700">
                            Email Address
                        </span>
                        <div className="mt-1 block w-full sm:text-sm">
                            {employee?.employee_email || "N/A"}
                        </div>
                    </div>

                    <div>
                        <span className="block text-sm font-light text-gray-700">
                            Phone Number
                        </span>
                        <div className="mt-1 block w-full sm:text-sm">
                            {employee?.employee_phonenumber || "N/A"}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EmployeeView