
import { useFormik } from "formik";
import { useMutation, useReactiveVar } from "@apollo/client";
import toast from "react-hot-toast";
import { StepFormBuilder } from "../..";
import { createTreatyFormItems } from "./form-steps";
import _ from "lodash";
import { CreateEndorsementOffer, CreateEndorsementOfferVariables } from "_graphql/mutation/__generated__/CreateEndorsementOffer";
import { createEndorsement } from "_graphql/mutation/endorsement";
import { currentUserVar } from "_graphql/cache/auth";

type Props = {
  setClose?: (value: boolean) => void;
};

const EndorsementFormContainer = ({ setClose }: Props) => {
  const user = useReactiveVar(currentUserVar);
  const [initiateAddMeeting] = useMutation<CreateEndorsementOffer, CreateEndorsementOfferVariables>(
    createEndorsement,
    {
      refetchQueries: ["GetEndorsements"],
    }
  );
  const form = useFormik<any>({
    initialValues: {
      offer: {
        offer: ""
      },
      details: {
        class_of_business_id: "",
        offer_details: [],
        insurer_id: "",
        premium: "",
        policy_number: "",
        insured_by: "",
        sum_insured: "",
        rate: "",
        facultative_offer: "",
        commission: "",
        brokerage: "",
        currency: "",
        period_of_insurance_from: "",
        period_of_insurance_to: "",
        comment: "",
      },
    },
    onSubmit: async (values) => {
      await initiateAddMeeting({
        variables: {
          offerInput: {
            offer_comment: values?.comment || "",
            offer_details: JSON.stringify(values.details.old_offer_detail),
            ..._.omit(values.details, ["old_class_of_business_id", "old_offer_detail", "comment", "offer_details"]) as any,
            employee_id: user?.employee?.employee_id ?? "",
          },
          offerId: values?.offer?.exisitingOffer?.offer_id,
        }
      }).then((res) => {
        if (res.data?.createEndorsementOffer) {
          toast.success("Endorsement Created Successfully");
          setClose?.(false);
        }
      });
    },
  });


  return <StepFormBuilder formSteps={createTreatyFormItems} form={form} setOpen={setClose} />;
};

export default EndorsementFormContainer;



