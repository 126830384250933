import numeral from "numeral";
import React from "react";

type Props = {
  data: any;
  allValues: any;
};

const TreatyDeductionFormSummary = ({ data }: Props) => {
  return (
    <div className="border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-md p-3">
      <div>
        <div className="grid grid-cols-2 gap-6">
          <div className="col-span-2">
            <span className="block text-sm font-light text-gray-700">
              Estimated Gross Net Retained Premim Income
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {data?.currency} {data?.ergnrpi || "N/A"}
            </div>
          </div>
          <div className="">
            <span className="block text-sm font-light text-gray-700">
              Numerator
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {data?.numerator || "N/A"}
            </div>
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Denominator
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {data?.denominator || "N/A"}
            </div>
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Minimum rate
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {data?.min_rate || "N/A"}
            </div>
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Maximum rate
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {data?.max_rate || "N/A"}
            </div>
          </div>
          <div className="col-span-2">
            <table className="min-w-full table-fixed divide-y divide-gray-300 border border-gray-300 rounded-md">
              <thead className="bg-gray-50">
                <tr className="divide-x divide-gray-200">
                  <th
                    scope="col"
                    className="whitespace-nowrap px-2 py-2 text-left text-sm font-semibold text-gray-900"
                  >
                    Limit
                  </th>
                  <th
                    scope="col"
                    className="whitespace-nowrap px-2 py-2 text-left text-sm font-semibold text-gray-900"
                  >
                    Deductible
                  </th>

                  <th
                    scope="col"
                    className="whitespace-nowrap px-2 py-2 text-left text-sm font-semibold text-gray-900"
                  >
                    Installments
                  </th>
                  <th
                    scope="col"
                    className="whitespace-nowrap px-2 py-2 text-left text-sm font-semibold text-gray-900"
                  >
                    Reinstatement
                  </th>
                  <th
                    scope="col"
                    className="whitespace-nowrap px-2 py-2 text-left text-sm font-semibold text-gray-900"
                  >
                    Discount (%)
                  </th>
                  <th
                    scope="col"
                    className="whitespace-nowrap px-2 py-2 text-left text-sm font-semibold text-gray-900"
                  >
                    Adjustable rate
                  </th>
                  <th
                    scope="col"
                    className="whitespace-nowrap px-2 py-2 text-left text-sm font-semibold text-gray-900"
                  >
                    M&D Premium
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {data?.layers?.map((material: any, idx: number) => (
                  <tr key={idx} className="divide-x divide-gray-200">
                    <td className="whitespace- px-2 py-2 text-sm text-gray-700 text-ellipsis overflow-hidden ">
                      {material?.limit || "N/A"}
                    </td>
                    <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 text-right">
                      {material?.deductible || "N/A"}
                    </td>
                    <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 text-right">
                      {material?.installments || "N/A"}
                    </td>
                    <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 text-right">
                      {material?.reinstatement || "N/A"}
                    </td>
                    <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 text-right">
                      {material?.discount_percentage || "N/A"}
                    </td>
                    <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 text-right">
                      {material?.adjustable_rate || "N/A"}
                    </td>
                    <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 text-right">
                      {data?.currency} {material?.m_and_d_premium || "N/A"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TreatyDeductionFormSummary;
