import { Payment_status } from '_graphql/__generated__/globalTypes'
import { useAppLocation, useAppNavigate } from '_graphql/cache/auth'
import { GetTreaties_rows } from '_graphql/queries/__generated__/GetTreaties'
import { ActionButton, Paginator, RegularInsurerPicker, RegularSelectInput, TableComponent, TableLoader } from 'components'
import { Action } from 'components/buttons/action-button'
import { usePagination } from 'hooks'
import { useTreaties } from 'hooks/data/use-treaties'
import React from 'react'
import { useUrlState } from 'utils'

type Props = {}

const TreatiesTabForBroker = (props: Props) => {
    const { params } = useAppLocation()
    const navigate = useAppNavigate();

    const { offset, limit, search, setPage } = usePagination()
    const [payment_status, setPaymentStatus] = useUrlState("payment_status");
    const [treatyType, setTreatyType] = useUrlState("treatyType");
    const [insurer_id, setInsurerID] = useUrlState("insurer_id");
    const { treaties, count, refetch, loading } = useTreaties({
        args: {
            broker_id: params?.broker as string,
            offset: offset,
            limit: limit,
            treaty_claim_status: [],
            treaty_payment_status: !payment_status ? [] : [payment_status],
            treaty_expiry_status: [],
            search: search || "",
            insurer_id: insurer_id || "",
            treaty_program_type: !treatyType ? [] : [treatyType]
        }
    })

    const navigateItem = (id: string, layers: string, treaty_type: string) => () => {
        navigate({
            to: `./${id}/view`,
            search: (old) => ({
                ...old,
                // layers: layers,
                // layer: "1",
                treaty_type: treaty_type
            }),
        });
    };

    const dispatchAction =
        (id: string, action: Exclude<Action, "expand" | "goto" | "clone">) =>
            () => {
                navigate({
                    search: (old) => ({
                        ...old,
                        id,
                        modal: action,
                    }),
                });
            };

    return (

        <TableComponent
            title={'treaties'}
            hasSearch
            columns={[
                {
                    name: "Ref#",
                    accessor: "treaty_reference",
                },
                {
                    name: "Insurer",
                    accessor: "insurer.insurer_company_name",
                },
                {
                    name: "Treaty Program",
                    accessor: "treaty_program.treaty_name",
                },
                {
                    name: "Program Type",
                    accessor: "treaty_program.treaty_type",
                },
                {
                    name: "Created By",
                    accessor: "employee.employee_first_name",
                    render: (value: GetTreaties_rows) => (
                        <div className='flex flex-row leading-0'>
                            <span>{value?.employee?.employee_first_name} {value?.employee?.employee_last_name}</span>
                        </div>
                    )
                },
                {
                    name: "Period",
                    accessor: "treaty_deduction",
                    render: (value: GetTreaties_rows) => (
                        <div className='flex leading-0'>
                            <span>{value?.treaty_deduction?.treaty_period_from} to {value?.treaty_deduction?.treaty_period_to}</span>
                        </div>
                    )
                },
                {
                    name: "Currency",
                    accessor: "currency",
                },
                {
                    name: "Payment Status",
                    accessor: "treaty_payment_status",
                    render: (value: GetTreaties_rows) => (
                        <div className='flex leading-0'>
                            <span className={`px-2 py-1 text-xs capitalize font-semibold rounded-full ${value?.treaty_payment_status === Payment_status.PAID ? 'bg-green-100 text-green-800' : value?.treaty_payment_status === Payment_status.UNPAID ? 'bg-red-100 text-red-800' : 'bg-yellow-100 text-yellow-800'}`}>
                                {value?.treaty_payment_status?.toLowerCase()}
                            </span>
                        </div>
                    )
                },
                {
                    name: "Actions",
                    accessor: "actions",
                    render: (value: GetTreaties_rows) => (
                        <>
                            <ActionButton action={'goto'} tooltip='View Treaty' onClick={navigateItem(value?.treaty_id || "", value?.layer_limit as string, value?.treaty_program?.treaty_type as string)} />
                            {/* <ActionButton action={'clone'} tooltip='View Dcouments' onClick={dispatchAction(value?.treaty_id || "", "view")} /> */}
                        </>
                    )
                },
            ]}
            loading={loading}
            data={treaties}
            refetch={refetch}
            renderLoader={() => (
                <TableLoader items={[1, 2, 1, 1, 1, 1, 1, 1, 1]} />
            )}
            renderHeaderItems={() => (
                <div className='flex items-center space-x-1'>
                    <RegularInsurerPicker
                        id={'insurer_id'}
                        placeholder='insurer '
                        label={''}
                        values={{ insurer_id }}
                        setFieldValue={(key: any, value: string) => setInsurerID(value)}
                        setFieldTouched={() => { }}
                        rawId
                        setFieldError={() => { }} />

                    <RegularSelectInput
                        id={'treatyType'}
                        label={''}
                        showDefaultOption={false}
                        values={{ treatyType }}
                        handleChange={(evt: any) => setTreatyType(evt.target.value)}
                        placeholder='Treaty Type'
                        options={[
                            { label: "All Treaty Types", value: "" },
                            { label: "Proportional", value: "PROPORTIONAL" },
                            { label: "Non-Proportional", value: "NONPROPORTIONAL" },
                        ]} />
                    <RegularSelectInput
                        id={'payment_status'}
                        label={''}
                        showDefaultOption={false}
                        values={{ payment_status }}
                        handleChange={(evt: any) => setPaymentStatus(evt.target.value)}
                        placeholder='payment status'
                        options={[
                            { label: "All Payments Statuses", value: "" },
                            { label: "Paid", value: "PAID" },
                            { label: "Unpaid", value: "UNPAID" },
                            { label: "Part-payment", value: "PARTPAYMENT" },
                        ]} />
                </div>
            )}
            pagination={() => (
                <Paginator
                    offset={offset}
                    limit={limit}
                    totalItems={count || 0}
                    currentSize={treaties.length}
                    setOffset={setPage}
                />
            )}
        />

    )
}

export default TreatiesTabForBroker