import { Offer_findSingleOffer } from '_graphql/queries/__generated__/Offer'
import { RegularSelectInput } from 'components'
import { useOfferFleet } from 'hooks/data/use-offers'
import React from 'react'
import { useMatch, useNavigate } from 'react-location'
import { LocationGenerics } from 'router/location'

type Props = {
    offer?: Offer_findSingleOffer | undefined
}

const FleetPicker = ({ offer }: Props) => {
    const { params } = useMatch<LocationGenerics>();
    const navigate = useNavigate<LocationGenerics>();
    const { offerFleet } = useOfferFleet({
        policy_number: offer?.offer_detail?.policy_number || "",
        skip: 0
    })

    // function to update the offer in the params 
    // when the user selects a fleet
    const handleChange = (e: any) => {
        const value = e.target.value
        const newParams = { ...params }
        newParams.offer = value
        navigate({
            to: `/create-slip/${value}/view`, search(prev) {
                return {
                    ...prev,
                }
            },
        })
    }

    return (
        <div>
            {["Motor Comprehensive Fleet"].includes(
                offer?.classofbusiness?.business_name || ""
            ) && (
                    <RegularSelectInput id={"offer"} label={""} values={params} options={offerFleet?.map((item) => ({
                        label: item?.vehile_reg_no || "",
                        value: item?.offer_id || ""
                    }))} handleChange={handleChange} />
                )}
        </div>
    )
}

export default FleetPicker