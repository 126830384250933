import { Modal } from "components";
import * as Yup from "yup";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import _ from "lodash";
import UpdateEndorsementFormContainer from "components/forms/update-endorsement";
import { useEndorsement } from "hooks/data/use-endorsement";

export const valiadationSchema = Yup.object().shape({
  class_of_business_id: Yup.object().required("Business is required"),
  offer_details: Yup.array().notRequired(),
  insurer_id: Yup.string().required("Insurer is required"),
  premium: Yup.number().min(0, "Number must be greater than 0")
    .typeError("Must be a number")
    .required("Premium is required"),
  policy_number: Yup.string().required("Policy Number is required"),
  insured_by: Yup.string().required("Insured is required"),
  sum_insured: Yup.number().min(0, "Number must be greater than 0")
    .typeError("Must be a number")
    .required("Sum Insured is required"),
  rate: Yup.number().min(0, "Number must be greater than 0").typeError("Must be a number").required("Rate is required"),
  facultative_offer: Yup.number().min(0, "Number must be greater than 0")
    .typeError("Must be a number")
    .required("Facultative Offer is required"),
  commission: Yup.number().min(0, "Number must be greater than 0")
    .typeError("Must be a number")
    .required("Commission is required"),
  brokerage: Yup.number().min(0, "Number must be greater than 0")
    .typeError("Must be a number")
    .required("Brokerage is required"),
  currency: Yup.string().required("Currency is required"),
  period_of_insurance_from: Yup.date().required(
    "Insurance Start Date is required"
  ),
  period_of_insurance_to: Yup.date()
    .min(Yup.ref("period_of_insurance_from"))
    .required("Insurance End Date is required"),
  comment: Yup.string().notRequired(),
});

export default function UpdateEndorsementContainer({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const { loading: fetching, endorsement } = useEndorsement({
    offerEndorsementId: searchParams.id || "",
  })




  return (
    <Modal
      open={open}
      setOpen={setOpen}
      size="4xl"
      loading={fetching}
      hideActions
      title="Update Endorsement"
      description="Provide the details to update an endorsement."
    >
      <UpdateEndorsementFormContainer setClose={setOpen} endorsement={endorsement} />
    </Modal>
  );
}
