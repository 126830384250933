import { gql } from "@apollo/client";

export const createClassOfBusiness = gql`
  mutation CreateClassOfBusiness(
    $businessName: String!
    $businessDetails: String!
    $businessCategory: String
  ) {
    createClassOfBusiness(
      business_name: $businessName
      business_details: $businessDetails
      business_category: $businessCategory
    ) {
      class_of_business_id
    }
  }
`;
export const updateClassOfBusiness = gql`
  mutation UpdateSingleClassOfBusiness(
    $classOfBusinessId: ID!
    $businessName: String!
    $businessDetails: String!
    $businessCategory: String
  ) {
    updateSingleClassOfBusiness(
      class_of_business_id: $classOfBusinessId
      business_name: $businessName
      business_details: $businessDetails
      business_category: $businessCategory
    ) {
      class_of_business_id
    }
  }
`;

export const removeClassOfBusiness = gql`
  mutation RemoveSingleClassOfBusiness($classOfBusinessId: ID!) {
    removeSingleClassOfBusiness(class_of_business_id: $classOfBusinessId)
  }
`;
