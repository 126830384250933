import { useMutation } from "@apollo/client";
import toast from "react-hot-toast";
import { PopUp } from "components";
import { useMatch, useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { removetreatyBroker } from "_graphql/mutation/treaty";
import { RemoveReBokerFromTreatyList, RemoveReBokerFromTreatyListVariables } from "_graphql/mutation/__generated__/RemoveReBokerFromTreatyList";



export default function RemoveBrokerContainer({
    open,
    setOpen,
    refetch,
}: {
    open: boolean;
    setOpen: (val: boolean) => void;
    refetch?: () => void;
}) {
    const searchParams = useSearch<LocationGenerics>();
    const { params } = useMatch<LocationGenerics>();
    const [remove, { loading }] = useMutation<RemoveReBokerFromTreatyList, RemoveReBokerFromTreatyListVariables>(removetreatyBroker, {
        refetchQueries: ["GetTreatyBrokersParticipants", "Treaty"]
    });


    const handleSubmit = async () => {
        await remove({
            variables: {
                treatyId: params?.treaty || "",
                reBrokerTreatiesParticipationId: searchParams?.id || "",
            },
        }).then(({ data }) => {
            if (data?.removeReBokerFromTreatyList) {
                toast.success("Broker Removed");
                refetch?.();
                setOpen?.(false);
            } else {
                toast.error("Failed to remove Broker");
            }
        });
    }

    return (
        <PopUp
            open={open}
            setOpen={setOpen}
            title="Remove Broker"
            cancelText="No, Don't Remove Broker"
            description="This action would completely remove broker details from system"
            renderActions={() => (
                <>
                    <button
                        type="button"
                        disabled={loading}
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={handleSubmit}
                    >
                        {loading ? "Removing Broker..." : "Remove Broker"}
                    </button>
                </>
            )}
        />
    );
}
