import { useQuery } from "@apollo/client";
import {
  GetOfferPaymentVariables,
  GetOfferPayment,
} from "_graphql/queries/__generated__/GetOfferPayment";
import {
  GetOfferPayments,
  GetOfferPaymentsVariables,
} from "_graphql/queries/__generated__/GetOfferPayments";
import { Offer, OfferVariables } from "_graphql/queries/__generated__/Offer";
import { OfferparticipantDeductions, OfferparticipantDeductionsVariables } from "_graphql/queries/__generated__/OfferparticipantDeductions";
import { getOffer } from "_graphql/queries/offers";
import { getInsurerOfferDeductions, getPayment, getPayments } from "_graphql/queries/payments";

export const useOfferPayments = (variables: GetOfferPaymentsVariables) => {
  const { data, ...rest } = useQuery<
    GetOfferPayments,
    GetOfferPaymentsVariables
  >(getPayments, {
    variables,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });
  const payments = data?.getOfferPayments || [];
  return { payments, ...rest };
};
export const useOfferPayment = (variables: GetOfferPaymentVariables) => {
  const { data, ...rest } = useQuery<GetOfferPayment, GetOfferPaymentVariables>(
    getPayment,
    {
      variables,
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "network-only",
    }
  );
  const payment = data?.getOfferPayment;
  return { payment, ...rest };
};
export const useOfferParticipantDeductions = (variables: OfferparticipantDeductionsVariables, onComplete?: (data: OfferparticipantDeductions) => void) => {
  const { data, ...rest } = useQuery<OfferparticipantDeductions, OfferparticipantDeductionsVariables>(
    getInsurerOfferDeductions,
    {
      variables,
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "cache-first",
      onCompleted(data) {
        onComplete?.(data);
      },
    }
  );
  const deductions = JSON.parse(data?.getOfferparticipantDeductions || "[]") || [];
  return { deductions, ...rest };
};



export const useOffer = (variables: OfferVariables) => {
  const { data, ...rest } = useQuery<Offer, OfferVariables>(getOffer, {
    variables,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });
  return {
    offer: data?.findSingleOffer,
    ...rest,
  };
};
