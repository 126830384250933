import { CheckIcon } from "@heroicons/react/24/solid"
import clsx from "clsx";
import _ from "lodash";
import { FC } from "react"



interface StepWizardProps {
  steps: any[];
  step: number;
  setStep: (step: number) => void;
  lastStep: number;
}

const StepWizard: FC<StepWizardProps> = ({ steps, step: currentStep, setStep, lastStep }) => {

  return (
    <nav aria-label="Progress" className="">
      <ol className="border border-gray-300 dark:border-gray-600 rounded-md divide-y divide-gray-300 dark:divide-gray-600 md:flex md:divide-y-0">
        {steps.map((step, stepIdx) => (
          <li key={step.href} className="relative md:flex-1 md:flex">
            {step.href === currentStep ? (
              <button
                onClick={() => setStep(step.href)}
                disabled={step.href > lastStep}
                className={clsx(
                  step.href > lastStep ? "cursor-not-allowed" : "cursor-pointer",
                  "px-4 py-3 flex items-center text-sm font-medium"
                )}
                aria-current="step"
              >
                <span className="flex-shrink-0 w-8 h-8 flex items-center justify-center border-2 border-primary-600 rounded-full">
                  <span className="text-primary-600">{_.padStart((step.href + 1).toString(), 2, "0")}</span>
                </span>
                <span className="ml-2 text-sm font-medium text-primary-600  whitespace-nowrap">{step.name}</span>
              </button>
            ) : step.href < lastStep ? (
              <button
                onClick={() => setStep(step.href)}
                disabled={step.href > lastStep}
                className={clsx(
                  step.href > lastStep ? "cursor-not-allowed" : "cursor-pointer",
                  "group flex items-center w-full"
                )}
              >
                <span className="px-4 py-3 flex items-center text-sm font-medium">
                  <span className="flex-shrink-0 w-8 h-8 flex items-center justify-center bg-primary-600 rounded-full group-hover:bg-primary-800">
                    <CheckIcon className="w-5 h-5 text-white" aria-hidden="true" />
                  </span>
                  <span className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-50 whitespace-nowrap">{step.name}</span>
                </span>
              </button>
            ) : (
              <button
                onClick={() => setStep(step.href)}
                disabled={step.href > lastStep}
                className={clsx(
                  step.href > lastStep ? "cursor-not-allowed" : "cursor-pointer",
                  "group flex items-center"
                )}
              >
                <span className="px-4 py-3 flex items-center text-sm font-medium">
                  <span className="flex-shrink-0 w-8 h-8 flex items-center justify-center border-2 border-gray-300 dark:border-gray-600 rounded-full group-hover:border-gray-400 dark:group-hover:border-gray-500">
                    <span className="text-gray-500 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-gray-50">{_.padStart((step.href + 1).toString(), 2, "0")}</span>
                  </span>
                  <span className="ml-2 text-sm font-medium text-gray-500 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-gray-50  whitespace-nowrap">{step.name}</span>
                </span>
              </button>
            )}

            {stepIdx !== steps.length - 1 ? (
              <>
                <div className="hidden md:block absolute top-0 right-0 h-full w-5" aria-hidden="true">
                  <svg
                    className="h-full w-full text-gray-300 dark:text-gray-600"
                    viewBox="0 0 22 80"
                    fill="none"
                    preserveAspectRatio="none"
                  >
                    <path
                      d="M0 -2L20 40L0 82"
                      vectorEffect="non-scaling-stroke"
                      stroke="currentcolor"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </>
            ) : null}
          </li>
        ))}
      </ol>
    </nav>
  )
}

export default StepWizard
