import { Menu, Transition } from "@headlessui/react";
import {
  DocumentTextIcon,
  EllipsisHorizontalIcon,
} from "@heroicons/react/24/outline";
import { useAppNavigate, useAppSearch } from "_graphql/cache/auth";
import { Treaty_treaty } from "_graphql/queries/__generated__/Treaty";
import _ from "lodash";
import React, { Fragment } from "react";
import { AccountPeriodsNames, classNames } from "utils";
import UpdateQuarterContainer from "./update-quarter";
import RemoveQuarterContainer from "./remove-quarter";
import ViewTreatyDebitNoteContainer from "./view-treaty-account-debit-note";

type Props = {
  treaty: Treaty_treaty | null | undefined;
};

const TreatyAccounts = ({ treaty }: Props) => {
  const navigate = useAppNavigate();
  const searchParams = useAppSearch();
  return (
    <>
      <div className="p-6">
        <ul
          role="list"
          className="grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8"
        >
          {treaty?.treaty_accounts?.map((account) => (
            <li
              key={account?.treaty_account_id}
              className="overflow-hidden rounded-xl border border-gray-700"
            >
              <div className="flex items-center gap-x-4 border-b border-gray-300/5 bg-card p-6">
                <DocumentTextIcon className="h-12 w-12 flex-none rounded-lg bg-white object-cover ring-1 ring-gray-300/10" />
                <div className="text-sm font-medium leading-6 text-gray-300">
                  {_.get(
                    AccountPeriodsNames,
                    account?.account_periods || "FIRSTQUARTER",
                    ""
                  )}
                </div>
                <Menu as="div" className="relative ml-auto">
                  <Menu.Button className="-m-2.5 block p-2.5 text-gray-400 hover:text-gray-500">
                    <span className="sr-only">Open options</span>
                    <EllipsisHorizontalIcon
                      className="h-5 w-5"
                      aria-hidden="true"
                    />
                  </Menu.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-0.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-300/5 focus:outline-none">
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            className={classNames(
                              active ? "bg-gray-50" : "",
                              "block w-full flex-1 px-3 py-1 text-sm leading-6 text-gray-700"
                            )}
                            onClick={() =>
                              navigate({
                                search(prev) {
                                  return {
                                    ...prev,
                                    treaty_account_id:
                                      account?.treaty_account_id || "",
                                    modal: "calculate",
                                  };
                                },
                              })
                            }
                          >
                            View
                            <span className="sr-only">
                              , {account?.account_periods}
                            </span>
                          </button>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            className={classNames(
                              active ? "bg-gray-50" : "",
                              "block w-full flex-1 px-3 py-1 text-sm leading-6 text-gray-700"
                            )}
                            onClick={() =>
                              navigate({
                                search(prev) {
                                  return {
                                    ...prev,
                                    treaty_account_id:
                                      account?.treaty_account_id || "",
                                    modal: "update",
                                  };
                                },
                              })
                            }
                          >
                            Edit
                            <span className="sr-only">
                              , {account?.account_periods}
                            </span>
                          </button>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            className={classNames(
                              active ? "bg-gray-50" : "",
                              "block w-full flex-1 px-3 py-1 text-sm leading-6 text-red-700"
                            )}
                            onClick={() =>
                              navigate({
                                search(prev) {
                                  return {
                                    ...prev,
                                    treaty_account_id:
                                      account?.treaty_account_id || "",
                                    modal: "remove",
                                  };
                                },
                              })
                            }
                          >
                            Remove
                            <span className="sr-only">
                              , {account?.account_periods}
                            </span>
                          </button>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
              <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
                <div className="flex justify-between gap-x-4 py-3">
                  <dt className="text-gray-200">Cash loss</dt>
                  <dd className="text-gray-200 flex space-x-4">
                    {account?.treaty_account_deduction?.map(
                      (deduction, index) => (
                        <dd className="flex flex-col">
                          <span>Surplus {index + 1}</span>
                          <span>{deduction?.cash_loss}</span>
                        </dd>
                      )
                    )}
                  </dd>
                </div>
                <div className="flex justify-between gap-x-4 py-3">
                  <dt className="text-gray-200">Claim Settled</dt>
                  <dd className="text-gray-200 flex space-x-4">
                    {account?.treaty_account_deduction?.map(
                      (deduction, index) => (
                        <dd className="flex flex-col">
                          <span>Surplus {index + 1}</span>
                          <span>{deduction?.claim_settled}</span>
                        </dd>
                      )
                    )}
                  </dd>
                </div>
              </dl>
            </li>
          ))}
        </ul>
      </div>
      {searchParams?.treaty_account_id && (
        <>
          <ViewTreatyDebitNoteContainer
            open={searchParams?.modal === "calculate"}
            setOpen={(val: boolean) =>
              navigate({
                search(prev) {
                  return {
                    ...prev,
                    modal: val ? "calculate" : undefined,
                    treaty_account_id: val
                      ? prev?.treaty_account_id
                      : undefined,
                  };
                },
              })
            }
          />
          <RemoveQuarterContainer
            open={searchParams?.modal === "remove"}
            setOpen={(val: boolean) =>
              navigate({
                search(prev) {
                  return {
                    ...prev,
                    modal: val ? "remove" : undefined,
                    treaty_account_id: val
                      ? prev?.treaty_account_id
                      : undefined,
                  };
                },
              })
            }
          />
          <UpdateQuarterContainer
            open={searchParams?.modal === "update"}
            setOpen={(val: boolean) =>
              navigate({
                search(prev) {
                  return {
                    ...prev,
                    modal: val ? "update" : undefined,
                    treaty_account_id: val
                      ? prev?.treaty_account_id
                      : undefined,
                  };
                },
              })
            }
          />
        </>
      )}
    </>
  );
};

export default TreatyAccounts;
