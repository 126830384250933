import { gql } from "@apollo/client";

export const createTreaty = gql`
  mutation CreateTreaty($treaty: TreatyData) {
    createTreaty(treaty: $treaty)
  }
`;


export const updateTreatyReinsurers = gql`
  mutation CreateReinsurersTreatyDistribution(
    $ids: [Reinsurer_representative_data]
    $treaty_id: ID
    $layer_number: [Int]
    $treaty_associate_deduction_id: ID
  ) {
    createReinsurersDistribution(
      reinsurer_ids: $ids
      treaty_id: $treaty_id
      layer_number: $layer_number
      treaty_associate_deduction_id: $treaty_associate_deduction_id
    )
  }
`;

export const updateTreatyReinsurersDeductions = gql`
mutation UpdateReinsurerDeductions(
  $data: [ReinsurerParticipationUpdate]
  $account_ids: [ID]
  $layer: String
  $isProp: Boolean
) {
  updateReinsurerDeductions(
    data: $data
    account_ids: $account_ids
    layer: $layer
    isProp: $isProp
  )
}
`;

export const updateTreatyBrokers = gql`
  mutation CreateReBrokerListForTreaty($brokersList: BrokerDistributionList) {
    createReBrokerListForTreaty(brokers_list: $brokersList)
  }
`;

export const sendCoverAndPlacingNotes = gql`
  mutation SendTreatyPlacingOrCovertNote(
    $treaty_id: ID
    $doc_type: String
    $emaildata: EmailComponent
  ) {
    sendTreatyPlacingOrCovertNote(
      treaty_id: $treaty_id
      doc_type: $doc_type
      emaildata: $emaildata
    )
  }
`;
export const updateTreatyForPlaingAndCover = gql`
  mutation UpdateTreatyForPlaingAndCover(
    $treaty_id: ID
    $treaty_details: String
  ) {
    updateTreatyForPlaingAndCover(
      treaty_id: $treaty_id
      treaty_details: $treaty_details
    )
  }
`;

export const removetreatyReinsurer = gql`
  mutation RemoveReinsurersParticipationPercentage($treatyParticipationId: ID) {
    removeReinsurersParticipationPercentage(
      treaty_participation_id: $treatyParticipationId
    )
  }
`;
export const removeAssociateFromTreaty = gql`
mutation RemoveAssociateFromTreaty($treatyToAssociateId: ID) {
  removeAssociateFromTreaty(treaty_to_associate_id: $treatyToAssociateId)
}

`;
export const removetreatyBroker = gql`
  mutation RemoveReBokerFromTreatyList(
    $reBrokerTreatiesParticipationId: ID
    $treatyId: ID
  ) {
    removeReBokerFromTreatyList(
      re_broker_treaties_participation_id: $reBrokerTreatiesParticipationId
      treaty_id: $treatyId
    )
  }
`;

export const updateTreatyReinsurerPercentage = gql`
 mutation AddReinsurersParticipationPercentage(
  $treatyParticipationId: ID!
  $layerNumber: Int!
  $participationPercentage: Float!
  $treatyId: ID!
  $associateDeductionId: ID!
) {
  addReinsurersParticipationPercentage(
    treaty_participation_id: $treatyParticipationId
    layer_number: $layerNumber
    participation_percentage: $participationPercentage
    treaty_id: $treatyId
    associate_deduction_id: $associateDeductionId
  )
}

`;

export const addQuarter = gql`
  mutation AddQuarterForTreaty($data: TreatyAccountData) {
    addQuarterForTreaty(treaty_account: $data)
  }
`;

export const duplicateTreatyCurrency = gql`
mutation DuplicateTreaty($treaty_id: ID, $currencies: [String]) {
    duplicateTreaty(treaty_id: $treaty_id, currencies: $currencies)
  }
    `;

export const updateLimitLayer = gql`
mutation UpdateLimitLayer($limit: LimitData) {
  updateLimitLayer(limit: $limit)
}

`;

export const createOrUpdateAdjustmentStatment = gql`
mutation CreateOrUpdateAdjustmentStatment(
  $treatyId: ID
  $claimsPaid: Float
  $outstandingPayment: Float
  $treatyNpDetailId: ID
) {
  createOrUpdateAdjustmentStatment(
    treaty_id: $treatyId
    claims_paid: $claimsPaid
    outstanding_payment: $outstandingPayment
    treaty_np_detail_id: $treatyNpDetailId
  )
}

`;


export const updateQuarter = gql`
  mutation UpdateQuarterForTreaty(
    $treatyAccount: TreatyAccountData
    $treatyAccountId: ID
  ) {
    updateQuarterForTreaty(
      treaty_account: $treatyAccount
      treaty_account_id: $treatyAccountId
    )
  }
`;

export const removeQuarterForTreaty = gql`
mutation RemoveQuarterForTreaty($treatyAccountId: ID) {
  removeQuarterForTreaty(treaty_account_id: $treatyAccountId)
}
`;


export const sendNPTreatyDebitNote = gql`
mutation SendNPTreatyDebitNote($nptreatydata: NPTreatyData) {
  sendNPTreatyDebitNote(nptreatydata: $nptreatydata)
}

`;
