import { gql } from "@apollo/client";

export const createOffer = gql`
  mutation CreateInputOffer($offerInput: Offer_data!) {
    createInputOffer(offer_input: $offerInput) {
      offer_id
    }
  }
`;

export const createFleetOffer = gql`
  mutation CreateFleetOffer($offerInput: [Offer_data]) {
    createFleetOffer(offer_input: $offerInput)
  }
`;

export const updateTreaty = gql`
mutation UpdateTreaty($treaty: TreatyData, $treatyId: ID) {
  updateTreaty(treaty: $treaty, treaty_id: $treatyId)
}
`;

export const updateOffer = gql`
  mutation UpdateInputOffer(
    $offerInput: Offer_data!
    $offerId: ID!
    $offerDetailId: ID!
  ) {
    updateInputOffer(
      offer_input: $offerInput
      offer_id: $offerId
      offer_detail_id: $offerDetailId
    )
  }
`;

export const deleteOffer = gql`
  mutation DeleteOfferFromSystem($offerId: ID!) {
    deleteOfferFromSystem(offer_id: $offerId)
  }
`;


export const deleteTreaty = gql`
mutation DeleteTreaty($treatyId: ID, $treatyType: TreatyType) {
  deleteTreaty(treaty_id: $treatyId, treaty_type: $treatyType)
}

`;

export const reOpenOffer = gql`
  mutation ReopenOffer($offerId: ID) {
    reopenOffer(offer_id: $offerId) {
      offer_id
    }
  }
`;

export const createDistributionList = gql`
  mutation CreateDistributionList(
    $offer_id: ID!
    $reinsurer_reps: [Reinsurer_representative_data!]!
  ) {
    createDistributionList(
      distribution_list: {
        offer_id: $offer_id
        reinsurer_reps: $reinsurer_reps
      }
    )
  }
`;

export const removeReInsurerFromParticipation = gql`
  mutation RemoveReInsurerFromList(
    $offer_id: ID!
    $reinsurer_id: ID!
    $offer_participating_id: ID!
  ) {
    removeParticipantByReinsurer(
      offer_participating_id: $offer_participating_id
      offer_id: $offer_id
      reinsurer_id: $reinsurer_id
    )
  }
`;

export const removeASsociateFromParticipation = gql`
  mutation RemoveAssociateFromParticipation($offer_to_associate_id: ID!) {
    removeParticipantByParticipant(
      offer_to_associate_id: $offer_to_associate_id
    )
  }
`;

export const addReInsurerPercentage = gql`
  mutation AddPercentage(
    $offer_participant_id: ID!
    $offer_id: ID!
    $percentage: Float!
  ) {
    addPrecentageToParticipant(
      offer_participant_id: $offer_participant_id
      offer_id: $offer_id
      participating_percentage: $percentage
    ) {
      offer_amount
    }
  }
`;


export const addReinsurerPercentageAndDeductions = gql`
  mutation AddReinsurerPercentageAndDeductions(
    $offerEndParticipantId: ID
    $deductions: EndorsementParticipationAndDeductions
  ) {
    addReinsurerPercentageAndDeductions( 
      offer_end_participant_id: $offerEndParticipantId
      deductions: $deductions
    )
  }
`;


export const deleteOfferEndorsementParticipant = gql`
mutation DeleteOfferEndorsementParticipant($offerEndParticipantId: ID) {
  deleteOfferEndorsementParticipant(
    offer_end_participant_id: $offerEndParticipantId
  )
}

`;


export const addReBrokerParticipationPercentage = gql`
  mutation AddReBrokerParticipationPercentage(
  $brokerParticipation: ReBrokerParticipationPercentageInput
) {
  addReBrokerParticipationPercentage(broker_participation: $brokerParticipation)
}

`;

export const closeOffer = gql`
  mutation CreateClosingForOffer($extraCharges: Offer_extra_charge_data!) {
    createClosingForOffer(extra_charges: $extraCharges) {
      offer_extra_charge_id
    }
  }
`;

export const sendOfferEmails = gql`
  mutation SendOfferAsBroadCast(
    $data: Email_data!
    $should_send: Int
    $include_attachment: Boolean
  ) {
    sendOfferAsBroadCast(
      email_data: $data
      should_send: $should_send
      include_attachment: $include_attachment
    )
  }
`;

export const sendDebitAndCreditNoteEmails = gql`
  mutation SendDebitAndCoverNotes($data: Email_data!) {
    sendDebitAndCoverNotes(email_data: $data)
  }
`;

export const sendClosingSlipEmails = gql`
  mutation SendClosingslip($data: Email_data!) {
    sendClosingslip(email_data: $data)
  }
`;
