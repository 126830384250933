import { useMutation } from '@apollo/client';
import { DeleteClaimAmount, DeleteClaimAmountVariables } from '_graphql/mutation/__generated__/DeleteClaimAmount';
import { removeOfferClaim } from '_graphql/mutation/claims';
import { PopUp } from 'components'
import { FC } from 'react'
import toast from 'react-hot-toast';
import { useMatch, useSearch } from 'react-location';
import { LocationGenerics } from 'router/location';

type Props = {
    open: boolean;
    setOpen: (val: boolean) => void;
    refetch?: () => void;
}

const RemoveClaimContainer: FC<Props> = ({ open, setOpen, refetch }) => {
    const searchParams = useSearch<LocationGenerics>();
    const { params } = useMatch<LocationGenerics>();
    const [initiate, { loading }] = useMutation<DeleteClaimAmount, DeleteClaimAmountVariables>(removeOfferClaim, {
        refetchQueries: ["GetEndorsements"],
    });

    const handleSubmit = () => {
        initiate({
            variables: {
                offerClaimId: searchParams?.id || "",
                offerId: params?.offer || "",
            },
        }).then(({ data }) => {
            if (data?.deleteClaimAmount) {
                toast.success("Claim Removed Successfully");
                refetch?.();
                setOpen?.(false);
            } else {
                toast.error("Failed to remove claim");
            }
        });
    }

    return (
        <PopUp
            open={open}
            setOpen={setOpen}
            title='Remove Claim'
            description='Are you sure you want to remove this claim?'
            renderActions={() => (
                <>
                    <button
                        type="button"
                        disabled={loading}
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={handleSubmit}
                    >
                        {loading ? "Removing Claim..." : "Remove Claim"}
                    </button>
                </>
            )}
        />
    )
}

export default RemoveClaimContainer