import {
  DocumentPlusIcon,
  RectangleStackIcon,
} from "@heroicons/react/24/outline";
import {
  ActionButton,
  OfficeButton,
  OfficeHeader,
  Paginator,
  RegularInsurerPicker,
  TableComponent,
  TableLoader,
} from "components";
import moment from "moment";
import { FC, useMemo } from "react";
import { useSearch, useNavigate } from "react-location";
import { LocationGenerics } from "router/location";
import { useUrlState } from "utils";
import CreateContainer from "./create";
import ViewContainer from "./view";
import { Action } from "components/buttons/action-button";
import UpdateContainer from "./update";
import { useTreatyPrograms } from "hooks/data/use-treaty-programs";
import { TreatyPrograms_rows } from "_graphql/queries/__generated__/TreatyPrograms";
import _ from "lodash";
import clsx from "clsx";
import RemoveContainer from "./remove";
import { usePagination } from "hooks";

type Props = {};

const TreatyProgramsPage: FC<Props> = (props) => {
  const [modal, setModal] = useUrlState("modal");
  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const { offset, limit, search, setPage } = usePagination()
  const dispatchAction =
    (id: string, action: Exclude<Action, "expand" | "goto" | "clone">) =>
      () => {
        navigate({
          search: (old) => ({
            ...old,
            id,
            modal: action,
          }),
        });
      };


  const expandItem = (id: string) => () => {
    navigate({
      to: `./${id}/view`,
    });
  };

  const { treatyPrograms, loading, refetch, count } = useTreatyPrograms({
    args: {
      insurer_id: searchParams.insurer || "",
      limit,
      offset,
      search,
      treaty_type: []
    }
  });

  return (
    <main className="flex-1 flex flex-col overflow-hidden bg-shade-500">
      <OfficeHeader
        renderActions={() => (
          <OfficeButton onClick={() => setModal("create")}>
            <DocumentPlusIcon className="w-5 h-5 mr-2" />
            <span className="hidden sm:inline">Create Treaty Program</span>
          </OfficeButton>
        )}
      />
      <div className="flex flex-1 overflow-y-auto">
        <div className="flex-1 mx-auto min-w-0  py-4 sm:py-6 overflow-hidden overflow-y-auto light flex">
          <TableComponent
            title={"Class of Business"}
            hasSearch
            renderHeaderItems={() => (
              <>
                <RegularInsurerPicker
                  id={"insurer"}
                  label={""}
                  rawId
                  values={searchParams}
                  setFieldValue={(key: string, value: string) => navigate({
                    search(prev) {
                      return {
                        ...prev,
                        [key]: value,
                      };
                    },
                  })}
                  setFieldTouched={() => { }}
                  setFieldError={() => { }} />
              </>
            )}
            columns={[
              {
                name: "Program Name",
                accessor: "treaty_name",
                render: (value: TreatyPrograms_rows) => (
                  <div className="flex">
                    <div>
                      <RectangleStackIcon className="w-9 h-9 mr-2" />
                    </div>
                    <div className="flex flex-col">
                      <span className="font-bold text-sm">
                        {value.treaty_name}
                      </span>
                      <span
                        className={clsx("font-light text-xs", {
                          "text-green-500":
                            value.treaty_type === "PROPORTIONAL",
                          "text-blue-600":
                            value.treaty_type === "NONPROPORTIONAL",
                        })}
                      >
                        {_.startCase(value.treaty_type?.toLowerCase()) || "N/A"}
                      </span>
                    </div>
                  </div>
                ),
              },
              {
                name: "Insurer",
                accessor: "insurer.insurer_company_name",
              },
              {
                name: "Period",
                accessor: "created_at",
                render: (value: TreatyPrograms_rows) => (
                  <div className="flex flex-col">
                    <span className="font-light text-xs">
                      From:{" "}
                      <span className="font-bold text-green-500">
                        {moment(
                          _.first(value?.treaty_associate_deductions)
                            ?.treaty_period_from
                        ).format("DD-MM-YYYY") || "N/A"}
                      </span>
                    </span>
                    <span className="font-light text-xs">
                      To:{" "}
                      <span className="font-bold text-red-600">
                        {moment(
                          _.first(value?.treaty_associate_deductions)
                            ?.treaty_period_to
                        ).format("DD-MM-YYYY") || "N/A"}
                      </span>
                    </span>
                  </div>
                ),
              },
              {
                name: "Actions",
                accessor: "actions",
                render: (value: TreatyPrograms_rows) => (
                  <>
                    <ActionButton
                      action="view"
                      onClick={dispatchAction(
                        value.treaty_program_id || "",
                        "view"
                      )}
                    />
                    <ActionButton
                      action="update"
                      onClick={dispatchAction(
                        value.treaty_program_id || "",
                        "update"
                      )}
                    />
                    <ActionButton
                      action="goto"
                      tooltip="Deductions"
                      onClick={expandItem(
                        value.treaty_program_id || "",
                      )}
                    />
                    <ActionButton action="remove" onClick={dispatchAction(
                      value.treaty_program_id || "",
                      "remove"
                    )} />
                  </>
                ),
              },
            ]}
            renderLoader={() => <TableLoader items={[1, 1, 1, 1]} />}
            loading={loading}
            data={treatyPrograms}
            refetch={refetch}
            pagination={() => <Paginator
              offset={offset}
              limit={limit}
              totalItems={count}
              currentSize={treatyPrograms.length}
              setOffset={setPage} />
            }
          />
        </div>
      </div>

      <CreateContainer
        open={modal === "create"}
        setOpen={(val: boolean) => setModal(val ? "create" : undefined)}
        refetch={refetch}
      />
      {searchParams.id && (
        <>
          <ViewContainer
            open={modal === "view"}
            setOpen={(val: boolean) => setModal(val ? "view" : undefined)}
          />
          <UpdateContainer
            open={modal === "update"}
            setOpen={(val: boolean) => setModal(val ? "update" : undefined)}
            refetch={refetch}
          />
          <RemoveContainer
            open={modal === "remove"}
            setOpen={(val: boolean) => setModal(val ? "remove" : undefined)}
            refetch={refetch}
          />
        </>
      )}
    </main>
  );
};

export default TreatyProgramsPage;
