import { classNames, wrapClick } from "utils";
import { LanguagePicker, Modal, PDFRenderer } from "components";
import { useSearch, useNavigate, useMatch } from "react-location";
import { LocationGenerics } from "router/location";
import config from "config";
import { useState } from "react";
import { useOffer, useOfferParticipant } from "hooks/data/use-offers";
import { useFormik } from "formik";


const tabs = [
    {
        name: "Closing Note",
        href: "CLOSING_NOTE",
    },
    {
        name: "NIC Schedule",
        href: "NIC_SCHEDULE",
        country: "ghana"
    }
]

export default function ViewNoteContainer({
    open,
    setOpen,
}: {
    open: boolean;
    setOpen: (val: boolean) => void;
}) {
    const form = useFormik({
        initialValues: {
            lang: "en"
        },
        onSubmit(values, formikHelpers) {

        },
    })
    const searchParams = useSearch<LocationGenerics>();
    const { params } = useMatch<LocationGenerics>();
    const [orderStatus, setOrderStatus] = useState("CLOSING_NOTE");
    const { loading, offer } = useOffer({
        offer_id: params?.offer || "",
    });

    const { loading: fetchingParticipant, participant } = useOfferParticipant({
        participantId: searchParams?.id || "",
    })


    const url = orderStatus === "NIC_SCHEDULE" ? `${config.asset.uri}nic_form/${btoa(
        JSON.stringify({
            offer_id: params?.offer || "",
            reinsurer_id: participant?.reinsurer.reinsurer_id,
            lang: form.values.lang,
        })
    )}` : `${config.asset.uri}generate_closing_slip/${btoa(
        JSON.stringify({
            offer_id: params?.offer || "",
            reinsurer_id: participant?.reinsurer.reinsurer_id,
            period_from: offer?.offer_detail?.period_of_insurance_from,
            period_to: offer?.offer_detail?.period_of_insurance_to,
            lang: form.values.lang,
        })
    )}`;

    return (
        <Modal
            open={open}
            setOpen={setOpen}
            loading={loading || fetchingParticipant}
            hideActions
            size="6xl"
            title="Closing Note and NIC Schedule"
            description="View Closing Note and NIC Schedule"
        >
            <div className="block">
                <div className="border-b border-shade-500 bg-card-500 px-6">
                    <nav className="-mb-px flex space-x-8 items-center" aria-label="Tabs">
                        {tabs.map((_orderStatus) => (
                            <button
                                key={_orderStatus.name}
                                onClick={wrapClick(() => setOrderStatus(_orderStatus.href))}
                                disabled={_orderStatus.country ? _orderStatus.country === participant?.reinsurer?.reinsurer_address?.country.toLowerCase() : false}
                                className={classNames(
                                    orderStatus === _orderStatus.href
                                        ? "border-gray-200 text-primary-600"
                                        : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200",
                                    "whitespace-nowrap flex py-4 px-1 border-b-2 disabled:cursor-not-allowed font-medium text-sm"
                                )}
                                aria-current={
                                    orderStatus === _orderStatus.href ? "page" : undefined
                                }
                            >
                                {_orderStatus.name}
                            </button>
                        ))}
                    </nav>
                </div>
            </div>
            <div className="p-3">
                <div className="mb-3">
                    <LanguagePicker id={"lang"} labelHidden {...form} />
                </div>
                <PDFRenderer
                    url={url}
                />
            </div>
        </Modal>
    );
}
