import { Combobox } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/24/solid";
import { useInsurers } from "hooks/data/use-insurers";
import { useReInsurers } from "hooks/data/use-re-insurers";
import { useTreatyPrograms } from "hooks/data/use-treaty-programs";
import _, { filter } from "lodash";
import { FC, useMemo, useState } from "react";
import { classNames } from "utils";

interface Option {
  label: {
    title: string;
    imageUrl?: string;
  };
  value: string | object;
}

interface SearchSelectInputProps {
  id: string;
  label: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  labelHidden?: boolean;
  values: any;
  errors?: any;
  touched?: any
  filter?: {
    type: "NONPROPORTIONAL" | "PROPORTIONAL";
    insurer_id?: string;
  }
  optionsLoading?: boolean;
  setFieldValue: any
  setFieldTouched: any
  setFieldError: any
  rounded?: boolean
  rawId?: boolean;
}

const SearchSelectInput: FC<SearchSelectInputProps> = ({ id, values, setFieldValue, setFieldTouched, placeholder, label, errors, touched, required, labelHidden, disabled, rounded, rawId, filter: _filter = { type: "NONPROPORTIONAL" } }) => {
  const { treatyPrograms, loading } = useTreatyPrograms({
    args: {
      insurer_id: _filter.insurer_id || "",
      limit: 1000,
      offset: 0,
      search: "",
      treaty_type: [_filter.type]
    }
  })
  const [query, setQuery] = useState('');
  const options = useMemo(() => treatyPrograms.filter(el => el?.treaty_type === _filter.type).map(({ ...rest }) => ({ label: rest.treaty_name, value: rawId ? rest?.treaty_program_id : { ...rest } })), [treatyPrograms]);

  const filteredOptions =
    query === ''
      ? options
      : options.filter((option) => {
        return option.label?.toLowerCase().includes(query.toLowerCase())
      })

  return (
    <Combobox as="div" disabled={disabled} value={_.get(values, id, null)} onChange={(val) => { setFieldTouched(id, true); setFieldValue(id, val) }}>
      {!labelHidden && (<Combobox.Label className="block text-sm font-medium text-gray-700">{label} {required ? <span className="text-red-500">*</span> : ""}</Combobox.Label>)}
      <div className={classNames(
        labelHidden ? "" : "mt-1",
        "relative"
      )}>
        <Combobox.Input
          className={classNames(
            disabled ? "cursor-not-allowed bg-gray-100" : "bg-white",
            "w-full  border border-gray-300 py-2 pl-3 pr-10 shadow-sm focus:border-gray-200 focus:outline-none focus:ring-1 focus:ring-primary-500 sm:text-sm placeholder:font-light placeholder:text-xs", !rounded ? "rounded-md" : "rounded-none"
          )}
          placeholder={loading ? "Loading, Please wait" : placeholder}
          autoComplete="none"
          onChange={(event) => setQuery(event.target.value)}
          displayValue={(option: any) => {
            // console.log("OPTION", option)
            return options.find(o => _.isEqual(typeof o?.value === "string" ? o?.value : o?.value?.treaty_program_id, typeof o?.value === "string" ? option : option?.treaty_program_id))?.label || options.find(o => _.isEqual(o.value, option))?.label || ""
          }}
        />
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </Combobox.Button>

        {filteredOptions.length > 0 && (
          <Combobox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            <Combobox.Option
              value={_.isString(_.first(filteredOptions)) ? "" : null}
              className={({ active }) =>
                classNames(
                  'relative cursor-default select-none py-2 pl-3 pr-9 font-light',
                  active ? 'bg-primary-600 text-white' : 'text-gray-500'
                )
              }
            >
              {({ active, selected }) => (
                <>
                  <div className="flex items-center">
                    <span className={""}>Select One</span>
                  </div>

                  {selected && (
                    <span
                      className={classNames(
                        'absolute inset-y-0 right-0 flex items-center pr-4',
                        active ? 'text-white' : 'text-primary-600'
                      )}
                    >
                      <CheckIcon className="h-5 w-5" aria-hidden="true" />
                    </span>
                  )}
                </>
              )}
            </Combobox.Option>
            {filteredOptions.map((option) => (
              <Combobox.Option
                key={JSON.stringify(option.value)}
                value={option.value}
                className={({ active }) =>
                  classNames(
                    'relative cursor-default select-none py-2 pl-3 pr-9',
                    active ? 'bg-primary-600 text-white' : 'text-gray-900'
                  )
                }
              >
                {({ active, selected }) => (
                  <>
                    <div className="flex items-center">
                      <span className={classNames(
                        option.label ? 'ml-3' : '',
                        'truncate',
                        selected ? 'font-semibold' : ''
                      )}
                      >{option.label}</span>
                    </div>

                    {selected && (
                      <span
                        className={classNames(
                          'absolute inset-y-0 right-0 flex items-center pr-4',
                          active ? 'text-white' : 'text-primary-600'
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
      {_.get(errors, id) && _.get(touched, id) ? (
        <p className="mt-2 text-sm text-red-600" id={`${id}-error`}>
          {_.get(errors, id)}
        </p>
      ) : null
      }
    </Combobox>
  )
}

export default SearchSelectInput;