import { useEmployees } from "hooks/data/use-employees";
import _ from "lodash";
import { FC, useMemo, useState } from "react";
import { classNames, wrapClick } from "utils";
import Select from "react-select/creatable";


interface EmployeePickerProps {
  id: string;
  label: string;
  placeholder?: string;
  accessor?: string;
  required?: boolean;
  disabled?: boolean;
  labelHidden?: boolean;
  values: any;
  errors?: any;
  touched?: any
  optionsLoading?: boolean;
  setFieldValue: any
  setFieldTouched: any
  setFieldError: any
  rounded?: boolean
  rawId?: boolean;
  multiple?: boolean;
  height?: string;
}

export const createOption = (label: string) => ({
  label,
  value: label,
});

const EmployeePicker: FC<EmployeePickerProps> = ({ id, values, setFieldValue, setFieldTouched, height, label, errors, touched, required, labelHidden, disabled, rounded, rawId, accessor, multiple = true }) => {
  const [inputvalue, setInputvalue] = useState("");
  const [mails, setMails] = useState<{ label: string, value: string }[]>([]);
  const { employees, loading } = useEmployees({
    offset: 0,
    limit: 500,
    search: "",
    sort: "",
  })

  console.log("employees", employees);
  const [query, setQuery] = useState('');
  const options = useMemo(() => employees?.map(({ employee_first_name, employee_last_name, employee_email }) => ({ label: `${employee_first_name} ${employee_last_name}`, value: employee_email })), [employees]);

  const filteredOptions =
    query === ''
      ? options
      : options?.filter((option) => {
        return option?.label?.toLowerCase()?.includes(query.toLowerCase())
      })

  const selectedValue = _.get(values, id, []) || [];

  const handleChange = (
    selectedOptions: any
  ): void => {
    const selectedValues = selectedOptions.map((option: any) => option.value);
    console.log("selectedOptions", mails.map(el => el.value), selectedValue);
    setFieldValue(id, _.merge(selectedValue, selectedValues));
    setMails(selectedOptions);
    setFieldTouched(id, true);
  };

  const handleInputChange = (event: any) => {
    console.log("event", event);
    setInputvalue(event);
  };

  const handleKeyDown = (event: any) => {
    if (!inputvalue) return;
    // eslint-disable-next-line default-case
    switch (event.key) {
      case "Enter":
      case "Tab":
        console.log("UO", [..._.get(values, id, []), inputvalue]);
        setMails([..._.get(values, id, []), createOption(inputvalue)]);
        setFieldValue(id, [..._.get(values, id, []), inputvalue]);
        setInputvalue("");
        event.preventDefault();
    }
  };

  const removeTag = (idx: number) => () => {
    const _copy = [...selectedValue];
    _copy.splice(idx, 1);
    setFieldValue(id, _copy);
    setMails(_copy.map((el) => createOption(el)));
  };

  return (
    <div>
      {!labelHidden && (
        <label className="block text-sm font-medium text-gray-700">
          {label} {required && <span className="text-red-500">*</span>}
        </label>
      )}
      <div className="space-y-1">

        <Select
          options={[
            ...filteredOptions,
          ]}
          value={mails}
          controlShouldRenderValue={false}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          onInputChange={handleInputChange}
          isClearable={false}
          isLoading={loading}
          placeholder={selectedValue.length > 0 ? "add more" : "select"}
          isDisabled={disabled}
          isMulti={multiple}
          className={classNames(
            _.get(values, id, "") === "" ? "font-light text-xs" : "text-sm",
            _.get(errors, id) && _.get(touched, id)
              ? "focus:ring-red-500 focus:border-red-500 border-red-600"
              : "focus:ring-primary-500 focus:border-gray-200 border-gray-300",
            disabled ? "cursor-not-allowed bg-gray-100" : "",
            "shadow-sm block outline-none w-full rounded-md placeholder:font-light placeholder:text-xs h-[38px]"
          )}
        />
        <div className={classNames(
          labelHidden ? "" : "mt-1",
          _.get(errors, id) && _.get(touched, id)
            ? "focus-within:ring-red-500 focus-within:border-red-500 border-red-600"
            : "focus-within:ring-primary-500 focus-within:border-primary-500 border-gray-300",
          "appearance-none group w-full border flex-wrap space-x-1 shadow-sm sm:text-sm focus-within:ring-1 rounded-md max-h-64  overflow-y-auto",
          height ? height : "min-h-[2rem]"
        )}>
          {selectedValue.map((tag: any, key: number) => (
            <span
              key={key}
              className="inline-flex m-1 h-[28px] rounded-md items-center py-0.5 pl-2.5 pr-1 sm:text-sm bg-primary-100 text-gray-700"
            >
              {tag}
              <button
                onClick={wrapClick(removeTag(key))}
                type="button"
                className="flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-primary-400 hover:bg-primary-200 hover:text-primary-500 focus:outline-none focus:bg-primary-500 focus:text-white"
              >
                <span className="sr-only">Remove {tag}</span>
                <svg
                  className="h-2 w-2"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 8 8"
                >
                  <path
                    stroke-linecap="round"
                    stroke-width="1.5"
                    d="M1 1l6 6m0-6L1 7"
                  />
                </svg>
              </button>
            </span>
          ))}
        </div>
      </div>
      {_.get(errors, id) && _.get(touched, id) && (
        <p className="mt-2 text-sm text-red-600" id={`${id}-error`}>
          {_.get(errors, id)}
        </p>
      )}
    </div>
  )
}

export default EmployeePicker;