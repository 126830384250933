import { GetTreatyBrokersParticipants_getTreatyBrokersParticipants } from '_graphql/queries/__generated__/GetTreatyBrokersParticipants';
import { ActionButton, Avatar, TableComponent, TableLoader } from 'components';
import { useTreatyBrokers } from 'hooks/data/use-treaties';
import { useMatch } from 'react-location';
import { LocationGenerics } from 'router/location';

export default function TreatyBrokersAssociates() {
    const { params } = useMatch<LocationGenerics>();
    const { loading, brokers, refetch } = useTreatyBrokers({
        treatyId: params.treaty || "",
    });

    return (
        <div className="grid grid-cols-3 sm:py-6 items-start px-3 flex-1">
            <div className='col-span-3'>
                <TableComponent
                    title={'brokers'}
                    hasSearch
                    loading={loading}
                    columns={[
                        {
                            accessor: "reinsurer_representative.reinsurer.re_company_name",
                            name: "Company",
                            render: (row: GetTreatyBrokersParticipants_getTreatyBrokersParticipants) => (
                                <div className='flex space-x-2 items-center'>
                                    <Avatar alt={row?.re_broker?.re_broker_name || ""} />
                                    <div className='flex flex-col'>
                                        <span className='font-bold text-xs'>{row?.re_broker?.re_broker_name}</span>
                                        <span className='font-light text-xs'>{row?.re_broker?.re_broker_website}</span>
                                    </div>
                                </div>
                            )
                        },
                        {
                            accessor: "reinsurer_representative.rep_first_name",
                            name: "Email",
                            render: (row: GetTreatyBrokersParticipants_getTreatyBrokersParticipants) => (
                                <div className='flex flex-col'>
                                    <span className='font-bold text-xs'>{row?.re_broker?.re_broker_email}</span>
                                </div>
                            )
                        },
                        {
                            accessor: "reinsurer_representative.rep_first_name",
                            name: "Actions",
                            render: (row: GetTreatyBrokersParticipants_getTreatyBrokersParticipants) => (
                                <>
                                    <ActionButton action='remove' onClick={() => { }} />
                                </>
                            )
                        },
                    ]}
                    data={brokers || []}
                    renderLoader={() => <TableLoader items={[1, 1]} />}
                    refetch={refetch} />
            </div>
        </div>
    )
}
