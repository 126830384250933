import { DocumentIcon } from '@heroicons/react/24/outline'
import React from 'react'

type Props = {
    notification: any
    parsedNotification: any
}

const EmailNotificationComponent = ({ notification, parsedNotification }: Props) => {
    return (
        <div className="container mx-auto px-4">
            <div className="row bg-yellow-200 p-4 mb-4">
                {JSON.parse(notification?.system_notification?.notification_content || "{}").message}
            </div>
            <div className="row mb-4">
                <table className="w-full table-auto">
                    <tbody>
                        <tr>
                            <td className="font-bold">Type of email </td>
                            <td>{parsedNotification?.type}</td>
                        </tr>
                        <tr>
                            <td className="font-bold">Created On</td>
                            <td>
                                {new Date(notification?.system_notification?.created_at).toLocaleString()}
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold">To</td>
                            <td>
                                {parsedNotification?.recipient?.company_name || parsedNotification?.recipient?.re_company_name}{" "}
                                {`<${parsedNotification?.recipient.email || parsedNotification?.recipient?.associate_email.join(", ")}>`}
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold">Cc</td>
                            <td>{parsedNotification?.other_recipients.join(", ")}</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className="row mb-4">
                <div className="col-md-12">
                    <p className="uppercase font-bold">Message</p>
                </div>
                <div
                    className="col-md-12"
                    dangerouslySetInnerHTML={{ __html: parsedNotification?.message_content }}
                ></div>
            </div>
            <div className="row">
                {parsedNotification?.attachments && (
                    <div className="col-md-12 mb-2">
                        <p className="uppercase font-bold">Attachments</p>
                    </div>
                )}
                <div className="col-md-12">
                    <ul className="list-none">
                        {parsedNotification?.attachments?.map((file: any, key: number) => (
                            <li className="" key={key}>
                                {/* <img src={PDF} className="h-8 w-8 inline m-2" /> */}
                                <DocumentIcon className="h-4 w-4 mr-2 inline" />
                                {file.file_name}{" "}
                                <a target="_blank" href={`${file.downloadblelink}`} className="text-blue-500">
                                    download
                                </a>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>

    )
}

export default EmailNotificationComponent