import { GetTreatyBrokersParticipants_getTreatyBrokersParticipants } from "_graphql/queries/__generated__/GetTreatyBrokersParticipants";
import { ActionButton, Avatar, TableComponent, TableLoader } from "components";
import { useTreatyBrokers } from "hooks/data/use-treaties";
import { useMatch, useNavigate, useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { useUrlState } from "utils";
import { Action } from "components/buttons/action-button";
import AddBrokerParticipantsPercentageContainer from "./add-percentage";
import RemoveBrokerContainer from "./remove";
import ViewBrokerTreatyClosingContainer from "./view-closing-slip";

export default function TreatyBrokers() {
  const [modal, setModal] = useUrlState("modal");
  const navigate = useNavigate<LocationGenerics>();
  const { params } = useMatch<LocationGenerics>();
  const searchParams = useSearch<LocationGenerics>();
  const { loading, brokers, refetch } = useTreatyBrokers({
    treatyId: params.treaty || "",
  });

  const dispatchAction =
    (id: string, action: Exclude<Action, "expand" | "goto">) => () => {
      navigate({
        search: (old) => ({
          ...old,
          id,
          modal: action,
        }),
      });
    };

  return (
    <div className="grid grid-cols-3 sm:py-6 items-start px-3 flex-1">
      <div className="col-span-3">
        <TableComponent
          title={"brokers"}
          hasSearch
          loading={loading}
          columns={[
            {
              accessor: "reinsurer_representative.reinsurer.re_company_name",
              name: "Company",
              render: (
                row: GetTreatyBrokersParticipants_getTreatyBrokersParticipants
              ) => (
                <div className="flex space-x-2 items-center">
                  <Avatar alt={row?.re_broker?.re_broker_name || ""} />
                  <div className="flex flex-col">
                    <span className="font-bold text-xs">
                      {row?.re_broker?.re_broker_name}
                    </span>
                    <span className="font-light text-xs">
                      {row?.re_broker?.re_broker_website}
                    </span>
                  </div>
                </div>
              ),
            },
            {
              accessor: "reinsurer_representative.rep_first_name",
              name: "Email",
              render: (
                row: GetTreatyBrokersParticipants_getTreatyBrokersParticipants
              ) => (
                <div className="flex flex-col">
                  <span className="font-bold text-xs">
                    {row?.re_broker?.re_broker_email}
                  </span>
                </div>
              ),
            },

            {
              accessor: "share_percentage",
              name: "Participation (%)",
            },
            {
              accessor: "",
              name: "Actions",
              render: (
                row: GetTreatyBrokersParticipants_getTreatyBrokersParticipants
              ) => (
                <>
                  <ActionButton
                    action={"assignPercentage"}
                    tooltip={"Assign Percentage"}
                    onClick={dispatchAction(
                      row?.re_broker_treaties_participation_id || "",
                      "assignPercentage"
                    )}
                  />
                  <ActionButton
                    action={"clone"}
                    tooltip={"View Treaty Closings"}
                    onClick={dispatchAction(
                      row?.re_broker_treaties_participation_id || "",
                      "clone"
                    )}
                  />
                  <ActionButton
                    action={"remove"}
                    tooltip={"Remove associate"}
                    onClick={dispatchAction(
                      row?.re_broker_treaties_participation_id || "",
                      "remove"
                    )}
                  />
                </>
              ),
            },
          ]}
          data={brokers || []}
          renderLoader={() => <TableLoader items={[1, 1, 1, 1]} />}
          refetch={refetch}
        />
      </div>

      <AddBrokerParticipantsPercentageContainer
        open={modal === "assignPercentage"}
        setOpen={(val: boolean) =>
          setModal(val ? "assignPercentage" : undefined)
        }
      />

      <RemoveBrokerContainer
        open={modal === "remove"}
        setOpen={(val: boolean) => setModal(val ? "remove" : undefined)}
      />

      {searchParams.id && (
        <ViewBrokerTreatyClosingContainer
          open={modal === "clone"}
          setOpen={(val: boolean) => setModal(val ? "clone" : undefined)}
        />
      )}
    </div>
  );
}
