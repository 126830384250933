import { Offer_findSingleOffer_offer_participant } from "_graphql/queries/__generated__/Offer";
import { ActionButton, Pie, TableComponent, TableLoader } from "components";
import { useOffer } from "hooks/data/use-offers";
import { useMatch, useNavigate, useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import AddParticipantsPercentageContainer from "./add-percentage";
import { useUrlState } from "utils";
import { Action } from "components/buttons/action-button";
import { Colors } from "_graphql/data";
import RemoveContainer from "./remove-participants";
import numeral from "numeral";

export default function OfferParticipation() {
  const [modal, setModal] = useUrlState("modal");
  const searchParams = useSearch<LocationGenerics>();
  const { params } = useMatch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const { loading, offer, refetch } = useOffer({
    offer_id: params.offer || "",
  });
  const dispatchAction =
    (
      id: string,
      action: Exclude<Action, "expand" | "goto" | "clone">,
      participation_id?: string
    ) =>
      () => {
        navigate({
          search: (old) => ({
            ...old,
            id,
            reinsurer: participation_id,
            modal: action,
          }),
        });
      };


  const participants =
    offer?.offer_participant?.map(
      (item, idx) =>
      ({
        bgColor: "bg-[" + Colors[idx] + "]",
        color: Colors[idx],
        id: item?.reinsurer?.re_company_name || "",
        label: item?.reinsurer?.re_company_name || "",
        value: item?.offer_participant_percentage || 0,
      } || [])
    ) || [];

  const sharedValue = offer?.offer_participant?.reduce((acc, curr) => acc + (curr?.offer_participant_percentage || 0), 0).toFixed(4) || 0;

  return (
    <div className="grid grid-cols-4 gap-6 items-start px-3 flex-1">
      <div className="col-span-3">
        <TableComponent
          title={"participants"}
          hasSearch
          loading={loading}
          columns={[
            {
              accessor: "reinsurer.re_company_name",
              name: "Company Name",
            },
            {
              accessor: "reinsurer.re_company_email",
              name: "Company Email",
            },
            {
              accessor: "offer_participant_percentage",
              name: "Participant %",
            },
            {
              accessor: "",
              name: "Actions",
              render: (row: Offer_findSingleOffer_offer_participant) => (
                <>
                  <ActionButton
                    action={"assignPercentage"}
                    tooltip={
                      row?.offer_participant_percentage
                        ? "Update Percentage"
                        : "Add Percentage"
                    }
                    onClick={dispatchAction(
                      row?.offer_participant_id || "",
                      "assignPercentage",
                      numeral(offer?.facultative_offer).subtract(sharedValue).add(row?.offer_participant_percentage).format("#,#.####")
                    )}
                  />
                  <ActionButton
                    action={"remove"}
                    tooltip={"Remove participant"}
                    onClick={dispatchAction(
                      row?.offer_participant_id || "",
                      "remove",
                      row?.reinsurer?.reinsurer_id || ""
                    )}
                  />
                </>
              ),
            },
          ]}
          data={offer?.offer_participant || []}
          renderLoader={() => <TableLoader items={[1, 1, 1, 1]} />}
          refetch={refetch}
        />
      </div>
      <div className="flex flex-col items-center justify-between ">
        {/* <span>{offer?.facultative_offer}</span>
        <span>{sharedValue}</span> */}
        <span className="text-left text-gray-200 text-sm font-bold">
          Percentage Left:{" "} {numeral(offer?.facultative_offer).subtract(sharedValue).format("0.0000")}
        </span>
        <div className="w-60 h-80">
          <Pie
            data={[
              ...participants,
              {
                label: "Unassigned",
                value:
                  (offer?.facultative_offer || 100) -
                  participants.reduce((acc, curr) => acc + curr.value, 0),
                color: "#ccc",
                bgColor: "#ccc",
                id: "Unassigned",
              },
            ]}
            innerRadius={0.2}
          />
        </div>
      </div>

      {searchParams?.id && (
        <>
          <AddParticipantsPercentageContainer
            open={modal === "assignPercentage"}
            setOpen={(val: boolean) =>
              setModal(val ? "assignPercentage" : undefined)
            }
          />
          <RemoveContainer
            open={modal === "remove"}
            setOpen={(val: boolean) => setModal(val ? "remove" : undefined)}
          />
        </>
      )}
    </div>
  );
}
