import { FormikProps } from 'formik'
import _ from 'lodash'
import React from 'react'
import { OfferDeduction } from './schema';
import numeral from 'numeral';
import { SelectInput, TextArea, TextInput } from 'components/core';

type Props = {
    form: FormikProps<any>;
    deductions: OfferDeduction[]
}



const DistributePaymentForm = ({ form, deductions }: Props) => {
    return (
        <div>
            {deductions.map((deduction, index) => (
                <div className='mt-6 first:mt-0'>
                    <div className=" border border-gray-300 border-dashed bg-gray-100 divide-y divide-dashed divide-gray-300 rounded-md">
                        <div className="grid grid-cols-3 gap-4 p-4">
                            <div className="col-span-2">
                                <span className="block text-sm font-light text-gray-700">
                                    Reinsurer
                                </span>
                                <div className="mt-1 block w-full sm:text-sm font-medium">
                                    {deduction.re_company_name}
                                </div>
                            </div>
                            <div className="">
                                <span className="block text-sm font-light text-gray-700">
                                    Participating (%)
                                </span>
                                <div className="mt-1 block w-full sm:text-sm font-medium">
                                    {numeral(deduction.offer_participant_percentage).format("0.00")}
                                </div>
                            </div>
                            <div className="">
                                <span className="block text-sm font-light text-gray-700">
                                    Facultative Premuim
                                </span>
                                <div className="mt-1 block w-full sm:text-sm font-medium">
                                    {numeral(deduction.participant_fac_premium).format("0,0.00")}
                                </div>
                            </div>
                            <div className="">
                                <span className="block text-sm font-light text-gray-700">
                                    Withholding Tax
                                </span>
                                <div className="mt-1 w-full block sm:text-sm font-medium">
                                    {numeral(_.last(deduction.offer_deductions)?.withholding_tax_paid).format("0,0.00")}

                                </div>
                            </div>
                            <div className="">
                                <span className="block text-sm font-light text-gray-700">
                                    Brokerage
                                </span>
                                <div className="mt-1 block w-full sm:text-sm font-medium">
                                    {numeral(_.last(deduction.offer_deductions)?.brokerage_amount_paid).format("0,0.00")}

                                </div>
                            </div>
                            <div className="">
                                <span className="block text-sm font-light text-gray-700">
                                    NIC levy
                                </span>
                                <div className="mt-1 block w-full sm:text-sm font-medium">
                                    {numeral(_.last(deduction.offer_deductions)?.nic_levy_paid).format("0,0.00")}
                                </div>
                            </div>
                            <div className="">
                                <span className="block text-sm font-light text-gray-700">
                                    Commission taken
                                </span>
                                <div className="mt-1 block w-full sm:text-sm text-green-600 font-medium">
                                    {numeral(_.last(deduction.offer_deductions)?.commission_taken).format("0,0.00")}
                                </div>
                            </div>
                            <div className="">
                                <span className="block text-sm font-light text-gray-700">
                                    Amount
                                </span>
                                <div className="mt-1 block w-full sm:text-sm text-green-600 font-medium">
                                    {numeral(_.last(deduction.offer_deductions)?.offer_amount).format("0,0.00")}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='grid grid-cols-2 gap-3 mt-6'>
                        <div>
                            <SelectInput options={[{ label: "Bank Tansfer", value: "Bank Transfer" }, {
                                label: "Cheque",
                                value: "Cheque"
                            }]} id={`deductions.${index}.payment_type`} label={'Payment Type'} {...form} />
                        </div>
                        <div className="">
                            <TextInput placeholder='Bank Name eg. Stanbic' id={`deductions.${index}.bank_name`} label={'Bank Name'} {...form} />
                        </div>
                        {_.get(form.values.deductions?.[index], "payment_type", "") === 'Cheque' && (
                            <div className="col-span-2">
                                <TextInput placeholder='Cheque Number eg. 00999991234' id={`deductions.${index}.cheque_number`} label={'Cheque Number'} {...form} />
                            </div>
                        )}
                        {_.get(form.values.deductions?.[index], "payment_type", "") === 'Bank Transfer' && (
                            <div className="col-span-2">
                                <TextInput placeholder='Beneficiary Bank Name eg. Ecobank' id={`deductions.${index}.b_bank_name`} label={'Beneficiary Bank Name'} {...form} />
                            </div>
                        )}
                        <div className='col-span-2'>
                            <TextArea id={`deductions.${index}.comment`} label={'Payment Details'} {...form} />
                        </div>
                    </div>
                </div>
            ))}
        </div>
    )
}

export default DistributePaymentForm