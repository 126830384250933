import { wrapClick } from "utils";
import { useMutation } from "@apollo/client";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { Modal } from "components";
import * as Yup from "yup";
import { FacultativeDeductionForm } from "components/forms";
import { UPDATE_EXTRA_CHARGE } from "_graphql/mutation/re-insurer";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { useOffer } from "hooks/data/use-offers";
import { UpdateReinsurerExtraCharges, UpdateReinsurerExtraChargesVariables } from "_graphql/mutation/__generated__/UpdateReinsurerExtraCharges";
import { useEffect } from "react";

export const insurerRepValidationSchema = Yup.object().shape({
    rep_first_name: Yup.string().required("First name is required"),
    rep_last_name: Yup.string().required("Last name is required"),
    rep_primary_phonenumber: Yup.string().required("Phone number is required"),
    rep_secondary_phonenumber: Yup.string().notRequired(),
    rep_email: Yup.string().required("Email is required"),
    position: Yup.string().required("Position is required"),
});

export default function SetupDeductionContainer({
    open,
    setOpen,
    refetch,
}: {
    open: boolean;
    setOpen: (val: boolean) => void;
    refetch?: () => void;
}) {
    const searchParams = useSearch<LocationGenerics>();
    const { offer, loading: fetching } = useOffer({
        offer_id: searchParams?.id || "",
    });


    const [create, { loading }] = useMutation<
        UpdateReinsurerExtraCharges,
        UpdateReinsurerExtraChargesVariables
    >(UPDATE_EXTRA_CHARGE);

    const form = useFormik<any>({
        initialValues: {
            agreed_brokerage_percentage: "",
            agreed_commission: "",
            nic_levy: "",
            withholding_tax: "",
        },
        validationSchema: insurerRepValidationSchema,
        onSubmit: async (variables) => {
            await create({
                variables: {
                    extraCharge: {
                        reinsurer_data: {
                            agreed_brokerage_percentage: variables.agreed_brokerage_percentage,
                            agreed_commission: variables.agreed_commission,
                            nic_levy: variables.nic_levy,
                            withholding_tax: variables.withholding_tax,
                            reinsurer_id: searchParams?.reinsurer || "",
                            reinsurer_participant_id: searchParams?.reinsurer || "",
                        },
                        offer_id: offer?.offer_id,
                    },
                    extraChargeId: offer?.reinsurer_offer_extra_charge?.offer_extra_charge_id || "",
                    participantId: searchParams?.reinsurer || "",

                },
            }).then(({ data }) => {
                if (data?.updateReinsurerExtraCharges) {
                    toast.success("Re-Insurer Representative updated successfully");
                    refetch?.();
                    form.resetForm();
                } else {
                    toast.error("Failed to update Re-Insurer Representative");
                }
            });
        },
        onReset: () => {
            setOpen(false);
        },
    });

    useEffect(() => {
        if (offer) {
            form.setValues({
                agreed_brokerage_percentage: offer?.reinsurer_offer_extra_charge?.agreed_brokerage_percentage || "",
                agreed_commission: offer?.reinsurer_offer_extra_charge?.agreed_commission || "",
                nic_levy: offer?.reinsurer_offer_extra_charge?.nic_levy || "",
                withholding_tax: offer?.reinsurer_offer_extra_charge?.withholding_tax || "",
            })
        }
    }, [offer])

    return (
        <Modal
            open={open}
            setOpen={setOpen}
            loading={fetching}
            title="Update Deduction Charges"
            description="Update the deduction charges for this re-insurer."
            renderActions={() => (
                <>
                    <button
                        type="button"
                        disabled={loading}
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={wrapClick(form.handleSubmit)}
                    >
                        {loading ? "Updating Deduction..." : "Update Deduction"}
                    </button>
                </>
            )}
        >
            <FacultativeDeductionForm form={form} program={offer} />
        </Modal>
    );
}
