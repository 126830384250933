import { useMutation } from "@apollo/client";
import toast from "react-hot-toast";
import { PopUp } from "components";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import {
  RemoveProportionalPayment,
  RemoveProportionalPaymentVariables,
} from "_graphql/mutation/__generated__/RemoveProportionalPayment";
import { removeProportionalPayment } from "_graphql/mutation/payment";

export default function RemoveContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const [remove, { loading }] = useMutation<
    RemoveProportionalPayment,
    RemoveProportionalPaymentVariables
  >(removeProportionalPayment, {
    refetchQueries: ["GetProportionalTreatyPayments"],
  });

  const handleSubmit = async () => {
    await remove({
      variables: {
        paymentId: searchParams?.payment_id || "",
      },
    })
      .then(({ data }) => {
        if (data?.removeProportionalPayment) {
          toast.success("Payment Removed Successfully");
          refetch?.();
          setOpen?.(false);
        } else {
          toast.error("Could not remove payment");
        }
      })
      .catch((error) => {
        toast.error("Could not remove payment");
        console.log("Could not remove payment", error);
      });
  };

  return (
    <PopUp
      open={open}
      setOpen={setOpen}
      title="Delete Payment"
      cancelText="No, Don't Delete Payment"
      description="This action would completely remove payment details from system"
      renderActions={() => (
        <>
          <button
            type="button"
            disabled={loading}
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={handleSubmit}
          >
            {loading ? "Removing Payment..." : "Remove Payment"}
          </button>
        </>
      )}
    />
  );
}
