import { FC } from 'react'

interface CoreComponentProps {

}

const CoreComponent: FC<CoreComponentProps> = () => {
  return (
    <div>
      Core Component
    </div>
  )
}

export default CoreComponent