import { useMutation } from "@apollo/client";
import toast from "react-hot-toast";
import { PopUp } from "components";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { DeleteReBroker, DeleteReBrokerVariables } from "_graphql/mutation/__generated__/DeleteReBroker";
import { removeBroker } from "_graphql/mutation/broker";
import { DeleteTreatyProgram, DeleteTreatyProgramVariables } from "_graphql/mutation/__generated__/DeleteTreatyProgram";
import { removeTreatyProgram } from "_graphql/mutation/treaty-programs";



export default function RemoveContainer({
    open,
    setOpen,
    refetch,
}: {
    open: boolean;
    setOpen: (val: boolean) => void;
    refetch?: () => void;
}) {
    const searchParams = useSearch<LocationGenerics>()
    const [remove, { loading }] = useMutation<DeleteTreatyProgram, DeleteTreatyProgramVariables>(removeTreatyProgram, {
        refetchQueries: ["TreatyPrograms"]
    });


    const handleSubmit = async () => {
        await remove({
            variables: {
                programId: searchParams?.id || ""
            },
        }).then(({ data }) => {
            if (data?.deleteTreatyProgram) {
                toast.success("Treaty Program removed successfully");
                refetch?.();
                setOpen?.(false);
            } else {
                toast.error("Failed to remove Treaty Program");
            }
        });
    }

    return (
        <PopUp
            open={open}
            setOpen={setOpen}
            title="Remove Treaty Program"
            cancelText="No, Don't Remove Treaty Program"
            description="This action would completely remove program details from system"
            renderActions={() => (
                <>
                    <button
                        type="button"
                        disabled={loading}
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={handleSubmit}
                    >
                        {loading ? "Removing Treaty Program..." : "Remove Treaty Program"}
                    </button>
                </>
            )}
        />
    );
}
