import { wrapClick } from "utils";
import { useMutation } from "@apollo/client";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { Modal } from "components";
import * as Yup from "yup";
import { EndorsementPercentageForm } from "components/forms";
import {
  addReinsurerPercentageAndDeductions,
} from "_graphql/mutation/offers";
import { useMatch, useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { useEndorsementParticipant } from "hooks/data/use-offers";
import { useEffect } from "react";
import { AddReinsurerPercentageAndDeductions, AddReinsurerPercentageAndDeductionsVariables } from "_graphql/mutation/__generated__/AddReinsurerPercentageAndDeductions";

export const valiadationSchema = Yup.object().shape({
  deductions: Yup.object().shape({
    brokerage: Yup.number().min(0, "Number must be greater than 0").required("Brokerage is required"),
    commission: Yup.number().min(0, "Number must be greater than 0").required("Commission is required"),
    nic: Yup.number().min(0, "Number must be greater than 0").required("NIC is required"),
    participating_percentage: Yup.number().min(0, "Number must be greater than 0").required("Participating percentage is required"),
    wht: Yup.number().min(0, "Number must be greater than 0").required("WHT is required"),
  }),
  // expected: Yup.number().min(0, "Number must be greater than 0").required("Expected percentage is required"),
  offerEndParticipantId: Yup.string().required("Offer End Participant ID is required"),
});

export default function AddParticipantsPercentageContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const { params } = useMatch<LocationGenerics>();
  const searchParams = useSearch<LocationGenerics>();
  const { participant, loading: fetching } = useEndorsementParticipant({
    offerEndParticipantId: searchParams.id || "",
  });

  const [create, { loading }] = useMutation<
    AddReinsurerPercentageAndDeductions,
    AddReinsurerPercentageAndDeductionsVariables
  >(addReinsurerPercentageAndDeductions, {
    refetchQueries: ["GetEndorsement", "GetEndorsementParticipant", "GetEndorsementParticipants"],
  });

  const form = useFormik<AddReinsurerPercentageAndDeductionsVariables & { expected: any }>({
    initialValues: {
      deductions: {
        brokerage: 0,
        commission: 0,
        nic: 0,
        participating_percentage: 0,
        wht: 0,
      },
      offerEndParticipantId: searchParams.id || "",
      expected: participant?.offer_end_participant_percentage,
    },
    validationSchema: valiadationSchema,
    onSubmit: async (variables) => {
      await create({
        variables: variables,
      }).then(({ data }) => {
        if (data?.addReinsurerPercentageAndDeductions) {
          toast.success("Percentage updated Successfully");
          refetch?.();
          form.resetForm();
        } else {
          toast.error("Could not update percentage");
        }
      });
    },
    onReset: () => {
      setOpen(false);
    },
  });

  useEffect(() => {
    if (participant) {
      form.setValues({
        ...form.values,
        deductions: {
          brokerage: participant.offer_endorsement_extra_charges?.end_agreed_brokerage_percentage || 0,
          commission: participant.offer_endorsement_extra_charges?.end_agreed_commission || 0,
          nic: participant.offer_endorsement_extra_charges?.end_nic_levy || 0,
          participating_percentage: participant.offer_end_participant_percentage || 0,
          wht: participant.offer_endorsement_extra_charges?.end_withholding_tax || 0,
        },
        expected: parseFloat(searchParams?.reinsurer || "0.00"),
        offerEndParticipantId: searchParams.id || "",
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [participant]);

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title={"Add Percentage to " + participant?.reinsurer?.re_company_name}
      loading={fetching}
      description="Add percentage for this ReInsurer"
      renderActions={() => (
        <>
          <button
            type="button"
            disabled={loading}
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(form.handleSubmit)}
          >
            {loading ? "Saving changes..." : "Save changes"}
          </button>
        </>
      )}
    >
      <EndorsementPercentageForm form={form} />
    </Modal>
  );
}
