import { Offers_rows } from "_graphql/queries/__generated__/Offers";
import clsx from "clsx";
import { TableComponent, ActionButton, TableLoader, Paginator } from "components";
import { Action } from "components/buttons/action-button";
import { usePagination } from "hooks";
import { useOffers } from "hooks/data/use-offers";
import YearPicker from "layouts/calendar-wrapper/year-picker";
import _ from "lodash";
import moment from "moment";
import numeral from "numeral";
import { FC, useMemo } from "react";
import { useMatch, useNavigate, useSearch } from "react-location";
import { LocationGenerics } from "router/location";

type Props = {};

const InsurerOffers: FC<Props> = (props) => {
  const navigate = useNavigate<LocationGenerics>();
  const searchParams = useSearch<LocationGenerics>();
  const { params } = useMatch<LocationGenerics>();
  const { offset, limit, setPage } = usePagination();

  const filter = useMemo(
    () => ({
      page: searchParams.page || 1,
      pageSize: searchParams.pageSize || 12,
      search: (searchParams.search || "").toString(),
      args: {
        insurer_id: params?.insurer,
        status: ["PENDING", "OPEN", "CLOSED"],
        claim_status: [],
        payment_status: [],
        year: searchParams.year?.toString() || moment().format("YYYY"),
      },
    }),
    [searchParams, params?.insurer]
  );
  const { loading, offers, refetch, count } = useOffers(filter);
  const dispatchAction =
    (id: string, action: Exclude<Action, "expand" | "goto" | "clone">) =>
      () => {
        navigate({
          search: (old) => ({
            ...old,
            id,
            modal: action,
          }),
        });
      };

  const expandItem = (id: string) => {
    navigate({
      to: `./${id}/payments`,
    });
  };
  return (
    <div className="flex flex-1 overflow-y-auto">
      <div className="flex-1 mx-auto min-w-0  py-4 sm:py-6 overflow-hidden overflow-y-auto light flex">
        <TableComponent
          title={"offers"}
          hasSearch
          loading={loading}
          renderHeaderItems={() => (
            <div className="flex justify-end">
              <YearPicker />
            </div>

          )}
          pagination={() => (
            <Paginator
              offset={offset}
              limit={limit}
              totalItems={count || 0}
              currentSize={offers.length}
              setOffset={setPage}
            />
          )}
          columns={[
            {
              name: "Policy #",
              accessor: "offer_detail.policy_number",
            },
            {
              name: "Class of Business",
              accessor: "classofbusiness.business_name",
              render: (value: Offers_rows) => (
                <div className="flex flex-col text-xs">
                  <span className="font-medium">
                    {value.classofbusiness?.business_name || "N/A"}
                  </span>
                </div>
              ),
            },
            {
              name: "Insured",
              accessor: "offer_detail.insured_by",
              render: (value: Offers_rows) => {
                const details = JSON.parse(value?.offer_detail?.offer_details || "[]");
                return (
                  <div className="flex flex-col text-xs ">
                    <span className="font-medium">
                      {value.offer_detail?.insured_by || "N/A"}
                    </span>
                    <span className="font-light">
                      {[
                        "Motor Comprehensive Fleet",
                        "Motor Comprehensive",
                        "Motor Comprehensive (Automobile Fac Facility)",
                      ].includes(value?.classofbusiness?.business_name || "") ? details?.find(
                        (el: { [x: string]: string; }) =>
                          el["keydetail"] === "Vehicle Reg No" ||
                          el["keydetail"] === "Vehicle Reg No."
                      )?.value : ""}
                    </span>
                  </div>
                )
              },
            },
            {
              name: "Sum Insured",
              accessor: "sum_insured",
              render: (value: Offers_rows) => (
                <span>
                  {value?.offer_detail?.currency}{" "}
                  {numeral(value?.sum_insured).format("0,0.00")}
                </span>
              ),
            },
            {
              name: "Comission (%)",
              accessor: "commission",
            },
            {
              name: "Offer Status",
              accessor: "offer_status",
              render: ({ offer_status }: Offers_rows) => (
                <div
                  className={clsx(
                    "w-auto flex items-center justify-center p-0.5 rounded-full",
                    {
                      "bg-yellow-100": offer_status === "PENDING",
                      "bg-blue-100": offer_status === "OPEN",
                      "bg-green-100": offer_status === "CLOSED",
                    }
                  )}
                >
                  <span
                    className={clsx("text-xs font-bold", {
                      "text-yellow-600": offer_status === "PENDING",
                      "text-blue-600": offer_status === "OPEN",
                      "text-green-600": offer_status === "CLOSED",
                    })}
                  >
                    {_.startCase(offer_status?.toLowerCase())}
                  </span>
                </div>
              ),
            },
            {
              name: "Payment Status",
              accessor: "payment_status",
              render: ({ payment_status }: Offers_rows) => (
                <div>
                  <div
                    className={clsx(
                      "w-auto flex items-center justify-center p-0.5 rounded-full",
                      {
                        "bg-red-100": payment_status === "UNPAID",
                        "bg-teal-100": payment_status === "PAID",
                        "bg-sky-100": payment_status === "PARTPAYMENT",
                      }
                    )}
                  >
                    <span
                      className={clsx("text-xs font-bold", {
                        "text-red-600": payment_status === "UNPAID",
                        "text-teal-600": payment_status === "PAID",
                        "text-sky-600": payment_status === "PARTPAYMENT",
                      })}
                    >
                      {_.startCase(payment_status?.toLowerCase())}
                    </span>
                  </div>
                </div>
              ),
            },
            {
              name: "Offer Date",
              accessor: "offer_date",
              render: (value: Offers_rows) => (
                <span>{moment(value?.created_at).format("DD-MM-YYYY")}</span>
              ),
            },
            {
              name: "Actions",
              accessor: "salary",
              render: ({ offer_id }: Offers_rows) => (
                <>
                  <ActionButton
                    action="view"
                    tooltip="View Offer"
                    onClick={dispatchAction(offer_id, "view")}
                  />
                  <ActionButton
                    action="expand"
                    tooltip="View payments"
                    onClick={() => expandItem(offer_id)}
                  />
                </>
              ),
            },
          ]}
          data={offers}
          refetch={refetch}
          renderLoader={() => <TableLoader items={[1, 1, 1, 1, 1, 1, 1, 1, 1]} />}
        />
      </div>
    </div>
  );
};

export default InsurerOffers;
