import { wrapClick } from "utils";
import { useMutation } from "@apollo/client";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { Modal } from "components";
import * as Yup from "yup";
import { BusinessForm } from "components/forms";
import { useClassOfBusiness } from "hooks/data/use-class-of-business";
import { LocationGenerics } from "router/location";
import { useSearch } from "react-location";
import { useEffect } from "react";
import {
  UpdateSingleClassOfBusiness,
  UpdateSingleClassOfBusinessVariables,
} from "_graphql/mutation/__generated__/UpdateSingleClassOfBusiness";
import { updateClassOfBusiness } from "_graphql/mutation/class-of-business";

export const businessValidationSchema = Yup.object().shape({
  business_name: Yup.string().required("Business name is required"),
  business_group: Yup.string().required("Business group is required"),
  requests: Yup.array().of(
    Yup.object().shape({
      keyDetail: Yup.string().required("Detail is required"),
      required: Yup.string().required("Check Required is status"),
      type: Yup.string().required("Type of is required"),
    })
  ),
});

export default function UpdateContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const { loading: fetching, singleClassOfBusiness } = useClassOfBusiness({
    classOfBusinessId: searchParams.id || "",
  });
  const [create, { loading }] = useMutation<
    UpdateSingleClassOfBusiness,
    UpdateSingleClassOfBusinessVariables
  >(updateClassOfBusiness, {
    refetchQueries: ["ClassOfBusinesses", "SingleClassOfBusiness"],
  });

  const form = useFormik<any>({
    initialValues: {
      business_name: "",
      business_group: "",
      requests: [],
    },
    validationSchema: businessValidationSchema,
    onSubmit: async (variables) => {
      await create({
        variables: {
          businessDetails: JSON.stringify(
            variables.requests?.map((item: any) => ({
              keydetail: item.keyDetail,
              required: item.required,
              type: item.type,
            }))
          ),
          businessName: variables.business_name,
          businessCategory: variables.business_group,
          classOfBusinessId: searchParams.id || "",
        },
      }).then(({ data }) => {
        if (data?.updateSingleClassOfBusiness?.class_of_business_id) {
          toast.success("Business Updated Successfully");
          refetch?.();
          form.resetForm();
        } else {
          toast.error("Failed to update Business");
        }
      });
    },
    onReset: () => {
      setOpen(false);
    },
  });

  useEffect(() => {
    if (singleClassOfBusiness) {
      form.setFieldValue("business_name", singleClassOfBusiness.business_name);
      form.setFieldValue(
        "business_group",
        singleClassOfBusiness.business_category
      );
      form.setFieldValue(
        "requests",
        JSON.parse(singleClassOfBusiness.business_details || "[]")?.map(
          (item: any) => ({
            keyDetail: item.keydetail,
            required: item.required || "Yes",
            type: item.type || "Text",
          })
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleClassOfBusiness]);

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      loading={fetching}
      title="Update Business"
      description="Provide the details to update business"
      renderActions={() => (
        <>
          <button
            type="button"
            disabled={loading}
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(form.handleSubmit)}
          >
            {loading ? "Updating Business..." : "Update Business"}
          </button>
        </>
      )}
    >
      <BusinessForm form={form} />
    </Modal>
  );
}
