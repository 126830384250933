import React from 'react'

type Props = {
    notification: any
    parsedNotification: any
}

const DocumentSMSNotificationComponent = ({ notification, parsedNotification }: Props) => {
    return (
        <div>
            <div className="bg-yellow-200 p-4 mb-4">
                {JSON.parse(notification?.system_notification?.notification_content || "{}").message}
            </div>
        </div>
    )
}

export default DocumentSMSNotificationComponent