import { wrapClick } from "utils";
import { useMutation } from "@apollo/client";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { Modal } from "components";
import * as Yup from "yup";
import { SendEmailForm } from "components/forms";
import { useMatch, useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import _ from "lodash";
import { SendTreatyPlacingOrCovertNote, SendTreatyPlacingOrCovertNoteVariables } from "_graphql/mutation/__generated__/SendTreatyPlacingOrCovertNote";
import { sendCoverAndPlacingNotes } from "_graphql/mutation/treaty";


export const valiadationSchema = Yup.object().shape({
    message: Yup.string().required("Message is required"),
    subject: Yup.string().required("Subject is required"),
});

export default function SendPlacingContainer({
    open,
    setOpen,
    refetch,
}: {
    open: boolean;
    setOpen: (val: boolean) => void;
    refetch?: () => void;
}) {
    const { params } = useMatch<LocationGenerics>();
    const searchParams = useSearch<LocationGenerics>();

    const [create, { loading }] = useMutation<SendTreatyPlacingOrCovertNote, SendTreatyPlacingOrCovertNoteVariables>(sendCoverAndPlacingNotes, {
        refetchQueries: ["Treaty"],
    });

    const form = useFormik<any>({
        initialValues: {
            copied_emails: [],
            subject: "",
            message: "",
            include_attachment: false,
            should_send: 0,
        },
        validationSchema: valiadationSchema,
        onSubmit: async (variables) => {
            await create({
                variables: {
                    emaildata: {
                        attachments: [],
                        copied_email: variables.copied_emails?.map((el: any) => el) || [],
                        subject: variables.subject,
                        email_content: variables.message,
                    },
                    doc_type: _.capitalize(_.last(_.split(searchParams.id, " "))),
                    treaty_id: params.treaty || "",
                },
            }).then(({ data }) => {
                if (data?.sendTreatyPlacingOrCovertNote) {
                    toast.success("Emails sent successfully");
                    refetch?.();
                    form.resetForm();
                } else {
                    toast.error("Failed to send emails");
                }
            });
        },
        onReset: () => {
            setOpen(false);
        },
    });

    return (
        <Modal
            open={open}
            setOpen={setOpen}
            title={`Send Email to ${searchParams.id} Participants`}
            description="Draft an email to send to the participant(s) of this offer."
            renderActions={() => (
                <>
                    <button
                        type="button"
                        disabled={loading}
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={wrapClick(form.handleSubmit)}
                    >
                        {loading ? "Sending emails..." : "Send Email"}
                    </button>
                </>
            )}
        >
            <SendEmailForm form={form} />
        </Modal>
    );
}
