import { TextInput } from "components/core";
import { FormikProps } from "formik";
import _ from "lodash";
import { FC } from "react";

export interface AdjustmentStatementFormProps {
  form: FormikProps<any>;
}

const AdjustmentStatementForm: FC<AdjustmentStatementFormProps> = ({
  form,
}) => {
  return (
    <>
      <div className="space-y-6 divide-y divide-gray-200">
        <div className="grid grid-cols-2 gap-6 mt-2">
          <div className="col-span-2">
            <TextInput
              id={"claimsPaid"}
              label="Claim Paid"
              required
              placeholder="Eg. 10"
              {...form}
            />
          </div>
          <div className="col-span-2">
            <TextInput
              id={"outstandingPayment"}
              label="Outstanding Payment"
              required
              placeholder="Eg. 10"
              {...form}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AdjustmentStatementForm;
