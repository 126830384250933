import { XMarkIcon } from "@heroicons/react/24/outline";
import {
  BusinessGroupPicker,
  InsurerPicker,
  TextInput,
  TreatyTypePicker,
} from "components/core";
import { FormikProps } from "formik";
import _ from "lodash";
import { FC, useEffect } from "react";
import { classNames, wrapClick } from "utils";

export interface ProgramFormProps {
  form: FormikProps<any>;
}

const proportionalArray = [
  { keyDetail: "currency", value: "", required: "Yes", type: "Text" },
  { keyDetail: "Geographical Scope", value: "", required: "Yes", type: "Text" },
  {
    keyDetail: "Class of Risk/ Class of Business",
    value: "",
    required: "Yes",
    type: "Text",
  },
  { keyDetail: "Treaty Detail", value: "", required: "Yes", type: "Text" },
  {
    keyDetail: "Facultative Inward Acceptance",
    value: "",
    required: "Yes",
    type: "Text",
  },
  { keyDetail: "Rates", value: "", required: "Yes", type: "Text" },
  { keyDetail: "Profit Comission", value: "", required: "Yes", type: "Text" },
  { keyDetail: "Portfolio", value: "", required: "Yes", type: "Text" },
  { keyDetail: "Notice of claim", value: "", required: "Yes", type: "Text" },
  { keyDetail: "Cash Loss limit", value: "", required: "Yes", type: "Text" },
  { keyDetail: "Account Submission", value: "", required: "Yes", type: "Text" },
  { keyDetail: "Account Settlement", value: "", required: "Yes", type: "Text" },
  {
    keyDetail: "Revision of confirmed balances",
    value: "",
    required: "Yes",
    type: "Text",
  },
  {
    keyDetail: "Choice of Law and Jurisdiction",
    value: "",
    required: "Yes",
    type: "Text",
  },
  { keyDetail: "General Conditions", value: "", required: "Yes", type: "Text" },
  { keyDetail: "Exclusion", value: "", required: "Yes", type: "Text" },
  { keyDetail: "Alteration", value: "", required: "Yes", type: "Text" },
  { keyDetail: "Wording", value: "", required: "Yes", type: "Text" },
  { keyDetail: "Exchange Rate", value: "", required: "Yes", type: "Number" },
  {
    keyDetail: "Additional Information",
    value: "",
    required: "Yes",
    type: "Text",
    hasMessage: true,
  },
];

const nonproportionalArray = [
  { keyDetail: "Class of Business", value: "", required: "Yes", type: "Text" },
  { keyDetail: "Territorial Scope", value: "", required: "Yes", type: "Text" },
  { keyDetail: "Limit", value: "", required: "Yes", type: "Number" },
  { keyDetail: "Reinstatement", value: "", required: "Yes", type: "Text" },
  {
    keyDetail: "Premium/Minimum & Deposit Premium",
    value: "",
    required: "Yes",
    type: "Text",
  },
  { keyDetail: "Premium Payable", value: "", required: "Yes", type: "Text" },
  {
    keyDetail: "Choice of Law and Jurisdiction",
    value: "",
    required: "Yes",
    type: "Text",
  },
  { keyDetail: "Conditions", value: "", required: "Yes", type: "Text" },
  { keyDetail: "Exclusions", value: "", required: "Yes", type: "Text" },
  { keyDetail: "Alterations", value: "", required: "Yes", type: "Text" },
  { keyDetail: "Wording", value: "", required: "Yes", type: "Text" },
  { keyDetail: "Exchange Rate", value: "", required: "Yes", type: "Number" },
  {
    keyDetail: "Additional Information",
    value: "",
    required: "Yes",
    type: "Text",
    hasMessage: true,
  },
];

const ProgramForm: FC<ProgramFormProps> = ({ form }) => {
  const removeItem = (field: string, id: number) => {
    form.setFieldValue(field, [
      ..._.get(form.values, field)?.filter(
        (val: string, _id: number) => _id !== id
      ),
    ]);
  };

  useEffect(() => {
    if (form.values.treaty_type) {
      form.setFieldValue(
        "requests",
        form.values?.treaty_type === "PROPORTIONAL"
          ? proportionalArray
          : nonproportionalArray
      );
    } else {
      form.setFieldValue("requests", []);
    }
  }, [form.values.treaty_type]);

  return (
    <div className="space-y-6 divide-y divide-gray-200">
      {/* {JSON.stringify(form.values, null, 2)} */}
      <div>
        <span className="text-xs font-light">Program Information</span>
        <div className="grid grid-cols-2 gap-2">
          <div>
            <InsurerPicker
              id="insurer_id"
              label="Insurer"
              placeholder="e.g. Vanguard Assurance"
              rawId={true}
              required={true}
              {...form}
            />
          </div>
          <div>
            <TreatyTypePicker
              id="treaty_type"
              label="Treaty Type"
              placeholder="e.g. Proportional"
              required={true}
              {...form}
            />
          </div>

          <div className="space-y-1 col-span-3">
            <TextInput
              id="treaty_name"
              label="Treaty Name"
              placeholder="e.g. Motor"
              required={true}
              {...form}
            />
          </div>

          <div className="col-span-2">
            <div className="">
              <span className="text-xs font-light">Business Details</span>
              <div className="grid grid-cols-3 gap-6 mt-2">
                <div className="col-span-3">
                  <span className="block text-sm font-medium text-gray-700">
                    Details
                  </span>
                  <div className="mt-1 w-full ">
                    <table className="min-w-full divide-y divide-gray-300 border border-gray-300 rounded-md">
                      <thead className="bg-gray-50">
                        <tr className="divide-x divide-gray-200">
                          <th
                            scope="col"
                            className="whitespace-nowrap px-2 py-2 text-left text-sm font-semibold text-gray-900"
                          >
                            Detail
                          </th>
                          <th
                            scope="col"
                            className="whitespace-nowrap px-2 py-2 text-left text-sm font-semibold text-gray-900"
                          >
                            Type
                          </th>
                          <th
                            scope="col"
                            className="whitespace-nowrap px-2 py-2 text-left text-sm font-semibold text-gray-900"
                          >
                            Required
                          </th>
                          <th
                            scope="col"
                            className="whitespace-nowrap px-2 py-2 text-sm font-semibold text-gray-900 w-[38px]"
                          ></th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {_.times(form.values.requests?.length + 1, (idx) => {
                          const id = `requests.${idx}`;
                          const isLast =
                            (form.values.requests?.length || 0) === idx;

                          return (
                            <>
                              {form?.values?.requests?.[idx]?.[
                                "hasMessage"
                              ] && (
                                  <tr>
                                    <td
                                      colSpan={4}
                                      className="bg-red-100 p-3 text-xs text-red-500 italic"
                                    >
                                      All other information (Special Exclusions,
                                      Articles, Other data) should be placed here.
                                      This will be appended as extra pages after
                                      the signatory page of the treaty document"
                                      in an alert above it
                                    </td>
                                  </tr>
                                )}
                              <tr
                                key={idx}
                                className="divide-x divide-gray-200"
                              >
                                <td className="p-0">
                                  <input
                                    key={idx}
                                    type={"text"}
                                    id={`${id}.keyDetail`}
                                    name={`${id}.keyDetail`}
                                    onChange={form.handleChange}
                                    onBlur={form.handleBlur}
                                    value={_.get(
                                      form.values,
                                      `${id}.keyDetail`,
                                      ""
                                    )}
                                    placeholder={"eg. Number Plate"}
                                    className={classNames(
                                      _.get(form.errors, `${id}.keyDetail`) &&
                                        _.get(form.touched, `${id}.keyDetail`)
                                        ? "focus:ring-primary-500 focus:border-gray-200 ring-red-500 border-red-600 border"
                                        : "focus:ring-primary-500 focus:border-gray-200 border-gray-200 border-0 focus:border",
                                      "outline-none relative pl-[54px] block w-full sm:text-sm placeholder:font-light placeholder:text-xs h-[38px]"
                                    )}
                                  />
                                </td>
                                <td className="p-0 relative">
                                  <select
                                    key={idx}
                                    id={`${id}.type`}
                                    name={`${id}.type`}
                                    value={_.get(form.values, `${id}.type`, "")}
                                    onChange={form.handleChange}
                                    onBlur={form.handleBlur}
                                    placeholder={"eg. 2000"}
                                    // max={bill?.totalAmountDue}
                                    className={classNames(
                                      _.get(form.errors, `${id}.type`) &&
                                        _.get(form.touched, `${id}.type`)
                                        ? "focus:ring-primary-500 focus:border-gray-200 ring-red-500 border-red-600 border"
                                        : "focus:ring-primary-500 focus:border-gray-200 border-gray-200 border-0 focus:border",
                                      "outline-none relative pl-[54px] block w-full sm:text-sm placeholder:font-light placeholder:text-xs h-[38px]"
                                    )}
                                  >
                                    <option value=""> --- SELECT --- </option>
                                    <option value="Number">Number</option>
                                    <option value="Text">Text</option>
                                  </select>
                                </td>
                                <td className="p-0 relative">
                                  <select
                                    key={idx}
                                    id={`${id}.required`}
                                    name={`${id}.required`}
                                    value={_.get(
                                      form.values,
                                      `${id}.required`,
                                      ""
                                    )}
                                    onChange={form.handleChange}
                                    onBlur={form.handleBlur}
                                    placeholder={"eg. 2000"}
                                    // max={bill?.totalAmountDue}
                                    className={classNames(
                                      _.get(form.errors, `${id}.required`) &&
                                        _.get(form.touched, `${id}.required`)
                                        ? "focus:ring-primary-500 focus:border-gray-200 ring-red-500 border-red-600 border"
                                        : "focus:ring-primary-500 focus:border-gray-200 border-gray-200 border-0 focus:border",
                                      "outline-none relative pl-[54px] block w-full sm:text-sm placeholder:font-light placeholder:text-xs h-[38px]"
                                    )}
                                  >
                                    <option value=""> --- SELECT --- </option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                  </select>
                                </td>
                                <td className="p-0 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400 text-right w-[38px] h-[38px] items-center justify-center flex">
                                  {!isLast && (
                                    <button
                                      type="button"
                                      onClick={wrapClick(() =>
                                        removeItem("requests", idx)
                                      )}
                                      className="inline-flex items-center rounded-full border border-transparent p-1 text-red-500 hover:bg-red-300 hover:text-red-900 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                                    >
                                      <XMarkIcon
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                      />
                                    </button>
                                  )}
                                </td>
                              </tr>
                            </>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProgramForm;
