import { LanguagePicker, Modal, PDFRenderer } from "components";
import { useMatch, useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import config from "config";
import { useOfferClaimShare } from "hooks/data/use-offer-claims";
import { useFormik } from "formik";

export default function ViewDebitNoteContainer({
    open,
    setOpen,
}: {
    open: boolean;
    setOpen: (val: boolean) => void;
}) {
    const form = useFormik({
        initialValues: {
            lang: "en"
        },
        onSubmit(values, formikHelpers) {

        },
    })
    const searchParams = useSearch<LocationGenerics>();
    const { params } = useMatch<LocationGenerics>();
    const { loading, share } = useOfferClaimShare({
        offerClaimParticipantId: searchParams?.id || "",
    })

    return (
        <Modal
            open={open}
            setOpen={setOpen}
            loading={loading}
            size="6xl"
            title="Debit Note"
            description="Details of debit note are shown below"
        >
            <div className="mb-3">
                <LanguagePicker id={"lang"} labelHidden {...form} />
            </div>
            <PDFRenderer
                url={`${config.asset.uri}claimdebitnote/${btoa(
                    JSON.stringify({
                        offer_id: params?.offer || "",
                        reinsurer_id: share?.reinsurer_id || "",
                        offer_claim_participant_id: share?.offer_claim_participant_id || "",
                        lang: form.values.lang,
                    })
                )}`}
            />
        </Modal>
    );
}
