import { useQuery } from "@apollo/client";
import {
  GetOfferClaims,
  GetOfferClaimsVariables,
} from "_graphql/queries/__generated__/GetOfferClaims";
import { iVariables } from "./use-offers";
import {
  getOfferClaimShare,
  getOfferClaimShares,
  getOfferClaims,
} from "_graphql/queries/offers";
import {
  GetClaimShares,
  GetClaimSharesVariables,
} from "_graphql/queries/__generated__/GetClaimShares";
import {
  GetClaimShare,
  GetClaimShareVariables,
} from "_graphql/queries/__generated__/GetClaimShare";

export const useOfferClaims = (variables?: GetOfferClaimsVariables) => {
  const { data, ...rest } = useQuery<GetOfferClaims, GetOfferClaimsVariables>(
    getOfferClaims,
    {
      variables,
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "network-only",
    }
  );

  const claims = data?.getOfferClaims || [];

  return { claims, count: claims.length, ...rest };
};

export const useOfferClaimShares = (variables?: GetClaimSharesVariables) => {
  const { data, ...rest } = useQuery<GetClaimShares, GetClaimSharesVariables>(
    getOfferClaimShares,
    {
      variables,
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "network-only",
    }
  );

  const shares = data?.getClaimShares || [];

  return { shares, count: shares.length, ...rest };
};
export const useOfferClaimShare = (variables?: GetClaimShareVariables) => {
  const { data, ...rest } = useQuery<GetClaimShare, GetClaimShareVariables>(
    getOfferClaimShare,
    {
      variables,
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "network-only",
    }
  );

  const share = data?.getClaimShare;

  return { share, ...rest };
};
