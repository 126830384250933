import clsx from "clsx";
import React, { FC, PropsWithChildren } from "react";
import { twMerge } from "tailwind-merge";
import { cva } from "class-variance-authority";

const variants = cva(
  "inline-flex items-center rounded-md border mx-1 border-transparent  disabled:cursor-not-allowed px-3 py-2 text-sm leading-4 text-white shadow-sm  focus:outline-none",
  {
    variants: {
      variant: {
        primary: "bg-primary-700 focus:ring-primary-500 hover:bg-primary-700",
        secondary: "bg-teal-600 focus:ring-teal-500 hover:bg-teal-700",
        warning: "bg-yellow-600 focus:ring-yellow-500 hover:bg-yellow-700",
        tertiary: "bg-gray-600 focus:ring-gray-500 hover:bg-gray-700",
        danger: "bg-red-600 focus:ring-red-500 hover:bg-red-700",
        success: "bg-green-600 focus:ring-green-500 hover:bg-green-700",
      },
      size: {
        sm: "text-sm leading-4 font-medium",
        md: "text-base leading-6 font-medium",
        lg: "text-lg leading-6 font-medium",
      },
    },
    compoundVariants: [
      {
        variant: "primary",
        size: "md",
      },
    ],
  }
);

type Props = {
  onClick: React.MouseEventHandler<HTMLButtonElement> | undefined;
  size?: "sm" | "md" | "lg";
  variant?:
    | "primary"
    | "secondary"
    | "tertiary"
    | "danger"
    | "warning"
    | "success";
  disabled?: boolean;
  loading?: boolean;
  type?: "button" | "submit" | "reset" | undefined;
};

const OfficeButton: FC<PropsWithChildren<Props>> = ({
  children,
  onClick,
  disabled,
  loading,
  size = "md",
  variant = "primary",
  type = "button",
}) => {
  return (
    <button
      type={type}
      disabled={disabled}
      onClick={onClick}
      className={clsx(twMerge(variants({ variant, size })))}
    >
      {loading ? "Please wait ..." : children}
    </button>
  );
};

export default OfficeButton;
