import { gql } from "@apollo/client";

export const createEmployee = gql`
  mutation CreateEmployeeAccount($employee: Employee_data!) {
    createEmployeeAccount(employee: $employee)
  }
`;

export const updateEmployee = gql`
  mutation UpdateEmployeeAccountDetails(
    $employeeId: ID!
    $employee: Employee_data!
  ) {
    updateEmployeeAccountDetails(employee_id: $employeeId, employee: $employee)
  }
`;

export const resetEmployee = gql`
  mutation AdminResetEmployeePassword($employeeId: ID!) {
    adminResetEmployeePassword(employee_id: $employeeId)
  }
`;

export const removeEmployee = gql`
  mutation AdminDeleteEmployeeAccount($employeeId: ID!) {
    adminDeleteEmployeeAccount(employee_id: $employeeId)
  }
`;

export const assignCedantToStaff = gql`
mutation AssignCedantToStaff($cedants: [ID], $staffId: ID) {
  assignCedantToStaff(cedants: $cedants, staff_id: $staffId) {
    status
    message
    data
  }
}

`;
