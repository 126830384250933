import { wrapClick } from "utils";
import { useMutation } from "@apollo/client";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { Modal } from "components";
import * as Yup from "yup";
import { PercentageForm } from "components/forms";
import {
    addReBrokerParticipationPercentage,
} from "_graphql/mutation/offers";
import { useMatch, useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { useEffect } from "react";
import { useTreatyBroker } from "hooks/data/use-treaties";
import { AddReBrokerParticipationPercentage, AddReBrokerParticipationPercentageVariables } from "_graphql/mutation/__generated__/AddReBrokerParticipationPercentage";
import { TreatyType } from "_graphql/__generated__/globalTypes";

export const valiadationSchema = Yup.object().shape({
    admin_percentage: Yup.number().min(0, "Number must be greater than 0")
        .typeError("Must be a number")
        .required("Percentage is required"),
    share_percentage: Yup.number().min(0, "Percentage must be greater than 0")
        .typeError("Must be a number")
        .required("Percentage is required"),
});

export default function AddBrokerParticipantsPercentageContainer({
    open,
    setOpen,
    refetch,
}: {
    open: boolean;
    setOpen: (val: boolean) => void;
    refetch?: () => void;
}) {
    const { params } = useMatch<LocationGenerics>();
    const searchParams = useSearch<LocationGenerics>();
    const { participant, loading: fetching } = useTreatyBroker({
        reBrokerTreatiesParticipationId: searchParams.id || "",
    });

    const [create, { loading }] = useMutation<
        AddReBrokerParticipationPercentage,
        AddReBrokerParticipationPercentageVariables
    >(addReBrokerParticipationPercentage, {
        refetchQueries: ["Treaty", "GetTreatyBrokersParticipants", "GetTreatyBrokerParticipation"],
    });

    const form = useFormik<any>({
        initialValues: {
            share_percentage: 0,
            admin_percentage: 0,
        },
        validationSchema: valiadationSchema,
        onSubmit: async (variables) => {
            await create({
                variables: {
                    brokerParticipation: {
                        admin_percentage: variables.admin_percentage,
                        share_percentage: variables.share_percentage,
                        re_broker_treaties_participation_id: searchParams.id || "",
                        treaty_id: params.treaty || "",
                        treaty_type: searchParams.treaty_type as TreatyType,
                    }
                },
            }).then(({ data }) => {
                if (data?.addReBrokerParticipationPercentage) {
                    toast.success("Percentage added successfully");
                    refetch?.();
                    form.resetForm();
                } else {
                    toast.error("Failed to add percentage");
                }
            });
        },
        onReset: () => {
            setOpen(false);
        },
    });

    useEffect(() => {
        if (participant) {
            form.setFieldValue(
                "share_percentage",
                participant?.share_percentage || 0
            );
            form.setFieldValue(
                "admin_percentage",
                participant?.admin_percentage || 0
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [participant]);

    return (
        <Modal
            open={open}
            setOpen={setOpen}
            title={"Add Percentage to " + participant?.re_broker?.re_broker_name}
            loading={fetching}
            description="Add percentage for this ReInsurer"
            renderActions={() => (
                <>
                    <button
                        type="button"
                        disabled={loading}
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={wrapClick(form.handleSubmit)}
                    >
                        {loading ? "Saving changes..." : "Save changes"}
                    </button>
                </>
            )}
        >
            <PercentageForm form={form} type="broker" />
        </Modal>
    );
}
