import { wrapClick } from "utils";
import { useMutation } from "@apollo/client";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { Modal } from "components";
import * as Yup from "yup";
import { BrokerRepForm } from "components/forms";
import { createBrokerRep } from "_graphql/mutation/broker";
import { CreateReBrokerAssociates, CreateReBrokerAssociatesVariables } from "_graphql/mutation/__generated__/CreateReBrokerAssociates";
import { useAppLocation, useAppSearch } from "_graphql/cache/auth";


export const insurerRepValidationSchema = Yup.object().shape({
    reBrokerAssociate: Yup.object().shape({
        re_broker_assoc_first_name: Yup.string().required("First name is required"),
        re_broker_assoc_last_name: Yup.string().required("Last name is required"),
        re_broker_assoc_primary_phone: Yup.string().required("Phone number is required"),
        re_broker_assoc_secondary_phone: Yup.string().notRequired(),
        re_broker_assoc_email: Yup.string().required("Email is required"),
        re_broker_assoc_position: Yup.string().required("Position is required"),
    })
});

export default function CreateInsurerManagerContainer({
    open,
    setOpen,
    refetch,
}: {
    open: boolean;
    setOpen: (val: boolean) => void;
    refetch?: () => void;
}) {
    const searchParams = useAppSearch();
    const { params } = useAppLocation()
    const [create, { loading }] = useMutation<CreateReBrokerAssociates, CreateReBrokerAssociatesVariables>(createBrokerRep, {
        refetchQueries: ["getReBrokerAssociates"]
    });

    const form = useFormik<CreateReBrokerAssociatesVariables>({
        initialValues: {
            reBrokerAssociate: {
                re_brokersre_broker_id: searchParams?.id || params?.broker || "",
                re_broker_assoc_first_name: "",
                re_broker_assoc_last_name: "",
                re_broker_assoc_primary_phone: "",
                re_broker_assoc_secondary_phone: "",
                re_broker_assoc_email: "",
                re_broker_assoc_position: "",
            }
        },
        validationSchema: insurerRepValidationSchema,
        onSubmit: async (variables) => {
            await create({
                variables: variables,
            }).then(({ data }) => {
                if (data?.createReBrokerAssociates) {
                    toast.success("Reinsurance Broker Representative created successfully");
                    refetch?.();
                    form.resetForm();
                } else {
                    toast.error("Failed to create Reinsurance Broker Representative");
                }
            });
        },
        onReset: () => {
            setOpen(false);
        },
    });

    return (
        <Modal
            open={open}
            setOpen={setOpen}
            title="Add New Reinsurance Broker Representative"
            description="Provide the details to add a new reinsurance broker representative"
            renderActions={() => (
                <>
                    <button
                        type="button"
                        disabled={loading}
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={wrapClick(form.handleSubmit)}
                    >
                        {loading ? "Adding Reinsurance Broker Representative..." : "Add Reinsurance Broker Representative"}
                    </button>
                </>
            )}
        >
            <BrokerRepForm form={form} />
        </Modal>
    );
}
