import { wrapClick } from "utils";
import { useMutation } from "@apollo/client";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { Modal } from "components";
import * as Yup from "yup";
import { ReInsurerForm } from "components/forms";
import { CreateReinsurer, CreateReinsurerVariables } from "_graphql/mutation/__generated__/CreateReinsurer";
import { createReInsurer } from "_graphql/mutation/re-insurer";


export const revaliadationSchema = Yup.object().shape({
    re_company_name: Yup.string().required("Company name is required"),
    re_company_website: Yup.string().required("Company website is required"),
    re_company_email: Yup.string().email("Invalid Email address").required("Email is required"),
    country: Yup.string().required("Country is required"),
    region: Yup.string().required("Region/State is required"),
    street: Yup.string().required("Street Address is required"),
    suburb: Yup.string().required("Suburb/City is required"),
});

export default function CreateInsurerContainer({
    open,
    setOpen,
    refetch,
}: {
    open: boolean;
    setOpen: (val: boolean) => void;
    refetch?: () => void;
}) {
    const [create, { loading }] = useMutation<CreateReinsurer, CreateReinsurerVariables>(createReInsurer, {
        refetchQueries: ["Reinsurers"]
    });

    const form = useFormik<CreateReinsurerVariables["reinsurer"]>({
        initialValues: {
            re_company_email: "",
            re_company_name: "",
            re_company_website: "",
            country: "",
            region: "",
            street: "",
            suburb: ""
        },
        validationSchema: revaliadationSchema,
        onSubmit: async (variables) => {
            await create({
                variables: {
                    reinsurer: variables,
                },
            }).then(({ data }) => {
                if (data?.createReinsurer.reinsurer_id) {
                    toast.success("Re-insurer Created Successfully");
                    refetch?.();
                    form.resetForm();
                } else {
                    toast.error("Failed to create Re-insurer");
                }
            });
        },
        onReset: () => {
            setOpen(false);
        },
    });

    return (
        <Modal
            open={open}
            setOpen={setOpen}
            title="Add New Re-insurer"
            description="Provide the details to add a new re-insurer"
            renderActions={() => (
                <>
                    <button
                        type="button"
                        disabled={loading}
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={wrapClick(form.handleSubmit)}
                    >
                        {loading ? "Adding Re-insurer..." : "Add Re-insurer"}
                    </button>
                </>
            )}
        >
            <ReInsurerForm form={form} />
        </Modal>
    );
}
