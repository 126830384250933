import { CreateReBrokerAssociatesVariables } from "_graphql/mutation/__generated__/CreateReBrokerAssociates";
import { RegularSelectInput, TextInput } from "components/core";
import { FormikProps } from "formik";
import { FC } from "react";

export interface BrokerRepFormProps {
    form: FormikProps<CreateReBrokerAssociatesVariables>;
}

const BrokerRepForm: FC<BrokerRepFormProps> = ({ form }) => {
    return (
        <div className="space-y-6 divide-y divide-gray-200">
            <div>
                <span className="text-xs font-light">Representative Information</span>
                <div className="grid grid-cols-2 gap-2">
                    <div>
                        <TextInput
                            id="reBrokerAssociate.re_broker_assoc_first_name"
                            label="First Name"
                            type="text"
                            placeholder="e.g. Emmanuel"
                            required={true}
                            {...form}
                        />
                    </div>

                    <div className="space-y-1 ">
                        <TextInput
                            id="reBrokerAssociate.re_broker_assoc_last_name"
                            label="Last Name"
                            type="text"
                            placeholder="e.g. Baidoo"
                            required={true}
                            {...form}
                        />
                    </div>
                    <div>
                        <TextInput
                            id="reBrokerAssociate.re_broker_assoc_email"
                            label="Email"
                            type="text"
                            placeholder="e.g. ebaidoo79@gmail.com"
                            required={true}
                            {...form}
                        />
                    </div>

                    <div className="space-y-1 ">
                        <TextInput
                            id="reBrokerAssociate.re_broker_assoc_primary_phone"
                            label="Primary Phone Number"
                            type="text"
                            placeholder="e.g. 0506339153"
                            required={true}
                            {...form}
                        />
                    </div>
                    <div className="space-y-1 ">
                        <TextInput
                            id="reBrokerAssociate.re_broker_assoc_secondary_phone"
                            label="Secondary Phone Number"
                            type="text"
                            placeholder="e.g. 0506339153"
                            {...form}
                        />
                    </div>
                    <div className="space-y-1  ">
                        <RegularSelectInput
                            id="reBrokerAssociate.re_broker_assoc_position"
                            label="Position"
                            placeholder=""
                            options={[
                                { label: "Manager", value: "Manager" },
                                { label: "Underwriter", value: "Underwriter" },
                            ]}
                            required={true}
                            {...form}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BrokerRepForm;
