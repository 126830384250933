import { useMutation } from '@apollo/client';
import { CreateTreatyProgramAssociateDeductions, CreateTreatyProgramAssociateDeductionsVariables } from '_graphql/mutation/__generated__/CreateTreatyProgramAssociateDeductions';
import { createAssociateDeduction } from '_graphql/mutation/treaty-programs';
import { Modal, OfficeButton } from 'components';
import { DeductionForm } from 'components/forms';
import { useFormik } from 'formik';
import { useTreatyProgram } from 'hooks/data/use-treaty-programs';
import { FC } from 'react'
import toast from 'react-hot-toast';
import { useMatch } from 'react-location';
import { LocationGenerics } from 'router/location';
import { wrapClick } from 'utils';
import * as Yup from 'yup';

type Props = {
    open: boolean;
    setOpen: (val: boolean) => void;
    refetch?: () => void;
}

const validationSchema = Yup.object().shape({
    deductions: Yup.object().shape({
        treaty_period_from: Yup.date().required("Period From is required"),
        treaty_period_to: Yup.date().required("Period To is required"),
        nic_levy: Yup.number().min(0,"Number must be greater than 0").typeError("Must be a number").required("NIC Levy is required"),
        withholding_tax: Yup.number().min(0,"Number must be greater than 0").typeError("Must be a number").required("Withholding Tax is required"),
        brokerage: Yup.number().min(0,"Number must be greater than 0").typeError("Must be a number").required("Brokerage is required"),
        commission: Yup.number().min(0,"Number must be greater than 0").typeError("Must be a number").required("Commission is required"),
        treaty_programstreaty_program_id: Yup.string().required("Treaty Program is required"),
    })
})

const CreateDeductionContainer: FC<Props> = ({ open, setOpen }) => {
    const { params } = useMatch<LocationGenerics>()
    const [initiate, { loading }] = useMutation<CreateTreatyProgramAssociateDeductions, CreateTreatyProgramAssociateDeductionsVariables>(createAssociateDeduction, {
        refetchQueries: ["GetTreatyProgramDeductions"]
    })
    const form = useFormik({
        initialValues: {
            deductions: {
                treaty_period_from: "",
                treaty_period_to: "",
                nic_levy: 0,
                withholding_tax: 0,
                brokerage: 0,
                commission: 0,
                treaty_programstreaty_program_id: params?.treaty_program || ""
            }
        },
        validationSchema,
        onSubmit: (values) => {
            initiate({
                variables: {
                    deductions: values.deductions
                }
            }).then(({ data }) => {
                if (data?.createTreatyProgramAssociateDeductions) {
                    toast.success("Deduction added successfully")
                    setOpen(false)
                    form.resetForm();
                }
            }).catch(err => {
                toast.error(err.message)
            })
        }
    });

    const { treatyProgram, loading: fetching } = useTreatyProgram({
        programId: params?.treaty_program
    });

    return (
        <Modal
            open={open}
            setOpen={setOpen}
            loading={fetching}
            size="4xl"
            title="Create New deduction"
            description="Provide the details to add a new deduction"
            renderActions={() => (
                <div className='ml-3'>
                    <OfficeButton onClick={wrapClick(form.handleSubmit)}>
                        {loading ? "Adding Deduction..." : "Add Deduction"}
                    </OfficeButton>
                </div>
            )}
        >
            <DeductionForm program={treatyProgram} form={form} />
        </Modal>
    )
}

export default CreateDeductionContainer