import { wrapClick } from "utils";
import { useMutation } from "@apollo/client";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { Modal } from "components";
import * as Yup from "yup";
import { LimitLayerForm } from "components/forms";
import { useMatch } from "react-location";
import { LocationGenerics } from "router/location";
import { updateLimitLayer } from "_graphql/mutation/treaty";
import _ from "lodash";
import { useTreaty } from "hooks/data/use-treaties";
import { useEffect } from "react";
import { useAppSearch } from "_graphql/cache/auth";
import {
  UpdateLimitLayer,
  UpdateLimitLayerVariables,
} from "_graphql/mutation/__generated__/UpdateLimitLayer";

const validationSchema = Yup.object().shape({
  limit: Yup.object().shape({
    limit: Yup.number()
      .min(0, "Number cannot be less than 0")
      .required("Limit is required"),
    deductible: Yup.number()
      .min(0, "Number cannot be less than 0")
      .required("Deductible is required"),
    m_and_d_premium: Yup.string().required("M&D Premium is required"),
    installment_type: Yup.number()
      .min(0, "Number cannot be less than 0")
      .required("Installment type is required"),
  }),
});

export default function UpdateLayerLimitContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const searchParams = useAppSearch();
  const { params } = useMatch<LocationGenerics>();
  const { loading: fetchingTreaty, treaty } = useTreaty({
    treatyId: params?.treaty,
  });
  const [create, { loading }] = useMutation<
    UpdateLimitLayer,
    UpdateLimitLayerVariables
  >(updateLimitLayer, {
    refetchQueries: ["Treaty"],
  });

  const form = useFormik<any>({
    initialValues: {
      treatyId: params?.treaty,
      limit: {},
    },
    validationSchema: validationSchema,
    onSubmit: async (variables) => {
      let layers = JSON.parse(treaty?.layer_limit || "[]");
      _.set(layers, Number(searchParams?.layer) - 1, variables.limit);

      await create({
        variables: {
          limit: {
            limit: JSON.stringify(layers),
            treaty_id: variables.treatyId,
          },
        },
      }).then(({ data }) => {
        if (data?.updateLimitLayer) {
          toast.success("Layer Limit updated successfully");
          refetch?.();
          setOpen?.(false);
          form.resetForm();
        } else {
          toast.error(
            "An error occurred while updating the layer limit. Please try again"
          );
        }
      });
    },
    onReset: () => {
      setOpen(false);
    },
  });

  useEffect(() => {
    if (treaty) {
      form.setValues({
        ...form.values,
        limit: JSON.parse(treaty?.layer_limit || "[]")?.[
          Number(searchParams?.layer) - 1
        ],
      });
    }
  }, [treaty]);

  return (
    <Modal
      open={open}
      loading={fetchingTreaty}
      setOpen={setOpen}
      title={"Update Layer "}
      description="Update Layer Limit"
      renderActions={() => (
        <>
          <button
            type="button"
            disabled={loading}
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(form.handleSubmit)}
          >
            {loading ? "Updating..." : "Update"}
          </button>
        </>
      )}
    >
      {/* {JSON.stringify(form.values, null, 2)} */}
      <LimitLayerForm form={form} />
    </Modal>
  );
}
