import { Offer_data } from "_graphql/__generated__/globalTypes";
import { Offer_findSingleOffer } from "_graphql/queries/__generated__/Offer";
import { TreatyProgram_treatyProgram } from "_graphql/queries/__generated__/TreatyProgram";
import {
    TextInput,
} from "components/core";
import { FormikProps } from "formik";
import _ from "lodash";
import { FC } from "react";

export interface FacultativeDeductionFormProps {
    form: FormikProps<any>;
    program?: Offer_findSingleOffer | null;
}

const FacultativeDeductionForm: FC<FacultativeDeductionFormProps> = ({ form, program }) => {
    return (
        <div className="space-y-6 divide-y divide-gray-200">
            <div className=" border border-gray-300 border-dashed bg-gray-100 divide-y divide-dashed divide-gray-300 rounded-md">
                <div className="grid grid-cols-1 gap-4 p-4">
                    <div className="">
                        <span className="block text-sm font-light text-gray-700">
                            Offer/Policy number
                        </span>
                        <div className="mt-1 block w-full sm:text-sm font-medium">
                            {program?.offer_detail?.policy_number || "N/A"}
                        </div>
                    </div>
                    <div className="col-span-2">
                        <span className="block text-sm font-light text-gray-700">
                            Insurer
                        </span>
                        <div className="mt-1 block w-full sm:text-sm font-medium">
                            {program?.insurer?.insurer_company_name || "N/A"}
                        </div>
                    </div>
                    <div className="">
                        <span className="block text-sm font-light text-gray-700">
                            Offer Date
                        </span>
                        <div className="mt-1 block w-full sm:text-sm font-medium">
                            {`${program?.offer_detail?.period_of_insurance_from} to ${program?.offer_detail?.period_of_insurance_to}` || "N/A"}
                        </div>
                    </div>

                </div>
            </div>

            <div className="pt-3">
                <span className="text-xs font-light">Deductions</span>
                <div className="grid grid-cols-2 gap-2">
                    <div>
                        <TextInput
                            type="number"
                            id="withholding_tax"
                            label="With-holding Tax (%)"
                            placeholder="With-holding Tax (%)"
                            required={true}
                            {...form}
                        />
                    </div>
                    <div>
                        <TextInput
                            type="number"
                            id="nic_levy"
                            label="NIC Levy (%)"
                            placeholder="NIC Levy (%)"
                            required={true}
                            {...form}
                        />
                    </div>
                    <div>
                        <TextInput
                            type="number"
                            id="agreed_brokerage_percentage"
                            label="Agreed Brokerage"
                            placeholder="Agreed Brokerage"
                            required={true}
                            {...form}
                        />
                    </div>
                    <div>
                        <TextInput
                            type="number"
                            id="agreed_commission"
                            label="Agreed Commission (%)"
                            placeholder="Agreed Commission (%)"
                            required={true}
                            {...form}
                        />
                    </div>
                </div>
            </div>

        </div>
    );
};

export default FacultativeDeductionForm;
