import {
  ActionButton,
  OfficeButton,
  TableComponent,
  TableLoader,
} from "components";
import { useNonProportionalPayments } from "hooks/data/use-treaties";
import { useMatch } from "react-location";
import { LocationGenerics } from "router/location";
import CreatePaymentCOntainer from "./create";
import { useAppNavigate, useAppSearch } from "_graphql/cache/auth";
import UpdateNonProportionalPaymentCOntainer from "./update";
import { GetNonProportionalTreatyPayments_getNonProportionalTreatyPayments } from "_graphql/queries/__generated__/GetNonProportionalTreatyPayments";
import RemoveContainer from "./remove";
import ViewPaymentReceiptContainer from "./view-receipt";

type Props = {};

const NonProportionalPayments = (props: Props) => {
  const { params } = useMatch<LocationGenerics>();
  const searchParams = useAppSearch();
  const navigate = useAppNavigate();
  const { payments, loading, refetch } = useNonProportionalPayments({
    filter: {
      treaty_id: params?.treaty || "",
    },
  });
  return (
    <>
      <div className="flex flex-1 overflow-y-auto">
        <div className="flex-1 mx-auto min-w-0  py-4 sm:py-6 overflow-hidden overflow-y-auto light flex">
          <TableComponent
            title={""}
            loading={loading}
            columns={[
              {
                accessor: "id",
                name: "Payment Type",
                render: (value: any) => {
                  const details = JSON.parse(value?.treaty_payment_details);
                  return (
                    <div className="flex flex-row leading-0">
                      <span>{details?.payment_type}</span>
                    </div>
                  );
                },
              },
              {
                accessor: "id",
                name: "Bank Name",
                render: (value: any) => {
                  const details = JSON.parse(value?.treaty_payment_details);
                  return (
                    <div className="flex flex-row leading-0">
                      <span>{details?.payment_from?.bank_name}</span>
                    </div>
                  );
                },
              },
              {
                accessor: "id",
                name: "Beneficiary Bank Name",
                render: (value: any) => {
                  const details = JSON.parse(value?.treaty_payment_details);
                  return (
                    <div className="flex flex-row leading-0">
                      <span>{details?.payment_to || "N/A"}</span>
                    </div>
                  );
                },
              },
              {
                accessor: "treaty_payment_amount",
                name: "Payment Amt",
              },
              {
                accessor: "created_at",
                name: "Payment Date	",
              },
              {
                accessor: "updated_at",
                name: "Last Payment Upate",
              },
              {
                accessor: "id",
                name: "Actions",
                render: (
                  value: GetNonProportionalTreatyPayments_getNonProportionalTreatyPayments
                ) => (
                  <div>
                    <ActionButton
                      tooltip="Update Payment Record"
                      action={"update"}
                      onClick={() =>
                        navigate({
                          search(prev) {
                            return {
                              ...prev,
                              id: value?.treaty_n_p_payment_id || "",
                              modal: "update",
                            };
                          },
                        })
                      }
                    />
                    <ActionButton
                      tooltip="Delete Payment Record"
                      action={"remove"}
                      onClick={() =>
                        navigate({
                          search(prev) {
                            return {
                              ...prev,
                              id: value?.treaty_n_p_payment_id || "",
                              modal: "remove",
                            };
                          },
                        })
                      }
                    />
                    <ActionButton
                      tooltip="Generate Payment Receipt"
                      action={"clone"}
                      onClick={() =>
                        navigate({
                          search(prev) {
                            return {
                              ...prev,
                              id: value?.treaty_n_p_payment_id || "",
                              modal: "export",
                            };
                          },
                        })
                      }
                    />
                    {/* <ActionButton
                      tooltip="Clear Paymnt Cheque"
                      action={"distribute"}
                      onClick={() => {}}
                    /> */}
                  </div>
                ),
              },
            ]}
            data={payments}
            renderHeaderItems={() => (
              <>
                <OfficeButton
                  onClick={() =>
                    navigate({
                      search(prev) {
                        return {
                          ...prev,
                          modal: "create",
                        };
                      },
                    })
                  }
                >
                  Make payment
                </OfficeButton>
              </>
            )}
            refetch={refetch}
            renderLoader={() => (
              <TableLoader
                showAction={false}
                actionsCount={1}
                items={[1, 1, 1, 1, 1, 1]}
              />
            )}
          />
        </div>
      </div>

      <CreatePaymentCOntainer
        open={searchParams?.modal === "create"}
        setOpen={(val) =>
          navigate({
            search(prev) {
              return {
                ...prev,
                modal: val ? "create" : undefined,
              };
            },
          })
        }
      />
      {searchParams?.id && (
        <>
          <UpdateNonProportionalPaymentCOntainer
            open={searchParams?.modal === "update"}
            setOpen={(val) =>
              navigate({
                search(prev) {
                  return {
                    ...prev,
                    id: val ? searchParams.id : undefined,
                    modal: val ? "update" : undefined,
                  };
                },
              })
            }
          />
          <RemoveContainer
            open={searchParams?.modal === "remove"}
            setOpen={(val) =>
              navigate({
                search(prev) {
                  return {
                    ...prev,
                    modal: val ? "remove" : undefined,
                    payment_id: val ? searchParams?.id : undefined,
                  };
                },
              })
            }
          />
          <ViewPaymentReceiptContainer
            open={searchParams?.modal === "export"}
            setOpen={(val) =>
              navigate({
                search(prev) {
                  return {
                    ...prev,
                    modal: val ? "export" : undefined,
                    payment_id: val ? searchParams?.id : undefined,
                  };
                },
              })
            }
          />
        </>
      )}
    </>
  );
};

export default NonProportionalPayments;
