import { CurrencyPicker, RegularSelectInput, TextInput } from "../..";
import * as yup from "yup";
import { useFormik } from "formik";
import { wrapClick } from "utils";
import { XMarkIcon } from "@heroicons/react/24/outline";
import _ from "lodash";
import numeral from "numeral";
import { useEffect } from "react";

type Props = {
  handleNext: (_values: any) => void;
  handlePrevious: () => void;
  handleCancel: () => void;
  initialValues: any;
};

const validationSchema = yup.object().shape({
  layers: yup.array().of(
    yup.object().shape({
      limit: yup
        .number()
        .min(0, "Number must be greater than 0")
        .required("Limit is required"),
      deductible: yup
        .number()
        .min(0, "Number must be greater than 0")
        .required("Deductible is required"),
      installments: yup
        .number()
        .min(0, "Number must be greater than 0")
        .required("Installments is required"),
      reinstatement: yup
        .number()
        .min(0, "Number must be greater than 0")
        .required("Reinstatement is required"),
      discount_percentage: yup
        .number()
        .min(0, "Number must be greater than 0")
        .required("Discount Percentage is required"),
      adjustable_rate: yup
        .number()
        .min(0, "Number must be greater than 0")
        .required("Adjustable Rate is required"),
    })
  ),
  ergnrpi: yup
    .number()
    .min(0, "Number must be greater than 0")
    .required("Estimated Gross Net Retained Premim Income is required"),
  min_rate: yup
    .number()
    .min(0, "Number must be greater than 0")
    .required("Minimum rate is required"),
  max_rate: yup
    .number()
    .min(0, "Number must be greater than 0")
    .required("Maximum rate is required"),
  numerator: yup
    .number()
    .min(0, "Number must be greater than 0")
    .required("Numerator is required"),
  denominator: yup
    .number()
    .min(0, "Number must be greater than 0")
    .required("Denominator is required"),
  currency: yup.string().required("Currency is required"),
});

const calculateMD = (rate: any, egrnpi: any, percentage: any = 10) => {
  const m = percentage ? 100 - parseFloat(percentage) : 90;
  return rate * egrnpi * (m / 100);
};

const TreatyDeductionForm = ({
  handleCancel,
  handleNext,
  initialValues,
  handlePrevious,
}: Props) => {
  const form = useFormik<{
    layers: any[];
    ergnrpi: string;
    min_rate: string;
    max_rate: string;
    numerator: string;
    denominator: string;
    currency: string;
  }>({
    initialValues,
    onSubmit: (values) => {
      handleNext({
        ...values,
        layers: values.layers.map((layer) => ({
          ...layer,
          limit: numeral(layer.limit).value(),
          deductible: numeral(layer.deductible).value(),
          reinstatement: numeral(layer.reinstatement).value(),
          discount_percentage: numeral(layer.discount_percentage).value(),
          adjustable_rate: numeral(layer.adjustable_rate).value(),
          m_and_d_premium: numeral(
            calculateMD(
              layer.adjustable_rate,
              values.ergnrpi,
              layer.discount_percentage
            )
          ).format("0,0.00"),
        })),
      });
    },
    validationSchema,
    onReset: () => {
      handleCancel();
    },
  });

  const removeItem = (field: string, id: number) => {
    form.setFieldValue(field, [
      ..._.get(form.values, field)?.filter(
        (val: string, _id: number) => _id !== id
      ),
    ]);
  };

  useEffect(() => {
    if (form.values.min_rate && form.values.layers?.length > 0) {
      form.setFieldValue("layers.0.adjustable_rate", form.values.min_rate);
    }
  }, [form.values.min_rate, form.values.layers?.length]);

  return (
    <div className="w-full">
      <form
        onSubmit={form.handleSubmit}
        className="flex-1 flex flex-col overflow-hidden"
      >
        {/* {JSON.stringify(initialValues, null, 2)} */}
        <div className="space-y-6 divide-y divide-gray-200 p-6 flex-1">
          <div>
            <div className="grid grid-cols-2 mb-4 gap-6">
              <div className="">
                <CurrencyPicker
                  id="currency"
                  label="Currency"
                  placeholder="Currency"
                  required={true}
                  {...form}
                />
              </div>
              <div className="">
                <TextInput
                  id="ergnrpi"
                  label="Estimated Gross Net Retained Premim Income"
                  type="number"
                  placeholder="Estimated Gross Net Retained Premim Income"
                  required={true}
                  postText={form.values.currency}
                  {...form}
                />
              </div>
            </div>
            <div>
              <span className="text-xs font-light">Laoding Factor</span>
              <div className="grid grid-cols-2 mb-4 gap-6">
                <div className="">
                  <TextInput
                    id="numerator"
                    label="Numerator"
                    type="number"
                    placeholder="Numerator"
                    required={true}
                    {...form}
                  />
                </div>
                <div className="">
                  <TextInput
                    id="denominator"
                    label="Denominator"
                    type="number"
                    placeholder="Denominator"
                    required={true}
                    {...form}
                  />
                </div>
              </div>
            </div>
            <div>
              <span className="text-xs font-light">Burning Cost</span>
              <div className="grid grid-cols-2 mb-4 gap-6">
                <div className="">
                  <TextInput
                    id="min_rate"
                    label="Minimum rate"
                    type="number"
                    placeholder="Minimum rate"
                    required={true}
                    {...form}
                  />
                </div>
                <div className="">
                  <TextInput
                    id="max_rate"
                    label="Maximum rate"
                    type="number"
                    placeholder="Maximum rate"
                    required={true}
                    {...form}
                  />
                </div>
              </div>
            </div>
            <div className="py-6">
              <div className="mt-1 w-full ">
                <table className="min-w-full divide-y divide-gray-300 border border-gray-300 rounded-md">
                  <thead className="bg-gray-50">
                    <tr className="divide-x divide-gray-200">
                      <th
                        scope="col"
                        className="whitespace-nowrap px-2 py-2 text-left text-sm font-semibold text-gray-900"
                      >
                        Limit
                      </th>
                      <th
                        scope="col"
                        className="whitespace-nowrap px-2 py-2 text-left text-sm font-semibold text-gray-900"
                      >
                        Deductible
                      </th>

                      <th
                        scope="col"
                        className="whitespace-nowrap px-2 py-2 text-left text-sm font-semibold text-gray-900"
                      >
                        Installments
                      </th>
                      <th
                        scope="col"
                        className="whitespace-nowrap px-2 py-2 text-left text-sm font-semibold text-gray-900"
                      >
                        Reinstatement
                      </th>
                      <th
                        scope="col"
                        className="whitespace-nowrap px-2 py-2 text-left text-sm font-semibold text-gray-900"
                      >
                        Discount (%)
                      </th>
                      <th
                        scope="col"
                        className="whitespace-nowrap px-2 py-2 text-left text-sm font-semibold text-gray-900"
                      >
                        Adjustable rate
                      </th>
                      <th
                        scope="col"
                        className="whitespace-nowrap px-2 py-2 text-left text-sm font-semibold text-gray-900"
                      >
                        M&D Premium
                      </th>
                      <th
                        scope="col"
                        className="whitespace-nowrap px-2 py-2 text-sm font-semibold text-gray-900 w-[38px]"
                      ></th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {_.times(form.values.layers?.length + 1, (idx) => {
                      const id = `layers.${idx}`;
                      const isLast = (form.values.layers?.length || 0) === idx;

                      return (
                        <tr key={idx} className="divide-x divide-gray-200">
                          <td className="text-center bg-gray-50 w-[96px]">
                            <TextInput
                              label={""}
                              labelHidden
                              key={idx}
                              id={`${id}.limit`}
                              borderless
                              type="number"
                              {...form}
                              placeholder={"Limit"}
                            />
                          </td>
                          <td className="p-0">
                            <TextInput
                              label={""}
                              labelHidden
                              key={idx}
                              id={`${id}.deductible`}
                              borderless
                              type="number"
                              {...form}
                              placeholder={"Deductible"}
                            />
                          </td>

                          <td className="p-0">
                            <RegularSelectInput
                              label={""}
                              hideLabel
                              key={idx}
                              id={`${id}.installments`}
                              options={[
                                {
                                  label: "1",
                                  value: "1",
                                },
                                {
                                  label: "2",
                                  value: "2",
                                },
                                {
                                  label: "3",
                                  value: "3",
                                },
                                {
                                  label: "4",
                                  value: "4",
                                },
                              ]}
                              {...form}
                              placeholder={"Installments"}
                            />
                          </td>
                          <td className="p-0">
                            <TextInput
                              label={""}
                              labelHidden
                              key={idx}
                              borderless
                              type="number"
                              id={`${id}.reinstatement`}
                              {...form}
                              placeholder={"Reinstatement"}
                            />
                          </td>
                          <td className="p-0">
                            <TextInput
                              label={""}
                              labelHidden
                              key={idx}
                              borderless
                              type="number"
                              id={`${id}.discount_percentage`}
                              {...form}
                              placeholder={"Discount Percentage"}
                            />
                          </td>
                          <td className="p-0">
                            <TextInput
                              label={""}
                              labelHidden
                              key={idx}
                              borderless
                              disabled={idx === 0}
                              type="number"
                              id={`${id}.adjustable_rate`}
                              {...form}
                              placeholder={"Adjustable Rate"}
                            />
                          </td>
                          <td className="p-0 text-center">
                            {numeral(
                              calculateMD(
                                idx === 0
                                  ? form.values.min_rate
                                  : form.values?.layers?.[idx]?.adjustable_rate,
                                form.values.ergnrpi,
                                form.values?.layers?.[idx]?.discount_percentage
                              )
                            ).format("0,0.00")}
                          </td>
                          <td className="p-0 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400 text-right w-[38px] h-[38px] items-center justify-center flex">
                            {!isLast && (
                              <button
                                type="button"
                                onClick={wrapClick(() =>
                                  removeItem("layers", idx)
                                )}
                                className="inline-flex items-center rounded-full border border-transparent p-1 text-red-500 hover:bg-red-300 hover:text-red-900 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                              >
                                <XMarkIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </button>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-gray-50 dark:bg-gray-800 px-4 py-3 sm:py-4 sm:px-6 sm:flex sm:flex-row-reverse border-t border-gray-200">
          <button
            type="submit"
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
          >
            Next
          </button>
          <button
            type="button"
            onClick={wrapClick(handlePrevious)}
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
          >
            Previous
          </button>
          <button
            type="button"
            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 dark:border-gray-600 shadow-sm px-4 py-2 bg-white dark:bg-gray-900 text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 hover:dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(form.resetForm)}
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default TreatyDeductionForm;
